/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

export interface DMTelephoneCountry {
  n: string;
  c: string;
  i: string;
  f: string;
}

const DMTelephoneCountries: Array<DMTelephoneCountry> = [
{n:'Afghanistan (‫افغانستان‬‎)',c:'af',i:'93',f:'dm_flg c_af'},
{n:'Albania (Shqipëri)',c:'al',i:'355',f:'dm_flg c_al'},
{n:'Algeria (‫الجزائر‬‎)',c:'dz',i:'213',f:'dm_flg c_dz'},
{n:'American Samoa',c:'as',i:'1',f:'dm_flg c_as'},
{n:'Andorra',c:'ad',i:'376',f:'dm_flg c_ad'},
{n:'Angola',c:'ao',i:'244',f:'dm_flg c_ao'},
{n:'Anguilla',c:'ai',i:'1',f:'dm_flg c_ai'},
{n:'Antigua and Barbuda',c:'ag',i:'1',f:'dm_flg c_ag'},
{n:'Argentina',c:'ar',i:'54',f:'dm_flg c_ar'},
{n:'Armenia (Հայաստան)',c:'am',i:'374',f:'dm_flg c_am'},
{n:'Aruba',c:'aw',i:'297',f:'dm_flg c_aw'},
{n:'Australia',c:'au',i:'61',f:'dm_flg c_au'},
{n:'Austria (Österreich)',c:'at',i:'43',f:'dm_flg c_at'},
{n:'Azerbaijan (Azərbaycan)',c:'az',i:'994',f:'dm_flg c_az'},
{n:'Åland Islands',c:'ax',i:'358',f:'dm_flg c_ax'},
{n:'Bahamas',c:'bs',i:'1',f:'dm_flg c_bs'},
{n:'Bahrain (‫البحرين‬‎)',c:'bh',i:'973',f:'dm_flg c_bh'},
{n:'Bangladesh (বাংলাদেশ)',c:'bd',i:'880',f:'dm_flg c_bd'},
{n:'Barbados',c:'bb',i:'1',f:'dm_flg c_bb'},
{n:'Belarus (Беларусь)',c:'by',i:'375',f:'dm_flg c_by'},
{n:'Belgium (België)',c:'be',i:'32',f:'dm_flg c_be'},
{n:'Belize',c:'bz',i:'501',f:'dm_flg c_bz'},
{n:'Benin (Bénin)',c:'bj',i:'229',f:'dm_flg c_bj'},
{n:'Bermuda',c:'bm',i:'1',f:'dm_flg c_bm'},
{n:'Bhutan (འབྲུག)',c:'bt',i:'975',f:'dm_flg c_bt'},
{n:'Bolivia',c:'bo',i:'591',f:'dm_flg c_bo'},
{n:'Bosnia and Herzegovina (Босна и Херцеговина)',c:'ba',i:'387',f:'dm_flg c_ba'},
{n:'Botswana',c:'bw',i:'267',f:'dm_flg c_bw'},
{n:'Brazil (Brasil)',c:'br',i:'55',f:'dm_flg c_br'},
{n:'British Indian Ocean Territory',c:'io',i:'246',f:'dm_flg c_io'},
{n:'British Virgin Islands',c:'vg',i:'1',f:'dm_flg c_vg'},
{n:'Brunei',c:'bn',i:'673',f:'dm_flg c_bn'},
{n:'Bulgaria (България)',c:'bg',i:'359',f:'dm_flg c_bg'},
{n:'Burkina Faso',c:'bf',i:'226',f:'dm_flg c_bf'},
{n:'Burundi (Uburundi)',c:'bi',i:'257',f:'dm_flg c_bi'},
{n:'Cambodia (កម្ពុជា)',c:'kh',i:'855',f:'dm_flg c_kh'},
{n:'Cameroon (Cameroun)',c:'cm',i:'237',f:'dm_flg c_cm'},
{n:'Canada',c:'ca',i:'1',f:'dm_flg c_ca'},
{n:'Cape Verde (Kabu Verdi)',c:'cv',i:'238',f:'dm_flg c_cv'},
{n:'Caribbean Netherlands',c:'bq',i:'599',f:'dm_flg c_bq'},
{n:'Cayman Islands',c:'ky',i:'1',f:'dm_flg c_ky'},
{n:'Central African Republic (République Centrafricaine)',c:'cf',i:'236',f:'dm_flg c_cf'},
{n:'Chad (Tchad)',c:'td',i:'235',f:'dm_flg c_td'},
{n:'Chile',c:'cl',i:'56',f:'dm_flg c_cl'},
{n:'China (中国)',c:'cn',i:'86',f:'dm_flg c_cn'},
{n:'Christmas Island',c:'cx',i:'61',f:'dm_flg c_cx'},
{n:'Cocos (Keeling) Islands',c:'cc',i:'61',f:'dm_flg c_cc'},
{n:'Colombia',c:'co',i:'57',f:'dm_flg c_co'},
{n:'Comoros (‫جزر القمر‬‎)',c:'km',i:'269',f:'dm_flg c_km'},
{n:'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',c:'cd',i:'243',f:'dm_flg c_cd'},
{n:'Congo (Republic) (Congo-Brazzaville)',c:'cg',i:'242',f:'dm_flg c_cg'},
{n:'Cook Islands',c:'ck',i:'682',f:'dm_flg c_ck'},
{n:'Costa Rica',c:'cr',i:'506',f:'dm_flg c_cr'},
{n:'Côte d’Ivoire',c:'ci',i:'225',f:'dm_flg c_ci'},
{n:'Croatia (Hrvatska)',c:'hr',i:'385',f:'dm_flg c_hr'},
{n:'Cuba',c:'cu',i:'53',f:'dm_flg c_cu'},
{n:'Curaçao',c:'cw',i:'599',f:'dm_flg c_cw'},
{n:'Cyprus (Κύπρος)',c:'cy',i:'357',f:'dm_flg c_cy'},
{n:'Czech Republic (Česká republika)',c:'cz',i:'420',f:'dm_flg c_cz'},
{n:'Denmark (Danmark)',c:'dk',i:'45',f:'dm_flg c_dk'},
{n:'Djibouti',c:'dj',i:'253',f:'dm_flg c_dj'},
{n:'Dominica',c:'dm',i:'1',f:'dm_flg c_dm'},
{n:'Dominican Republic (República Dominicana)',c:'do',i:'1',f:'dm_flg c_do'},
{n:'Ecuador',c:'ec',i:'593',f:'dm_flg c_ec'},
{n:'Egypt (‫مصر‬‎)',c:'eg',i:'20',f:'dm_flg c_eg'},
{n:'El Salvador',c:'sv',i:'503',f:'dm_flg c_sv'},
{n:'Equatorial Guinea (Guinea Ecuatorial)',c:'gq',i:'240',f:'dm_flg c_gq'},
{n:'Eritrea',c:'er',i:'291',f:'dm_flg c_er'},
{n:'Estonia (Eesti)',c:'ee',i:'372',f:'dm_flg c_ee'},
{n:'Ethiopia',c:'et',i:'251',f:'dm_flg c_et'},
{n:'Falkland Islands (Islas Malvinas)',c:'fk',i:'500',f:'dm_flg c_fk'},
{n:'Faroe Islands (Føroyar)',c:'fo',i:'298',f:'dm_flg c_fo'},
{n:'Fiji',c:'fj',i:'679',f:'dm_flg c_fj'},
{n:'Finland (Suomi)',c:'fi',i:'358',f:'dm_flg c_fi'},
{n:'France',c:'fr',i:'33',f:'dm_flg c_fr'},
{n:'French Guiana (Guyane française)',c:'gf',i:'594',f:'dm_flg c_gf'},
{n:'French Polynesia (Polynésie française)',c:'pf',i:'689',f:'dm_flg c_pf'},
{n:'Gabon',c:'ga',i:'241',f:'dm_flg c_ga'},
{n:'Gambia',c:'gm',i:'220',f:'dm_flg c_gm'},
{n:'Georgia (საქართველო)',c:'ge',i:'995',f:'dm_flg c_ge'},
{n:'Germany (Deutschland)',c:'de',i:'49',f:'dm_flg c_de'},
{n:'Ghana (Gaana)',c:'gh',i:'233',f:'dm_flg c_gh'},
{n:'Gibraltar',c:'gi',i:'350',f:'dm_flg c_gi'},
{n:'Greece (Ελλάδα)',c:'gr',i:'30',f:'dm_flg c_gr'},
{n:'Greenland (Kalaallit Nunaat)',c:'gl',i:'299',f:'dm_flg c_gl'},
{n:'Grenada',c:'gd',i:'1',f:'dm_flg c_gd'},
{n:'Guadeloupe',c:'gp',i:'590',f:'dm_flg c_gp'},
{n:'Guam',c:'gu',i:'1',f:'dm_flg c_gu'},
{n:'Guatemala',c:'gt',i:'502',f:'dm_flg c_gt'},
{n:'Guernsey',c:'gg',i:'44',f:'dm_flg c_gg'},
{n:'Guinea (Guinée)',c:'gn',i:'224',f:'dm_flg c_gn'},
{n:'Guinea-Bissau (Guiné Bissau)',c:'gw',i:'245',f:'dm_flg c_gw'},
{n:'Guyana',c:'gy',i:'592',f:'dm_flg c_gy'},
{n:'Haiti',c:'ht',i:'509',f:'dm_flg c_ht'},
{n:'Honduras',c:'hn',i:'504',f:'dm_flg c_hn'},
{n:'Hong Kong (香港)',c:'hk',i:'852',f:'dm_flg c_hk'},
{n:'Hungary (Magyarország)',c:'hu',i:'36',f:'dm_flg c_hu'},
{n:'Iceland (Ísland)',c:'is',i:'354',f:'dm_flg c_is'},
{n:'India (भारत)',c:'in',i:'91',f:'dm_flg c_in'},
{n:'Indonesia',c:'id',i:'62',f:'dm_flg c_id'},
{n:'Iran (‫ایران‬‎)',c:'ir',i:'98',f:'dm_flg c_ir'},
{n:'Iraq (‫العراق‬‎)',c:'iq',i:'964',f:'dm_flg c_iq'},
{n:'Ireland',c:'ie',i:'353',f:'dm_flg c_ie'},
{n:'Isle of Man',c:'im',i:'44',f:'dm_flg c_im'},
{n:'Israel (‫ישראל‬‎)',c:'il',i:'972',f:'dm_flg c_il'},
{n:'Italy (Italia)',c:'it',i:'39',f:'dm_flg c_it'},
{n:'Jamaica',c:'jm',i:'1',f:'dm_flg c_jm'},
{n:'Japan (日本)',c:'jp',i:'81',f:'dm_flg c_jp'},
{n:'Jersey',c:'je',i:'44',f:'dm_flg c_je'},
{n:'Jordan (‫الأردن‬‎)',c:'jo',i:'962',f:'dm_flg c_jo'},
{n:'Kazakhstan (Казахстан)',c:'kz',i:'7',f:'dm_flg c_kz'},
{n:'Kenya',c:'ke',i:'254',f:'dm_flg c_ke'},
{n:'Kiribati',c:'ki',i:'686',f:'dm_flg c_ki'},
{n:'Kosovo',c:'xk',i:'383',f:'dm_flg c_xk'},
{n:'Kuwait (‫الكويت‬‎)',c:'kw',i:'965',f:'dm_flg c_kw'},
{n:'Kyrgyzstan (Кыргызстан)',c:'kg',i:'996',f:'dm_flg c_kg'},
{n:'Laos (ລາວ)',c:'la',i:'856',f:'dm_flg c_la'},
{n:'Latvia (Latvija)',c:'lv',i:'371',f:'dm_flg c_lv'},
{n:'Lebanon (‫لبنان‬‎)',c:'lb',i:'961',f:'dm_flg c_lb'},
{n:'Lesotho',c:'ls',i:'266',f:'dm_flg c_ls'},
{n:'Liberia',c:'lr',i:'231',f:'dm_flg c_lr'},
{n:'Libya (‫ليبيا‬‎)',c:'ly',i:'218',f:'dm_flg c_ly'},
{n:'Liechtenstein',c:'li',i:'423',f:'dm_flg c_li'},
{n:'Lithuania (Lietuva)',c:'lt',i:'370',f:'dm_flg c_lt'},
{n:'Luxembourg',c:'lu',i:'352',f:'dm_flg c_lu'},
{n:'Macau (澳門)',c:'mo',i:'853',f:'dm_flg c_mo'},
{n:'Macedonia (FYROM) (Македонија)',c:'mk',i:'389',f:'dm_flg c_mk'},
{n:'Madagascar (Madagasikara)',c:'mg',i:'261',f:'dm_flg c_mg'},
{n:'Malawi',c:'mw',i:'265',f:'dm_flg c_mw'},
{n:'Malaysia',c:'my',i:'60',f:'dm_flg c_my'},
{n:'Maldives',c:'mv',i:'960',f:'dm_flg c_mv'},
{n:'Mali',c:'ml',i:'223',f:'dm_flg c_ml'},
{n:'Malta',c:'mt',i:'356',f:'dm_flg c_mt'},
{n:'Marshall Islands',c:'mh',i:'692',f:'dm_flg c_mh'},
{n:'Martinique',c:'mq',i:'596',f:'dm_flg c_mq'},
{n:'Mauritania (‫موريتانيا‬‎)',c:'mr',i:'222',f:'dm_flg c_mr'},
{n:'Mauritius (Moris)',c:'mu',i:'230',f:'dm_flg c_mu'},
{n:'Mayotte',c:'yt',i:'262',f:'dm_flg c_yt'},
{n:'Mexico (México)',c:'mx',i:'52',f:'dm_flg c_mx'},
{n:'Micronesia',c:'fm',i:'691',f:'dm_flg c_fm'},
{n:'Moldova (Republica Moldova)',c:'md',i:'373',f:'dm_flg c_md'},
{n:'Monaco',c:'mc',i:'377',f:'dm_flg c_mc'},
{n:'Mongolia (Монгол)',c:'mn',i:'976',f:'dm_flg c_mn'},
{n:'Montenegro (Crna Gora)',c:'me',i:'382',f:'dm_flg c_me'},
{n:'Montserrat',c:'ms',i:'1',f:'dm_flg c_ms'},
{n:'Morocco (‫المغرب‬‎)',c:'ma',i:'212',f:'dm_flg c_ma'},
{n:'Mozambique (Moçambique)',c:'mz',i:'258',f:'dm_flg c_mz'},
{n:'Myanmar (Burma) (မြန်မာ)',c:'mm',i:'95',f:'dm_flg c_mm'},
{n:'Namibia (Namibië)',c:'na',i:'264',f:'dm_flg c_na'},
{n:'Nauru',c:'nr',i:'674',f:'dm_flg c_nr'},
{n:'Nepal (नेपाल)',c:'np',i:'977',f:'dm_flg c_np'},
{n:'Netherlands (Nederland)',c:'nl',i:'31',f:'dm_flg c_nl'},
{n:'New Caledonia (Nouvelle-Calédonie)',c:'nc',i:'687',f:'dm_flg c_nc'},
{n:'New Zealand',c:'nz',i:'64',f:'dm_flg c_nz'},
{n:'Nicaragua',c:'ni',i:'505',f:'dm_flg c_ni'},
{n:'Niger (Nijar)',c:'ne',i:'227',f:'dm_flg c_ne'},
{n:'Nigeria',c:'ng',i:'234',f:'dm_flg c_ng'},
{n:'Niue',c:'nu',i:'683',f:'dm_flg c_nu'},
{n:'Norfolk Island',c:'nf',i:'672',f:'dm_flg c_nf'},
{n:'North Korea (조선 민주주의 인민 공화국)',c:'kp',i:'850',f:'dm_flg c_kp'},
{n:'Northern Mariana Islands',c:'mp',i:'1',f:'dm_flg c_mp'},
{n:'Norway (Norge)',c:'no',i:'47',f:'dm_flg c_no'},
{n:'Oman (‫عُمان‬‎)',c:'om',i:'968',f:'dm_flg c_om'},
{n:'Pakistan (‫پاکستان‬‎)',c:'pk',i:'92',f:'dm_flg c_pk'},
{n:'Palau',c:'pw',i:'680',f:'dm_flg c_pw'},
{n:'Palestine (‫فلسطين‬‎)',c:'ps',i:'970',f:'dm_flg c_ps'},
{n:'Panama (Panamá)',c:'pa',i:'507',f:'dm_flg c_pa'},
{n:'Papua New Guinea',c:'pg',i:'675',f:'dm_flg c_pg'},
{n:'Paraguay',c:'py',i:'595',f:'dm_flg c_py'},
{n:'Peru (Perú)',c:'pe',i:'51',f:'dm_flg c_pe'},
{n:'Philippines',c:'ph',i:'63',f:'dm_flg c_ph'},
{n:'Poland (Polska)',c:'pl',i:'48',f:'dm_flg c_pl'},
{n:'Portugal',c:'pt',i:'351',f:'dm_flg c_pt'},
{n:'Puerto Rico',c:'pr',i:'1',f:'dm_flg c_pr'},
{n:'Qatar (‫قطر‬‎)',c:'qa',i:'974',f:'dm_flg c_qa'},
{n:'Réunion (La Réunion)',c:'re',i:'262',f:'dm_flg c_re'},
{n:'Romania (România)',c:'ro',i:'40',f:'dm_flg c_ro'},
{n:'Russia (Россия)',c:'ru',i:'7',f:'dm_flg c_ru'},
{n:'Rwanda',c:'rw',i:'250',f:'dm_flg c_rw'},
{n:'Saint Barthélemy',c:'bl',i:'590',f:'dm_flg c_bl'},
{n:'Saint Helena',c:'sh',i:'290',f:'dm_flg c_sh'},
{n:'Saint Kitts and Nevis',c:'kn',i:'1',f:'dm_flg c_kn'},
{n:'Saint Lucia',c:'lc',i:'1',f:'dm_flg c_lc'},
{n:'Saint Martin (Saint-Martin (partie française))',c:'mf',i:'590',f:'dm_flg c_mf'},
{n:'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',c:'pm',i:'508',f:'dm_flg c_pm'},
{n:'Saint Vincent and the Grenadines',c:'vc',i:'1',f:'dm_flg c_vc'},
{n:'Samoa',c:'ws',i:'685',f:'dm_flg c_ws'},
{n:'San Marino',c:'sm',i:'378',f:'dm_flg c_sm'},
{n:'São Tomé and Príncipe (São Tomé e Príncipe)',c:'st',i:'239',f:'dm_flg c_st'},
{n:'Saudi Arabia (‫المملكة العربية السعودية‬‎)',c:'sa',i:'966',f:'dm_flg c_sa'},
{n:'Senegal (Sénégal)',c:'sn',i:'221',f:'dm_flg c_sn'},
{n:'Serbia (Србија)',c:'rs',i:'381',f:'dm_flg c_rs'},
{n:'Seychelles',c:'sc',i:'248',f:'dm_flg c_sc'},
{n:'Sierra Leone',c:'sl',i:'232',f:'dm_flg c_sl'},
{n:'Singapore',c:'sg',i:'65',f:'dm_flg c_sg'},
{n:'Sint Maarten',c:'sx',i:'1',f:'dm_flg c_sx'},
{n:'Slovakia (Slovensko)',c:'sk',i:'421',f:'dm_flg c_sk'},
{n:'Slovenia (Slovenija)',c:'si',i:'386',f:'dm_flg c_si'},
{n:'Solomon Islands',c:'sb',i:'677',f:'dm_flg c_sb'},
{n:'Somalia (Soomaaliya)',c:'so',i:'252',f:'dm_flg c_so'},
{n:'South Africa',c:'za',i:'27',f:'dm_flg c_za'},
{n:'South Korea (대한민국)',c:'kr',i:'82',f:'dm_flg c_kr'},
{n:'South Sudan (‫جنوب السودان‬‎)',c:'ss',i:'211',f:'dm_flg c_ss'},
{n:'Spain (España)',c:'es',i:'34',f:'dm_flg c_es'},
{n:'Sri Lanka (ශ්‍රී ලංකාව)',c:'lk',i:'94',f:'dm_flg c_lk'},
{n:'Sudan (‫السودان‬‎)',c:'sd',i:'249',f:'dm_flg c_sd'},
{n:'Suriname',c:'sr',i:'597',f:'dm_flg c_sr'},
{n:'Svalbard and Jan Mayen',c:'sj',i:'47',f:'dm_flg c_sj'},
{n:'Swaziland',c:'sz',i:'268',f:'dm_flg c_sz'},
{n:'Sweden (Sverige)',c:'se',i:'46',f:'dm_flg c_se'},
{n:'Switzerland (Schweiz)',c:'ch',i:'41',f:'dm_flg c_ch'},
{n:'Syria (‫سوريا‬‎)',c:'sy',i:'963',f:'dm_flg c_sy'},
{n:'Taiwan (台灣)',c:'tw',i:'886',f:'dm_flg c_tw'},
{n:'Tajikistan',c:'tj',i:'992',f:'dm_flg c_tj'},
{n:'Tanzania',c:'tz',i:'255',f:'dm_flg c_tz'},
{n:'Thailand (ไทย)',c:'th',i:'66',f:'dm_flg c_th'},
{n:'Timor-Leste',c:'tl',i:'670',f:'dm_flg c_tl'},
{n:'Togo',c:'tg',i:'228',f:'dm_flg c_tg'},
{n:'Tokelau',c:'tk',i:'690',f:'dm_flg c_tk'},
{n:'Tonga',c:'to',i:'676',f:'dm_flg c_to'},
{n:'Trinidad and Tobago',c:'tt',i:'1',f:'dm_flg c_tt'},
{n:'Tunisia (‫تونس‬‎)',c:'tn',i:'216',f:'dm_flg c_tn'},
{n:'Turkey (Türkiye)',c:'tr',i:'90',f:'dm_flg c_tr'},
{n:'Turkmenistan',c:'tm',i:'993',f:'dm_flg c_tm'},
{n:'Turks and Caicos Islands',c:'tc',i:'1',f:'dm_flg c_tc'},
{n:'Tuvalu',c:'tv',i:'688',f:'dm_flg c_tv'},
{n:'U.S. Virgin Islands',c:'vi',i:'1',f:'dm_flg c_vi'},
{n:'Uganda',c:'ug',i:'256',f:'dm_flg c_ug'},
{n:'Ukraine (Україна)',c:'ua',i:'380',f:'dm_flg c_ua'},
{n:'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',c:'ae',i:'971',f:'dm_flg c_ae'},
{n:'United Kingdom',c:'gb',i:'44',f:'dm_flg c_gb'},
{n:'United States',c:'us',i:'1',f:'dm_flg c_us'},
{n:'Uruguay',c:'uy',i:'598',f:'dm_flg c_uy'},
{n:'Uzbekistan (Oʻzbekiston)',c:'uz',i:'998',f:'dm_flg c_uz'},
{n:'Vanuatu',c:'vu',i:'678',f:'dm_flg c_vu'},
{n:'Vatican City (Città del Vaticano)',c:'va',i:'39',f:'dm_flg c_va'},
{n:'Venezuela',c:'ve',i:'58',f:'dm_flg c_ve'},
{n:'Vietnam (Việt Nam)',c:'vn',i:'84',f:'dm_flg c_vn'},
{n:'Wallis and Futuna (Wallis-et-Futuna)',c:'wf',i:'681',f:'dm_flg c_wf'},
{n:'Western Sahara (‫الصحراء الغربية‬‎)',c:'eh',i:'212',f:'dm_flg c_eh'},
{n:'Yemen (‫اليمن‬‎)',c:'ye',i:'967',f:'dm_flg c_ye'},
{n:'Zambia',c:'zm',i:'260',f:'dm_flg c_zm'},
{n:'Zimbabwe',c:'zw',i:'263',f:'dm_flg c_zw'}];

export { DMTelephoneCountries };
