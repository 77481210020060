import { AppPath } from '@prolex/shared';

export const paths = {
	// Landing Page
	landingPage: {
		slug: '',
		fullPath: ''
	} as AppPath,
	cookiePolicy: {
		slug: 'politica-folosirii-cookieurilor',
		fullPath: '/politica-folosirii-cookieurilor'
	} as AppPath,
	termsAndConditions: {
		slug: 'termeni-si-conditii',
		fullPath: '/termeni-si-conditii'
	} as AppPath,
	privacyPolicy: {
		slug: 'politica-de-confidentialitate',
		fullPath: '/politica-de-confidentialitate'
	} as AppPath,
	// Authentication & Registration
	login: {
		slug: 'autentificare',
		fullPath: '/autentificare'
	} as AppPath,
	register: {
		slug: 'inregistrare',
		fullPath: '/inregistrare'
	} as AppPath,
	existingMemberRegister: {
		slug: 'inregistrare-membru-existent',
		fullPath: '/inregistrare-membru-existent'
	} as AppPath,
	createPassword: {
		slug: 'creare-parola',
		fullPath: '/creare-parola'
	} as AppPath,
	forgotPassword: {
		slug: 'am-uitat-parola',
		fullPath: '/am-uitat-parola'
	} as AppPath,
	resetPassword: {
		slug: 'resetare-parola',
		fullPath: '/resetare-parola'
	} as AppPath,
	// Common
	dashboard: {
		slug: 'aplicatie',
		fullPath: '/aplicatie'
	} as AppPath,
	profile: {
		slug: 'profil',
		fullPath: '/aplicatie/profil'
	} as AppPath,
	settings: {
		slug: 'setari',
		fullPath: '/aplicatie/setari'
	} as AppPath,
	notifications: {
		slug: 'notificari',
		fullPath: '/aplicatie/notificari'
	} as AppPath,
	// Global
	error: {
		slug: 'eroare',
		fullPath: '/eroare'
	} as AppPath,
	unauthorized: {
		slug: 'acces-neautorizat',
		fullPath: '/acces-neautorizat'
	} as AppPath,
	// Members
	payments: {
		slug: 'plati',
		fullPath: '/aplicatie/plati'
	} as AppPath,
	paymentsNewMembership: {
		slug: 'plata-noua',
		fullPath: '/aplicatie/plati/plata-noua'
	} as AppPath,
	paymentsNewBeneficiaries: {
		slug: 'plata-noua-abonamente',
		fullPath: '/aplicatie/plati/plata-noua-abonamente'
	} as AppPath,
	paymentsReturn: {
		slug: 'inapoi',
		fullPath: '/aplicatie/plati/inapoi'
	} as AppPath,
	paymentsHistory: {
		slug: 'istoric',
		fullPath: '/aplicatie/plati/istoric'
	} as AppPath,
	subscriptions: {
		slug: 'abonamente',
		fullPath: '/aplicatie/abonamente'
	} as AppPath,
	vouchers: {
		slug: 'vouchere',
		fullPath: '/aplicatie/vouchere'
	} as AppPath,
	memberPersonalTrial: {
		slug: 'proces-personal',
		fullPath: '/aplicatie/proces-personal'
	} as AppPath,
	memberGenericTrial: {
		slug: 'proces-generic',
		fullPath: '/aplicatie/proces-generic'
	} as AppPath,
	voucherManagerManagement: {
		slug: 'management-vouchere',
		fullPath: '/aplicatie/management-vouchere'
	} as AppPath,
	// Admin
	admin: {
		slug: 'administrare',
		fullPath: '/administrare'
	} as AppPath,
	selectAdminAction: {
		slug: 'administrare/selectie-actiune',
		fullPath: '/aplicatie/administrare/selectie-actiune'
	} as AppPath,
	genericTrialsEdit: {
		slug: 'administrare/procese-generice',
		fullPath: '/aplicatie/administrare/procese-generice'
	} as AppPath,
	faq: {
		slug: 'administrare/intrebari-frecvente',
		fullPath: '/aplicatie/administrare/intrebari-frecvente'
	} as AppPath,
	categoryContacts: {
		slug: 'administrare/persoane-contact',
		fullPath: '/aplicatie/administrare/persoane-contact'
	} as AppPath,
	clubMobileAppPrivacyPolicy: {
		slug: 'administrare/politica-de-confidentialitate-mobile',
		fullPath: '/aplicatie/administrare/politica-de-confidentialitate-mobile'
	} as AppPath,
	clubMobileCasaDracula: {
		slug: 'administrare/casa-dracula-mobile',
		fullPath: '/aplicatie/administrare/casa-dracula-mobile'
	},
	clubMobileAppAbout: {
		slug: 'administrare/despre-aplicatie-mobile',
		fullPath: '/aplicatie/administrare/despre-aplicatie-mobile'
	} as AppPath,
	beneficiaryServices: {
		slug: 'administrare/servicii-beneficiari',
		fullPath: '/aplicatie/administrare/servicii-beneficiari'
	} as AppPath,
	beneficiariesList: {
		slug: 'administrare/raportare-beneficiari',
		fullPath: '/aplicatie/administrare/raportare-beneficiari'
	} as AppPath,
	paymentsReport: {
		slug: 'administrare/raportare-plati',
		fullPath: '/aplicatie/administrare/raportare-plati'
	} as AppPath,
	voucherSettings: {
		slug: 'administrare/setari-voucher',
		fullPath: '/aplicatie/administrare/setari-voucher'
	} as AppPath,
	vouchersManagement: {
		slug: 'administrare/management-vouchere',
		fullPath: '/aplicatie/administrare/management-vouchere'
	} as AppPath,
	clubMobileReferenceCodeInfo: {
		slug: 'administrare/informatii-cod-referinta-mobile',
		fullPath: '/aplicatie/administrare/informatii-cod-referinta-mobile'
	}
};
