/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[toggleFullscreen]'
})
export class DMToggleFullscreenDirective {
  private _isFullScreenActive = false;

  @HostListener('click') public onClick() {
    this.toggleFullscreen();
  }

  public openFullscreen(): void {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen)
      void docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) /* Firefox */
      void docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) /* Chrome, Safari and Opera */
      void docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) /* IE/Edge */
      void docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    this._isFullScreenActive = true;
  }

  public closeFullscreen(): void {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen)
      void docWithBrowsersExitFunctions.exitFullscreen();
    else if (docWithBrowsersExitFunctions.mozCancelFullScreen) /* Firefox */
      void docWithBrowsersExitFunctions.mozCancelFullScreen();
    else if (docWithBrowsersExitFunctions.webkitExitFullscreen) /* Chrome, Safari and Opera */
      void docWithBrowsersExitFunctions.webkitExitFullscreen();
    else if (docWithBrowsersExitFunctions.msExitFullscreen) /* IE/Edge */
      void docWithBrowsersExitFunctions.msExitFullscreen();
    this._isFullScreenActive = false;
  }

  public toggleFullscreen(): void {
    if (this._isFullScreenActive)
      this.closeFullscreen();
    else
      this.openFullscreen();
  }
}
