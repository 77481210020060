/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { NgModule, ModuleWithProviders, Optional, Inject } from '@angular/core';
import { GtagConfig, GtagConfigToken, gtagFactory } from './gtag-factory';
import { GtagService, GTAG } from './gtag.service';
import { GtagDirective } from './gtag.directive';

@NgModule({
  providers: [ GtagService ],
  declarations: [ GtagDirective ],
  exports: [ GtagDirective ]
})
export class GtagModule {
  public static init(config: GtagConfig): ModuleWithProviders<GtagModule> {
    return {
      ngModule: GtagModule,
      providers: [
        {
          provide: GtagConfigToken,
          useValue: config
        },
        {
          provide: GTAG,
          useFactory: gtagFactory,
          deps: [[ new Optional(), new Inject(GtagConfigToken) ]]
        }
      ]
    };
  }
}
