import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService, SyncfusionDataType } from '@dynamicmedia/syncfusion';
import { DMFunctions } from '@dynamicmedia/shared';
import { BackendUrls, WorkplaceType } from '../../enums';
import { Functions } from '../../functions';
import { CommonApiConfigService, CommonApiServiceConfig } from './common-api-service.config';

@Injectable()
export class CommonApiService {
	constructor(
		@Inject(CommonApiConfigService) private readonly config: CommonApiServiceConfig,
		private _httpClient: HttpClient,
		private _dialogService: DialogService)
	{
		this.config = this.config || { backendApiUrl: 'http://localhost' };
	}

	public getRomaniaCountry(): Observable<Array<SyncfusionDataType>> {
		return this._httpClient.get<Array<SyncfusionDataType>>(
			`${this.config?.backendApiUrl}${BackendUrls.commonApiUrl}countries/romania`
		).pipe(
			catchError(error => {
				this._dialogService.error(Functions.handleError(error));
				return []; // return empty array in order for Syncfusion components to not throw errors on databinding
			})
		);
	}

	public getRomaniaCounties(): Observable<Array<SyncfusionDataType>> {
		return this._httpClient.post<Array<SyncfusionDataType>>(
			`${this.config?.backendApiUrl}${BackendUrls.commonApiUrl}counties/romania/?simpleDto=true`,
			{} // must set body to empty object for DataManagerRequest compatibility in backend
		).pipe(
			catchError(error => {
				this._dialogService.error(Functions.handleError(error));
				return []; // return empty array in order for Syncfusion components to not throw errors on databinding
			})
		);
	}

	public getCounties(countryId: string): Observable<Array<SyncfusionDataType>> {
		return this._httpClient.post<Array<SyncfusionDataType>>(
			`${this.config?.backendApiUrl}${BackendUrls.commonApiUrl}counties/?countryId=${countryId}&simpleDto=true`,
			{} // must set body to empty object for DataManagerRequest compatibility in backend
		).pipe(
			catchError(error => {
				this._dialogService.error(Functions.handleError(error));
				return []; // return empty array in order for Syncfusion components to not throw errors on databinding
			})
		);
	}

	public getLocalities(countyId: string): Observable<Array<SyncfusionDataType>> {
		return this._httpClient.post<Array<SyncfusionDataType>>(
			`${this.config?.backendApiUrl}${BackendUrls.commonApiUrl}localities/?countyId=${countyId}&simpleDto=true`,
			{} // must set body to empty object for DataManagerRequest compatibility in backend
		).pipe(
			catchError(error => {
				this._dialogService.error(Functions.handleError(error));
				return []; // return empty array in order for Syncfusion components to not throw errors on databinding
			})
		);
	}

	public getWorkplaces(categoryId: string, parentId: string | null = null): Observable<Array<SyncfusionDataType>> {
		const parentIdParam = DMFunctions.isNullOrEmpty(parentId) ? '' : `&parentId=${parentId}`;
		return this._httpClient.post<Array<SyncfusionDataType>>(
			`${this.config?.backendApiUrl}${BackendUrls.commonApiUrl}workplaces/?categoryId=${categoryId}${parentIdParam}&simpleDto=true`,
			{} // must set body to empty object for DataManagerRequest compatibility in backend
		).pipe(
			catchError(error => {
				this._dialogService.error(Functions.handleError(error));
				return []; // return empty array in order for Syncfusion components to not throw errors on databinding
			})
		);
	}

	public getCategories(categoryType: WorkplaceType): Observable<Array<SyncfusionDataType>> {
		return this._httpClient.post<Array<SyncfusionDataType>>(
			`${this.config?.backendApiUrl}${BackendUrls.membersApiUrl}categories/?type=${categoryType}`,
			{} // must set body to empty object for DataManagerRequest compatibility in backend
		).pipe(
			catchError(error => {
				this._dialogService.error(Functions.handleError(error));
				return []; // return empty array in order for Syncfusion components to not throw errors on databinding
			})
		);
	}
}
