export const locale = {
	lang: 'ro',
	data: {
	  title: 'Înregistrare membru',
		steps: {
			personalData: 'Date personale',
			contactData: 'Date contact',
      workplace: 'Loc de muncă',
			email: 'Email',
			password: 'Parolă',
			validation: 'Verificare',
		}
	}
};
