/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IDMLoadingConfig } from './loading-config';
import { DMLoadingComponent } from './loading.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DMLoadingComponent],
  exports: [DMLoadingComponent],
  entryComponents: [DMLoadingComponent]
})
export class DMLoadingModule {
  static forRoot(loadingConfig: IDMLoadingConfig): ModuleWithProviders<DMLoadingModule> {
    return {
      ngModule: DMLoadingModule,
      providers: [{ provide: 'loadingConfig', useValue: loadingConfig }]
    };
  }
}
