import { Component, Output, Input, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'signature-pad,[SignaturePad]',
	templateUrl: './signature-pad.component.html',
	styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements AfterViewInit {
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() public onSave = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() public onClear = new EventEmitter();

	private _fromDataURL!: string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private _fromData: any;

	public _width = 200;
	public _height = 200;
	public _hideFooter = false;
	public _label = 'Semnează deasupra';

	private _canvas!: HTMLCanvasElement;
	private _signaturePad!: SignaturePad;

	constructor(private _el: ElementRef) {
		this._fromDataURL = null;
	}

	@Input() public set width(value:number) {
		this._width = value;
	}

	@Input() public set height(value:number) {
		this._height = value;
	}

	@Input() public set fromDataURL(value: string) {
		this._fromDataURL = value;
		this._signaturePad.fromDataURL(this._fromDataURL);
	}

	@Input() public set fromData(value: string) {
		this._fromData = value;
		this._signaturePad.fromData(this._fromData);
	}

	@Input() public set hideFooter(value:boolean) {
		this._hideFooter = value;
	}

	@Input() public set label(value:string) {
		this._label = value;
	}

	public ngAfterViewInit() {
		this._canvas = this._el.nativeElement.querySelector('canvas');
		this._signaturePad = new SignaturePad(this._canvas);
	}

	public onClearClick() {
		this._signaturePad.clear();
		this.onClear.emit();
	}

	public onSaveClick() {
		this.onSave.emit(this._signaturePad.toDataURL());
	}

	public isEmpty(): boolean {
		return this._signaturePad.isEmpty();
	}

	public saveSignature(): string {
		return this._signaturePad.toDataURL();
	}
}
