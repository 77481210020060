import { HttpErrorResponse } from '@angular/common/http';
import { AvailableLanguages } from './enums';

/* eslint-disable @typescript-eslint/no-explicit-any */
export abstract class Functions {
	public static parseAvailableLanguage(typeString: string | null | undefined): AvailableLanguages | null {
		if (typeString === null || typeString === undefined)
			return AvailableLanguages.ro;
		const type = AvailableLanguages[typeString as keyof typeof AvailableLanguages];
		if (type === undefined)
			return AvailableLanguages.ro;
		return type;
	}

	public static handleError(error: any) {
	  if (Array.isArray(error) && error.length > 0)
      error = error[0];
		const applicationError = error && error.headers && error.headers.get('Application-Error');
		// either applicationError in header or model error in body
		if (applicationError)
			return applicationError;
		let modelStateErrors: string | null = '';
		if (error instanceof Error)
			error = error.message;
		else if (error instanceof HttpErrorResponse) {
			if (typeof error.error === 'string')
				error = error.error;
			else {
				if (error.message.indexOf('Http failure response', 0) !== -1)
					error = 'Serverul nu răspunde';
			}
		}
		// else if (error instanceof ProgressEvent || error === 'PROLEX_ERROR_SERVER_NOT_RESPONDING' || error == null)
		// 	error = translateService.instant('Global.Errors.ServerNotResponding');
		else if (error && error.error) {
		  if (error.error instanceof XMLHttpRequest) {
		    if (error.error.responseText)
		      error = error.error.responseText;
        else {
          const status = error.error.status as number;
          switch (status) {
            case 0:
              error = 'Serverul nu răspunde. Te rog să încerci din nou.';
              break;
            case 400:
              error = 'Cererea către server este invalidă.';
              break;
            case 500:
              error = 'Eroare internă server.';
              break;
            case 504:
              error = 'Serverul nu a răspuns în timp util.';
              break;
            default:
              error = `Eroare server: Cod ${status}`;
          }
        }
      }
		  else {
        for (const key in error.error) {
          // eslint-disable-next-line no-prototype-builtins
          if (error.error && error.error.hasOwnProperty(key) && error.error[key])
            modelStateErrors += error.error[key] + '\n';
        }
        modelStateErrors = modelStateErrors == '' ? null : modelStateErrors;
        error = modelStateErrors;
      }
		}
		else if (error && error.message)
			error = error.message;
		return error;
	}
}
