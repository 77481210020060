/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpHeadersInterceptor } from './http-headers.interceptor';
import { HttpHeadersInterceptorConfig, HttpHeadersInterceptorConfigService } from './http-headers-interceptor.config';

@NgModule(
// 	{
// 	providers: [
// 		{
// 			provide: HTTP_INTERCEPTORS,
// 			useClass: HttpHeadersInterceptor,
// 			multi: true
// 		}
// 	]
// }
)
export class HttpHeadersInterceptorModule {
  static forRoot(config: HttpHeadersInterceptorConfig): ModuleWithProviders<HttpHeadersInterceptorModule> {
    return {
      ngModule: HttpHeadersInterceptorModule,
      providers: [
	      {
		      provide: HTTP_INTERCEPTORS,
		      useClass: HttpHeadersInterceptor,
		      multi: true
	      },
        { provide: HttpHeadersInterceptorConfigService, useValue: config }
      ]
    };
  }
}
