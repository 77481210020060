import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { paths } from '../../../app-paths';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'landing-page-terms-and-conditions',
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
	// Public members
	public routerPaths = paths;
	public backendUrl = environment.backendUrl
}
