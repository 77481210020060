<div class="authentication-box">
	<div class="text-center"><img src="assets/images/logo.png" alt="ProLex Logo"></div>
	<div class="card mt-4 p-3">
		<h4 class="text-center">{{ 'title' | translate }}</h4>
		<aw-wizard #registerWizard navBarLayout="large-filled-symbols" [disableNavigationBar]="true">
			<aw-wizard-step stepTitle="{{ 'steps.personalData' | translate }}" [canExit]="personalDataCanExit" (stepEnter)="personalDataEnter()">
				<ng-template awWizardStepSymbol let-wizardStep="wizardStep"><i class="cis-address-card"></i></ng-template>
				<members-auth-personal-data [(model)]="wizardModel"></members-auth-personal-data>
			</aw-wizard-step>
			<aw-wizard-step stepTitle="{{ 'steps.contactData' | translate }}" [canExit]="contactDataCanExit" (stepEnter)="contactDataEnter()">
				<ng-template awWizardStepSymbol let-wizardStep="wizardStep"><i class="cis-contact-phone"></i></ng-template>
        <members-auth-contact-info [(model)]="wizardModel"></members-auth-contact-info>
			</aw-wizard-step>
			<aw-wizard-step stepTitle="{{ 'steps.workplace' | translate }}" [canExit]="workplaceDataCanExit" (stepEnter)="workplaceDataEnter()">
				<ng-template awWizardStepSymbol let-wizardStep="wizardStep"><i class="cis-building-business"></i></ng-template>
				<members-auth-workplace-info [(model)]="wizardModel"></members-auth-workplace-info>
			</aw-wizard-step>
			<aw-wizard-step stepTitle="{{ 'steps.password' | translate }}" [canExit]="passwordCanExit" (stepEnter)="passwordEnter()">
				<ng-template awWizardStepSymbol let-wizardStep="wizardStep"><i class="cis-check-circle"></i></ng-template>
				<members-auth-verify-set-password [model]="wizardModel"></members-auth-verify-set-password>
      </aw-wizard-step>
		</aw-wizard>
	</div>
</div>
