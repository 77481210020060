import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Output, EventEmitter,
	OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ChangeEventArgs, ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ReactiveFormsValidation } from '@dynamicmedia/shared';
import { DialogService, EjsPhoneInputValidation, SpinnerService, SyncfusionDataType } from '@dynamicmedia/syncfusion';
import { Functions, CommonApiService, BackendResponse } from '@prolex/shared';
import { RegistrationMember } from '../../../../shared/models';
import { RegisterService, TranslationLoaderService } from '../../../../shared/services';
import { locale as romanian } from './i18n/ro';

@Component({
	selector: 'members-auth-contact-info',
	templateUrl: './contact-info.component.html',
	styleUrls: ['./contact-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactInfoComponent implements OnInit, AfterViewInit {
	// Inputs
	@Input() public model: RegistrationMember;
	// Outputs
	@Output() public modelChange = new EventEmitter();
	// Components
	@ViewChild('county') private _countyComboBox: ComboBoxComponent;
  @ViewChild('locality') private _localityComboBox: ComboBoxComponent;
	@ViewChild('contactInfoSpinner') private _spin: ElementRef;
	// Public members
  public contactInfoForm: FormGroup;
	public countiesDataSource: Observable<Array<SyncfusionDataType>>;
	public localitiesDataSource: Observable<Array<SyncfusionDataType>>;
	public validatedEmail = false;
	// Private members
	private _mailSent = false;

	//#region Form controls
	public get countyControl(): AbstractControl {
	  return this.contactInfoForm && this.contactInfoForm.get('countyId');
  }

  public get localityControl(): AbstractControl {
	  return this.contactInfoForm && this.contactInfoForm.get('localityId');
  }

  public get addressControl(): AbstractControl {
	  return this.contactInfoForm && this.contactInfoForm.get('address');
  }

  public get emailControl(): AbstractControl {
	  return this.contactInfoForm && this.contactInfoForm.get('email');
  }

  public get emailCodeControl(): AbstractControl {
		return this.contactInfoForm && this.contactInfoForm.get('emailCode');
  }

  public get phoneNumberControl(): AbstractControl {
		return this.contactInfoForm && this.contactInfoForm.get('phoneNumber');
	}

	constructor(
		private _translationService: TranslationLoaderService,
		private _registerService: RegisterService,
		private _spinnerService: SpinnerService,
	  private _dialogService: DialogService,
	  private _commonApiService: CommonApiService,
	  private _formBuilder: FormBuilder,
		private _changeDetector: ChangeDetectorRef)
	{
    this._translationService.loadRomanianTranslations(romanian);
    this.contactInfoForm = this._formBuilder.group({
      countyId: [null, ReactiveFormsValidation.required],
      localityId: [null, ReactiveFormsValidation.required],
      address: [null, ReactiveFormsValidation.required],
      email: [null, ReactiveFormsValidation.emailInvalid(true)],
			phoneNumber: [null, EjsPhoneInputValidation.validNumber(true)],
	    emailCode: [null, ReactiveFormsValidation.required]
    });
		this.contactInfoForm.valueChanges.subscribe(() => {
			this._changeDetector.markForCheck();
		});
		this.emailControl.valueChanges.subscribe(() => {
			this.validatedEmail = false;
		});
	}

	public ngOnInit() {
		this.countiesDataSource = this._commonApiService.getRomaniaCounties();
	}

	public ngAfterViewInit() {
		this._spinnerService.createSpinner({
			target: this._spin.nativeElement,
			label: this._translationService.getTranslation('labelsContact.emailSpinner')
		});
	}

	public async countyOnValueChanged(args: ChangeEventArgs) {
		if (args.value === null || args.value === undefined) {
			this.addressControl.setValue(null);
			this.addressControl.disable({ onlySelf: true });
			this.localityControl.setValue(null);
			this.localityControl.disable({ onlySelf: true });
			return;
		}
		this.localityControl.enable({ onlySelf: true });
		this.localityControl.setValue(null);
		this.localitiesDataSource = this._commonApiService.getLocalities(args.value as string);
		// this._localityComboBox.dataBind();
    // clear the existing selection in city ComboBox
		this.addressControl.setValue(null);
		this.addressControl.disable({ onlySelf: true });
  }

	public localityOnValueChanged(args: ChangeEventArgs) {
		if (args.value === null || args.value === undefined) {
			this.addressControl.setValue(null);
			this.addressControl.disable({ onlySelf: true });
			return;
		}
		this.addressControl.enable({ onlySelf: true });
  }

	private _sendEmailVerification(): void {
		this._spinnerService.showSpinner(this._spin.nativeElement);
		this._registerService.sendValidationEmail(this.emailControl.value).subscribe({
			next: (result: BackendResponse) => {
				this._mailSent = result.isSuccess;
				this._spinnerService.hideSpinner(this._spin.nativeElement);
				this._changeDetector.markForCheck();
				if (result.isSuccess)
					this._dialogService.information(result.message);
				else
					this._dialogService.error(result.message);
			},
			error: (e) => {
				this._spinnerService.hideSpinner(this._spin.nativeElement);
				this._dialogService.error(Functions.handleError(e));
			}
		});
	}

	public get isMailSent(): boolean {
		return this._mailSent || (this.model && this.model.emailVerified);
	}

	public verifyEmail(): void {
		this._registerService.verifyEmail(this.emailControl.value, this.emailCodeControl.value)
		.subscribe({
			next: (result: BackendResponse) => {
				this.validatedEmail = result.isSuccess;
				if (result.isSuccess)
					this._dialogService.information(result.message);
				else
					this._dialogService.warning(result.message);
				this._changeDetector.markForCheck();
			},
			error: (e) => {
				this._dialogService.error(Functions.handleError(e));
			}
		});
	}

	public emailExistsValidation(): void {
		this._spinnerService.showSpinner(this._spin.nativeElement);
		this._registerService.emailExists(this.emailControl.value).subscribe({
			next: (result: boolean) => {
				this._spinnerService.hideSpinner(this._spin.nativeElement);
				if (result === true) {
					this.emailControl.setErrors({ emailExists: true });
					this._changeDetector.markForCheck();
				}
				else
					this._sendEmailVerification()
			},
			error: (e) => {
				this._spinnerService.hideSpinner(this._spin.nativeElement);
				this._dialogService.error(Functions.handleError(e));
			}
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public prev(event: any): void {
		// FIX: prevent going to previous section when enter is pressed (screenX and screenY = 0)
		if (event.screenX == 0)
			return;
		this.model.contactInfoVerified = true;
	}

	public next(): void {
  	this.model.countyId = this.countyControl.value;
		this.model.countyName = this._countyComboBox.text;
		this.model.localityId = this.localityControl.value;
		this.model.localityName = this._localityComboBox.text;
		this.model.address = this.addressControl.value;
		this.model.email = this.emailControl.value;
		this.model.emailVerified = this.validatedEmail;
		this.model.phoneNumber = this.phoneNumberControl.value;
		this.model.contactInfoVerified = true;
		this.modelChange.emit(this.model);
	}
}
