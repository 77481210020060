/* eslint-disable max-len */
export const locale = {
  lang: 'ro',
  data: {
    labels: {
      profile: 'Profil',
      notifications: 'Notificări',
      settings: 'Setări',
      logout: 'Deautentificare',
    },
    logoutMessage: 'Ești sigur că vrei să te deautentifici?'
  }
};
