<footer class="prolex-site-footer">
	<div class="container">
		<div class="inner-container">
				<div class="row">
						<div class="col-lg-3 col-md-6 col-sm-12">
							<div class="prolex-footer-widget">
								<a href="/"><img src="assets/images/logo.png" style="width:160px;height:auto;" alt="Pro Lex logo" /></a>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-12">
								<div class="prolex-footer-widget">
										<div class="widget-title">
												<h3>Contact</h3>
										</div>
										<ul class="contact-list">
												<li><i class="icofont-ui-touch-phone"></i> <a href="tel:+40213161412" style="padding-left:5px;">+4 021 316 1412</a></li>
												<li><i class="icofont-mail"></i> <a href="mailto:office@prolex.ro" style="padding-left:5px;">office@prolex.ro</a></li>
												<li><i class="icofont-building-alt"></i> Bucureşti, Str. Mendeleev,<br>nr. 36-38, etaj 8, Sector 1</li>
										</ul>
								</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-12">
								<div class="prolex-footer-widget">
										<div class="widget-title">
												<h3>Link-uri</h3>
										</div>
										<ul class="links-list">
											<li><a [routerLink]="routerPaths.termsAndConditions.fullPath">Termeni și condiții</a></li>
											<li><a [routerLink]="routerPaths.privacyPolicy.fullPath">Politica de confidențialitate</a></li>
											<li><a [routerLink]="routerPaths.cookiePolicy.fullPath">Politica folosirii cookie-urilor</a></li>
											<li><a href="https://prolex.ro">Sindicatul Pro Lex</a></li>
											<li><a href="https://casa-dracula.ro">Pensiunea Casa Dracula</a></li>
											<li><a href="https://prolex.ro/club">ProLex Club</a></li>
										</ul>
								</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-12">
								<div class="prolex-footer-widget">
										<div class="widget-title">
												<h3>Urmărește-ne</h3>
										</div>
										<div class="social-block">
												<a href="https://facebook.com/sindicatulprolex"><i class="icofont-facebook"></i></a>
												<a href="https://www.youtube.com/channel/UC1G5ODSxAXRl2JnHr3EWfeg"><i class="icofont-brand-youtube"></i></a>
										</div>
								</div>
						</div>
				</div>
		</div>
	</div>
</footer>
<div class="prolex-bottom-footer text-center">
	<div class="container">
		<p>Copyright &copy; 2020 - 2021 <a href="https://www.prolex.ro">Pro Lex</a> &amp; <a href="https://dynamic-media.ro"><img alt="Logo Dynamic Media" src="/assets/images/logo-dynamic-media.png" style="height: 19px; width:auto;"></a></p>
	</div>
</div>
