<div class="authentication-box">
  <div class="text-center"><img src="assets/images/logo.png" alt="ProLex Logo"></div>
  <div class="card mt-4 shadow-box">
    <div class="card-body">
      <div class="text-center">
        <h4>{{ 'labels.title' | translate }}</h4>
        <h6 class="text-justify">{{ 'labels.subtitle' | translate }}</h6>
      </div>
      <form [formGroup]="existingMemberRegisterForm" class="theme-form" (submit)="sendClicked()">
        <div class="form-group">
          <ejs-textbox
            placeholder="{{ 'labels.email' | translate }}"
            formControlName="email"
            cssClass="e-outline"
            floatLabelType="Auto"
            autocomplete="off">
          </ejs-textbox>
          <div *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)" class="error-msg">
            <div *ngIf="emailControl.errors.required">{{ 'validation.emailRequired' | translate }}</div>
            <div *ngIf="emailControl.errors.email">{{ 'validation.emailInvalid' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <ejs-numerictextbox
            placeholder="{{ 'labels.cnp' | translate }}"
            formControlName="cnp"
            cssClass="e-outline"
            floatLabelType="Auto"
            format="#############"
            min="1010101000000"
            max="9991231999999"
            [strictMode]="false"
            [htmlAttributes]="{ 'maxLength': '13' }"
            [showSpinButton]="false">
          </ejs-numerictextbox>
          <div *ngIf="cnpControl.invalid && (cnpControl.dirty || cnpControl.touched)" class="error-msg">
            <div *ngIf="cnpControl.errors.required">{{ 'validation.cnpRequired' | translate }}</div>
            <div *ngIf="cnpControl.errors.cnpInvalid">{{ 'validation.cnpInvalid' | translate }}</div>
            <div *ngIf="cnpControl.errors.cnpExists">{{ 'validation.cnpExists' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <ejs-checkbox #termsAndConditions formControlName="termsAndConditions"></ejs-checkbox>
          <span style="padding-left: 10px;" (click)="termeniClick($event)" class="fakeLink noSelect" innerHTML="{{ 'labels.termsAndConditions' | translate }}"></span>
          <div *ngIf="termsAndConditionsControl.invalid && (termsAndConditionsControl.dirty || termsAndConditionsControl.touched)" class="error-msg">
            <div *ngIf="termsAndConditionsControl.errors.required">{{ 'validation.termsAndConditions' | translate }}</div>
          </div>
        </div>
        <div class="form-group text-center">
          <button
            #sendButton ejs-progressbutton
            content="{{ 'labels.send' | translate }}"
            cssClass="dm-progress-button e-success"
            iconCss="cis-send"
            [spinSettings]="spinSettings"
            [duration]="spinDuration"
            type="submit"
            [disabled]="existingMemberRegisterForm.invalid"
            (begin)="contractBegin()"
            (end)="contractEnd()">
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
