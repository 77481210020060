/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { PhoneInputComponent } from './ejs-phone-input.component';

@NgModule({
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent],
  imports: [
    CommonModule,
    TextBoxModule,
    DropDownListModule
  ]
})
export class PhoneInputModule { }
