<span class="text-center font-weight-bold" style="display: block;">{{ 'paymentRequiredLabel' | translate }}</span><br>
<span class="text-center font-weight-bold" style="display: block;">{{ 'subtitlePersonal' | translate }}</span>
<form [formGroup]="personalDataForm" class="theme-form">
  <div class="form-group">
    <ejs-textbox
      placeholder="{{ 'labelsPersonal.lastName' | translate }}"
      formControlName="lastName"
      cssClass="e-outline name-control"
      floatLabelType="Auto"
      [htmlAttributes]="{ 'maxLength': '32' }"
      autocomplete="off">
    </ejs-textbox>
    <div *ngIf="lastNameControl.invalid && (lastNameControl.dirty || lastNameControl.touched)" class="error-msg">
      <div *ngIf="lastNameControl.errors.required">{{ 'validationPersonal.lastNameRequired' | translate }}</div>
      <div *ngIf="lastNameControl.errors.rangeLength">{{ 'validationPersonal.lastNameRange' | translate }}</div>
      <div *ngIf="lastNameControl.errors.namePattern">{{ 'validationPersonal.lastNamePattern' | translate }}</div>
    </div>
  </div>
  <div class="form-group">
    <ejs-textbox
      placeholder="{{ 'labelsPersonal.firstName' | translate }}"
      formControlName="firstName"
      cssClass="e-outline name-control"
      floatLabelType="Auto"
      [htmlAttributes]="{ 'maxLength': '32' }"
      autocomplete="off">
    </ejs-textbox>
    <div *ngIf="firstNameControl.invalid && (firstNameControl.dirty || firstNameControl.touched)" class="error-msg">
      <div *ngIf="firstNameControl.errors.required">{{ 'validationPersonal.firstNameRequired' | translate }}</div>
      <div *ngIf="firstNameControl.errors.rangeLength">{{ 'validationPersonal.firstNameRange' | translate }}</div>
      <div *ngIf="firstNameControl.errors.namePattern">{{ 'validationPersonal.firstNamePattern' | translate }}</div>
    </div>
  </div>
  <div class="form-group">
    <div style="display: inline-flex; align-items: center;">
      <div style="width: 50%; margin-right: 5px;">
        <ejs-numerictextbox
          placeholder="{{ 'labelsPersonal.cnp' | translate }}"
          formControlName="cnp"
          cssClass="e-outline"
          floatLabelType="Auto"
          format="#############"
          min="1010101000000"
          max="9991231999999"
          [strictMode]="false"
          [htmlAttributes]="{ 'maxLength': '13' }"
          [showSpinButton]="false">
        </ejs-numerictextbox>
      </div>
      <div style="width: 50%">
        <button
            ejs-button
            cssClass="e-info"
            [disabled]="cnpControl.errors !== null || validatedCnp"
            (click)="cnpExistsCheck()">
          {{ 'labelsPersonal.validateCnp' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="cnpControl.invalid && (cnpControl.dirty || cnpControl.touched)" class="error-msg">
      <div *ngIf="cnpControl.errors.required">{{ 'validationPersonal.cnpRequired' | translate }}</div>
      <div *ngIf="cnpControl.errors.cnpInvalid">{{ 'validationPersonal.cnpInvalid' | translate }}</div>
      <div *ngIf="cnpControl.errors.cnpExists">{{ 'validationPersonal.cnpExists' | translate }}</div>
    </div>
  </div>
  <div class="form-group">
    <ejs-textbox
      placeholder="{{ 'labelsPersonal.referenceCode' | translate }}"
      formControlName="referenceCode"
      cssClass="e-outline uppercase-control"
      floatLabelType="Auto"
      [htmlAttributes]="{ 'maxLength': '16' }"
      autocomplete="off">
    </ejs-textbox>
	  <div class="hint">Se trece codul primit de la membrul care ți-a recomandat ProLex (dacă este cazul)</div>
  </div>
  <div class="form-group">
    <button
        ejs-button
        type="submit"
        cssClass="e-info right-button"
        awNextStep
        (click)="next()"
        [disabled]="personalDataForm.invalid || !validatedCnp">
      {{ 'labelsPersonal.next' | translate }}
    </button>
  </div>
	<div #personalDataSpinner></div>
</form>
