import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { paths } from '../../../app-paths';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'landing-page-registration-popup',
	templateUrl: './registration-popup.component.html',
	styleUrls: ['./registration-popup.component.scss']
})
export class RegistrationPopupComponent implements OnDestroy {
	public registrationFormGroup: FormGroup;
	public continueDisabled = true;
	private _formSubscription: Subscription;

	constructor(private _activeModal: NgbActiveModal, private _router: Router) {
		const form = this.registrationFormGroup = new FormGroup({
			type: new FormControl(null)
		});
		this._formSubscription = form.valueChanges.subscribe(() => {
			this.continueDisabled = this.registrationFormGroup.get('type').value == null;
		});
	}

	public ngOnDestroy(): void {
		this._formSubscription.unsubscribe();
	}

	public cancel(): void {
		this._activeModal.close();
	}

	public gotoRegistration(): void {
		this._activeModal.close();
		switch (this.registrationFormGroup.get('type').value) {
			case '1':
				void this._router.navigate([paths.register.fullPath]);
				break;
			case '2':
				void this._router.navigate([paths.existingMemberRegister.fullPath]);
				break;
		}
	}
}
