import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthenticationConfigService, AuthenticationServiceConfig } from './authentication-service.config';
import { AuthenticationService } from './authentication.service';

@NgModule()
export class AuthenticationServiceModule {
  static forRoot(config: AuthenticationServiceConfig): ModuleWithProviders<AuthenticationServiceModule> {
    return {
      ngModule: AuthenticationServiceModule,
      providers: [
      	AuthenticationService,
        {provide: AuthenticationConfigService, useValue: config }
      ]
    };
  }
}
