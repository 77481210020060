/* eslint-disable max-len */
import { paths } from '../../../app-paths';

export const locale = {
	lang: 'ro',
	data: {
		labels: {
			title: 'Înregistrare membru existent',
			subtitle: 'Ești deja membru ProLex? Dacă au trecut minim 24 de ore de când ai depus adeziunea de membru atunci poți folosi formularul de mai jos pentru a solicita un cont de utilizator.',
			email: 'Email',
			cnp: 'CNP',
			send: 'Trimite solicitarea',
      termsAndConditions: `Sunt de acord cu <a href="${paths.termsAndConditions.fullPath}" target="_blank">Termenii și condițiile</a> de folosire a platformei Membri ProLex.`
		},
		validation: {
			emailRequired: 'Adresa de email este obligatorie',
			emailInvalid: 'Adresa de email este invalidă',
			cnpRequired: 'CNP-ul este obligatoriu',
      cnpInvalid: 'CNP-ul este invalid',
      termsAndConditions: 'Pentru a putea continua cu crearea contului, trebuie să fii de acord cu Termenii și condițiile de utilizare ale aplicației Membri ProLex',
		}
	}
};
