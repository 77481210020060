/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

export interface IImage {
	url: string | null;
	href?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
	clickAction?: Function;
	caption?: string;
	title?: string;
	backgroundSize?: string;
	backgroundPosition?: string;
	backgroundRepeat?: string;
}

export interface ISlide {
	image: IImage;
	action: string;
	leftSide: boolean;
	rightSide: boolean;
	selected: boolean;
	loaded: boolean;
}

export class State {
  // a tag width
  public aw = 0;
  // a tag height
  public ah = 0;
  // actual image width
  public w = 0;
  // actual image height
  public h = 0;

  // aspect ratio of image
  public get ar(): number {
    return this.w / this.h;
  }

  // diagonal of image
  public get diag(): number {
    return Math.sqrt((this.w * this.w) + (this.h * this.h));
  }

  // is state populated and valid
  public get valid(): boolean {
    return this.w > 0 && this.h > 0 && this.aw > 0 && this.ah > 0;
  }

  // is image actual size bounded by width against the a tag view port,
  // horizontal clip in case of cover
  public get widthBound(): boolean {
    return this.ar > (this.aw / this.ah);
  }
}
