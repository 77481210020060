import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { paths } from '../../../app-paths';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'landing-page-header',
	templateUrl: './landing-page-header.component.html',
	styleUrls: ['./landing-page-header.component.scss'],
	encapsulation: ViewEncapsulation.None // for the header scripts in theme.js to work with the scss
})
export class LandingPageHeaderComponent implements AfterViewInit {
	// Public members
	public routerPaths = paths;

	public ngAfterViewInit(): void {
		const mainNavToggle = document.querySelector('.prolex-header-navigation .prolex-menu-toggler');
    if (mainNavToggle) {
      mainNavToggle.addEventListener('click', function() {
        const menu = document.querySelector(this.getAttribute('data-target'));
        if (menu)
          menu.classList.toggle('shown');
        return false;
      })
    }
    document.querySelectorAll('.scrollToLink a').forEach(function(element) {
      element.addEventListener('click', function() {
        if (window.innerWidth < 1200) {
          const menu = document.querySelector('.header-one .main-navigation');
          if (menu)
            menu.classList.toggle('shown');
        }
        return false;
      })
    });
	}
}
