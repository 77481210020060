<section class="prolex-feature-style-two" id="casa-dracula">
	<div class="container">
		<div class="row text-center">
			<h1>Pensiunea Casa Dracula</h1>
		</div>
		<div class="row">
			<div class="col-lg-6 text-center mb-3">
				<div class="content-block">
					<p class="text-justify">
						Casa Dracula by ProLex**** este situată în localitatea Bran, la aproximativ 400 de metri de Castelul Bran și atrage an de an turiști din ţară și străinătate, fermecaţi nu doar de legenda lui Dracula, ci și de peisajul, liniștea și confortul oferite. Locația se află într-o zonă liniștită având de o parte și de alta livezi de meri, iar în fața porții, dincolo de drum, pe deal, veghează o pădurice de mesteceni argintii.
					</p>
					<ol class="gradient-list" style="font-size:20px;">
						<li>Oferte promoționale pentru membrii Pro Lex.</li>
						<li>Sală de mese generoasă unde se pot organiza mese festive sau ședințe împreună cu partenerii sau colegii tăi.</li>
						<li>Pentru că iubești să stai în aer liber îți oferim un foișor încăpător împreună cu un grătar „profesional”</li>
						<li>Nu vei duce niciodată grija automobilului tău. Vă punem la dispoziție o parcare cu 10 locuri în incinta pensiunii.</li>
					</ol>
					<a href="https://www.casa-dracula.ro" class="feature-btn" target="_blank">Află mai multe</a>
				</div>
			</div>
			<div class="col-lg-6 mb-3">
				<dm-slideshow
					[imageUrls]="slideshowSlides"
					height="360px"
					arrowSize="30px"
					[autoPlay]="true"
					[showDots]="true"
					[lazyLoad]="true">
				</dm-slideshow>
				<div style="height: 3rem;"></div>
				<youtube-player videoId="n7T1meYD3Lw" width="100%"></youtube-player>
			</div>
		</div>
	</div>
</section>
