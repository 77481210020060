/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { InjectionToken } from '@angular/core';

export class DialogServiceConfig {
  appName?: string;
  locale?: string;
}

export const DialogConfigService = new InjectionToken<DialogServiceConfig>('DialogServiceConfig');
