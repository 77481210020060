import { Component, OnDestroy } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Observable, Subscription } from 'rxjs';
import { L10n, setCulture, loadCldr, setCurrencyCode  } from '@syncfusion/ej2-base';
import { dmLoadingAnimationTypes } from '@dynamicmedia/shared';
import { LayoutService } from './shared/services';
// import * as EJ2_LOCALE from '../assets/i18n/syncfusion/ro.json';
import { ro as EJ2_LOCALE_RO } from '../assets/i18n/syncfusion/ro';
// eslint-disable-next-line
declare var require: any;

L10n.load({ ro: EJ2_LOCALE_RO });
setCulture('ro');
setCurrencyCode('RON');

loadCldr(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
  require('cldr-data/main/ro/numbers.json'),
	// eslint-disable-next-line @typescript-eslint/no-var-requires
  require('cldr-data/main/ro/ca-gregorian.json'),
	// eslint-disable-next-line @typescript-eslint/no-var-requires
  require('cldr-data/supplemental/numberingSystems.json'),
	// eslint-disable-next-line @typescript-eslint/no-var-requires
  require('cldr-data/main/ro/timeZoneNames.json'),
	// eslint-disable-next-line @typescript-eslint/no-var-requires
  require('cldr-data/supplemental/weekdata.json'), // To load the culture based first day of week
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('cldr-data/main/ro/currencies.json')
);

@Component({
	selector: 'members-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
	// Public members
	public isSpinnerVisible$: Observable<boolean>;
	public dmLoadingAnimationTypes = dmLoadingAnimationTypes;
	public readonly primaryColor = '#2f3c4e';
	public readonly secondaryColor = '#767676';
	// Private members
	// keep refs to subscriptions to be able to unsubscribe later
	private popupOpenSubscription: Subscription;
	// private popupCloseSubscription: Subscription;
	// private initializeSubscription: Subscription;
	// private statusChangeSubscription: Subscription;
	// private revokeChoiceSubscription: Subscription;
	// private noCookieLawSubscription: Subscription;

	constructor(
		private ccService: NgcCookieConsentService,
		private _layoutService: LayoutService)
	{
		this.isSpinnerVisible$ = this._layoutService.isNavigationPending$;
		// subscribe to cookie consent observables to react to main events
		this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
			// you can use this.ccService.getConfig() to do stuff...
		});
	}

	public ngOnDestroy(): void {
		// unsubscribe to cookie consent observables to prevent memory leaks
 		this.popupOpenSubscription.unsubscribe();
	}
}
