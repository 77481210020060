/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeadersInterceptorConfig, HttpHeadersInterceptorConfigService } from './http-headers-interceptor.config';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  constructor(@Inject(HttpHeadersInterceptorConfigService) private readonly config?: HttpHeadersInterceptorConfig) {
    if (this.config === null || this.config === undefined) {
      this.config = {
        authenticationTokenLocalStorageKey: 'appToken',
        localeLocalStorageKey: 'appLocale'
      };
    }
    else {
      this.config.authenticationTokenLocalStorageKey = this.config.authenticationTokenLocalStorageKey || 'appToken';
      this.config.localeLocalStorageKey = this.config.localeLocalStorageKey || 'appLocale';
    }
  }

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const headers: any = {};
		// Authorization header
		const token = localStorage && localStorage.getItem(this.config?.authenticationTokenLocalStorageKey as string);
		if (token != null && token.length > 0)
			headers['Authorization'] = `Bearer ${token}`;
		// Accept-Language header
    let language = localStorage && localStorage.getItem(this.config?.localeLocalStorageKey as string);
    if (language === null || language === undefined || language.trim().length === 0)
      language = 'ro';
		headers['Accept-Language'] = language;
		const requestToForward = request.clone({ setHeaders: headers });
		return next.handle(requestToForward);
	}
}
