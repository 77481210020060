/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy,
	ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'dm-password-strength-meter',
	templateUrl: './password-strength-meter.component.html',
	styleUrls: ['./password-strength-meter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordStrengthMeterComponent implements OnChanges {
	@Input() public password!: string;
	@Input() public minPasswordLength = 8;
	@Input() public enableFeedback = false;
	@Input() public colors: Array<string> = [];
	@Output() public strengthChanged = new EventEmitter<number>();
	public passwordStrength: number | null = null;
	public feedback: { suggestions: Array<string>; warning: string } | null = null;

	private _prevPasswordStrength: number | null = null;
	private _defaultColours = ['darkred', 'orangered', 'orange', 'yellowgreen', 'green'];

	constructor(private _changeDetector: ChangeDetectorRef) {}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.password)
			this.calculatePasswordStrength();
	}

	private calculatePasswordStrength() {
		let force = 0;
		if (this.password != null && this.password.length !== 0) {
      // eslint-disable-next-line no-useless-escape
			const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g; ///[$-/:-?{-~!"^_@`[\]]/g;
			const lowerLetters = /[a-z]+/.test(this.password);
			const upperLetters = /[A-Z]+/.test(this.password);
			const numbers = /[0-9]+/.test(this.password);
			const symbols = regex.test(this.password);
			const flags = [lowerLetters, upperLetters, numbers, symbols];
			let passedMatches = 0;
			for (const flag of flags)
				passedMatches += flag ? 1 : 0;
			force = (this.password.length >= this.minPasswordLength) ? force + 1 : force;
			force += passedMatches;
		}
		this.passwordStrength = force;
		// Only emit the passwordStrength if it changed
		if (this._prevPasswordStrength !== this.passwordStrength) {
			this.strengthChanged.emit(this.passwordStrength);
			this._prevPasswordStrength = this.passwordStrength;
			this._changeDetector.markForCheck();
		}
	}

	public getMeterFillColor(strength: number) {
		if (!strength || strength < 0)
			return 'unset';
		else if (strength > 5)
			return this.colors[4];
		return this.colors[strength - 1] ? this.colors[strength - 1] : this._defaultColours[strength - 1];
	}
}
