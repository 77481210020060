/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DMLoadingModule } from './components/loading/loading.module';
import { GtagModule } from './gtag/gtag.module';
import { DMVerifiedContactModule } from './components/verified-contact/verified-contact.module';
import { DMToggleFullscreenDirective } from './directives/fullscreen.directive';
import { SlideshowModule } from './components/slideshow/slideshow.module';
import { DMPasswordStrengthMeterModule } from './components/password-strength-meter/password-strength-meter.module';
import { ContainsPipe } from './pipes/contains.pipe';
import { ExcerptPipe } from './pipes/excerpt.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SignaturePadModule } from './components/signature-pad/signature-pad.module';

@NgModule({
  imports: [ CommonModule ],
  declarations: [
    DMToggleFullscreenDirective,
		ContainsPipe,
		ExcerptPipe,
		SafeHtmlPipe
  ],
  exports: [
    DMLoadingModule,
    GtagModule,
    DMVerifiedContactModule,
    DMToggleFullscreenDirective,
    SlideshowModule,
    DMPasswordStrengthMeterModule,
    SafeHtmlPipe,
	  SignaturePadModule
  ]
})
export class DynamicMediaSharedModule {}
