/*
 * Project: ProLex Membri Frontend
 * Author: Bogdan Anghel
 * Copyright © 2019 - 2020 Dynamic Media S.R.L.
 * Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fisier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

export const environment = {
	production: true,
	backendUrl: 'https://apigateway.prolex.ro/',
	version: '0.1.24',
	localDomain: 'membri.prolex.ro',
	appName: 'Membri ProLex',
	localStorage: {
		rememberMe: 'prolexMembersRemember',
		authenticationToken: 'prolexMembersAuthenticationToken',
		userInfo: 'prolexMembersUserInfo',
    locale: 'prolexLocale'
	}
};
