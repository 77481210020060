import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { paths } from '@members/app-paths';
import { NewPaymentComponent } from '@members/member/payments/components';
import { AuthenticationService } from '@prolex/shared';
import { Observable } from 'rxjs';
import { MembersService } from './members.service';

export interface IPaymentsGuard{
  canDeactivate: () => boolean;
}

@Injectable()
export class PaymentsGuard implements CanDeactivate<NewPaymentComponent> {
  constructor(
    private _memberService: MembersService,
    private _authenticationService: AuthenticationService,)
    {  }

  public canDeactivate(component: NewPaymentComponent, currentRoute:ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?:RouterStateSnapshot) : Observable<boolean> | boolean{
    const memberId = this._authenticationService.userData?.memberId;
    if (nextState.url === paths.login.fullPath)
      return true;
    return this._memberService.hasValidPayments$.value != null ? 
      this._memberService.hasValidPayments$.value : 
      this._memberService.hasPayments(memberId);
  }
}
