/*
 * Project: ProLex Membri Frontend
 * Copyright © 2020 Dynamic Media S.R.L.
 * Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fisier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LandingPageHeaderComponent, LandingPageFooterComponent } from './components';

@NgModule({
  exports: [
		LandingPageHeaderComponent,
		LandingPageFooterComponent
	],
	declarations: [
		LandingPageHeaderComponent,
		LandingPageFooterComponent
	],
	imports: [
		CommonModule,
		RouterModule
	]
})
export class LandingPageLayoutModule { }
