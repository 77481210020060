<section class="prolex-pricing-style-one" id="cotizatie">
	<div class="container">
		<div class="prolex-block-title text-center">
			<h2>Cotizație</h2>
		</div>
		<div class="animate__animated animate__fadeInUp">
			<div class="row justify-content-center mb-3">
				<div class="col-lg-4 col-md-6 col-sm-12">
					<div class="prolex-single-pricing-one">
						<div class="top-block">
							<span class="price">25.00 lei</span>
							<span class="pack-name">pe lună</span>
							<div class="line"></div>
						</div>
						<ul class="feature-lists">
							<li>Reprezentare juridică</li>
							<li>Informare corectă</li>
							<li>Oferte speciale</li>
							<li>Tarife preferențiale pensiune</li>
						</ul>
						<div class="bottom-block">
							<a class="feature-btn" style="margin-left:15px;margin-right:15px;" (click)="registerClicked()">Vreau să devin membru</a>
							<span class="tag-line">Fără costuri ascunse!</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
