/*
 * Project: ProLex Membri Frontend
 * Author: Dynamic Media S.R.L.
 * Copyright © 2019 - 2021 Dynamic Media S.R.L. Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fișier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule, CheckBoxModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ArchwizardModule } from 'angular-archwizard';
import { RememberMeService, DMPasswordStrengthMeterModule, DynamicMediaSharedModule } from '@dynamicmedia/shared';
import { DialogService, PhoneInputModule, SpinnerService } from '@dynamicmedia/syncfusion';
import { AuthenticationService } from '@prolex/shared';
import { HttpHeadersInterceptorProvider } from '@dynamicmedia/shared';
import { RegisterService, MembersService, LayoutService } from '../shared/services';
import { SharedModule } from '../shared';
import { ContactInfoComponent, PersonalDataComponent, VerifySetPasswordComponent, WorkplaceInfoComponent } from './register/components';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ExistingMemberRegisterComponent } from './existing-member-register/existing-member-register.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
	exports: [
		LoginComponent,
		RegisterComponent,
    ResetPasswordComponent,
    ExistingMemberRegisterComponent,
    CreatePasswordComponent,
    ForgetPasswordComponent,
    UnauthorizedComponent
	],
	imports: [
		RouterModule,
		CommonModule,
		TranslateModule,
		ArchwizardModule,
		ButtonModule,
		ProgressButtonModule,
		CheckBoxModule,
		TextBoxModule,
		NumericTextBoxModule,
		ComboBoxModule,
		RadioButtonModule,
		NgbModule,
		SharedModule,
		DMPasswordStrengthMeterModule,
		PhoneInputModule,
		DynamicMediaSharedModule
	],
	declarations: [
		LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
		RegisterComponent,
		PersonalDataComponent,
		ContactInfoComponent,
		WorkplaceInfoComponent,
		VerifySetPasswordComponent,
    ExistingMemberRegisterComponent,
    CreatePasswordComponent,
    UnauthorizedComponent
	],
	providers: [
    HttpHeadersInterceptorProvider,
		RegisterService,
		MembersService,
    LayoutService,
    AuthenticationService,
    RememberMeService,
    SpinnerService,
    DialogService,
	]
})
export class AuthPagesModule {}
