/* eslint-disable max-len */
export const ro = {
	tab: {
		closeButtonTitle: 'Închide'
	},
	pivotview: {
		grandTotal: 'Total general',
		total: 'Total',
		value: 'Valoare',
		noValue: 'Fără valoare',
		row: 'Rând',
		column: 'Coloană',
		collapse: 'Contractă',
		expand: 'Extinde',
		rowAxisPrompt: 'Aruncă rândul aici',
		columnAxisPrompt: 'Aruncă coloana aici',
		valueAxisPrompt: 'Scade valoarea aici',
		filterAxisPrompt: 'Aruncă filtrul aici',
		filter: 'Filtru',
		filtered: 'Filtrate',
		sort: 'Ordonare',
		filters: 'Filtre',
		rows: 'rânduri',
		columns: 'coloane',
		values: 'valori',
		close: 'Închide',
		cancel: 'Anulare',
		delete: 'Șterge',
		CalculatedField: 'Câmpul calculat',
		createCalculatedField: 'Crează câmpul calculat',
		fieldName: 'Introdu numele câmpului',
		error: 'Eroare',
		invalidFormula: 'Formulă invalidă.',
		dropText: 'Exemplu: ("Suma (Comanda_Conte)" + "Suma (In_Stock)") * 250',
		dropTextMobile: 'Adăugă câmpuri și modifică formula aici.',
		dropAction: 'Câmpul calculat nu poate fi plasat în nicio altă regiune, cu excepția axei valorice.',
		alert: 'Alertă',
		warning: 'Avertizare',
		ok: 'OK',
		search: 'Căutare',
		drag: 'Trage',
		remove: 'Elimină',
		allFields: 'Toate câmpurile',
		formula: 'Formulă',
		addToRow: 'Adăugă la rând',
		addToColumn: 'Adăugă la coloană',
		addToValue: 'Adăugă la valoare',
		addToFilter: 'Adaugă în filtru',
		emptyData: 'Nu există înregistrări de afișat',
		fieldExist: 'Un câmp cu acest nume există deja. Te rog să introduci un alt nume.',
		confirmText: 'Un câmp de calcul cu acest nume există deja. Vrei sa îl inlocuiești?',
		noMatches: 'Nicio potrivire',
		format: 'Format',
		edit: 'Editare',
		clear: 'golește',
		formulaField: 'Trage și plasează câmpurile în formulă',
		dragField: 'Trage câmpul către formulă',
		clearFilter: 'golește',
		by: 'de',
		all: 'Toate',
		multipleItems: 'Mai multe elemente',
		member: 'Membru',
		label: 'Etichetă',
		date: 'Data',
		enterValue: 'Introdu valoarea',
		chooseDate: 'Introdu data',
		Before: 'Înainte de',
		BeforeOrEqualTo: 'Înainte sau egal cu',
		After: 'După',
		AfterOrEqualTo: 'După sau egal cu',
		labelTextContent: 'Afișează elementele pentru care eticheta',
		dateTextContent: 'Afișează elementele pentru care data',
		valueTextContent: 'Afișează elementele pentru care',
		Equals: 'Este egal',
		DoesNotEquals: 'Nu este egal',
		BeginWith: 'Începe cu',
		DoesNotBeginWith: 'Nu începe cu',
		EndsWith: 'Se termină cu',
		DoesNotEndsWith: 'Nu se termină cu',
		Contains: 'Conține',
		DoesNotContains: 'Nu conține',
		GreaterThan: 'Mai mare ca',
		GreaterThanOrEqualTo: 'Mai mare decât sau egal cu',
		LessThan: 'Mai mic decât',
		LessThanOrEqualTo: 'Mai mic sau egal cu',
		Between: 'Între',
		NotBetween: 'Nu între',
		And: 'și',
		Sum: 'Sumă',
		Count: 'Numărare',
		DistinctCount: 'Numărare distinctă',
		Product: 'Produs',
		Avg: 'Medie',
		Min: 'min',
		SampleVar: 'Mostră variație',
		PopulationVar: 'Variație populare',
		RunningTotals: 'Totaluri rulate',
		Max: 'Max',
		Index: 'Index',
		SampleStDev: 'Mostră deviație standard',
		PopulationStDev: 'Populația StDev',
		PercentageOfRowTotal: '% din totalul rândului',
		PercentageOfParentTotal: '% din totalul părintelui',
		PercentageOfParentColumnTotal: '% din totalul coloanelor părinte',
		PercentageOfParentRowTotal: '% din totalul rândului părinte',
		DifferenceFrom: 'Diferență de',
		PercentageOfDifferenceFrom: '% din diferența de',
		PercentageOfGrandTotal: '% din totalul general',
		PercentageOfColumnTotal: '% din totalul coloanei',
		NotEquals: 'Nu este egal',
		AllValues: 'Toate valorile',
		conditionalFormating: 'Formatarea condițională',
		apply: 'APLICĂ',
		condition: 'Adaugă condiție',
		formatLabel: 'Format etichetă',
		valueFieldSettings: 'Setări câmp valoare',
		baseField: 'Câmp de bază:',
		baseItem: 'Element de bază:',
		summarizeValuesBy: 'Rezumează valorile după:',
		sourceName: 'Numele sursei:',
		sourceCaption: 'Legenda câmpului:',
		example: 'de exemplu:',
		editorDataLimitMsg: ' mai multe articole. Caută să perfecționezi în continuare.',
		details: 'Detalii',
		manageRecords: 'Gestionează înregistrările',
		Years: 'Ani',
		Quarters: 'Trimestre',
		Months: 'Luni',
		Days: 'zile',
		Hours: 'ore',
		Minutes: 'Minute',
		Seconds: 'secunde',
		save: 'Salvează un raport',
		new: 'Creează un raport nou',
		load: 'Încarcă',
		saveAs: 'Salvează ca raport curent',
		rename: 'Redenumește raportul curent',
		deleteReport: 'Șterge raportul curent',
		export: 'Exportă',
		subTotals: 'Subtotaluri',
		grandTotals: 'Totaluri generale',
		reportName: 'Nume raport:',
		pdf: 'PDF',
		excel: 'Excel',
		csv: 'CSV',
		png: 'PNG',
		jpeg: 'JPEG',
		svg: 'SVG',
		mdxQuery: 'Interogare MDX',
		showSubTotals: 'Afișează subtotaluri',
		doNotShowSubTotals: 'Nu afișa subtotaluri',
		showSubTotalsRowsOnly: 'Afișează doar rândurile cu subtotal',
		showSubTotalsColumnsOnly: 'Afișează doar coloanele cu subtotal',
		showGrandTotals: 'Afișează totalurile generale',
		doNotShowGrandTotals: 'Nu afișa totalurile generale',
		showGrandTotalsRowsOnly: 'Afișează doar rândurile cu totaluri generale',
		showGrandTotalsColumnsOnly: 'Afișează doar coloanele cu totaluri generale',
		fieldList: 'Afișare listă de câmpuri',
		grid: 'Afișează tabelul',
		toolbarFormatting: 'Bară de instrumente formatare',
		chart: 'Diagramă',
		reportMsg: 'Te rog să introduci un nume vaild pentru raport!',
		reportList: 'Listă de rapoarte',
		removeConfirm: 'Sigur dorești să ștergi acest raport?',
		emptyReport: 'Nu s-au găsit rapoarte!',
		bar: 'Bară',
		line: 'Linie',
		area: 'Zonă',
		scatter: 'Împrăștiat',
		polar: 'Polar',
		of: 'de',
		emptyFormat: 'Niciun format nu a fost găsit!',
		emptyInput: 'Introdu o valoare',
		newReportConfirm: 'Dorești să salvezi modificările în raport?',
		emptyReportName: 'Introdu un nume de raport',
		qtr: 'Sfert',
		'null': 'nul',
		'undefined': 'nedefinit',
		groupOutOfRange: 'În afara gamei de valori',
		fieldDropErrorAction: 'Câmpul pe care îl muți nu poate fi plasat în acea zonă a raportului',
		MoreOption: 'Mai mult...',
		aggregate: 'Agregat',
		drillThrough: 'Treci prin',
		ascending: 'Ascendent',
		descending: 'Descendent',
		number: 'Număr',
		currency: 'Valută',
		percentage: 'Procent',
		formatType: 'Tip de format',
		customText: 'Simbol valută',
		symbolPosition: 'Poziția simbolului',
		left: 'Stânga',
		right: 'Dreapta',
		grouping: 'grupare',
		'true': 'Adevărat',
		'false': 'Fals',
		decimalPlaces: 'Zecimale',
		numberFormat: 'Formatarea numărului',
		memberType: 'Tipul câmpului',
		formatString: 'Formatează șirul',
		expressionField: 'Expresie',
		customFormat: 'Introdu șirul formatului personalizat',
		selectedHierarchy: 'Ierarhia părinților',
		olapDropText: 'Exemplu: [Măsuri]. [Cantitate comandă] + ([Măsuri]. [Cantitate comandă] * 0.10)',
		Percent: 'Procent',
		Custom: 'Personalizat',
		Measure: 'Măsură',
		Dimension: 'Dimensiune',
		Standard: 'Standard',
		blank: '(Gol)',
		fieldTooltip: 'Trage și plasează câmpurile pentru a crea o expresie. Poți să editezi câmpurile calculate existente prin simpla selectare a câmpului în \'Câmpuri calculate\'.',
		QuarterYear: 'Trimestru',
		fieldTitle: 'Numele câmpului',
		drillError: 'Nu se pot afișa elementele brute ale câmpurilor calculate.',
		caption: 'Legendă',
		copy: 'Copiere',
		defaultReport: 'Raport implicit',
		customFormatString: 'Format personalizat',
		invalidFormat: 'Format invalid.',
		group: 'grup',
		unGroup: 'Degrupează',
		invalidSelection: 'Nu se poate grupa acea selecție.',
		groupName: 'Introdu numele grupului pentru a fi afișat în antet',
		captionName: 'Introdu legenda pentru câmpul de grup',
		selectedItems: 'Elemente selectate',
		groupFieldCaption: 'Legenda câmpului',
		groupTitle: 'Numele Grupului',
		startAt: 'Începând de la',
		endAt: 'Terminând cu',
		groupBy: 'Grupează după',
		selectGroup: 'Selectează grupuri',
		numberFormatString: 'Exemplu: C, P, 0000%, ### 0. ## 0 # etc.',
		stackingcolumn: 'Coloana stivuită',
		stackingbar: 'Bara stivuită',
		stackingarea: 'Zona stivuită',
		stepline: 'Linia de pas',
		steparea: 'Zona de pas',
		splinearea: 'Zona Spline',
		spline: 'Spline',
		stackingcolumn100: 'Coloana stivuită 100%',
		stackingbar100: 'Bară stivuită 100%',
		stackingarea100: 'Suprafață 100% stivuită',
		bubble: 'balon',
		pareto: 'Pareto',
		radar: 'Radar',
		chartTypeSettings: 'Setări tip grafic',
		multipleAxes: 'Axe multiple',
		sortAscending: 'Sortează ordinea ascendentă',
		sortDescending: 'Sortează ordinea descendentă',
		sortNone: 'Fără sortare',
		clearCalculatedField: 'Șterge informațiile câmpului calculat',
		editCalculatedField: 'Editează câmpul calculat',
		ChartType: 'Tipul graficului',
		yes: 'Da',
		no: 'Nu',
		numberFormatMenu: 'Formatare numărului...',
		conditionalFormatingMenu: 'Formatare condițională...',
		removeCalculatedField: 'Sigur dorești să ștergi acest câmp calculat?',
		replaceConfirmBefore: 'Un raport numit ',
		replaceConfirmAfter: ' deja există. Vrei sa îl inlocuiești?',
		pie: 'Plăcintă',
		funnel: 'Pâlnie',
		doughnut: 'Gogoașă',
		pyramid: 'Piramidă',
		showLegend: 'Arată Legenda',
		exit: 'Ieșire'
	},
	pivotfieldlist: {
		staticFieldList: 'Lista câmpurilor pivot',
		fieldList: 'Lista câmpurilor',
		dropFilterPrompt: 'Aruncați filtrul aici',
		dropColPrompt: 'Aruncați coloana aici',
		dropRowPrompt: 'Aruncați rândul aici',
		dropValPrompt: 'Scade valoarea aici',
		addPrompt: 'Adaugă câmpul aici',
		adaptiveFieldHeader: 'Alegeți câmpul',
		centerHeader: 'Trageți câmpurile între axe de mai jos:',
		add: 'adăuga',
		drag: 'Trage',
		filter: 'Filtru',
		filtered: 'Filtrate',
		sort: 'Fel',
		remove: 'Elimina',
		filters: 'Filtre',
		rows: 'rânduri',
		columns: 'coloane',
		values: 'valori',
		CalculatedField: 'Câmpul calculat',
		createCalculatedField: 'Creează câmpul calculat',
		fieldName: 'Introduceți numele câmpului',
		error: 'Eroare',
		invalidFormula: 'Formula nevalidă.',
		dropText: 'Exemplu: ("Suma(Comanda_Cont)) + Suma (In_Stoc) ") * 250',
		dropTextMobile: 'Adaugă câmpuri și modificați formula aici.',
		dropAction: 'Câmpul calculat nu poate fi plasat în nicio altă regiune, cu excepția axei valorice.',
		search: 'Căutare',
		close: 'Închide',
		cancel: 'Anulare',
		delete: 'Șterge',
		alert: 'Alerta',
		warning: 'Avertizare',
		ok: 'O.K',
		allFields: 'Toate câmpurile',
		formula: 'Formulă',
		fieldExist: 'Un câmp există deja în acest nume. Vă rugăm să introduceți un alt nume.',
		confirmText: 'Un câmp de calcul există deja în acest nume. Vrei sa o inlocuiesti?',
		noMatches: 'Nici-o potrivire',
		format: 'Rezumarea valorilor de',
		edit: 'Editați | ×',
		clear: 'clar',
		formulaField: 'Trageți și plasați câmpurile în formulă',
		dragField: 'Trageți câmpul către formulă',
		clearFilter: 'clar',
		by: 'de',
		enterValue: 'Introduceți valoarea',
		chooseDate: 'Introduceți data',
		all: 'Toate',
		multipleItems: 'Mai multe articole',
		Equals: 'equals',
		DoesNotEquals: 'Nu este egal',
		BeginWith: 'Începe cu',
		DoesNotBeginWith: 'Nu începe cu',
		EndsWith: 'Se termină cu',
		DoesNotEndsWith: 'Nu se termină',
		Contains: 'Conține',
		DoesNotContains: 'Nu contine',
		GreaterThan: 'Mai mare ca',
		GreaterThanOrEqualTo: 'Mai mare decât sau egală cu',
		LessThan: 'Mai puțin decât',
		LessThanOrEqualTo: 'Mai mic sau egal cu',
		Between: 'Între',
		NotBetween: 'Nu între',
		Before: 'Inainte de',
		BeforeOrEqualTo: 'Înainte sau egal cu',
		After: 'După',
		AfterOrEqualTo: 'După sau egal cu',
		member: 'Membru',
		label: 'Eticheta',
		date: 'Data',
		value: 'Valoare',
		labelTextContent: 'Afișează elementele pentru care eticheta',
		dateTextContent: 'Afișează elementele pentru care data',
		valueTextContent: 'Afișează elementele pentru care',
		And: 'și',
		Sum: 'Sumă',
		Count: 'Numara',
		DistinctCount: 'Contele distins',
		Product: 'Produs',
		Avg: 'Medie',
		Min: 'min',
		Max: 'Max',
		Index: 'Index',
		SampleStDev: 'Proba StDev',
		PopulationStDev: 'Populația StDev',
		SampleVar: 'Var. Probă',
		PopulationVar: 'Pop. Var',
		RunningTotals: 'Totaluri de rulare',
		DifferenceFrom: 'Diferență de',
		PercentageOfDifferenceFrom: '% din diferență de',
		PercentageOfGrandTotal: '% din totalul mare',
		PercentageOfColumnTotal: '% din totalul coloanei',
		PercentageOfRowTotal: '% din rândul total',
		PercentageOfParentTotal: '% din totalul părinților',
		PercentageOfParentColumnTotal: '% din totalul coloanelor părinte',
		PercentageOfParentRowTotal: '% din totalul rândului părinților',
		Years: 'Ani',
		Quarters: 'Quarters',
		Months: 'Luni',
		Days: 'zi',
		Hours: 'ore',
		Minutes: 'Minute',
		Seconds: 'secunde',
		apply: 'APLICA',
		valueFieldSettings: 'Setări câmp valorice',
		sourceName: 'Numele domeniului :',
		sourceCaption: 'Legenda câmpului:',
		summarizeValuesBy: 'Rezumați valorile după:',
		baseField: 'Câmp de bază:',
		baseItem: 'Element de bază:',
		example: 'de exemplu:',
		editorDataLimitMsg: ' mai multe articole. Căutați să perfecționați în continuare.',
		deferLayoutUpdate: 'Amânarea actualizării aspectului',
		'null': 'nul',
		'undefined': 'nedefinit',
		groupOutOfRange: 'În afara razei de acțiune',
		fieldDropErrorAction: 'Câmpul pe care îl mutați nu poate fi plasat în acea zonă a raportului',
		MoreOption: 'Mai Mult...',
		memberType: 'Tipul câmpului',
		selectedHierarchy: 'Ierarhia părinților',
		formatString: 'Formatează șirul',
		expressionField: 'Expresie',
		olapDropText: 'Exemplu: [Măsuri]. [Cantitate comandă] + ([Măsuri]. [Cantitate comandă] * 0.10)',
		customFormat: 'Introduceți șirul formatului personalizat',
		Measure: 'Măsura',
		Dimension: 'Dimensiune',
		Standard: 'Standard',
		Currency: 'Valută',
		Percent: 'La sută',
		Custom: 'Personalizat',
		blank: '(Gol)',
		fieldTooltip: 'Trageți și plasați câmpurile pentru a crea o expresie. Și, dacă doriți să editați câmpurile existente, calculate! Puteți obține prin simpla selectare a câmpului în \'Membrii calculați\'.',
		fieldTitle: 'Numele domeniului',
		QuarterYear: 'Anul trimestrial',
		caption: 'Subtitrare câmp',
		copy: 'Copie',
		group: 'grup',
		numberFormatString: 'Exemplu: C, P, 0000%, ### 0. ## 0 # etc.',
		sortAscending: 'Sortează ordinea ascendentă',
		sortDescending: 'Sortează ordinea descendentă',
		sortNone: 'Sortare ordine de date',
		clearCalculatedField: 'Ștergeți informațiile câmpului editate',
		editCalculatedField: 'Editați câmpul calculat',
		selectGroup: 'Selectați grupuri',
		of: 'de',
		removeCalculatedField: 'Sigur doriți să ștergeți acest câmp calculat?'
	},
	filemanager: {
		NewFolder: 'Dosar nou',
		Upload: 'Încărcați',
		Delete: 'Șterge',
		Rename: 'Redenumire',
		Download: 'Descarca',
		Cut: 'A tăia',
		Copy: 'Copie',
		Paste: 'Pastă',
		SortBy: 'Filtrează după',
		Refresh: 'Reîmprospăta',
		'Item-Selection': 'element selectat',
		'Items-Selection': 'elemente selectate',
		View: 'Vedere',
		Details: 'Detalii',
		SelectAll: 'Selectează tot',
		Open: 'Deschis',
		'Tooltip-NewFolder': 'Dosar nou',
		'Tooltip-Upload': 'Încărcați',
		'Tooltip-Delete': 'Șterge',
		'Tooltip-Rename': 'Redenumire',
		'Tooltip-Download': 'Descarca',
		'Tooltip-Cut': 'A tăia',
		'Tooltip-Copy': 'Copie',
		'Tooltip-Paste': 'Pastă',
		'Tooltip-SortBy': 'Filtrează după',
		'Tooltip-Refresh': 'Reîmprospăta',
		'Tooltip-Selection': 'Selecție clară',
		'Tooltip-View': 'Vedere',
		'Tooltip-Details': 'Detalii',
		'Tooltip-SelectAll': 'Selectează tot',
		Name: 'Nume',
		Size: 'mărimea',
		DateModified: 'Modificat',
		DateCreated: 'Data creării',
		Path: 'cale',
		Modified: 'Modificat',
		Created: 'Creată',
		Location: 'Locație',
		Type: 'Tip',
		Permission: 'Permisiune',
		Ascending: 'Ascendent',
		Descending: 'Descendentă',
		None: 'Nici unul',
		'View-LargeIcons': 'Pictograme mari',
		'View-Details': 'Detalii',
		Search: 'Căutare',
		'Button-Ok': 'O.K',
		'Button-Cancel': 'Anulare',
		'Button-Yes': 'da',
		'Button-No': 'Nu',
		'Button-Create': 'Crea',
		'Button-Save': 'salva',
		'Header-NewFolder': 'Pliant',
		'Content-NewFolder': 'Introduceți numele folderului',
		'Header-Rename': 'Redenumire',
		'Content-Rename': 'Introduceți noul nume',
		'Header-Rename-Confirmation': 'Redenumiți confirmarea',
		'Content-Rename-Confirmation': 'Dacă schimbați o extensie de nume de fișier, fișierul ar putea deveni instabil. Ești sigur că vrei să o schimbi?',
		'Header-Delete': 'Sterge fisierul',
		'Content-Delete': 'Sigur doriți să ștergeți acest fișier?',
		'Header-Folder-Delete': 'Ștergeți folderul',
		'Content-Folder-Delete': 'Sigur doriți să ștergeți acest folder?',
		'Header-Multiple-Delete': 'Ștergeți mai multe fișiere',
		'Content-Multiple-Delete': 'Sigur doriți să ștergeți aceste fișiere {0}?',
		'Header-Duplicate': 'Există fișier / dosar',
		'Content-Duplicate': '{0} există deja. Doriți să redenumiți și să lipiți?',
		'Header-Upload': 'Încărca fișiere',
		Error: 'Eroare',
		'Validation-Empty': 'Numele fișierului sau folderului nu poate fi gol.',
		'Validation-Invalid': 'Numele fișierului sau folderului {0} conține caractere nevalide. Vă rugăm să utilizați un alt nume. Numele de fișiere sau folder valide nu se pot termina cu un punct sau un spațiu și nu pot conține niciunul dintre următoarele caractere: \\ /: *? \'<> |',
		'Validation-NewFolder-Exists': 'Există deja un fișier sau folder cu numele {0}.',
		'Validation-Rename-Exists': 'Nu se poate redenumi {0} la {1}: destinația există deja.',
		'Folder-Empty': 'Acest dosar este gol',
		'File-Upload': 'Trageți fișierele aici pentru a le încărca',
		'Search-Empty': 'Nici un rezultat gasit',
		'Search-Key': 'Încercați cu diferite cuvinte cheie',
		'Filter-Empty': 'Nici un rezultat gasit',
		'Filter-Key': 'Încercați cu filtru diferit',
		'Sub-Folder-Error': 'Folderul destinație este subfolderul folderului sursă.',
		'Access-Denied': 'Acces interzis',
		'Access-Details': 'Nu aveți permisiunea de a accesa acest folder.',
		'Header-Retry': 'Fisierul există deja',
		'Content-Retry': 'Un fișier cu acest nume există deja în acest folder. Ce ai vrea sa faci?',
		'Button-Keep-Both': 'Păstrați ambele',
		'Button-Replace': 'A inlocui',
		'Button-Skip': 'Ocolire',
		'ApplyAll-Label': 'Faceți acest lucru pentru toate articolele curente',
		KB: 'KB',
		'Access-Message': '{0} nu este accesibil. Aveți nevoie de permisiunea pentru a efectua acțiunea {1}.',
		'Network-Error': 'Eroare la trimiterea pe XMLHTTPRequest: Nu a putut fi încărcat',
		'Server-Error': 'Eroare de server: răspuns nevalid din'
	},
	inplaceeditor: {
		save: 'Salvează',
		cancel: 'Anulare',
		loadingText: 'Se încarcă...',
		editIcon: 'Fă clic pentru a edita',
		editAreaClick: 'Fă clic pentru a edita',
		editAreaDoubleClick: 'Fă dublu clic pentru a edita'
	},
	treegrid: {
		Above: 'De mai sus',
		Below: 'De mai jos',
		AddRow: 'Adaugă rândul',
		ExpandAll: 'Extinde toate',
		CollapseAll: 'Reduceți totul'
	},
	colorpicker: {
		Apply: 'Aplică',
		Cancel: 'Anulare',
		ModeSwitcher: 'Mod de comutare'
	},
	uploader: {
		Browse: 'Navigare...',
		Clear: 'golește',
		Upload: 'Încarcă',
		dropFilesHint: 'Sau aruncă fișierele aici',
		invalidMaxFileSize: 'Dimensiunea fișierului este prea mare',
		invalidMinFileSize: 'Dimensiunea fișierului este prea mică',
		invalidFileType: 'Tipul fișierului nu este permis',
		uploadFailedMessage: 'Fișierul nu s-a putut încărca',
		uploadSuccessMessage: 'fisier încărcat cu succes',
		removedSuccessMessage: 'Fișierul a fost eliminat cu succes',
		removedFailedMessage: 'Imposibil de eliminat fișierul',
		inProgress: 'Se încarcă',
		readyToUploadMessage: 'Gata de încărcare',
		abort: 'Abandonează',
		remove: 'Elimină',
		cancel: 'Anulare',
		delete: 'Șterge fișierul',
		pauseUpload: 'Încărcarea fișierului a fost întreruptă',
		pause: 'Pauză',
		resume: 'Reluare',
		retry: 'Reîncercare',
		fileUploadCancel: 'Încărcarea fișierului a fost anulată'
	},
	numerictextbox: {
		incrementTitle: 'Incrementare valoare',
		decrementTitle: 'Decrementare valoare'
	},
	slider: {
		incrementTitle: 'Crește',
		decrementTitle: 'Scade'
	},
	formValidator: {
		required: 'Acest câmp este obligatoriu.',
		email: 'Te rog să introduci o adresa de email validă.',
		url: 'Te rog să introduci o adresă URL validă.',
		date: 'Te rog să introduci o dată validă.',
		dateIso: 'Te rog să introduci o dată valabilă (ISO).',
		creditcard: 'Te rog să introduci numărul de card valabil',
		number: 'Te rog să introduci un număr valid.',
		digits: 'Te rog introdu doar cifre.',
		maxLength: 'Te rog să introduci nu mai mult de {0} caractere.',
		minLength: 'Te rog să introduci cel puțin {0} caractere.',
		rangeLength: 'Te rog să introduci o valoare între {0} și {1} caractere.',
		range: 'Te rog să introduci o valoare între {0} și {1}.',
		max: 'Te rog să introduci o valoare mai mică sau egală cu {0}.',
		min: 'Te rog să introduci o valoare mai mare sau egală cu {0}.',
		regex: 'Te rog să introduci o valoare corectă.',
		tel: 'Va rugam sa introduceti un numar de telefon valid.',
		pattern: 'Te rog să introduci o valoare corectă a modelului.',
		equalTo: 'Te rog să introduci textul de potrivire valid'
	},
	richtexteditor: {
		alignments: 'Aliniere text',
		justifyLeft: 'Aliniere la stânga',
		justifyCenter: 'Aliniere la centru',
		justifyRight: 'Aliniere dreapta',
		justifyFull: 'Aliniere justificată',
		fontName: 'Numele fontului',
		fontSize: 'Marimea fontului',
		fontColor: 'Culoarea fontului',
		backgroundColor: 'Culoare de fundal',
		bold: 'Îngroșat',
		italic: 'Înclinat',
		underline: 'Subliniat',
		strikethrough: 'Tăiat',
		clearFormat: 'Ștergeți formatul',
		clearAll: 'Curata tot',
		cut: 'A tăia',
		copy: 'Copie',
		paste: 'Pastă',
		unorderedList: 'Listă cu puncte',
		orderedList: 'Lista numerotată',
		indent: 'Mărește indentificarea',
		outdent: 'Micșorează indentificarea',
		undo: 'Anula',
		redo: 'A reface',
		superscript: 'Exponent',
		subscript: 'subscript',
		createLink: 'Introduceți hyperlink',
		openLink: 'Deschide link-ul',
		editLink: 'Editează linkul',
		removeLink: 'Eliminați linkul',
		image: 'Inserați imagine',
		replace: 'A inlocui',
		align: 'Alinia',
		caption: 'Legenda imaginii',
		remove: 'Elimina',
		insertLink: 'Inserare link',
		display: 'Afişa',
		altText: 'Text alternativ',
		dimension: 'Modificați dimensiunea',
		fullscreen: 'Ecran complet',
		maximize: 'Maximizare',
		minimize: 'Minimizare',
		lowerCase: 'Minuscule',
		upperCase: 'Majuscule',
		print: 'Imprimare',
		formats: 'Formatare text',
		sourcecode: 'Vizualizare cod',
		preview: 'previzualizare',
		viewside: 'ViewSide',
		insertCode: 'Introduce codul',
		linkText: 'Afișează textul',
		linkTooltipLabel: 'Titlu',
		linkWebUrl: 'Adresa de internet',
		linkTitle: 'Introduceți un titlu',
		linkurl: 'http://example.com',
		linkOpenInNewWindow: 'Deschide link-ul într-o fereastră nouă',
		linkHeader: 'Inserare link',
		dialogInsert: 'Inserare',
		dialogCancel: 'Anulare',
		dialogUpdate: 'Actualizare',
		imageHeader: 'Inserare imagine',
		imageLinkHeader: 'Puteți furniza, de asemenea, un link de pe web',
		mdimageLink: 'Vă rugăm să furnizați o adresă URL pentru imaginea dvs.',
		imageUploadMessage: 'Aruncați imaginea aici sau navigați pentru a încărca',
		imageDeviceUploadMessage: 'Faceți clic aici pentru a încărca',
		imageAlternateText: 'Text alternativ',
		alternateHeader: 'Text alternativ',
		browse: 'Navigă',
		imageUrl: 'http://example.com/image.png',
		imageCaption: 'Legendă',
		imageSizeHeader: 'Marimea imaginii',
		imageHeight: 'Înălţime',
		imageWidth: 'Lăţime',
		textPlaceholder: 'Introduceți text',
		inserttablebtn: 'Inserare',
		tabledialogHeader: 'Inserare tabel',
		tableWidth: 'Lăţime',
		cellpadding: 'Margine celule',
		cellspacing: 'Spațiere celule',
		columns: 'Numar de coloane',
		rows: 'Număr de rânduri',
		tableRows: 'Rânduri',
		tableColumns: 'Coloane',
		tableCellHorizontalAlign: 'Aliniere orizontală a celulelor',
		tableCellVerticalAlign: 'Alinierea verticală a celulelor',
		createTable: 'Inserare tabel',
		removeTable: 'Eliminare tabel',
		tableHeader: 'Antet tabel',
		tableRemove: 'Eliminare tabel',
		tableCellBackground: 'Fundalul celulei',
		tableEditProperties: 'Proprietăți de editare a tabelului',
		styles: 'stiluri',
		insertColumnLeft: 'Inserare coloană la stânga',
		insertColumnRight: 'Inserare coloană la dreapta',
		deleteColumn: 'Eliminare coloană',
		insertRowBefore: 'Inserare rând înainte',
		insertRowAfter: 'Inserare rând după',
		deleteRow: 'Eliminare rând',
		tableEditHeader: 'Editare tabel',
		TableHeadingText: 'Titlu',
		TableColText: 'Col',
		imageInsertLinkHeader: 'Inserare link',
		editImageHeader: 'Editează imagine',
		alignmentsDropDownLeft: 'Aliniere la stânga',
		alignmentsDropDownCenter: 'Aliniere la centru',
		alignmentsDropDownRight: 'Aliniere la dreapta',
		alignmentsDropDownJustify: 'Aliniere justificată',
		imageDisplayDropDownInline: 'În linie',
		imageDisplayDropDownBreak: 'Pauză',
		tableInsertRowDropDownBefore: 'Inserare rând înainte',
		tableInsertRowDropDownAfter: 'Inserare rând după',
		tableInsertRowDropDownDelete: 'Eliminare rând',
		tableInsertColumnDropDownLeft: 'Inserare coloană la stânga',
		tableInsertColumnDropDownRight: 'Inserare coloană la dreapta',
		tableInsertColumnDropDownDelete: 'Eliminare coloană',
		tableVerticalAlignDropDownTop: 'Aliniere sus',
		tableVerticalAlignDropDownMiddle: 'Aliniere mijloc',
		tableVerticalAlignDropDownBottom: 'Aliniere jos',
		tableStylesDropDownDashedBorder: 'Chenar întrerupt',
		tableStylesDropDownAlternateRows: 'Rânduri alternative',
		pasteFormat: 'Format lipire text',
		pasteFormatContent: 'Alegeți acțiunea de formatare',
		plainText: 'Text simplu',
		cleanFormat: 'Curăță formatul',
		keepFormat: 'Păstrează formatul',
		pasteDialogOk: 'Lipire',
		pasteDialogCancel: 'Anulare',
		fileManager: 'Manager de fișiere',
		fileDialogHeader: 'Manager de fișiere'
	},
	diagram: {
		Copy: 'Copie',
		Cut: 'A tăia',
		Paste: 'Pastă',
		Undo: 'Anula',
		Redo: 'A reface',
		SelectAll: 'Selectează tot',
		Grouping: 'gruparea',
		Group: 'grup',
		UnGroup: 'Degrupeaza',
		Order: 'Ordin',
		BringToFront: 'Aduceți în față',
		MoveForward: 'Mergi inainte',
		SendToBack: 'Trimite înapoi',
		SendBackward: 'Trimite înapoi'
	},
	DocumentEditor: {
		Table: 'Tabel',
		Row: 'Rând',
		Cell: 'celulă',
		Ok: 'O.K',
		Cancel: 'Anulare',
		Size: 'mărimea',
		'Preferred Width': 'Lățimea preferată',
		Points: 'puncte',
		Percent: 'La sută',
		'Measure in': 'Măsurați în',
		Alignment: 'Aliniere',
		Left: 'Stânga',
		Center: 'Centru',
		Right: 'Dreapta',
		Justify: 'Justifica',
		'Indent from left': 'Indentare de la stânga',
		'Borders and Shading': 'Granițele și umbrirea',
		Options: 'Opțiuni',
		'Specify height': 'Precizați înălțimea',
		'At least': 'Macar',
		Exactly: 'Exact',
		'Row height is': 'Înălțimea rândului este',
		'Allow row to break across pages': 'Permiteți rândului să împartă paginile',
		'Repeat as header row at the top of each page': 'Repetați ca rândul antet în partea de sus a fiecărei pagini',
		'Vertical alignment': 'Aliniere verticală',
		Top: 'Top',
		Bottom: 'Partea de jos',
		'Default cell margins': 'Marjele celulare implicite',
		'Default cell spacing': 'Spațiul implicit al celulelor',
		'Allow spacing between cells': 'Permiteți distanțarea între celule',
		'Cell margins': 'Marjele celulare',
		'Same as the whole table': 'La fel ca întreaga masă',
		Borders: 'Frontiere',
		None: 'Nici unul',
		Style: 'Stil',
		Width: 'Lăţime',
		Height: 'Înălţime',
		Letter: 'Scrisoare',
		Tabloid: 'tabloid',
		Legal: 'legal',
		Statement: 'Afirmație',
		Executive: 'Executiv',
		A3: 'A3',
		A4: 'A4',
		A5: 'A5',
		B4: 'B4',
		B5: 'B5',
		'Custom Size': 'Marime personalizata',
		'Different odd and even': 'Diferite și impar',
		'Different first page': 'Prima pagină diferită',
		'From edge': 'De la margine',
		Header: 'Antet',
		Footer: 'Subsol',
		Margin: 'Margini',
		Paper: 'Hârtie',
		Layout: 'schemă',
		Orientation: 'Orientare',
		Landscape: 'Peisaj',
		Portrait: 'Portret',
		'Table Of Contents': 'Cuprins',
		'Show page numbers': 'Afișează numerele paginii',
		'Right align page numbers': 'Alegeți numerele drepte ale paginii',
		Nothing: 'Nimic',
		'Tab leader': 'Lider de file',
		'Show levels': 'Afișează nivelurile',
		'Use hyperlinks instead of page numbers': 'Folosiți hyperlink-uri în loc de numere de pagină',
		'Build table of contents from': 'Construiți un conținut din',
		Styles: 'stiluri',
		'Available styles': 'Stiluri disponibile',
		'TOC level': 'Nivelul TOC',
		Heading: 'Titlu',
		'List Paragraph': 'Paragraful listei',
		Normal: 'Normal',
		'Outline levels': 'Niveluri contur',
		'Table entry fields': 'Câmpuri de intrare în tabel',
		Modify: 'Modifica',
		Color: 'Culoare',
		Setting: 'reglaj',
		Box: 'Cutie',
		All: 'Toate',
		Custom: 'Personalizat',
		Preview: 'previzualizare',
		Shading: 'Umbrire',
		Fill: 'Completati',
		'Apply To': 'Aplica pentru',
		'Table Properties': 'Proprietățile tabelului',
		'Cell Options': 'Opțiuni celulare',
		'Table Options': 'Opțiuni de tabel',
		'Insert Table': 'Inserare tabel',
		'Number of columns': 'Numar de coloane',
		'Number of rows': 'Număr de rânduri',
		'Text to display': 'Text de afișat',
		Address: 'Abordare',
		'Insert Hyperlink': 'Inserare link',
		'Edit Hyperlink': 'Editare link',
		Insert: 'Introduce',
		General: 'General',
		Indentation: 'zimțuire',
		'Before text': 'Înainte de text',
		Special: 'Special',
		'First line': 'Prima linie',
		Hanging: 'Agăţat',
		'After text': 'După text',
		By: 'De',
		Before: 'Inainte de',
		'Line Spacing': 'Distanța de linie',
		After: 'După',
		At: 'La',
		Multiple: 'Multiplu',
		Spacing: 'spaţiere',
		'Define new Multilevel list': 'Definiți o nouă listă pe mai multe niveluri',
		'List level': 'Nivel de listă',
		'Choose level to modify': 'Alegeți nivelul de modificat',
		Level: 'Nivel',
		'Number format': 'Formatul numărului',
		'Number style for this level': 'Stilul numerelor pentru acest nivel',
		'Enter formatting for number': 'Introduceți formatarea pentru numărul',
		'Start at': 'Incepe la',
		'Restart list after': 'Reporniți lista după',
		Position: 'Poziţie',
		'Text indent at': 'Litiu de text la',
		'Aligned at': 'Aliniat la',
		'Follow number with': 'Urmăriți numărul cu',
		'Tab character': 'Caracterul filei',
		Space: 'Spaţiu',
		Arabic: 'arabic',
		UpRoman: 'UpRoman',
		LowRoman: 'LowRoman',
		UpLetter: 'UpLetter',
		LowLetter: 'LowLetter',
		Number: 'Număr',
		'Leading zero': 'Conducerea zero',
		Bullet: 'Glonţ',
		Ordinal: 'Ordinal',
		'Ordinal Text': 'Text ordinal',
		'For East': 'Pentru est',
		'No Restart': 'Fără restart',
		Font: 'Font',
		'Font style': 'Stilul fontului',
		'Underline style': 'Stil subliniat',
		'Font color': 'Culoarea fontului',
		Effects: 'Efecte',
		Strikethrough: 'Tăiat',
		Superscript: 'Exponent',
		Subscript: 'subscript',
		'Double strikethrough': 'Dublu strikethrough',
		Regular: 'Regulat',
		Bold: 'Îngroșat',
		Italic: 'Înclinat',
		Cut: 'A tăia',
		Copy: 'Copie',
		Paste: 'Pastă',
		Hyperlink: 'Hyperlink',
		'Open Hyperlink': 'Deschideți hyperlink',
		'Copy Hyperlink': 'Copiați hyperlink',
		'Remove Hyperlink': 'Îndepărtați Hyperlink',
		Paragraph: 'Paragraf',
		'Merge Cells': 'Uneste celulele',
		'Insert Above': 'Introduceți mai sus',
		'Insert Below': 'Introduceți mai jos',
		'Insert Left': 'Introduceți în stânga',
		'Insert Right': 'Introduceți dreapta',
		Delete: 'Șterge',
		'Delete Table': 'Ștergeți tabelul',
		'Delete Row': 'Ștergeți rândul',
		'Delete Column': 'Ștergeți coloana',
		'File Name': 'Nume de fișier',
		'Format Type': 'Tip de format',
		Save: 'salva',
		Navigation: 'Navigare',
		Results: 'Rezultate',
		Replace: 'A inlocui',
		'Replace All': 'Înlocuiește-le pe toate',
		'We replaced all': 'Am înlocuit toate',
		Find: 'Găsi',
		'No matches': 'Nici-o potrivire',
		'All Done': 'Totul este gata',
		Result: 'Rezultat',
		'of': 'de',
		instances: 'instanțe',
		'with': 'cu',
		'Click to follow link': 'Faceți clic pentru a urma linkul',
		'Continue Numbering': 'Continuați numerotarea',
		'Bookmark name': 'Numele marcajului',
		Close: 'Închide',
		'Restart At': 'Reporniți At',
		Properties: 'Proprietăți',
		Name: 'Nume',
		'Style type': 'Tip de stil',
		'Style based on': 'Stil bazat pe',
		'Style for following paragraph': 'Stil pentru următorul alineat',
		Formatting: 'Formatarea',
		'Numbering and Bullets': 'Numerotare și Buletine',
		Numbering: 'Numerotare',
		'Update Field': 'Câmpul de actualizare',
		'Edit Field': 'Modificați câmpul',
		Bookmark: 'semn de carte',
		'Page Setup': 'Configurare pagina',
		'No bookmarks found': 'Nu au fost găsite marcaje',
		'Number format tooltip information': 'Format de număr la un singur nivel: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> De exemplu, Capitolul% 1. va afișa numerotarea ca </br> Capitolul 1. Elemente </br> Capitolul 2. Elemente </br> ... </br> Capitolul N. Elemente </br> </br> Formatul numerelor pe mai multe niveluri: </ br > [PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> De exemplu,% 1.% 2. va afișa numerotare ca </br> 1.1. Postul </br> 1.2. Articol </br> ... </br> 1.N. Articol',
		Format: 'Format',
		'Create New Style': 'Creați stil nou',
		'Modify Style': 'Modificați stilul',
		New: 'Nou',
		Bullets: 'Marcatori',
		'Use bookmarks': 'Folosiți marcaje',
		'Table of Contents': 'Cuprins'
	},
	barcode: {},
	datamatrix: {},
	qrcode: {},
	drawing: {},
	schedule: {
		day: 'Zi',
		week: 'Săptămână',
		workWeek: 'Saptamana de lucru',
		month: 'Lună',
		agenda: 'Agendă',
		weekAgenda: 'Agenda săptămânii',
		workWeekAgenda: 'Agenda săptămânii de lucru',
		monthAgenda: 'Agenda lunară',
		today: 'Astăzi',
		noEvents: 'Fără evenimente',
		emptyContainer: 'Nu există evenimente programate în această zi.',
		allDay: 'Toată ziua',
		start: 'start',
		end: 'Sfârșit',
		more: 'Mai Mult',
		close: 'Închide',
		cancel: 'Anulare',
		noTitle: '(Fara titlu)',
		delete: 'Șterge',
		deleteEvent: 'Acest eveniment',
		deleteMultipleEvent: 'Ștergeți mai multe evenimente',
		selectedItems: 'Articole selectate',
		deleteSeries: 'Serie întreagă',
		edit: 'Editați | ×',
		editSeries: 'Serie întreagă',
		editEvent: 'Acest eveniment',
		createEvent: 'Crea',
		subject: 'Subiect',
		addTitle: 'Adauga titlu',
		moreDetails: 'Mai multe detalii',
		save: 'salva',
		editContent: 'Cum ați dori să schimbați programarea în serie?',
		deleteContent: 'Sigur doriți să ștergeți acest eveniment?',
		deleteMultipleContent: 'Sigur doriți să ștergeți evenimentele selectate?',
		newEvent: 'Eveniment nou',
		title: 'Titlu',
		location: 'Locație',
		description: 'Descriere',
		timezone: 'Fus orar',
		startTimezone: 'Start Timezone',
		endTimezone: 'Fusul orar final',
		repeat: 'Repeta',
		saveButton: 'salva',
		cancelButton: 'Anulare',
		deleteButton: 'Șterge',
		recurrence: 'recurență',
		wrongPattern: 'Modelul de recurență nu este valid.',
		seriesChangeAlert: 'Doriți să anulați modificările aduse unor instanțe specifice ale acestei serii și să o potriviți din nou la întreaga serie?',
		createError: 'Durata evenimentului trebuie să fie mai scurtă decât cât de des are loc. Scurtați durata sau modificați modelul de recurență în editorul de evenimente de recurență.',
		sameDayAlert: 'Două apariții ale aceluiași eveniment nu pot apărea în aceeași zi.',
		editRecurrence: 'Editează recurența',
		repeats: 'repetă',
		alert: 'Alerta',
		startEndError: 'Data de încheiere selectată apare înainte de data de început.',
		invalidDateError: 'Valoarea dată introdusă nu este validă.',
		blockAlert: 'Evenimentele nu pot fi programate în intervalul de timp blocat.',
		ok: 'O.K',
		yes: 'da',
		no: 'Nu',
		occurrence: 'Apariţie',
		series: 'Serie',
		previous: 'Anterior',
		next: 'Următor →',
		timelineDay: 'Ziua cronologiei',
		timelineWeek: 'Saptamana Timeline',
		timelineWorkWeek: 'Săptămâna muncii Timeline',
		timelineMonth: 'Luna cronologică',
		timelineYear: 'Anul cronologic',
		editFollowingEvent: 'În urma evenimentelor',
		deleteTitle: 'Ștergeți evenimentul',
		editTitle: 'Editează evenimentul',
		beginFrom: 'Începeți de la',
		endAt: 'Încheiați la'
	},
	recurrenceeditor: {
		none: 'Nici unul',
		daily: 'Zilnic',
		weekly: 'Săptămânal',
		monthly: 'Lunar',
		month: 'Lună',
		yearly: 'Anual',
		never: 'Nu',
		until: 'Pana cand',
		count: 'Numara',
		first: 'Primul',
		second: 'Al doilea',
		third: 'Al treilea',
		fourth: 'Al patrulea',
		last: 'Ultimul',
		repeat: 'Repeta',
		repeatEvery: 'Repetați fiecare',
		on: 'Repetați Pornit',
		end: 'Sfârșit',
		onDay: 'Zi',
		days: 'Ziua (s)',
		weeks: 'Săptămâna (s)',
		months: 'Luni)',
		years: 'Ani)',
		every: 'fiecare',
		summaryTimes: 'timp (s)',
		summaryOn: 'pe',
		summaryUntil: 'pana cand',
		summaryRepeat: 'repetă',
		summaryDay: 'zi (s)',
		summaryWeek: 'săptămână (s)',
		summaryMonth: 'luni)',
		summaryYear: 'ani)',
		monthWeek: 'Săptămâna lunii',
		monthPosition: 'Poziția lunară',
		monthExpander: 'Expander lunar',
		yearExpander: 'Anul Expander',
		repeatInterval: 'Repetați intervalul'
	},
	spreadsheet: {
		InsertingEmptyValue: 'Valoarea de referință nu este valabilă.',
		FindValue: 'Găsiți valoare',
		ReplaceValue: 'Înlocuiți valoarea',
		FindReplaceTooltip: 'Găsiți și înlocuiți',
		ByRow: ' Pe rânduri',
		ByColumn: 'Prin Coloane',
		MatchExactCellElements: 'Potriviți conținutul celulelor exact',
		EntercellAddress: 'Introduceți adresa celulei',
		FindAndReplace: 'Găsiți și înlocuiți',
		ReplaceAllEnd: ' chibrituri înlocuite cu',
		FindNextBtn: 'Găsește următorul',
		FindPreviousBtn: 'Găsiți anterior',
		ReplaceBtn: 'A inlocui',
		ReplaceAllBtn: 'Înlocuiește-le pe toate',
		GotoHeader: 'Mergi la',
		GotoSpecialHeader: 'Mergeți la Special',
		Sheet: 'Foaie',
		SearchWithin: 'Căutați în interior',
		SearchBy: 'Cautat de',
		Reference: 'Referinţă',
		Workbook: 'Workbook',
		NoElements: 'Nu am putut găsi ce căutați. Faceți clic pe opțiuni pentru mai multe modalități de căutare',
		FindWhat: 'Gaseste ce',
		ReplaceWith: 'Înlocui cu',
		EnterValue: 'Introduceți valoarea',
		Cut: 'A tăia',
		Copy: 'Copie',
		Paste: 'Pastă',
		PasteSpecial: 'Paste speciale',
		All: 'Toate',
		Values: 'valori',
		Formats: 'Formatare text',
		Font: 'Font',
		FontSize: 'Marimea fontului',
		Bold: 'Îngroșat',
		Italic: 'Înclinat',
		Underline: 'Subliniat',
		Strikethrough: 'Tăiat',
		TextColor: 'Culoarea textului',
		FillColor: 'Culoare umplere',
		HorizontalAlignment: 'Aliniere orizontala',
		AlignLeft: 'Alinia la stânga',
		AlignCenter: 'Centru',
		AlignRight: 'Aliniați dreapta',
		VerticalAlignment: 'Aliniere verticală',
		AlignTop: 'Aliniați Topul',
		AlignMiddle: 'Aliniați mijlocul',
		AlignBottom: 'Aliniați partea de jos',
		MergeCells: 'Uneste celulele',
		MergeAll: 'Îmbină toți',
		MergeHorizontally: 'Fuziune orizontală',
		MergeVertically: 'Unire pe verticală',
		Unmerge: 'unmerge',
		UnmergeCells: 'Unmerge Cell',
		SelectMergeType: 'Selectați Tip Merge',
		MergeCellsAlert: 'Celulele de fuziune vor păstra doar valoarea de sus-stânga (de sus). Mergeți oricum?',
		PasteMergeAlert: 'Nu putem face asta într-o celulă de îmbinare.',
		Borders: 'Frontiere',
		TopBorders: 'Frontierele de vârf',
		LeftBorders: 'Frontierele de stânga',
		RightBorders: 'Frontierele drepte',
		BottomBorders: 'Frontierele de jos',
		AllBorders: 'Toate granițele',
		HorizontalBorders: 'Frontiere orizontale',
		VerticalBorders: 'Granițele verticale',
		OutsideBorders: 'În afara granițelor',
		InsideBorders: 'În interiorul granițelor',
		NoBorders: 'Fără granițe',
		BorderColor: 'Culoare de graniță',
		BorderStyle: 'Stil de graniță',
		InsertFunction: 'Funcția Insert',
		Insert: 'Introduce',
		Delete: 'Șterge',
		Rename: 'Redenumire',
		Hide: 'Ascunde',
		Unhide: 'Unhide',
		NameBox: 'Caseta de nume',
		ShowHeaders: 'Afișează anteturile',
		HideHeaders: 'Ascundeți anteturile',
		ShowGridLines: 'Afișare grile',
		HideGridLines: 'Ascundeți rețelele',
		AddSheet: 'Adaugă foaie',
		ListAllSheets: 'Lista tuturor foilor',
		FullScreen: 'Ecran complet',
		CollapseToolbar: 'Bara de instrumente Colaps',
		ExpandToolbar: 'Extindeți Bara de instrumente Google',
		CollapseFormulaBar: 'Colaps Formula Bar',
		ExpandFormulaBar: 'Extindeți Formula Bar',
		File: 'Fişier',
		Home: 'Acasă',
		Formulas: 'Formulele',
		View: 'Vedere',
		New: 'Nou',
		Open: 'Deschis',
		SaveAs: 'Salvează ca',
		ExcelXlsx: 'Microsoft Excel',
		ExcelXls: 'Microsoft Excel 97-2003',
		CSV: 'Valori separate prin virgulă',
		FormulaBar: 'Formula Bar',
		Sort: 'Fel',
		SortAscending: 'Ascendent',
		SortDescending: 'Descendentă',
		CustomSort: 'Sortare personalizată',
		AddColumn: 'Adaugă Coloana',
		ContainsHeader: 'Datele conțin antet',
		CaseSensitive: 'Caz sensibil',
		SortBy: 'Filtrează după',
		ThenBy: 'Apoi prin',
		SelectAColumn: 'Selectați o coloană',
		SortEmptyFieldError: 'Toate criteriile de sortare trebuie să aibă o coloană specificată. Verificați criteriile de sortare selectate și încercați din nou.',
		SortDuplicateFieldError: ' este sortat după valori de mai multe ori. Ștergeți criteriile de sortare duplicate și încercați din nou.',
		SortOutOfRangeError: 'Selectați o celulă sau un interval din intervalul utilizat și încercați din nou.',
		HideRow: 'Ascundeți rândul',
		HideRows: 'Ascundeți rândurile',
		UnHideRows: 'UnHide Rows',
		HideColumn: 'Ascunde coloana',
		HideColumns: 'Ascundeți Coloanele',
		UnHideColumns: 'Coloanați ascundeți',
		InsertRow: 'Introduceți rândul',
		InsertRows: 'Introduceți rândurile',
		Above: 'De mai sus',
		Below: 'De mai jos',
		InsertColumn: 'Introduceți coloana',
		InsertColumns: 'Introduceți Coloane',
		Before: 'Inainte de',
		After: 'După',
		DeleteRow: 'Ștergeți rândul',
		DeleteRows: 'Ștergeți rândurile',
		DeleteColumn: 'Ștergeți coloana',
		DeleteColumns: 'Ștergeți Coloanele',
		Ok: 'O.K',
		Close: 'Închide',
		Cancel: 'Anulare',
		Apply: 'aplica',
		MoreColors: 'Mai multe culori',
		StandardColors: 'Culori standard',
		General: 'General',
		Number: 'Număr',
		Currency: 'Valută',
		Accounting: 'Contabilitate',
		ShortDate: 'Scurtă dată',
		LongDate: 'Long Date',
		Time: 'Timp',
		Percentage: 'Procent',
		Fraction: 'Fracțiune',
		Scientific: 'Științific',
		Text: 'Text',
		NumberFormat: 'Formatul numărului',
		MobileFormulaBarPlaceHolder: 'Introduceți valoarea sau formula',
		PasteAlert: 'Nu puteți lipi acest lucru aici, deoarece zona de copiere și zona de lipire nu au aceeași dimensiune. Încercați să lipiți într-un interval diferit.',
		DestroyAlert: 'Sunteți sigur că doriți să distrugeți cartea de lucru curentă fără să economisiți și să creați o carte de lucru nouă?',
		SheetRenameInvalidAlert: 'Numele foii conține un caracter nevalid.',
		SheetRenameEmptyAlert: 'Numele foii nu poate fi gol.',
		SheetRenameAlreadyExistsAlert: 'Numele foii există deja. Vă rugăm să introduceți un alt nume.',
		DeleteSheetAlert: 'Sigur doriți să ștergeți această foaie?',
		DeleteSingleLastSheetAlert: 'Un registru de lucru trebuie să conțină cel puțin o fișă de lucru vizibilă.',
		PickACategory: 'Alege o categorie',
		Description: 'Descriere',
		UnsupportedFile: 'Fisier nesuportat',
		InvalidUrl: 'URL invalid',
		SUM: 'Adaugă o serie de numere și / sau celule.',
		SUMIF: 'Adăugă celulele în funcție de condiția specificată.',
		SUMIFS: 'Adăugă celulele pe baza unor condiții specificate.',
		ABS: 'Returnează valoarea unui număr fără semnul său.',
		RAND: 'Returnează un număr aleatoriu între 0 și 1.',
		RANDBETWEEN: 'Returnează un număr întreg aleatoriu bazat pe valori specificate.',
		FLOOR: 'Rotunji un număr până la cel mai apropiat multiplu al unui factor dat.',
		CEILING: 'Rotunji un număr până la cel mai apropiat multiplu al unui factor dat.',
		PRODUCT: 'Multiplică o serie de numere și / sau celule.',
		AVERAGE: 'Calculează media pentru seria de numere și / sau celule, cu excepția textului.',
		AVERAGEIF: 'Calculează media pentru celule pe baza criteriului specificat.',
		AVERAGEIFS: 'Calculează media pentru celule pe baza unor condiții specificate.',
		AVERAGEA: 'Calculează media celulelor care evaluează TRUE ca 1, text și FALSE ca 0.',
		COUNT: 'Numără celulele care conțin valori numerice într-un interval.',
		COUNTIF: 'Numără celulele în funcție de condiția specificată.',
		COUNTIFS: 'Numără celulele în funcție de condiții specificate.',
		COUNTA: 'Numără celulele care conțin valori într-un interval.',
		MIN: 'Returnează cel mai mic număr dintre argumentele date.',
		MAX: 'Returnează cel mai mare număr de argumente date.',
		DATE: 'Returnează data în funcție de anul, luna și ziua dată.',
		DAY: 'Returnează ziua de la data dată.',
		DAYS: 'Returnează numărul de zile între două date.',
		IF: 'Returnează valoarea bazată pe expresia dată.',
		IFS: 'Returnează valoarea bazată pe expresiile multiple date.',
		CalculateAND: 'Returnează TRUE dacă toate argumentele sunt TRUE, altfel returnează FALSE.',
		CalculateOR: 'Returnează TRUE dacă vreunul dintre argumente este TRUE, altfel returnează FALSE.',
		IFERROR: 'Întoarce valoarea dacă nu a fost găsită nicio eroare, aceasta va returna valoarea specificată.',
		CHOOSE: 'Returnează o valoare dintr-o listă de valori, pe baza numărului de index.',
		INDEX: 'Returnează o valoare a celulei într-un interval dat pe baza numărului de rând și coloană.',
		FIND: 'Întoarce poziția unei șiruri într-un alt șir, care este sensibil la litere mari și mici',
		CONCATENATE: 'Combină două sau mai multe șiruri.',
		CONCAT: 'Concatenează o listă sau o serie de șiruri de text.',
		SUBTOTAL: 'Întoarce subtotal pentru un interval folosind numărul de funcții dat.',
		RADIANS: 'Convertește grade în radiani.',
		MATCH: 'Returnează poziția relativă a unei valori specificate într-un interval dat.',
		SLOPE: 'Întoarce panta liniei de la regresia liniară a punctelor de date.',
		INTERCEPT: 'Calculează punctul liniei de interceptare Y prin regresie liniară.',
		DefineNameExists: 'Acest nume există deja, încercați alt nume.',
		CircularReference: 'Când o formulă se referă la una sau mai multe referințe circulare, aceasta poate duce la un calcul incorect.',
		ShowRowsWhere: 'Afișează rândurile unde:',
		OR: 'SAU',
		AND: 'ȘI',
		CustomFilterDatePlaceHolder: 'Alegeți o dată',
		CustomFilterPlaceHolder: 'Introduceți valoarea',
		CustomFilter: 'Filtru customizat',
		Between: 'Între',
		MatchCase: 'Caz de potrivire',
		DateTimeFilter: 'Filtre DateTime',
		Undo: 'Anula',
		Redo: 'A reface',
		ClearAllFilter: 'clar',
		ReapplyFilter: 'Reaplicați',
		DateFilter: 'Filtre de date',
		TextFilter: 'Filtre de text',
		NumberFilter: 'Filtre cu număr',
		ClearFilter: 'Ștergeți filtrul',
		NoResult: 'Nu s-a gasit nici o potrivire',
		FilterFalse: 'Fals',
		FilterTrue: 'Adevărat',
		Blanks: 'Blanks',
		SelectAll: 'Selectează tot',
		GreaterThanOrEqual: 'Mai mare decât sau egal',
		GreaterThan: 'Mai mare ca',
		LessThanOrEqual: 'Mai puțin decât sau egal',
		LessThan: 'Mai puțin decât',
		NotEqual: 'Nu este egal',
		Equal: 'Egal',
		Contains: 'Conține',
		EndsWith: 'Se termină cu',
		StartsWith: 'Incepe cu',
		ClearButton: 'clar',
		FilterButton: 'Filtru',
		CancelButton: 'Anulare',
		OKButton: 'O.K',
		Search: 'Căutare',
		DataValidation: 'Data validarii',
		CLEARALL: 'CURATA TOT',
		APPLY: 'APLICA',
		CellRange: 'Gama de celule',
		Allow: 'Permite',
		Data: 'Date',
		Minimum: 'Minim',
		Maximum: 'Maxim',
		IgnoreBlank: 'Ignorați gol',
		WholeNumber: 'Număr întreg',
		Decimal: 'Zecimal',
		Date: 'Data',
		TextLength: 'Lungimea textului',
		List: 'Listă',
		NotBetween: 'Nu între',
		EqualTo: 'Egal cu',
		NotEqualTo: 'Nu este egal cu',
		Greaterthan: 'Mai mare ca',
		Lessthan: 'Mai puțin decât',
		GreaterThanOrEqualTo: 'Mai mare sau egal cu',
		LessThanOrEqualTo: 'Mai mic sau egal cu',
		InCellDropDown: 'In-cell-dropdown',
		Sources: 'surse',
		Value: 'Valoare',
		Retry: 'Reîncercați',
		DialogError: 'Sursa listei trebuie să fie o referință la un singur rând sau coloană.',
		ValidationError: 'Această valoare nu corespunde restricțiilor de validare a datelor definite pentru celulă.',
		ListLengthError: 'Valorile listei permit doar 256 de caractere',
		ProtectSheet: 'Foaie de protecție',
		UnprotectSheet: 'Foaie neprotejată',
		SelectCells: 'Selectați celule',
		FormatCells: 'Celule de format',
		FormatRows: 'Formatează rândurile',
		'Format Columns': 'Formatează coloanele',
		InsertLinks: 'Inserați legături',
		ProtectContent: 'Protejați conținutul celulelor blocate',
		ProtectAllowUser: 'Permiteți tuturor utilizatorilor acestei foi de lucru să:',
		EditAlert: 'Celula pe care încercați să o schimbați este protejată. Pentru a face modificări, protejați foaia.',
		ClearValidation: 'Validare clară',
		ISNUMBER: 'Se întoarce atunci când valoarea analizează ca valoare numerică.',
		Round: 'Rotunji un număr la un număr specificat de cifre.',
		GEOMEAN: 'Returnează media geometrică a unui tablou sau a unui interval de date pozitive.',
		POWER: 'Returnează rezultatul unui număr ridicat la putere',
		LOG: 'Returnează logaritmul unui număr la baza specificată.',
		TRUNC: 'Returnează valoarea trunchiată a unui număr la un număr specificat de zecimale.',
		EXP: 'Se întoarce e crescut la puterea numărului dat.',
		HighlightCellsRules: 'Evidențiați regulile celulelor',
		CFEqualTo: 'Egal cu',
		TextThatContains: 'Text care conține',
		ADateOccuring: 'O întâlnire care se întâmplă',
		DuplicateValues: 'Valori duplicate',
		TopBottomRules: 'Reguli de sus / jos',
		Top10Items: 'Top 10 articole',
		Top10: 'Top 10',
		Bottom10Items: 'Partea de jos a 10 articole',
		Bottom10: 'Fundul 10',
		AboveAverage: 'Peste medie',
		BelowAverage: 'Sub medie',
		FormatCellsGreaterThan: 'Formatați celulele mai mari decât:',
		FormatCellsLessThan: 'Formatați celulele mai mici decât:',
		FormatCellsBetween: 'Formatați celulele care sunt ÎNTRE:',
		FormatCellsEqualTo: 'Formatați celule care sunt EGALE PENTRU:',
		FormatCellsThatContainTheText: 'Formatați celulele care conțin textul:',
		FormatCellsThatContainADateOccurring: 'Formatați celulele care conțin o dată care apare:',
		FormatCellsDuplicate: 'Formatați celulele care conțin:',
		FormatCellsTop: 'Formatați celulele care se clasează în TOP:',
		FormatCellsBottom: 'Formatați celulele care se clasează în partea de jos:',
		FormatCellsAbove: 'Formatați celulele care sunt MAI MULTE:',
		FormatCellsBelow: 'Formatați celulele care SUNT MEDIU:',
		With: 'cu',
		DataBars: 'Bare de date',
		ColorScales: 'Scale de culoare',
		IconSets: 'Seturi de pictograme',
		ClearRules: 'ClearRules',
		SelectedCells: 'Ștergeți regulile din celulele selectate',
		EntireSheet: 'Ștergeți regulile din întreaga foaie',
		LightRedFillWithDarkRedText: 'Umpleți roșu deschis cu text roșu închis',
		YellowFillWithDarkYellowText: 'Umplere galbenă cu text galben închis',
		GreenFillWithDarkGreenText: 'Completați verde cu text verde închis',
		RedFill: 'Umplere roșie',
		RedText: 'Text roșu',
		Duplicate: 'Duplicat',
		Unique: 'Unic',
		And: 'și',
		WebPage: 'PAGINĂ WEB',
		ThisDocument: 'ACEST DOCUMENT',
		DisplayText: 'Afișare text',
		Url: 'URL',
		CellReference: 'Referință celulară',
		DefinedNames: 'Nume definite',
		EnterTheTextToDisplay: 'Introduceți textul de afișat',
		EnterTheUrl: 'Introduceți adresa URL',
		INT: 'Returnează un număr la cel mai apropiat număr întreg.',
		SUMPRODUCT: 'Returnează suma produsului gamelor date de matrice.',
		TODAY: 'Returnează data curentă ca valoare a datei.',
		ROUNDUP: 'Rotunjește un număr de la zero.',
		SelectBorderOption: 'Selectați opțiunea de frontieră'
	},
	pdfviewer: {
		PdfViewer: 'PDF Viewer',
		Cancel: 'Anulare',
		'Download file': 'Descărcare fișier',
		Download: 'Descarca',
		'Enter Password': 'Acest document este protejat prin parolă. Vă rugăm să introduceți o parolă.',
		'File Corrupted': 'Fisier corupt',
		'File Corrupted Content': 'Fișierul este corupt și nu poate fi deschis.',
		'Fit Page': 'Pagina potrivit',
		'Fit Width': 'Lățimea potrivită',
		Automatic: 'Automat',
		'Go To First Page': 'Afișează prima pagină',
		'Invalid Password': 'Parola incorecta. Vă rugăm să încercați din nou.',
		'Next Page': 'Afișează pagina următoare',
		OK: 'O.K',
		Open: 'Deschide fișierul',
		'Page Number': 'Numărul curent al paginii',
		'Previous Page': 'Arată pagina anterioară',
		'Go To Last Page': 'Afișează ultima pagină',
		Zoom: 'Zoom',
		'Zoom In': 'Mareste',
		'Zoom Out': 'A micsora',
		'Page Thumbnails': 'Miniaturi de pagină',
		Bookmarks: 'Marcaje',
		Print: 'Fisier de printat',
		'Password Protected': 'Parola obligatorie',
		Copy: 'Copie',
		'Text Selection': 'Instrument de selectare a textului',
		Panning: 'Mod Pan',
		'Text Search': 'Găsiți text',
		'Find in document': 'Găsiți în document',
		'Match case': 'Carcasă potrivită',
		Apply: 'aplica',
		GoToPage: 'Mergi la pagina',
		'No matches': 'Viewer a terminat căutarea documentului. Nu s-au găsit mai multe meciuri',
		'No Text Found': 'Nu a fost găsit niciun text',
		Undo: 'Anula',
		Redo: 'A reface',
		Annotation: 'Adaugă sau Editați adnotări',
		Highlight: 'Text subliniat',
		Underline: 'Subliniază textul',
		Strikethrough: 'Strikethrough Text',
		Delete: 'Ștergeți adnotarea',
		Opacity: 'Opacitate',
		'Color edit': 'Schimba culoarea',
		'Opacity edit': 'Schimbă opacitatea',
		'Highlight context': 'A scoate in evidenta',
		'Underline context': 'Subliniat',
		'Strikethrough context': 'Loviti prin',
		'Server error': 'Serviciul web nu ascultă. PDF Viewer depinde de serviciul web pentru toate funcțiile sale. Vă rugăm să începeți serviciul web pentru a continua.',
		'Open text': 'Deschis',
		'First text': 'Prima pagina',
		'Previous text': 'Pagina precedentă',
		'Next text': 'Pagina următoare',
		'Last text': 'Ultima pagina',
		'Zoom in text': 'Mareste',
		'Zoom out text': 'A micsora',
		'Selection text': 'Selecţie',
		'Pan text': 'Tigaie',
		'Print text': 'Imprimare',
		'Search text': 'Căutare',
		'Annotation Edit text': 'Editează adnotarea',
		'Line Thickness': 'Grosimea liniei',
		'Line Properties': 'Proprietăți de linie',
		'Start Arrow': 'Începeți săgeata',
		'End Arrow': 'Săgeata finală',
		'Line Style': 'Stil de linie',
		'Fill Color': 'Culoare umplere',
		'Line Color': 'Culoarea liniei',
		None: 'Nici unul',
		'Open Arrow': 'Deschis',
		'Closed Arrow': 'Închis',
		'Round Arrow': 'Rundă',
		'Square Arrow': 'Pătrat',
		'Diamond Arrow': 'Diamant',
		Cut: 'A tăia',
		Paste: 'Pastă',
		'Delete Context': 'Șterge',
		Properties: 'Proprietăți',
		'Add Stamp': 'Adaugă timbru',
		'Add Shapes': 'Adaugă forme',
		'Stroke edit': 'Schimbați culoarea cursei',
		'Change thickness': 'Schimbați grosimea graniței',
		'Add line': 'Adaugă linie',
		'Add arrow': 'Adaugă săgeata',
		'Add rectangle': 'Adaugă Rectangle',
		'Add circle': 'Adaugă cerc',
		'Add polygon': 'Adaugă Poligon',
		'Add Comments': 'Adaugă comentarii',
		Comments: 'Comentarii',
		'No Comments Yet': 'Nici un comentariu',
		Accepted: 'Admis',
		Completed: 'Efectuat',
		Cancelled: 'Anulat',
		Rejected: 'respins',
		'Leader Length': 'Lungimea liderului',
		'Scale Ratio': 'Raportul scării',
		Calibrate: 'Calibra',
		'Calibrate Distance': 'Calibrați distanța',
		'Calibrate Perimeter': 'Calibrați Perimetrul',
		'Calibrate Area': 'Zona de calibrare',
		'Calibrate Radius': 'Calibrați Radius',
		'Calibrate Volume': 'Calibrați volumul'
	},
	querybuilder: {
		StartsWith: 'Incepe cu',
		EndsWith: 'Se termină cu',
		Contains: 'Conține',
		Equal: 'Egal',
		NotEqual: 'Nu este egal',
		LessThan: 'Mai puțin decât',
		LessThanOrEqual: 'Mai puțin decât sau egal',
		GreaterThan: 'Mai mare ca',
		GreaterThanOrEqual: 'Mai mare decât sau egal',
		Between: 'Între',
		NotBetween: 'Nu între',
		Empty: 'Gol',
		NotEmpty: 'Nu gol',
		In: 'În',
		NotIn: 'Nu în',
		NotContains: 'Nu conține',
		Remove: 'ELIMINA',
		SelectField: 'Selectați un câmp',
		SelectOperator: 'Selectați operatorul',
		DeleteRule: 'Eliminați această condiție',
		DeleteGroup: 'Ștergeți grupul',
		AddGroup: 'Adăugare grup',
		AddCondition: 'Adaugă condiție',
		Edit: 'EDITAȚI | ×',
		ValidationMessage: 'Acest câmp este obligatoriu',
		SummaryViewTitle: 'Vizualizare sumară',
		OtherFields: 'Alte domenii',
		AND: 'ȘI',
		OR: 'SAU',
		SelectValue: 'Introduceți valoare',
		IsEmpty: 'Este gol',
		IsNotEmpty: 'Nu este gol',
		IsNull: 'Este nul',
		IsNotNull: 'Nu este nul',
		True: 'Adevărat',
		False: 'fals'
	},
	grid: {
		EmptyRecord: 'Nu există înregistrări de afișat',
		True: 'Adevărat',
		False: 'Fals',
		InvalidFilterMessage: 'Date de filtrare invalide',
		GroupDropArea: 'Trage un antet de coloană aici pentru a grupa după coloană',
		UnGroup: 'Fă clic aici pentru a degrupa',
		GroupDisable: 'Gruparea este dezactivată pentru această coloană',
		FilterbarTitle: 'celula de bare a filtrului',
		EmptyDataSourceError: 'Sursa de date nu trebuie să fie goală la încărcarea inițială, deoarece coloanele sunt generate din aceasta în autogenerarea coloanelor',
		Add: 'Adaugă',
		Edit: 'Editează',
		Cancel: 'Anulare',
		Update: 'Modificare',
		Delete: 'Eliminare',
		Print: 'Imprimare',
		Pdfexport: 'Export PDF',
		Excelexport: 'Export Excel',
		Wordexport: 'Export Word',
		Csvexport: 'Export CSV',
		Search: 'Căutare',
		Columnchooser: 'coloane',
		Save: 'salvează',
		Item: 'înregistrare',
		Items: 'înregistrări',
		EditOperationAlert: 'Nu există înregistrări selectate pentru operația de editare',
		DeleteOperationAlert: 'Nu există înregistrări selectate pentru operațiunea de ștergere',
		SaveButton: 'salvează',
		OKButton: 'OK',
		CancelButton: 'Anulare',
		EditFormTitle: 'Detaliile',
		AddFormTitle: 'Adaugă înregistrare nouă',
		BatchSaveConfirm: 'Sigur dorești să salvezi modificările?',
		BatchSaveLostChanges: 'Modificările salvate vor fi pierdute. Esti sigur că vrei sa continui?',
		ConfirmDelete: 'Sigur dorești să elimini înregistrarea?',
		CancelEdit: 'Sigur dorești să anulezi modificările?',
		ChooseColumns: 'Alege coloana',
		SearchColumns: 'coloane de căutare',
		Matchs: 'Nu s-a gasit nicio potrivire',
		FilterButton: 'Filtru',
		ClearButton: 'golește',
		StartsWith: 'Începe cu',
		EndsWith: 'Se termină cu',
		Contains: 'Conține',
		Equal: 'Egal',
		NotEqual: 'Nu este egal',
		LessThan: 'Mai puțin decât',
		LessThanOrEqual: 'Mai puțin decât sau egal',
		GreaterThan: 'Mai mare ca',
		GreaterThanOrEqual: 'Mai mare decât sau egal',
		ChooseDate: 'Alege o dată',
		EnterValue: 'Introdu valoarea',
		Copy: 'Copie',
		Group: 'Grupează după această coloană',
		Ungroup: 'Degrupează după această coloană',
		autoFitAll: 'Autodimensionează toate coloanele',
		autoFit: 'Autodimensionează aceasă coloană',
		Export: 'Export',
		FirstPage: 'Prima pagină',
		LastPage: 'Ultima pagină',
		PreviousPage: 'Pagina precedentă',
		NextPage: 'Pagina următoară',
		SortAscending: 'Sortare ascendentă',
		SortDescending: 'Sortare descendentă',
		EditRecord: 'Editează înregistrarea',
		DeleteRecord: 'Elimină înregistrarea',
		FilterMenu: 'Filtru',
		SelectAll: 'Selectează tot',
		Blanks: 'Goluri',
		FilterTrue: 'Adevărat',
		FilterFalse: 'Fals',
		NoResult: 'Nu s-a gasit nicio potrivire',
		ClearFilter: 'Șterge filtrul',
		NumberFilter: 'Filtre cu număr',
		TextFilter: 'Filtre de text',
		DateFilter: 'Filtre de date',
		DateTimeFilter: 'Filtre dată/oră',
		MatchCase: 'Potrivire',
		Between: 'Între',
		CustomFilter: 'Filtru customizat',
		CustomFilterPlaceHolder: 'Introdu valoarea',
		CustomFilterDatePlaceHolder: 'Alege o dată',
		AND: 'ȘI',
		OR: 'SAU',
		ShowRowsWhere: 'Afișează rândurile unde:'
	},
	pager: {
		currentPageInfo: '{0} din {1} pagini',
		totalItemsInfo: '({0} înregistrări)',
		firstPageTooltip: 'Accesează prima pagină',
		lastPageTooltip: 'Accesează ultima pagină',
		nextPageTooltip: 'Accesează pagina următoare',
		previousPageTooltip: 'Accesează pagina anterioară',
		nextPagerTooltip: 'Accesează pagina următoare',
		previousPagerTooltip: 'Accesează pagina anterioară',
		pagerDropDown: 'înregistrări pe pagină',
		pagerAllDropDown: 'Înregistrări',
		All: 'Toate',
		totalItemInfo: '({0} articole)'
	},
	calendar: {
		today: 'Astăzi'
	},
	datepicker: {
		today: 'Astăzi',
		placeholder: 'Alege o dată'
	},
	daterangepicker: {
		placeholder: 'Alege un interval de date',
		startLabel: 'Dată de început',
		endLabel: 'Dată de sfârșit',
		applyText: 'aplică',
		cancelText: 'Anulare',
		selectedDays: 'Zilele alese',
		days: 'zile',
		customRange: 'Gamă personalizată'
	},
	timepicker: {
		placeholder: 'Alege un timp'
	},
	datetimepicker: {
		today: 'Astăzi',
		placeholder: 'Alege data și ora'
	},
	gantt: {
		emptyRecord: 'Nu există înregistrări de afișat',
		id: 'ID-ul',
		name: 'Nume',
		startDate: 'Data de început',
		endDate: 'Data de încheiere',
		duration: 'Durată',
		progress: 'progres',
		dependency: 'Dependenţă',
		notes: 'notițe',
		baselineStartDate: 'Data inițială de început',
		baselineEndDate: 'Data de încheiere de bază',
		taskMode: 'Mod de activități',
		changeScheduleMode: 'Schimbați modul de programare',
		subTasksStartDate: 'Data de început a subTasks-urilor',
		subTasksEndDate: 'Data de încheiere a subTasks-urilor',
		scheduleStartDate: 'Planificați data de început',
		scheduleEndDate: 'Data de încheiere a programului',
		auto: 'Auto',
		manual: 'Manual',
		type: 'Tip',
		offset: 'Decalaj',
		resourceName: 'Resurse',
		resourceID: 'ID-ul resursei',
		day: 'zi',
		hour: 'ora',
		minute: 'minut',
		days: 'zi',
		hours: 'ore',
		minutes: 'minute',
		generalTab: 'General',
		customTab: 'Coloane personalizate',
		writeNotes: 'Scrie Note',
		addDialogTitle: 'Sarcina noua',
		editDialogTitle: 'Informații despre sarcini',
		saveButton: 'salva',
		add: 'Adăuga',
		edit: 'Editați | ×',
		update: 'Actualizați',
		delete: 'Șterge',
		cancel: 'Anulare',
		search: 'Căutare',
		task: ' sarcină',
		tasks: ' sarcini',
		zoomIn: 'Mareste',
		zoomOut: 'A micsora',
		zoomToFit: 'Zoom pentru a se potrivi',
		excelExport: 'Export Excel',
		csvExport: 'Export CSV',
		expandAll: 'Extinde toate',
		collapseAll: 'Reduceți totul',
		nextTimeSpan: 'Durata următoare',
		prevTimeSpan: 'Durata anterioară',
		okText: 'O.K',
		confirmDelete: 'Sigur doriți să ștergeți înregistrarea?',
		from: 'Din',
		to: 'La',
		taskLink: 'Link-ul sarcinii',
		lag: 'întârziere',
		start: 'start',
		finish: 'finalizarea',
		enterValue: 'Introduceți valoarea',
		taskBeforePredecessor_FS: 'Ați mutat \'{0}\' pentru a începe înainte de finalizarea \'{1}\' și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskAfterPredecessor_FS: 'Ați mutat \'{0}\' departe de \'{1}\' și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskBeforePredecessor_SS: 'Ați mutat \'{0}\' pentru a începe înainte de a începe \'{1}\' și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskAfterPredecessor_SS: 'Ați mutat \'{0}\' pentru a începe după ce \'{1}\' începe și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskBeforePredecessor_FF: 'Ați mutat \'{0}\' pentru a termina înainte de finalizarea \'{1}\' și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskAfterPredecessor_FF: 'Ați mutat \'{0}\' pentru a termina după terminarea \'{1}\' și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskBeforePredecessor_SF: 'Ați mutat \'{0}\' departe de \'{1}\' la început și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskAfterPredecessor_SF: 'Ați mutat \'{0}\' pentru a termina după începerea \'{1}\' și cele două sarcini sunt legate. Drept urmare, linkurile nu pot fi onorate. Selectați o acțiune de mai jos pentru a efectua',
		taskInformation: 'Informații despre sarcini',
		deleteTask: 'Ștergeți Task',
		deleteDependency: 'Ștergeți dependența',
		convert: 'Convertit',
		save: 'salva',
		above: 'De mai sus',
		below: 'De mai jos',
		child: 'Copil',
		milestone: 'Milestone',
		toTask: 'Pentru sarcină',
		toMilestone: 'Spre Milestone',
		eventMarkers: 'Markeri de eveniment',
		leftTaskLabel: 'Eticheta sarcinii din stânga',
		rightTaskLabel: 'Eticheta corectă a sarcinii',
		timelineCell: 'Celula cronologică',
		confirmPredecessorDelete: 'Sigur doriți să eliminați linkul de dependență?',
		unit: 'Unitate',
		work: 'Muncă',
		taskType: 'Tipul sarcinii',
		unassignedTask: 'Sarcina neatribuită',
		group: 'grup',
		indent: 'Indentați',
		outdent: 'fără indentare',
		segments: 'Segmente',
		splitTask: 'Sarcină împărțită',
		mergeTask: 'Îmbinați sarcina',
		left: 'Stânga',
		right: 'Dreapta'
	},
	dropdowns: {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat',
		overflowCountTemplate: '+${număr} mai mult ..',
		selectAllText: 'Selectează tot',
		unSelectAllText: 'Deselectează tot',
		totalCountTemplate: '${număr} selectat'
	},
	'drop-down-list': {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat'
	},
	'combo-box': {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat'
	},
	'auto-complete': {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat'
	},
	'multi-select': {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat',
		overflowCountTemplate: '+${număr} mai mult ..',
		selectAllText: 'Selectează tot',
		unSelectAllText: 'Deselectează tot',
		totalCountTemplate: '${număr} selectat'
	},
	listbox: {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat',
		selectAllText: 'Selectează tot',
		unSelectAllText: 'Deselectează tot',
		moveUp: 'Mută în sus',
		moveDown: 'Mută-te în jos',
		moveTo: 'Treceți la',
		moveFrom: 'Misca din',
		moveAllTo: 'Mutați totul la',
		moveAllFrom: 'Mutați totul de la'
	},
	dialog: {
		close: 'Închide'
	},
	'rich-text-editor': {
		alignments: 'Aliniere text',
		justifyleft: 'justifyLeft',
		justifycenter: 'justifyCenter',
		justifyright: 'justifyRight',
		justifyfull: 'justifyFull',
		fontname: 'FontName',
		fontsize: 'marimea fontului',
		fontcolor: 'fONTCOLOR',
		backgroundcolor: 'culoare de fundal',
		clearformat: 'clearFormat',
		clearall: 'curata tot',
		unorderedlist: 'lista neordonata',
		orderedlist: 'lista ordonata',
		createlink: 'Creați link',
		openlink: 'deschide link-ul',
		editlink: 'EDITLINK',
		removelink: 'removeLink',
		openimagelink: 'deschide link-ul',
		editimagelink: 'EDITLINK',
		removeimagelink: 'removeLink',
		image: 'imagine',
		replace: 'a inlocui',
		align: 'alinia',
		caption: 'legendă',
		remove: 'elimina',
		insertlink: 'insertLink',
		display: 'afişa',
		alttext: 'aLTTEXT',
		dimension: 'dimensiune',
		fullscreen: 'Ecran complet',
		maximize: 'Maximizare',
		minimize: 'Minimizare',
		lowercase: 'Minuscule',
		uppercase: 'Majuscule',
		print: 'imprimare',
		formats: 'Formatare text',
		sourcecode: 'cod sursa',
		preview: 'previzualizare',
		viewside: 'viewside',
		insertcode: 'introduce codul',
		justifyLeft: 'Alinia la stânga',
		justifyCenter: 'Aliniere la centru',
		justifyRight: 'Aliniați dreapta',
		justifyFull: 'Aliniază Justificare',
		fontName: 'Numele fontului',
		fontSize: 'Marimea fontului',
		fontColor: 'Culoarea fontului',
		backgroundColor: 'Culoare de fundal',
		bold: 'Îngroșat',
		italic: 'Înclinat',
		underline: 'Subliniat',
		strikethrough: 'strikethrough',
		cut: 'a tăia',
		copy: 'copie',
		paste: 'pastă',
		indent: 'liniuță',
		outdent: 'fără indentare',
		undo: 'Anulare acțiune',
		redo: 'Refacere acțiune',
		superscript: 'superscript',
		subscript: 'subscript',
		createLink: 'Creare link',
		openLink: 'Deschide link-ul',
		editLink: 'Editare link',
		removeLink: 'Eliminare link',
		insertLink: 'Inserare link',
		altText: 'Text alternativ',
		lowerCase: 'Minuscule',
		upperCase: 'Majuscule',
		insertCode: 'Inserare cod',
		linkText: 'Afișare text',
		linkTooltipLabel: 'Indicație',
		linkWebUrl: 'Adresa de internet',
		linkurl: 'http://example.com',
		linkOpenInNewWindow: 'Deschide link-ul într-o fereastră nouă',
		linkHeader: 'Inserare link',
		dialogInsert: 'Inserare',
		dialogCancel: 'Anulare',
		dialogUpdate: 'Actualizare',
		imageHeader: 'Inserare imagine',
		imageLinkHeader: 'Puteți furniza, de asemenea, un link de pe web',
		mdimageLink: 'Vă rugăm să furnizați o adresă URL pentru imaginea dvs.',
		imageUploadMessage: 'Aruncați imaginea aici sau navigați pentru a încărca',
		imageDeviceUploadMessage: 'Faceți clic aici pentru a încărca',
		imageAlternateText: 'Text alternativ',
		alternateHeader: 'Text alternativ',
		browse: 'Navigă',
		imageUrl: 'http://example.com/image.png',
		imageCaption: 'Legendă',
		imageSizeHeader: 'Marimea imaginii',
		imageHeight: 'Înălţime',
		imageWidth: 'Lăţime',
		textPlaceholder: 'Introduceți text'
	},
	'inplace-editor': {
		editIcon: 'Faceți clic pentru a edita',
		save: 'salva',
		cancel: 'Anulare'
	},
	chart: {
		Zoom: 'Zoom',
		ZoomIn: 'Mareste',
		ZoomOut: 'A micsora',
		Reset: 'Resetați',
		Pan: 'Tigaie',
		ResetZoom: 'Resetare Zoom'
	},
	'drop-down-base': {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Solicitarea a eșuat'
	},
	maps: {
		Zoom: 'Zoom',
		ZoomIn: 'Mareste',
		ZoomOut: 'A micsora',
		Reset: 'Resetați',
		Pan: 'Tigaie',
		ResetZoom: 'Resetare Zoom'
	},
	PdfViewer: {
		PdfViewer: 'PDF Viewer',
		Cancel: 'Anulare',
		'Download file': 'Descărcare fișier',
		Download: 'Descarca',
		'Enter Password': 'Acest document este protejat prin parolă. Vă rugăm să introduceți o parolă.',
		'File Corrupted': 'Fisier corupt',
		'File Corrupted Content': 'Fișierul este corupt și nu poate fi deschis.',
		'Fit Page': 'Pagina potrivit',
		'Fit Width': 'Lățimea potrivită',
		Automatic: 'Automat',
		'Go To First Page': 'Afișează prima pagină',
		'Invalid Password': 'Parola incorecta. Vă rugăm să încercați din nou.',
		'Next Page': 'Afișează pagina următoare',
		OK: 'O.K',
		Open: 'Deschide fișierul',
		'Page Number': 'Numărul curent al paginii',
		'Previous Page': 'Arată pagina anterioară',
		'Go To Last Page': 'Afișează ultima pagină',
		Zoom: 'Zoom',
		'Zoom In': 'Mareste',
		'Zoom Out': 'A micsora',
		'Page Thumbnails': 'Miniaturi de pagină',
		Bookmarks: 'Marcaje',
		Print: 'Fisier de printat',
		'Password Protected': 'Parola obligatorie',
		Copy: 'Copie',
		'Text Selection': 'Instrument de selectare a textului',
		Panning: 'Mod Pan',
		'Text Search': 'Găsiți text',
		'Find in document': 'Găsiți în document',
		'Match case': 'Carcasă potrivită',
		Apply: 'aplica',
		GoToPage: 'Mergi la pagina',
		'No matches': 'Viewer a terminat căutarea documentului. Nu s-au găsit mai multe meciuri',
		'No Text Found': 'Nu a fost găsit niciun text',
		Undo: 'Anula',
		Redo: 'A reface',
		Annotation: 'Adaugă sau Editați adnotări',
		Highlight: 'Text subliniat',
		Underline: 'Subliniază textul',
		Strikethrough: 'Strikethrough Text',
		Delete: 'Ștergeți adnotarea',
		Opacity: 'Opacitate',
		'Color edit': 'Schimba culoarea',
		'Opacity edit': 'Schimbă opacitatea',
		'Highlight context': 'A scoate in evidenta',
		'Underline context': 'Subliniat',
		'Strikethrough context': 'Loviti prin',
		'Server error': 'Serviciul web nu ascultă. PDF Viewer depinde de serviciul web pentru toate funcțiile sale. Vă rugăm să începeți serviciul web pentru a continua.',
		'Open text': 'Deschis',
		'First text': 'Prima pagina',
		'Previous text': 'Pagina precedentă',
		'Next text': 'Pagina următoare',
		'Last text': 'Ultima pagina',
		'Zoom in text': 'Mareste',
		'Zoom out text': 'A micsora',
		'Selection text': 'Selecţie',
		'Pan text': 'Tigaie',
		'Print text': 'Imprimare',
		'Search text': 'Căutare',
		'Annotation Edit text': 'Editează adnotarea',
		'Line Thickness': 'Grosimea liniei',
		'Line Properties': 'Proprietăți de linie',
		'Start Arrow': 'Începeți săgeata',
		'End Arrow': 'Săgeata finală',
		'Line Style': 'Stil de linie',
		'Fill Color': 'Culoare umplere',
		'Line Color': 'Culoarea liniei',
		None: 'Nici unul',
		'Open Arrow': 'Deschis',
		'Closed Arrow': 'Închis',
		'Round Arrow': 'Rundă',
		'Square Arrow': 'Pătrat',
		'Diamond Arrow': 'Diamant',
		Butt: 'Fund',
		Cut: 'A tăia',
		Paste: 'Pastă',
		'Delete Context': 'Șterge',
		Properties: 'Proprietăți',
		'Add Stamp': 'Adaugă timbru',
		'Add Shapes': 'Adaugă forme',
		'Stroke edit': 'Schimbați culoarea cursei',
		'Change thickness': 'Schimbați grosimea graniței',
		'Add line': 'Adaugă linie',
		'Add arrow': 'Adaugă săgeata',
		'Add rectangle': 'Adaugă Rectangle',
		'Add circle': 'Adaugă cerc',
		'Add polygon': 'Adaugă Poligon',
		'Add Comments': 'Adaugă comentarii',
		Comments: 'Comentarii',
		'No Comments Yet': 'Nici un comentariu',
		Accepted: 'Admis',
		Completed: 'Efectuat',
		Cancelled: 'Anulat',
		Rejected: 'respins',
		'Leader Length': 'Lungimea liderului',
		'Scale Ratio': 'Raportul scării',
		Calibrate: 'Calibra',
		'Calibrate Distance': 'Calibrați distanța',
		'Calibrate Perimeter': 'Calibrați Perimetrul',
		'Calibrate Area': 'Zona de calibrare',
		'Calibrate Radius': 'Calibrați Radius',
		'Calibrate Volume': 'Calibrați volumul'
	},
	documenteditor: {
		Table: 'Masa',
		Row: 'Rând',
		Cell: 'celulă',
		Ok: 'O.K',
		Cancel: 'Anulare',
		Size: 'mărimea',
		'Preferred Width': 'Lățimea preferată',
		Points: 'puncte',
		Percent: 'La sută',
		'Measure in': 'Măsurați în',
		Alignment: 'Aliniere',
		Left: 'Stânga',
		Center: 'Centru',
		Right: 'Dreapta',
		Justify: 'Justifica',
		'Indent from left': 'Indentare de la stânga',
		'Borders and Shading': 'Granițele și umbrirea',
		Options: 'Opțiuni',
		'Specify height': 'Precizați înălțimea',
		'At least': 'Macar',
		Exactly: 'Exact',
		'Row height is': 'Înălțimea rândului este',
		'Allow row to break across pages': 'Permiteți rândului să împartă paginile',
		'Repeat as header row at the top of each page': 'Repetați ca rândul antet în partea de sus a fiecărei pagini',
		'Vertical alignment': 'Aliniere verticală',
		Top: 'Top',
		Bottom: 'Partea de jos',
		'Default cell margins': 'Marjele celulare implicite',
		'Default cell spacing': 'Spațiul implicit al celulelor',
		'Allow spacing between cells': 'Permiteți distanțarea între celule',
		'Cell margins': 'Marjele celulare',
		'Same as the whole table': 'La fel ca întreaga masă',
		Borders: 'Frontiere',
		None: 'Nici unul',
		Style: 'Stil',
		Width: 'Lăţime',
		Height: 'Înălţime',
		Letter: 'Scrisoare',
		Tabloid: 'tabloid',
		Legal: 'legal',
		Statement: 'Afirmație',
		Executive: 'Executiv',
		A3: 'A3',
		A4: 'A4',
		A5: 'A5',
		B4: 'B4',
		B5: 'B5',
		'Custom Size': 'Marime personalizata',
		'Different odd and even': 'Diferite și impar',
		'Different first page': 'Prima pagină diferită',
		'From edge': 'De la margine',
		Header: 'Antet',
		Footer: 'Subsol',
		Margin: 'Margini',
		Paper: 'Hârtie',
		Layout: 'schemă',
		Orientation: 'Orientare',
		Landscape: 'Peisaj',
		Portrait: 'Portret',
		'Show page numbers': 'Afișează numerele paginii',
		'Right align page numbers': 'Alegeți numerele drepte ale paginii',
		Nothing: 'Nimic',
		'Tab leader': 'Lider de file',
		'Show levels': 'Afișează nivelurile',
		'Use hyperlinks instead of page numbers': 'Folosiți hyperlink-uri în loc de numere de pagină',
		'Build table of contents from': 'Construiți un conținut din',
		Styles: 'Stiluri tabel',
		'Available styles': 'Stiluri disponibile',
		'TOC level': 'Nivelul TOC',
		Heading: 'Titlu',
		'Heading 1': 'Rubrica 1',
		'Heading 2': 'Rubrica 2',
		'Heading 3': 'Titlul 3',
		'Heading 4': 'Rubrica 4',
		'Heading 5': 'Rubrica 5',
		'Heading 6': 'Titlul 6',
		'List Paragraph': 'Paragraful listei',
		Normal: 'Normal',
		'Outline levels': 'Niveluri contur',
		'Table entry fields': 'Câmpuri de intrare în tabel',
		Modify: 'Modifica',
		Color: 'Culoare',
		Setting: 'reglaj',
		Box: 'Cutie',
		All: 'Toate',
		Custom: 'Personalizat',
		Preview: 'previzualizare',
		Shading: 'Umbrire',
		Fill: 'Completati',
		'Apply To': 'Aplica pentru',
		'Table Properties': 'Proprietățile tabelului',
		'Cell Options': 'Opțiuni celulare',
		'Table Options': 'Opțiuni de tabel',
		'Insert Table': 'Introduceți tabelul',
		'Number of columns': 'Numar de coloane',
		'Number of rows': 'Număr de rânduri',
		'Text to display': 'Text de afișat',
		Address: 'Adresă',
		'Insert Hyperlink': 'Inserare link',
		'Edit Hyperlink': 'Editare link',
		Insert: 'Introduce',
		General: 'General',
		Indentation: 'zimțuire',
		'Before text': 'Înainte de text',
		Special: 'Special',
		'First line': 'Prima linie',
		Hanging: 'Agăţat',
		'After text': 'După text',
		By: 'De',
		Before: 'Inainte de',
		'Line Spacing': 'Distanța de linie',
		After: 'După',
		At: 'La',
		Multiple: 'Multiplu',
		Spacing: 'spaţiere',
		'Define new Multilevel list': 'Definiți o nouă listă pe mai multe niveluri',
		'List level': 'Nivel de listă',
		'Choose level to modify': 'Alegeți nivelul de modificat',
		Level: 'Nivel',
		'Number format': 'Formatul numărului',
		'Number style for this level': 'Stilul numerelor pentru acest nivel',
		'Enter formatting for number': 'Introduceți formatarea pentru numărul',
		'Start at': 'Incepe la',
		'Restart list after': 'Reporniți lista după',
		Position: 'Poziţie',
		'Text indent at': 'Litiu de text la',
		'Aligned at': 'Aliniat la',
		'Follow number with': 'Urmăriți numărul cu',
		'Tab character': 'Caracterul filei',
		Space: 'Spaţiu',
		Arabic: 'arabic',
		UpRoman: 'UpRoman',
		LowRoman: 'LowRoman',
		UpLetter: 'UpLetter',
		LowLetter: 'LowLetter',
		Number: 'Număr',
		'Leading zero': 'Conducerea zero',
		Bullet: 'Glonţ',
		Ordinal: 'Ordinal',
		'Ordinal Text': 'Text ordinal',
		'For East': 'Pentru est',
		'No Restart': 'Fără restart',
		Font: 'Font',
		'Font style': 'Stilul fontului',
		'Underline style': 'Stil subliniat',
		'Font color': 'Culoarea fontului',
		Effects: 'Efecte',
		Strikethrough: 'Tăiat',
		Superscript: 'Exponent',
		Subscript: 'subscript',
		'Double strikethrough': 'Dublu strikethrough',
		Regular: 'Regulat',
		Bold: 'Îngroșat',
		Italic: 'Înclinat',
		Cut: 'Taie',
		Copy: 'Copie',
		Paste: 'Lipește',
		Hyperlink: 'Hyperlink',
		'Open Hyperlink': 'Deschide hyperlink',
		'Copy Hyperlink': 'Copie hyperlink',
		'Remove Hyperlink': 'Elimină hyperlink',
		Paragraph: 'Paragraf',
		'Linked Style': 'Legat (paragraf și caracter)',
		Character: 'Caracter',
		'Merge Cells': 'Uneste celulele',
		'Insert Above': 'Introduceți mai sus',
		'Insert Below': 'Introduceți mai jos',
		'Insert Left': 'Introduceți în stânga',
		'Insert Right': 'Introduceți dreapta',
		Delete: 'Șterge',
		'Delete Table': 'Ștergeți tabelul',
		'Delete Row': 'Ștergeți rândul',
		'Delete Column': 'Ștergeți coloana',
		'File Name': 'Nume de fișier',
		'Format Type': 'Tip de format',
		Save: 'salva',
		Navigation: 'Navigare',
		Results: 'Rezultate',
		Replace: 'A inlocui',
		'Replace All': 'Înlocuiește-le pe toate',
		'We replaced all': 'Am înlocuit toate',
		Find: 'Găsi',
		'No matches': 'Nici-o potrivire',
		'All Done': 'Totul este gata',
		Result: 'Rezultat',
		'of': 'de',
		instances: 'instanțe',
		'with': 'cu',
		'Click to follow link': 'Faceți clic pentru a urma linkul',
		'Continue Numbering': 'Continuați numerotarea',
		'Bookmark name': 'Numele marcajului',
		Close: 'Închide',
		'Restart At': 'Reporniți At',
		Properties: 'Proprietăți',
		Name: 'Nume',
		'Style type': 'Tip de stil',
		'Style based on': 'Stil bazat pe',
		'Style for following paragraph': 'Stil pentru următorul alineat',
		Formatting: 'Formatarea',
		'Numbering and Bullets': 'Numerotare și Buletine',
		Numbering: 'Numerotare',
		'Update Field': 'Câmpul de actualizare',
		'Edit Field': 'Modificați câmpul',
		Bookmark: 'semn de carte',
		'Page Setup': 'Configurare pagina',
		'No bookmarks found': 'Nu au fost găsite marcaje',
		'Number format tooltip information': 'Format de număr la un singur nivel: </br> [PREFIX]% [NIVEL]] [SUFFIX] </br> De exemplu, \'Capitolul% 1.\' va afișa numerotare ca </br> Capitolul 1. Element </br> Capitolul 2. Elemente </br> ... </br> Capitolul N. Elemente </br> </br> Format de număr pe mai multe niveluri: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> De exemplu, \'% 1.% 2.\' va afișa numerotare ca </br> 1.1. Postul </br> 1.2. Articol </br> ... </br> 1.N. Articol',
		Format: 'Format',
		'Create New Style': 'Creați stil nou',
		'Modify Style': 'Modificați stilul',
		New: 'Nou',
		Bullets: 'Marcatori',
		'Use bookmarks': 'Folosiți marcaje',
		'Table of Contents': 'Cuprins',
		AutoFit: 'Potrivire automată',
		'AutoFit to Contents': 'AutoFit la Cuprins',
		'AutoFit to Window': 'AutoFit la fereastră',
		'Fixed Column Width': 'Lățimea fixă a coloanei',
		Reset: 'Resetați',
		'Match case': 'Carcasă potrivită',
		'Whole words': 'Cuvinte întregi',
		Add: 'Adăuga',
		'Go To': 'Mergi la',
		'Search for': 'Caută',
		'Replace with': 'Înlocui cu',
		'TOC 1': 'TOC 1',
		'TOC 2': 'TOC 2',
		'TOC 3': 'TOC 3',
		'TOC 4': 'TOC 4',
		'TOC 5': 'TOC 5',
		'TOC 6': 'TOC 6',
		'TOC 7': 'TOC 7',
		'TOC 8': 'TOC 8',
		'TOC 9': 'TOC 9',
		'Right-to-left': 'De la dreapta la stanga',
		'Left-to-right': 'De la stânga la dreapta',
		Direction: 'Direcţie',
		'Table direction': 'Direcția tabelului',
		'Indent from right': 'Indentare de la dreapta',
		'Contextual Spacing': 'Nu Adaugă spațiu între alineatele din aceleași stiluri',
		'Password Mismatch': 'Parola nu se potrivește',
		'Restrict Editing': 'Restricți editarea',
		'Formatting restrictions': 'Restricții de formatare',
		'Allow formatting': 'Permite formatarea',
		'Editing restrictions': 'Restricții de editare',
		'Read only': 'Numai citit',
		'Exceptions Optional': 'Excepții (opțional)',
		'Select Part Of Document And User': 'Selectați părți ale documentului și alegeți utilizatorii cărora li se permite să le editeze liber.',
		Everyone: 'Toata lumea',
		'More users': 'Mai mulți utilizatori',
		'Add Users': 'Adaugă utilizatori',
		'Enforcing Protection': 'Da, începeți să impuneți protecția',
		'Start Enforcing Protection': 'Începeți să impuneți protecția',
		'Enter User': 'Intră Utilizator',
		Users: 'Utilizatori',
		'Enter new password': 'Introduceți o parolă nouă',
		'Reenter new password to confirm': 'Reintroduceți noua parolă pentru confirmare',
		'Your permissions': 'Permisiunile dvs.',
		'Protected Document': 'Acest document este protejat împotriva editării neintenționate. Puteți edita în această regiune.',
		FormFieldsOnly: 'Acest document este protejat împotriva editării neintenționate. Puteți completa doar formularele din această regiune.',
		'You may format text only with certain styles': 'Puteți formata textul doar cu anumite stiluri.',
		'Stop Protection': 'Opriți protecția',
		Password: 'Parola',
		'Spelling Editor': 'Editor de ortografie',
		Spelling: 'Ortografie',
		'Spell Check': 'Verificare a ortografiei',
		'Underline errors': 'Subliniază erorile',
		Ignore: 'Ignora',
		'Ignore all': 'Ignora tot',
		'Add to Dictionary': 'Adaugă în dicționar',
		Change: 'Schimbare',
		'Change All': 'Schimbă toate',
		Suggestions: 'sugestii',
		'The password is incorrect': 'Parola este incorecta',
		'Error in establishing connection with web server': 'Eroare la stabilirea conexiunii cu serverul web',
		'Highlight the regions I can edit': 'Evidențiați regiunile pe care le pot edita',
		'Show All Regions I Can Edit': 'Afișează toate regiunile pe care le pot edita',
		'Find Next Region I Can Edit': 'Găsiți regiunea următoare pe care o pot edita',
		'Keep source formatting': 'Mențineți formatarea sursei',
		'Match destination formatting': 'Potrivirea formatării destinației',
		'Text only': 'Doar text',
		Comments: 'Comentarii',
		'Type your comment': 'Tastați comentariul',
		Post: 'Post',
		Reply: 'Răspuns',
		'New Comment': 'Comentariu nou',
		Edit: 'Editați | ×',
		Resolve: 'Rezolva',
		Reopen: 'Redeschide',
		'No comments in this document': 'Nu există comentarii în acest document',
		more: 'Mai Mult',
		'Type your comment here': 'Introdu comentariul tău aici',
		'Next Comment': 'Următorul comentariu',
		'Previous Comment': 'Comentariu anterior',
		'Un-posted comments': 'Comentarii postate',
		'Discard Comment': 'Comentarii adăugate care nu au fost postate. Dacă continuați, acel comentariu va fi aruncat.',
		'No Headings': 'Nu a fost găsit un titlu!',
		'Add Headings': 'Acest document nu are titluri. Vă rugăm să Adaugă rubrici și să încercați din nou.',
		'More Options': 'Mai multe opțiuni',
		'Click to see this comment': 'Faceți clic pentru a vedea acest comentariu',
		'Drop Down Form Field': 'Derulează câmpul formularului',
		'Drop-down items': 'Elemente drop-down',
		'Items in drop-down list': 'Elemente din lista derulantă',
		ADD: 'ADĂUGA',
		REMOVE: 'ELIMINA',
		'Field settings': 'Setări câmp',
		Tooltip: 'tooltip',
		'Drop-down enabled': 'Funcție drop-down activată',
		'Check Box Form Field': 'Bifează câmpul formularului casetei',
		'Check box size': 'Bifează dimensiunea casetei',
		Auto: 'Auto',
		'Default value': 'Valoare implicită',
		'Not checked': 'Nu a verificat',
		Checked: 'verificat',
		'Check box enabled': 'Caseta de selectare este activată',
		'Text Form Field': 'Câmp Formular Text',
		Type: 'Tip',
		'Default text': 'Text implicit',
		'Maximum length': 'Lungime maxima',
		'Text format': 'Formatul textului',
		'Fill-in enabled': 'Completare activată',
		'Default number': 'Numărul implicit',
		'Default date': 'Data implicită',
		'Date format': 'Formatul datei',
		'Merge Track': 'Această acțiune nu va fi marcată ca modificare. Doriți să continuați?',
		UnTrack: 'Nu poate fi urmărit',
		Accept: 'Accept',
		Reject: 'Respinge',
		'Previous Changes': 'Modificări anterioare',
		'Next Changes': 'Următoarele modificări',
		Inserted: 'inserat',
		Deleted: 'şters',
		Changes: 'Schimbări',
		'Accept all': 'Accepta toate',
		'Reject all': 'Respingeți toate',
		'No changes': 'Nici o schimbare',
		'Accept Changes': 'Acceptați modificările',
		'Reject Changes': 'Respingeți modificările',
		User: 'Utilizator',
		View: 'Vedere',
		Insertion: 'Inserare',
		Deletion: 'Ștergere',
		'All caps': 'Toate capacele',
		'This region is locked by': 'Această regiune este blocată de',
		Lock: 'Lacăt',
		Unlock: 'Deblocați',
		'Already locked': 'Selectat sau o parte a regiunii este deja blocată de un alt utilizator',
		'1, 2, 3, ...': '1, 2, 3, ...',
		'a, b, c, ...': 'a, b, c, ...',
		'A, B, C, ...': 'A, B, C, ...',
		'I, II, III, ...': 'I, II, III, ...',
		'i, ii, iii, ...': 'i, ii, iii, ...',
		'Text Form': 'Formular text',
		'Check Box': 'Caseta de bifat',
		DropDown: 'Scapă jos',
		'Form Fields': 'Câmpuri formular',
		'Fillin enabled': 'Completarea este activată'
	},
	documenteditorcontainer: {
		New: 'Nou',
		Open: 'Deschis',
		Undo: 'Anula',
		Redo: 'A reface',
		Image: 'Imagine',
		Table: 'Masa',
		Link: 'Legătură',
		Bookmark: 'semn de carte',
		'Table of Contents': 'Cuprins',
		'HEADING - - - - 1': 'CAPITOLUL - - - - 1',
		'HEADING - - - - 2': 'CAPITOLUL - - - - 2',
		'HEADING - - - - 3': 'CAPITOLUL - - - - 3',
		Header: 'Antet',
		Footer: 'Subsol',
		'Page Setup': 'Configurare pagina',
		'Page Number': 'Numărul paginii',
		Break: 'Pauză',
		Find: 'Găsi',
		'Local Clipboard': 'Clipboard local',
		'Restrict Editing': 'Restricți editarea',
		'Upload from computer': 'Încărcați de pe computer',
		'By URL': 'Pe adresa URL',
		'Page Break': 'Pauză de pagină',
		'Section Break': 'Pauză de secțiune',
		'Header And Footer': 'Antet și subsol',
		Options: 'Opțiuni',
		Levels: 'niveluri',
		'Different First Page': 'Prima pagină diferită',
		'Different header and footer for odd and even pages': 'Antet și subsol diferit pentru pagini impare și par.',
		'Different Odd And Even Pages': 'Pagini diferite impar și par',
		'Different header and footer for first page': 'Antet și subsol diferit pentru prima pagină.',
		Position: 'Poziţie',
		'Header from Top': 'Antet de sus',
		'Footer from Bottom': 'Subsol de jos',
		'Distance from top of the page to top of the header': 'Distanța de la partea de sus a paginii până la partea de sus a antetului.',
		'Distance from bottom of the page to bottom of the footer': 'Distanța de la partea de jos a paginii până la partea de jos a subsolului.',
		'Aspect ratio': 'Raportul de aspect',
		W: 'W',
		H: 'H',
		Width: 'Lăţime',
		Height: 'Înălţime',
		Text: 'Text',
		Paragraph: 'Paragraf',
		Fill: 'Completati',
		'Fill color': 'Umple culoarea',
		'Border Style': 'Stil de graniță',
		'Outside borders': 'În afara granițelor',
		'All borders': 'Toate granițele',
		'Inside borders': 'În interiorul granițelor',
		'Left border': 'Frontiera stângă',
		'Inside vertical border': 'În interiorul chenarului vertical',
		'Right border': 'Frontiera dreapta',
		'Top border': 'Frontiera de sus',
		'Inside horizontal border': 'În interiorul frontierei orizontale',
		'Bottom border': 'Bordura de jos',
		'Border color': 'Culoarea frontierei',
		'Border width': 'Lățimea graniței',
		Cell: 'celulă',
		'Merge cells': 'Uneste celulele',
		'Insert Or Delete': 'Introduceți / Ștergeți',
		'Insert columns to the left': 'Introduceți coloane la stânga',
		'Insert columns to the right': 'Introduceți coloane la dreapta',
		'Insert rows above': 'Introduceți rândurile deasupra',
		'Insert rows below': 'Introduceți rândurile de mai jos',
		'Delete rows': 'Ștergeți rândurile',
		'Delete columns': 'Ștergeți coloane',
		'Cell Margin': 'Marja celulară',
		Top: 'Top',
		Bottom: 'Partea de jos',
		Left: 'Stânga',
		Right: 'Dreapta',
		'Align Text': 'Aliniere text',
		'Align top': 'Aliniați partea de sus',
		'Align bottom': 'Aliniați partea inferioară',
		'Align center': 'Aliniere la centru',
		'Number of heading or outline levels to be shown in table of contents': 'Numărul de niveluri de titlu sau contur care trebuie afișate în conținut.',
		'Show page numbers': 'Afișează numerele paginii',
		'Show page numbers in table of contents': 'Afișează numerele de pagină în cuprins.',
		'Right align page numbers': 'Alegeți numerele drepte ale paginii',
		'Right align page numbers in table of contents': 'Alegeți numerele drepte de pagini în cuprins.',
		'Use hyperlinks': 'Folosiți hyperlink-uri',
		'Use hyperlinks instead of page numbers': 'Folosiți hyperlink-uri în loc de numere de pagină.',
		Font: 'Font',
		'Font Size': 'Marimea fontului',
		'Font color': 'Culoarea fontului',
		'Text highlight color': 'Culoarea evidențiată a textului',
		'Clear all formatting': 'Ștergeți toate formatările',
		'Bold Tooltip': 'Bold (Ctrl + B)',
		'Italic Tooltip': 'Italic (Ctrl + I)',
		'Underline Tooltip': 'Subliniere (Ctrl + U)',
		Strikethrough: 'Tăiat',
		'Superscript Tooltip': 'Superscript (Ctrl + Shift ++)',
		'Subscript Tooltip': 'Abonament (Ctrl + =)',
		'Align left Tooltip': 'Aliniați la stânga (Ctrl + L)',
		'Center Tooltip': 'Centru (Ctrl + E)',
		'Align right Tooltip': 'Aliniați dreapta (Ctrl + R)',
		'Justify Tooltip': 'Justificați (Ctrl + J)',
		'Decrease indent': 'Reduceți liniuța',
		'Increase indent': 'Mareste indent-ul',
		'Line spacing': 'Distanța de linie',
		Bullets: 'Marcatori',
		Numbering: 'Numerotare',
		Styles: 'stiluri',
		'Manage Styles': 'Gestionați stiluri',
		Page: 'Pagină',
		'of': 'de',
		'Fit one page': 'Potriviți o pagină',
		'Spell Check': 'Verificare a ortografiei',
		'Underline errors': 'Subliniază erorile',
		'Fit page width': 'Potriviți lățimea paginii',
		Update: 'Actualizați',
		Cancel: 'Anulare',
		Insert: 'Introduce',
		'No Border': 'Fara limita',
		'Create a new document': 'Creați un document nou.',
		'Open a document': 'Deschideți un document.',
		'Undo Tooltip': 'Anulați ultima operație (Ctrl + Z).',
		'Redo Tooltip': 'Reîncărcați ultima operație (Ctrl + Y).',
		'Insert inline picture from a file': 'Inserați o imagine în linie dintr-un fișier.',
		'Insert a table into the document': 'Introduceți o tabelă în document',
		'Create Hyperlink': 'Creați un link în documentul dvs. pentru acces rapid la paginile și fișierele web (Ctrl + K).',
		'Insert a bookmark in a specific place in this document': 'Introduceți un marcaj într-un loc specific în acest document.',
		'Provide an overview of your document by adding a table of contents': 'Oferiți o imagine de ansamblu a documentului dvs. adăugând un cuprins.',
		'Add or edit the header': 'Adaugă sau editați antetul.',
		'Add or edit the footer': 'Adaugă sau editați subsolul.',
		'Open the page setup dialog': 'Deschideți dialogul de configurare a paginii.',
		'Add page numbers': 'Adaugă numere de pagină.',
		'Find Text': 'Găsiți text în document (Ctrl + F).',
		'Toggle between the internal clipboard and system clipboard': 'Comutați între clipboardul intern și panoul de sistem. </br> Accesul la panoul de bord al sistemului prin script este refuzat din cauza politicii de securitate a browserelor. În schimb, </br> 1. Puteți permite clipboard-ului intern să taie, să copieze și să lipească în componentă. </br> 2. Puteți utiliza comenzile rapide de la tastatură (Ctrl + X, Ctrl + C și Ctrl + V) pentru a tăia , copiați și lipiți cu clipboardul sistemului.',
		'Current Page Number': 'Numărul de pagină curent din document. Faceți clic sau atingeți pentru a naviga pe o anumită pagină.',
		'Read only': 'Numai citit',
		Protections: 'protectii',
		'Error in establishing connection with web server': 'Eroare la stabilirea conexiunii cu serverul web',
		Single: 'Singur',
		Double: 'Dubla',
		'New comment': 'Comentariu nou',
		Comments: 'Comentarii',
		'Print layout': 'Aspect imprimat',
		'Web layout': 'Aspect web',
		'Text Form': 'Formular de text',
		'Check Box': 'Caseta de bifat',
		DropDown: 'Scapă jos',
		'Update Fields': 'Câmpuri de actualizare',
		'Update cross reference fields': 'Actualizați câmpurile de referință încrucișate',
		'Hide properties pane': 'Ascundeți panoul de proprietăți',
		'Show properties pane': 'Afișează panoul de proprietăți',
		'Form Fields': 'Câmpuri formular',
		'Track Changes': 'Țineți evidența modificărilor făcute în document',
		TrackChanges: 'Urmareste schimbarile',
		AllCaps: 'AllCaps'
	},
	toast: {
		close: 'Închide'
	},
	kanban: {
		items: 'articole',
		min: 'min',
		max: 'Max',
		cardsSelected: 'Carduri selectate',
		addTitle: 'Adaugă card nou',
		editTitle: 'Editează detaliile cardului',
		deleteTitle: 'Ștergeți cardul',
		deleteContent: 'Sigur doriți să ștergeți acest card?',
		save: 'Salvați',
		delete: 'Șterge',
		cancel: 'Anulare',
		yes: 'da',
		no: 'Nu',
		close: 'Închide'
	},
	'drop-down-tree': {
		noRecordsTemplate: 'Nu au fost găsite',
		actionFailureTemplate: 'Cerere nereusita',
		overflowCountTemplate: '+ $ {count} mai mult ..',
		totalCountTemplate: '$ {count} selectat'
	}
};
