import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IImage } from '@dynamicmedia/shared';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'landing-page-casa-dracula',
  templateUrl: './casa-dracula.component.html',
	styleUrls: ['./casa-dracula.component.scss'],
	encapsulation: ViewEncapsulation.None // for the header scripts in theme.js to work with the scss
})
export class CasaDraculaComponent implements OnInit {
	// Public members
	public slideshowSlides: Array<IImage>;

  constructor() {
		const sliderTitles = [
			'Casa Dracula by Pro Lex',
			'Curtea interioară',
			'Vedere de sus',
			'Foișorul',
			'Bucătăria',
			'Camera 1',
			'Camera 2',
			'Camera 3',
			'Camera 4',
			'Terasă'
		];
		this.slideshowSlides = new Array<IImage>();
		for (let i = 1; i <= 10; i++)
			this.slideshowSlides.push({
				url: `/assets/images/landing-page/pensiune/pensiune-${i}.jpg`,
				caption: sliderTitles[i - 1]
			}
		);
	}

  public ngOnInit(): void {
		// This code loads the IFrame Player API code asynchronously, according to the
		// instructions at https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		document.body.appendChild(tag);
  }
}
