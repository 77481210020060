/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { RememberMeConfigService, RememberMeServiceConfig } from './remember-me-service.config';
import { RememberMeService } from './remember-me.service';

@NgModule()
export class RememberMeServiceModule {
  static forRoot(config: RememberMeServiceConfig): ModuleWithProviders<RememberMeServiceModule> {
    return {
      ngModule: RememberMeServiceModule,
      providers: [
      	RememberMeService,
        { provide: RememberMeConfigService, useValue: config }
      ]
    };
  }
}
