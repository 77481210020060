import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticatedUser, AuthenticationService, UserRoles } from '@prolex/shared';
import { environment } from '../../../../environments/environment';
import { paths } from '../../../app-paths';
import { MenuItem } from './menu-item.model';

@Component({
	selector: 'members-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnDestroy {
	// Public members
	public menuItems!: Array<MenuItem>;
	public url!: string;
	public userData: AuthenticatedUser | null;
  public collapseSidebar: boolean;
	public profilePicture: string | null = null;
	// Private members
	private readonly _profilePictureSubscription: Subscription;
	private _callChangeDetector = false;

	constructor(
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private _changeDetector: ChangeDetectorRef)
	{
    this.collapseSidebar = false;
    this.menuItems = [
      {
        title: 'Acasă',
        icon: 'cis-home',
        type: 'link',
        path: paths.dashboard.fullPath
      },
      {
        title: 'Notificări',
        icon: 'cis-bell',
        type: 'link',
        path: paths.notifications.fullPath
      }
    ];
		const userRole = this._authenticationService.getUserRole();
    if (this._authenticationService.userData?.memberId != null) {
      this.menuItems.push(
        {
          title: 'Plată nouă',
          icon: 'cis-credit-card',
          path: paths.paymentsNewMembership.fullPath,
          type: 'link'
        });
      this.menuItems.push(
        {
          title: 'Istoric plăți',
          icon: 'cis-featured-playlist',
          path: paths.paymentsHistory.fullPath,
          type: 'link'
        }
      );
      this.menuItems.push({
	      title: 'Abonamente',
	      icon: 'cis-shield-alt',
	      path: paths.subscriptions.fullPath,
	      type: 'link'
      });
      this.menuItems.push({
	      title: 'Vouchere',
	      icon: 'cis-money-bill',
	      path: paths.vouchers.fullPath,
	      type: 'link'
      });
			if (userRole === UserRoles.VouchersAdministrator) { // || userRole === UserRoles.SuperAdministrator) {
				this.menuItems.push({
					title: 'Management vouchere',
					icon: 'cis-money',
					path: paths.voucherManagerManagement.fullPath,
					type: 'link'
				});
			}
      //  {
        // title: 'Plăți',
        // icon: 'cis-credit-card',
        // type: 'sub',
        // children: [
        //   {
        //     title: 'Plată nouă',
        //     path: paths.paymentsNew.fullPath,
        //     type: 'link'
        //   },
        //   {
        //     title: 'Istoric',
        //     path: paths.paymentsHistory.fullPath,
        //     type: 'link'
        //   }
        // ]
      //}
      //);
    }
    // else {
    //   if (this.menuItems.filter((item) => item.title === 'Plăți').length === 0) {
    //     this.menuItems.push({
    //       title: 'Plăți',
    //       icon: 'cis-credit-card',
    //       type: 'sub',
    //       children: [
    //         {
    //           title: 'Plată nouă',
    //           path: paths.paymentsNew.fullPath,
    //           type: 'link'
    //         },
    //         {
    //           title: 'Istoric plăți',
    //           path: paths.paymentsHistory.fullPath,
    //           type: 'link'
    //         }
    //       ]
    //     });
    //   }
    //}
    if (userRole == UserRoles.SuperAdministrator || userRole == UserRoles.MembersAdministrator ||
      (userRole instanceof Array && userRole.find(i => i == UserRoles.MembersAdministrator)))
    {
      this.menuItems.push(
        // {
        //   title: 'Procese generice',
        //   icon: 'cis-balance-scale',
        //   type: 'link',
        //   path: paths.genericTrialsEdit.fullPath
        // },
	      // {
	      // 	title: 'Listă beneficiari',
		    //   icon: 'cis-chart-table',
		    //   type: 'link',
		    //   path: paths.beneficiariesList.fullPath
	      // },
	      // {
	      // 	title: 'Raportare beneficiari',
		    //   icon: 'cis-money',
		    //   type: 'link',
		    //   path: paths.beneficiariesReport.fullPath
	      // },
	      // {
		    //   title: 'Persoane contact',
		    //   icon: 'cis-contact-phone',
		    //   type: 'link',
		    //   path: paths.categoryContacts.fullPath
	      // },
	      // {
		    //   title: 'Întrebări generice',
		    //   icon: 'cis-comment-bubble-question',
		    //   type: 'link',
		    //   path: paths.faq.fullPath
	      // },
        {
          title: 'Administrare',
          icon: 'cis-browser',
          type: 'link',
          path: paths.selectAdminAction.fullPath
        }
      );
    }
    // this.menuItems.push({
    //   title: 'Setări',
    //   icon: 'cis-settings',
    //   type: 'link',
    //   path: paths.settings.fullPath
    // });
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems.filter(items => {
          if (items.path === event.url)
            this.setNavActive(items);
          if (!items.children)
            return false;
          items.children.filter(subItems => {
            if (subItems.path === event.url)
              this.setNavActive(subItems);
            if (!subItems.children)
              return false;
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url)
                this.setNavActive(subSubItems);
            });
            return true;
          });
          return true;
        });
      }
    });
		this.userData = this._authenticationService.userData;
		this._profilePictureSubscription = this._authenticationService.profilePicture$.subscribe((result: string | null) => {
			this.profilePicture = result;
			if (this._callChangeDetector)
				this._changeDetector.detectChanges();
		});
	}

	public ngOnInit() {
		this._callChangeDetector = true;
		// if (this._callChangeDetector)
		// 	this._changeDetector.detectChanges();
	}

	public ngOnDestroy() {
		if (this._profilePictureSubscription)
			this._profilePictureSubscription.unsubscribe();
	}

	// Active Nave state
	public setNavActive(item: MenuItem) {
		this.menuItems.filter(menuItem => {
			if (menuItem !== item)
				menuItem.active = false;
			if (menuItem.children && menuItem.children.includes(item))
				menuItem.active = true;
			if (menuItem.children) {
				menuItem.children.filter(submenuItems => {
					if (submenuItems.children && submenuItems.children.includes(item)) {
						menuItem.active = true;
						submenuItems.active = true;
					}
				});
			}
		});
	}

	// Click Toggle menu
	public toggleNavigationActive(item: MenuItem) {
		if (!item.active) {
			this.menuItems.forEach(a => {
				if (this.menuItems.includes(item))
					a.active = false;
				if (!a.children)
					return false;
				a.children.forEach(b => {
					if (a.children?.includes(item)) {
						b.active = false;
					}
				});
				return true;
			});
		}
		item.active = !item.active;
	}

	public getAvatarPath(pictureUrl: string): string {
		return `${environment.backendUrl.substr(0, environment.backendUrl.length - 1)}${pictureUrl}`;
	}

	// Fileupload
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// public readUrl(event: any) {
	// 	if (event.target.files.length === 0)
	// 		return;
	// 	// Image upload validation
	// 	const mimeType = event.target.files[0].type;
	// 	if (mimeType.match(/image\/*/) == null)
	// 		return;
	// 	// Image upload
	// 	const reader = new FileReader();
	// 	reader.readAsDataURL(event.target.files[0]);
	// 	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// 	reader.onload = (event) => this.url = reader.result as string;
	// }
}
