/* eslint-disable max-len */
export const locale = {
	lang: 'ro',
	data: {
		labels: {
			title: 'Recuperare parolă',
			subtitle: 'Nu îți mai știi parola? Te ajutăm noi. Introdu adresa de email aferentă contului tău de utilizator și îți trimitem un email cu instrucțiunile pentru resetarea parolei.',
			email: 'Email',
			reset: 'Recuperează-mi parola',
			login: 'Autentifică-mă'
		},
		messages: {
			sent: 'Ți-am trimis un email cu instrucțiunile de recuperare a parolei la adresa de email specificată de tine.',
			error: 'Nu am putut să îți trimit email-ul cu instrucțiunile de recuperare a parolei din cauza unei erori'
		},
		validation: {
			emailRequired: 'Adresa de email este obligatorie',
			emailInvalid: 'Adresa de email este invalidă'
		}
	}
};