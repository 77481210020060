import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ProgressButton, SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { DialogService } from '@dynamicmedia/syncfusion';
import { ReactiveFormsValidation } from '@dynamicmedia/shared';
import { AuthenticationService, Functions, BackendResponse } from '@prolex/shared';
import { paths } from '../../app-paths';
import { TranslationLoaderService } from '../../shared/services';
import { locale as romanian } from './i18n/ro';

@Component({
  selector: 'members-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {
	// Components
	@ViewChild('resetButton') private _resetButton: ProgressButton;
	// Public members
  public resetPasswordForm: FormGroup;
  public spinSettings = {
  	position: 'Right',
	  width: 20,
	  template: `
			<div class="sk-fading-circle sk-fading-circle-round-button">
				<div class="sk-circle1 sk-circle"></div>
				<div class="sk-circle2 sk-circle"></div>
				<div class="sk-circle3 sk-circle"></div>
				<div class="sk-circle4 sk-circle"></div>
				<div class="sk-circle5 sk-circle"></div>
				<div class="sk-circle6 sk-circle"></div>
				<div class="sk-circle7 sk-circle"></div>
				<div class="sk-circle8 sk-circle"></div>
				<div class="sk-circle9 sk-circle"></div>
				<div class="sk-circle10 sk-circle"></div>
				<div class="sk-circle11 sk-circle"></div>
				<div class="sk-circle12 sk-circle"></div>
			</div>` } as SpinSettingsModel;
  public currentPasswordStrength: number;
  public spinDuration = 12000;
  // Private members
  private _loginSpin = false;
  private _requestId: string;

  //#region Form controls
  public get passwordControl(): AbstractControl {
    return this.resetPasswordForm && this.resetPasswordForm.get('password');
  }

  public get verifyPasswordControl(): AbstractControl {
    return this.resetPasswordForm && this.resetPasswordForm.get('verifyPassword');
  }
  //#endregion

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _translationLoaderService: TranslationLoaderService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef)
  {
    this._translationLoaderService.loadRomanianTranslations(romanian);
    this.resetPasswordForm = this._formBuilder.group(
      {
        password: [null, [ReactiveFormsValidation.required]],
        verifyPassword: [null, [ReactiveFormsValidation.required]]
      },
      {
        validators: ReactiveFormsValidation.matchingValues('password', 'verifyPassword')
      }
    );
    this.resetPasswordForm.valueChanges.subscribe(() => {
      this._changeDetector.detectChanges();
    });
    this.currentPasswordStrength = 0;
    this._requestId = new URLSearchParams(window.location.search).get('requestId');
  }

  public ngOnInit(): void {
    const resolvedData: BackendResponse | null = this._route.snapshot.data.resetPasswordResolver;
    if (resolvedData === null)
      this._dialogService.error(this._translationLoaderService.getTranslation('validation.noRequestId')).show();
    else {
      if (!resolvedData.isSuccess) {
        this._dialogService.question(
          resolvedData.message,
          () => {
            void this._router.navigate([paths.forgotPassword.fullPath]);
          },
          () => {
            void this._router.navigate([paths.landingPage.fullPath]);
          }
        ).show();
      }
    }
  }

  public resetClicked(): void {
    if (this._loginSpin)
      return;
    this._resetButton.start();
    this._loginSpin = true;
    this._authenticationService.resetPassword(this._requestId, this.passwordControl.value as string)
      .pipe(
				finalize(() => {
        	this._resetButton.progressComplete();
        	this._loginSpin = false;
      	})
			).subscribe({
        next: (result: BackendResponse) => {
          if (result.isSuccess)
            this._dialogService.information(result.message);
          else
            this._dialogService.question(
              result.message,
              () => {
                this.spinDuration = 0;
                setTimeout(() => void this._router.navigate([paths.forgotPassword.fullPath]), 200);
              },
              () => {
                this.spinDuration = 0;
                setTimeout(() => void this._router.navigate([paths.landingPage.fullPath]), 200);
              }
            );
        },
        error: (e) => {
        	if (e.errors && e.errors.password)
        		this._dialogService.error(e.errors.password);
        	else
            this._dialogService.error(Functions.handleError(e));
        }
      });
  }

  public contractBegin() {
    this._resetButton.element.classList.add('e-round');
    this.passwordControl.disable();
    this.verifyPasswordControl.disable();
  }

  public contractEnd() {
    this._resetButton.element.classList.remove('e-round');
    this.passwordControl.enable();
    this.verifyPasswordControl.enable();
  }

  public passwordStrengthChanged(event: number): void {
    this.currentPasswordStrength = event;
  }
}
