<ul class="profile-dropdown onhover-show-div p-20">
 <li>
    <a [routerLink]="routerPaths.profile.fullPath" active="active">
      <i class="cis-user" style="padding-right: 10px;"></i>
      {{ 'labels.profile' | translate }}
    </a>
  </li>
  <li>
    <a [routerLink]="routerPaths.notifications.fullPath" active="active">
      <i class="cis-bell" style="padding-right: 10px;"></i>
      {{ 'labels.notifications' | translate }}
    </a>
  </li>
  <li>
    <a [routerLink]="routerPaths.settings.fullPath" active="active">
      <i class="cis-settings" style="padding-right: 10px;"></i>
      {{ 'labels.settings' | translate }}
    </a>
  </li>
  <li>
    <a href="javascript:void(0)" (click)="logout()">
      <i class="cis-account-logout" style="padding-right: 10px;"></i>
      {{ 'labels.logout' | translate }}
    </a>
  </li>
</ul>
