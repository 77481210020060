import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressButton, SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { ReactiveFormsValidation } from '@dynamicmedia/shared';
import { DialogService } from '@dynamicmedia/syncfusion';
import { AuthenticationService, BackendResponse } from '@prolex/shared';
import { paths } from '../../app-paths';
import { TranslationLoaderService } from '../../shared/services';
import { locale as romanian } from './i18n/ro';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'members-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePasswordComponent implements OnInit {
	// Components
	@ViewChild('createPasswordButton') private _createPasswordButton!: ProgressButton;
	// Public members
  public createPasswordForm!: FormGroup;
  public currentPasswordStrength: number;
  public spinSettings = {
  	position: 'Right',
	  width: 20,
	  template: `
			<div class="sk-fading-circle sk-fading-circle-round-button">
				<div class="sk-circle1 sk-circle"></div>
				<div class="sk-circle2 sk-circle"></div>
				<div class="sk-circle3 sk-circle"></div>
				<div class="sk-circle4 sk-circle"></div>
				<div class="sk-circle5 sk-circle"></div>
				<div class="sk-circle6 sk-circle"></div>
				<div class="sk-circle7 sk-circle"></div>
				<div class="sk-circle8 sk-circle"></div>
				<div class="sk-circle9 sk-circle"></div>
				<div class="sk-circle10 sk-circle"></div>
				<div class="sk-circle11 sk-circle"></div>
				<div class="sk-circle12 sk-circle"></div>
			</div>` } as SpinSettingsModel;
  public spinDuration = 12000;
  // Private members
  private _createPasswordButtonSpin = false;
  private _requestId: string;

  //#region Form controls
  public get passwordControl(): AbstractControl {
    return this.createPasswordForm && this.createPasswordForm.get('password');
  }

  public get verifyPasswordControl(): AbstractControl {
    return this.createPasswordForm && this.createPasswordForm.get('verifyPassword');
  }
  //#endregion

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _translationLoaderService: TranslationLoaderService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef)
  {
    this._translationLoaderService.loadRomanianTranslations(romanian);
    this.createPasswordForm = this._formBuilder.group(
      {
        password: [null, [ReactiveFormsValidation.required]],
        verifyPassword: [null, [ReactiveFormsValidation.required]]
      },
      {
        validators: ReactiveFormsValidation.matchingValues('password', 'verifyPassword')
      }
    );
    this.createPasswordForm.valueChanges.subscribe(() => {
      this._changeDetector.detectChanges();
    });
    this.currentPasswordStrength = 0;
    this._requestId = new URLSearchParams(window.location.search).get('requestId');
  }

  public ngOnInit(): void {
    const resolvedData: BackendResponse | null = this._route.snapshot.data.createPasswordResolver;
    if (resolvedData === null)
      this._dialogService.error(this._translationLoaderService.getTranslation('validation.noRequestId')).show();
    else {
      if (!resolvedData.isSuccess)
        this._dialogService.question(
          resolvedData.message,
          () => {
            void this._router.navigate([paths.forgotPassword.fullPath]);
          },
          () => {
            void this._router.navigate([paths.landingPage.fullPath]);
          }
        ).show();
    }
  }

  public createPasswordClicked(): void {
    if (this._createPasswordButtonSpin)
      return;
    this._createPasswordButton.start();
    this._createPasswordButtonSpin = true;
    this._authenticationService.createPassword(this._requestId, this.passwordControl.value as string)
			.pipe(
				finalize(() => {
					this._createPasswordButton.progressComplete();
					this._createPasswordButtonSpin = false;
				})
			).subscribe({
        next: (result) => {
          if (result.isSuccess)
            this._dialogService.information(result.message, () => {
              this.spinDuration = 0;
              setTimeout(() => void this._router.navigate([paths.login.fullPath]), 200);
            });
          else {
            if (result.message.substr(result.message.length - 1) == '?')
              this._dialogService.question(
                result.message,
                () => {
                  this.spinDuration = 0;
                  setTimeout(() => void this._router.navigate([paths.existingMemberRegister.fullPath]), 200);
                },
                () => {
                  this.spinDuration = 0;
                  setTimeout(() => void this._router.navigate(['/']), 200);
                }
              );
            else
              this._dialogService.warning(
                result.message,
                () => {
                  this.spinDuration = 0;
                  setTimeout(() => void this._router.navigate(['/']));
                }
              );
          }
        },
        error: (e) => {
          this._dialogService.error(e);
        }
			});
  }

  public contractBegin() {
    this._createPasswordButton.element.classList.add('e-round');
    this.passwordControl.disable();
    this.verifyPasswordControl.disable();
  }

  public contractEnd() {
    this._createPasswordButton.element.classList.remove('e-round');
    this.passwordControl.enable();
    this.verifyPasswordControl.enable();
  }

  public passwordStrengthChanged(event: number): void {
    this.currentPasswordStrength = event;
  }
}
