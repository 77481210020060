/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DMVerifiedContactComponent } from './verified-contact.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DMVerifiedContactComponent],
  entryComponents: [DMVerifiedContactComponent]
})
export class DMVerifiedContactModule { }
