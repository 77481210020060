import { Component } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fromLeftEasing } from '@dynamicmedia/shared';
import { Browser } from '@syncfusion/ej2-base';

@Component({
	selector: 'members-content-layout',
	templateUrl: './content-layout.component.html',
	styleUrls: ['./content-layout.component.scss'],
	animations:  [
		trigger('fromLeftEasing',  [ transition('* => *', useAnimation(fromLeftEasing)) ])
	]
})
export class ContentLayoutComponent {
	// Public members
  public collapsedSidebar = window.innerWidth < 991;
	public isMobileDevice: boolean;

	constructor() {
		this.isMobileDevice = Browser.isDevice;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getState(outlet: any) {
		return outlet.activatedRouteData.state;
	}

	public collapseSidebar(collapse: boolean): void {
    this.collapsedSidebar = collapse;
  }
}
