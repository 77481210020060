import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthenticationGuardConfig, AuthenticationGuardConfigService } from './authentication-guard.config';
import { AuthenticationGuard } from './authentication.guard';

@NgModule()
export class AuthenticationGuardModule {
  static forRoot(config: AuthenticationGuardConfig): ModuleWithProviders<AuthenticationGuardModule> {
    return {
      ngModule: AuthenticationGuardModule,
      providers: [
	      AuthenticationGuard,
        { provide: AuthenticationGuardConfigService, useValue: config },
      ]
    };
  }
}
