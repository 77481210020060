import { UserData } from './user-data.dto';
import { EmploymentType, WorkplaceType } from '@prolex/shared';
import { MemberConstants } from '../constants';
import { MemberRegisterDocumentDataDto } from './member-register-document-data.dto';

// export interface RegistrationContactInfo {
// 	countyId?: string;
// 	countyName?: string;
// 	localityId?: string;
// 	localityName?: string;
// 	address?: string;
// 	email?: string;
// 	emailVerified?: boolean;
// 	phoneNumber?: string;
// 	contactInfoVerified?: boolean;
// }
//
// export interface RegistrationPersonalInfo {
// 	firstName?: string;
// 	lastName?: string ;
// 	cnp?: number;
// 	gender?: string;
// 	birthday?: Date;
// 	// Used only on client side for validation
// 	personalDataVerified?: boolean;
// }
//
// export interface RegistrationWorkplaceInfo {
// 	workplaceType?: WorkplaceType;
// 	workplaceL1Id?: string;
// 	workplaceL1Name?: string;
// 	workplaceL2Id?: string;
// 	workplaceL2Name?: string;
// 	workplaceL3Id?: string;
// 	workplaceL3Name?: string;
// 	workplaceOther?: string;
// 	categoryId?: string;
// 	categoryName?: string;
// 	isRetired?: boolean;
// 	isPersonalContractual?: boolean;
// 	workplaceInfoVerified?: boolean;
// }
//
// export interface PasswordInfo {
// 	password?: string;
// 	verifiedPassword?: string;
// 	passwordVerified?: boolean;
// }

export class RegistrationMember { //implements RegistrationContactInfo, RegistrationPersonalInfo, RegistrationWorkplaceInfo, PasswordInfo {
	public email?: string;
	public emailVerified?: boolean;
	public countyId?: string;
	public countyName?: string;
	public localityId?: string;
	public localityName?: string;
	public address?: string;
	public phoneNumber?: string;
	public firstName?: string;
	public lastName?: string;
	public cnp?: number;
	public gender?: string;
	public birthday?: Date;
	public workplaceType?: WorkplaceType;
	public workplaceL1Id?: string;
	public workplaceL1Name?: string;
	public workplaceL2Id?: string;
	public workplaceL2Name?: string;
	public workplaceL3Id?: string;
	public workplaceL3Name?: string;
	public workplaceOther?: string;
	public paidBy: string;
	public isPersonalContractual?: boolean;
	public categoryId?: string;
	public categoryName?: string;
	public isRetired?: boolean;
	public password?: string;
	public signature: string;
	public documentType: number; // 1-pdf 2-docx
	public referenceCode?: string;

	// Used only on client side for validation
	public personalDataVerified?: boolean;
	public contactInfoVerified?: boolean;
	public workplaceInfoVerified?: boolean;
	public passwordVerified?: boolean;

	constructor() {
		this.isRetired = false;
		this.isPersonalContractual = false;
		this.emailVerified = false;
		this.personalDataVerified = false;
		this.contactInfoVerified = false;
		this.workplaceInfoVerified = false;
		this.passwordVerified = false;
	}

	public toUserData() {
		return {
			email: this.email,
			countyId: this.countyId,
			countryId: MemberConstants.romaniaId,
			localityId: this.localityId,
			address: this.address,
			phoneNumber: this.phoneNumber,
			phoneNumberVerified: false,
			firstName: this.firstName,
			lastName: this.lastName,
			cnp: this.cnp,
			gender: this.gender,
			birthday: this.birthday,
			isPersonalContractual: this.isPersonalContractual,
			workplaceId: this.workplaceL3Id || this.workplaceL2Id || this.workplaceL1Id,
			workplaceOther: this.workplaceOther,
			paidBy: this.paidBy,
			categoryId: this.categoryId,
			isRetired: this.isRetired,
			emailVerified: this.emailVerified,
			password: this.password,
			signature: this.signature,
			referenceCode: this.referenceCode
		} as UserData;
	}

	public toMemberRegisterDocumentDataDto() {
		let employmentType: EmploymentType;
		if (this.workplaceType === WorkplaceType.SectorPrivat)
			employmentType = EmploymentType.Angajat;
		else {
			if (this.isPersonalContractual === true)
				employmentType = EmploymentType.PersonalContractual;
			else
				employmentType = EmploymentType.FunctionarPublic;
		}
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			cnp: this.cnp,
			countyId: this.countyId,
			localityId: this.localityId,
			address: this.address,
			phoneNumber: this.phoneNumber,
			email: this.email,
			paidBy: this.paidBy,
			signature: this.signature,
			documentType: this.documentType, // 1-pdf, 2-docx
			workplaceId: this.workplaceL3Id || this.workplaceL2Id || this.workplaceL1Id,
			workplaceOther: this.workplaceOther,
			employmentType: employmentType,
			categoryId: this.categoryId,
		} as MemberRegisterDocumentDataDto;
	}
}
