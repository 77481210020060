import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService, BackendResponse } from '@prolex/shared';

@Injectable({ providedIn: 'any' })
export class ResetPasswordResolver implements Resolve<Observable<BackendResponse | null>> {
  constructor(private _httpClient: HttpClient, private _authenticationService: AuthenticationService) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BackendResponse | null> {
    const requestId = route.queryParams.requestId;
    if (requestId === null || requestId === undefined || requestId.length == 0)
      return of(null);
    return this._authenticationService.verifyResetPasswordRequest(requestId);
  }
}
