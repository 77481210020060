/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordStrengthMeterComponent } from './password-strength-meter.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PasswordStrengthMeterComponent],
  exports: [PasswordStrengthMeterComponent]
})
export class DMPasswordStrengthMeterModule { }
