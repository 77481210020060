import { Component, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DMFunctions } from '@dynamicmedia/shared';
import { AuthenticatedUser, AuthenticationService } from '@prolex/shared';
import { environment } from '../../../../environments/environment';
import { paths } from '../../../app-paths';

@Component({
	selector: 'members-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
	// Outputs
	@Output() public collapseSidebar = new EventEmitter<boolean>();
	// Public members
	public routerPaths = paths;
	public openNav = false;
	public userData: AuthenticatedUser | null;
	public collapsedSidebar = window.innerWidth < 991;
	public profilePicture: string | null = null;
	// Private members
	private readonly _profilePictureSubscription: Subscription;
	private _callChangeDetector = false;

	constructor(
	  private _eventManager: EventManager,
    private _authenticationService: AuthenticationService,
		private _changeDetector: ChangeDetectorRef)
	{
		this.userData = this._authenticationService.userData;
    this._eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
		this._profilePictureSubscription = this._authenticationService.profilePicture$.subscribe((result: string | null) => {
			this.profilePicture = result;
			if (this._callChangeDetector)
				this._changeDetector.detectChanges();
		});
	}

	public ngOnInit(): void {
		this._callChangeDetector = true;
	}

	public ngOnDestroy(): void {
		if (this._profilePictureSubscription)
			this._profilePictureSubscription.unsubscribe();
	}

	public collapseSidebarInternal(): void {
		this.collapsedSidebar = !this.collapsedSidebar;
		this.collapseSidebar.emit(this.collapsedSidebar);
	}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onResize(_event: UIEvent): void {
    const tempValue = window.innerWidth < 991;
    // do not trigger binding every time
    if (tempValue != this.collapsedSidebar) {
      this.collapsedSidebar = tempValue;
      this.collapseSidebar.emit(this.collapsedSidebar);
    }
  }

	public getAvatarPath(pictureUrl: string): string {
		if (DMFunctions.isNullOrEmpty(pictureUrl))
			return null;
		return `${environment.backendUrl.substr(0, environment.backendUrl.length - 1)}${pictureUrl}`;
	}
}
