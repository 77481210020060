import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from '@dynamicmedia/syncfusion';
import { DynamicMediaSharedModule } from '@dynamicmedia/shared';
import { HeaderComponent, PageTitleComponent, SidebarComponent, UserMenuComponent } from '../shared/components';
import { FullLayoutComponent, ContentLayoutComponent } from '../shared/layouts';

@NgModule({
	declarations: [
		HeaderComponent,
		SidebarComponent,
		UserMenuComponent,
		ContentLayoutComponent,
		FullLayoutComponent,
		PageTitleComponent
	],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PerfectScrollbarModule,
    DynamicMediaSharedModule
  ],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		PageTitleComponent
	],
	providers: [ DialogService ]
})
export class SharedModule { }

