/*
 * Project: ProLex Membri Frontend
 * Author: Dynamic Media S.R.L.
 * Copyright © 2020 Dynamic Media S.R.L.
 * Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fisier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { AuthenticationGuardConfig, AuthenticationGuardConfigService } from './authentication-guard.config';

@Injectable()
export class AuthenticationGuard implements CanLoad, CanActivate {
	constructor(
		@Inject(AuthenticationGuardConfigService) private readonly config: AuthenticationGuardConfig,
	  private _authenticationService: AuthenticationService,
    private _router: Router)
	{ }

	public canLoad(route: Route): boolean {
		return this._verify(route, route.path as string);
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this._verify(route, state.url);
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this._verify(route, state.url);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private _verify(route: ActivatedRouteSnapshot | Route, _url: string): boolean {
		if (this._authenticationService.isAuthenticated()) {
			if (!this._authenticationService.isAuthorized(route.data?.allowedRoles)) {
				void this._router.navigate([ this.config?.unauthorizedPath as string ]);
				return false;
			}
			return true;
		}
		void this._router.navigate([ this.config?.loginPath as string ]);
		return false;
	}
}
