import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgressButton, SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { RememberMeServiceModel, ReactiveFormsValidation, RememberMeService } from '@dynamicmedia/shared';
import { DialogService } from '@dynamicmedia/syncfusion';
import { AuthenticationService, LoginRequestModel, ProLexApps } from '@prolex/shared';
import { paths } from '../../app-paths';
import { MembersService, TranslationLoaderService } from '../../shared/services';
import { locale as romanian } from './i18n/ro';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationPopupComponent } from '../../landing-page/components';

@Component({
	selector: 'members-login',
	templateUrl: './login.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements AfterViewInit, OnInit {
	// Components
	@ViewChild('password') private _passwordTextBox: TextBoxComponent;
	@ViewChild('loginButton') private _loginButton: ProgressButton;
	@ViewChild('forgotPasswordButton') private _forgotPasswordButton: ProgressButton;
	@ViewChild('registerButton') private _registerButton: ProgressButton;
	// Public members
	public loginForm: FormGroup;
	public redirectUrlAfterLogin = paths.dashboard.fullPath;
	public disabledControls = false;
	public spinSettings = {
		position: 'Right',
		width: 20,
		template:
			`<div class="sk-fading-circle sk-fading-circle-round-button">
				<div class="sk-circle1 sk-circle"></div>
				<div class="sk-circle2 sk-circle"></div>
				<div class="sk-circle3 sk-circle"></div>
				<div class="sk-circle4 sk-circle"></div>
				<div class="sk-circle5 sk-circle"></div>
				<div class="sk-circle6 sk-circle"></div>
				<div class="sk-circle7 sk-circle"></div>
				<div class="sk-circle8 sk-circle"></div>
				<div class="sk-circle9 sk-circle"></div>
				<div class="sk-circle10 sk-circle"></div>
				<div class="sk-circle11 sk-circle"></div>
				<div class="sk-circle12 sk-circle"></div>
			</div>` } as SpinSettingsModel;
  public spinDuration = 12000;
	// Private members
	private readonly _rememberMeModel!: RememberMeServiceModel | null;
	private readonly _alreadyLoggedIn: boolean;
	private _loginSpin = false;

	//#region Form controls
  public get emailControl(): AbstractControl {
    return this.loginForm && this.loginForm.get('email');
  }

  public get passwordControl(): AbstractControl {
    return this.loginForm && this.loginForm.get('password');
  }

  public get rememberMeControl(): AbstractControl {
    return this.loginForm && this.loginForm.get('rememberMe');
  }
  //#endregion

	constructor(
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		private _translationLoaderService: TranslationLoaderService,
		private _authenticationService: AuthenticationService,
		private _rememberMeService: RememberMeService,
		private _dialogService: DialogService,
		private _memberService: MembersService,
		private _changeDetector: ChangeDetectorRef,
		private _modalService: NgbModal)
	{
		this.loginForm = this._formBuilder.group({
			email: [null, [ReactiveFormsValidation.required, Validators.email]],
			password: [null, ReactiveFormsValidation.required],
			rememberMe: null
		});
		this.loginForm.valueChanges.subscribe(() => {
			this._changeDetector.detectChanges();
		});
		if (this._authenticationService.isAuthenticated()) {
			this._alreadyLoggedIn = true;
			void this._router.navigate([this.redirectUrlAfterLogin]);
			return;
		}
		else
			this._alreadyLoggedIn = false;
		this._translationLoaderService.loadRomanianTranslations(romanian);
		this._rememberMeModel = this._rememberMeService.getData();
	}

  public ngOnInit() {
		if (this._alreadyLoggedIn)
			return;
    const emailParameter = new URLSearchParams(window.location.search).get('email');
    if (emailParameter !== null && emailParameter !== undefined && emailParameter.length > 0) {
	    this.loginForm.setValue({
		    email: emailParameter,
		    password: null,
		    rememberMe: this._rememberMeModel?.rememberMe ?? false
	    });
    }
	}

	public ngAfterViewInit(): void {
		if (this._alreadyLoggedIn)
			return;
		// add password eye button
		this._passwordTextBox.addIcon('append', 'password-icon cis-eye');
		// map click event
		const icon = document.getElementsByClassName('password-icon')[0];
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		icon.addEventListener('click', function (_e) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const textObj: any = (document.getElementById('password') as any).ej2_instances[0];
			if (textObj.element.type === 'password') {
				textObj.element.type = 'text';
				icon.classList.remove('cis-eye');
				icon.classList.add('cis-eye-slash');
			}
			else {
				textObj.element.type = 'password';
				icon.classList.remove('cis-eye-slash');
				icon.classList.add('cis-eye');
			}
		});
		if (this._rememberMeModel == null || !this._rememberMeModel.rememberMe)
			return;
		this.emailControl.setValue(this._rememberMeModel.email);
		this.passwordControl.setValue(this._rememberMeModel.password);
		this.rememberMeControl.setValue(this._rememberMeModel.rememberMe);
	}

	public loginClicked(): void {
	  if (this._loginSpin)
	    return;
    this._loginButton.start();
    this._loginSpin = true;
    this._authenticationService.login(new LoginRequestModel(this.emailControl.value, this.passwordControl.value, ProLexApps.Members))
			.pipe(
				finalize(
					() => {
						this._loginButton.progressComplete();
						this._loginSpin = false;
					})
			).subscribe({
        next: (result: boolean) => {
          if (result) {
            // Set remember me data or clear it if not checked
            if (this.rememberMeControl.value)
              this._rememberMeService.setData(this.emailControl.value, this._passwordTextBox.value)
            else
              this._rememberMeService.clear();
            const memberId = this._authenticationService.userData?.memberId;
            if (memberId != null) {
							setTimeout(() => void this._router.navigate([this.redirectUrlAfterLogin]), 200);
							// Dezactivare mesaj de plata + redirect to home page
						// this._memberService.hasPayments(memberId).subscribe(
						// 	(result: boolean) => {
						// 		if (result)
						// 			setTimeout(
	          //   			() => void this._router.navigate([this.redirectUrlAfterLogin]),
	          // 	  		200
            // 			);
						// 		else {
						// 			this._dialogService.information(
						// 				'Poți plăti cotizația de membru în decurs de 24 de ore de' +
            // 				' la înregistrare. Dacă nu vei efectua cu succes o plată ' +
            // 				'în acest interval de timp, contul tău de utilizator va ' +
            // 				'fi eliminat automat.',
						// 				() => {
						// 					this.spinDuration = 0;
						// 					setTimeout(
            // 	  				() => void this._router.navigate([
            // 	  	  			paths.paymentsNewMembership.fullPath
            // 	  				]),
            //   					200
            // 					);
						// 				});
						// 		}
						// 	},
						// 	// eslint-disable-next-line @typescript-eslint/no-unused-vars
						// 	(_error) => {
						// 		this._dialogService.error(
            //   		'Nu pot determina dacă ești membru!',
            // 	  	() => {
						// 		  	this.spinDuration = 0;
						// 			  setTimeout(
            // 			    () => void this._router.navigate([this.redirectUrlAfterLogin]),
            // 			    200
            // 			  );
						// 		});
						// 	});
						}
            else {
							this.spinDuration = 0;
							setTimeout(() => void this._router.navigate([this.redirectUrlAfterLogin]), 200);
						}
          }
          else
            this._dialogService.warning('Adresă de email sau parolă invalidă');
        },
        error: (e) => {
          this._dialogService.error(e);
        }
      });
	}

	public contractBegin() {
		this._loginButton.element.classList.add('e-round');
		this.emailControl.disable();
		this.passwordControl.disable();
		this.rememberMeControl.disable();
		this._forgotPasswordButton.disabled = true;
		this._registerButton.disabled = true;
	}

	public contractEnd() {
		this._loginButton.element.classList.remove('e-round');
		this.emailControl.enable();
		this.passwordControl.enable();
		this.rememberMeControl.enable();
		this._forgotPasswordButton.disabled = false;
		this._registerButton.disabled = false;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public forgotPasswordClicked(event: any): void {
	  event.preventDefault();
		const params: NavigationExtras = { relativeTo: this._route.parent };
		const emailParam: string | null = this.emailControl.value;
    if (emailParam !== null && emailParam !== undefined && emailParam.length > 0)
		 	params.queryParams = { email: emailParam };
		void this._router.navigate([paths.forgotPassword.fullPath], params);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public registerClicked(event: any): void {
	  event.preventDefault();
		//void this._router.navigate([paths.register.fullPath]);
		this._modalService.open(RegistrationPopupComponent, {
			size: 'xl',
			animation: true,
			backdrop: 'static',
			centered: true,
			keyboard: false,
			scrollable: true
		});
	}
}
