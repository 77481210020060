/*
 * Project: ProLex Membri Frontend
 * Author: Bogdan Anghel
 * Copyright © 2019 - 2020 Dynamic Media S.R.L.
 * Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fisier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';
import { DialogService } from '@dynamicmedia/syncfusion';
import { Functions } from '@prolex/shared';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({providedIn: 'root'})
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private dialogService: DialogService) {}

	public extractError(error: any): Error | string {
		if (error && error.ngOriginalError)
			error = error.ngOriginalError;
		// We can handle messages and Error objects directly.
		if (typeof error === 'string' || error instanceof Error)
			return error;
		// If it's http module error, extract as much information from it as we can.
		if (error instanceof HttpErrorResponse) {
			// The `error` property of http exception can be either an `Error` object,
			// which we can use directly...
			if (error.error instanceof Error)
				return error.error;
			// ... or an`ErrorEvent`, which can provide us with the message but no stack...
			if (error.error instanceof ErrorEvent)
				return error.error.message;
			// ...or the request body itself, which we can use as a message instead.
			if (typeof error.error === 'string')
				return `Server returned code ${error.status} with body "${error.error}"`;
			// If we don't have any detailed information, fallback to the request message itself.
			return error.message;
		}
		// Skip if there's no error, and let user decide what to do with it.
		return null;
	}

	public handleError(error: any): void {
		// Fix for loading chung error
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message)) {
			window.location.reload();
			return;
		}
		const extractedError = this.extractError(error) || 'Handled unknown error';
		// Capture handled exception and send it to Sentry.
		Sentry.captureException(error.originalError || error);
		// When in development mode, log the error to console for immediate feedback.
		if (!environment.production)
			console.error(extractedError);
		this.dialogService.error(Functions.handleError(error));
		// Optionally show user dialog-service to provide details on what happened.
		// Sentry.showReportDialog({ eventId });
	}
}
