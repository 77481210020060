/*
 * Project: ProLex Membri Frontend
 * Author: Dynamic Media S.R.L.
 * Copyright © 2020 - 2021 Dynamic Media S.R.L.
 * Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fisier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationResponse, BackendDataResponse, LoginRequestModel, AuthenticatedUser, BackendResponse } from '../../models';
import { AvailableLanguages } from '../../enums';
import { Functions } from '../../functions';
import { AuthenticationConfigService, AuthenticationServiceConfig } from './authentication-service.config';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
	private readonly _jwtHelper = new JwtHelperService(); // not using as a service
	private readonly _jwtRoleClaim = 'role';
	public readonly isLoggedIn$ = new BehaviorSubject<boolean>(false);
	public readonly profilePicture$: BehaviorSubject<string | null>;
	// private automaticDeauthenticationMessage: HTMLElement;
	// private automaticDeauthenticationRunning: boolean = false;
	// private readonly automaticDeauthenticationIdleValue: number = 30 * 60; // 30 minutes
	// private readonly automaticDeauthenticationTimeoutValue: number = 10 + 1; // 10 seconds. Must add 1 for interface bravity
	// private readonly automaticDeauthenticationPingValue: number = 5; // 5 seconds

	constructor(
		@Inject(AuthenticationConfigService) private readonly config: AuthenticationServiceConfig,
		private _router: Router,
		private _httpClient: HttpClient)
	{
	  this.config = this.config || {
	    backendApiUrl: 'http://localhost',
      localeLocalStorageKey: 'appLocale',
      userInfoLocalStorageKey: 'appUserInfo',
      tokenLocalStorageKey: 'appToken',
			loginUrl: 'autentificare'
	  };
		this._jwtHelper.tokenGetter = () => localStorage.getItem(this.config?.tokenLocalStorageKey as string) as string;
		const userData = localStorage.getItem(this.config?.userInfoLocalStorageKey as string) as string;
		const pictureUrl = userData ? (JSON.parse(userData) as AuthenticatedUser).pictureUrl : null;
		this.profilePicture$ = new BehaviorSubject<string | null>(pictureUrl);
		/*
		const that = this;
		// Start watching when user idle is starting.
		this.userIdle.onTimerStart().subscribe(count => {
			if (count == 1) {
				swal.fire({
					title: that.translateService.instant('LoginPage.AutomaticLogout'),
					html: that.translateService.instant('LoginPage.IdleLogout'),
					icon: 'info',
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					confirmButtonText: '<i class="la la-check"></i> ' + this.translateService.instant('LoginPage.DoNotLogMeOut'),
					onBeforeOpen: () => {
						that.automaticDeauthenticationMessage = swal.getContent();
						that._updateSwalContent(that.automaticDeauthenticationTimeoutValue - 1);
					},
				}).then((result) => {
					if (result && result.value && result.value[0])
						that.userIdle.resetTimer();
				});
			}
			else if (count)
				that._updateSwalContent(that.automaticDeauthenticationTimeoutValue - count);
					// else // not required because the timeout event is fired before this
					// 	that._updateSwalContent(0);
		});
		// Start watch when time is up.
		this.userIdle.onTimeout().subscribe(() => {
			swal.close();
			this.userIdle.stopWatching();
			this.logout();
		});
		*/
	}

	//#region Public Methods
	public login(request: LoginRequestModel): Observable<boolean> {
		if (!request || !request.email || request.email.length === 0 || !request.password || request.password.length === 0)
			return new Observable((subscriber) => {
				subscriber.next(false);
				subscriber.complete();
			});
		return this._httpClient.post<BackendDataResponse<AuthenticationResponse>>(
			`${this.config?.backendApiUrl as string}login`,
			request)
			.pipe(
				map((response: BackendDataResponse<AuthenticationResponse>) => {
					if (!response.isSuccess)
						throw response.message;
					localStorage.setItem(this.config?.tokenLocalStorageKey as string, response.data.token);
					localStorage.setItem(this.config?.userInfoLocalStorageKey as string, JSON.stringify(response.data.userInfo));
					localStorage.setItem(
						this.config?.localeLocalStorageKey as string,
						response.data.userInfo.locale ?? AvailableLanguages.ro
					);
					// this._startAutomaticDeauthentication();
					this.isLoggedIn$.next(true);
					this.profilePicture$.next(response.data.userInfo.pictureUrl);
					return true;
				}),
				catchError(error => {
					throw Functions.handleError(error);
				})
			);
	}

	public logout() {
		localStorage.removeItem(this.config?.tokenLocalStorageKey as string);
    localStorage.removeItem(this.config?.userInfoLocalStorageKey as string);
    localStorage.removeItem(this.config?.localeLocalStorageKey as string);
		this.isLoggedIn$.next(false);
		// this._stopAutomaticDeauthentication();
		void this._router.navigate([this.config?.loginUrl as string]);
		// TODO: call backend to logout from there too
	}

	public forgotPassword(email: string): Observable<BackendResponse> {
    return this._httpClient.get<BackendResponse>(
			`${this.config?.backendApiUrl as string}forgot-password/send/${encodeURIComponent(email)}/${this.config?.app}`
			).pipe(
        map((response: BackendResponse) => {
          if (!response.isSuccess)
            throw response.message;
          return response;
        }),
        catchError(error => {
          throw Functions.handleError(error);
        })
      );
  }

  public verifyResetPasswordRequest(requestId: string): Observable<BackendResponse> {
    return this._httpClient.get<BackendResponse>(`${this.config?.backendApiUrl as string}forgot-password/${requestId}`)
      .pipe(
        catchError(error => {
          throw Functions.handleError(error);
        })
      );
  }

  public resetPassword(requestId: string, password: string): Observable<BackendResponse> {
    return this._httpClient.post<BackendResponse>(
      `${this.config?.backendApiUrl as string}reset-password/${requestId}`,
      { password: password })
      .pipe(
        catchError(error => {
          throw Functions.handleError(error);
        })
      );
  }

  //#region Existing member create account
  public existingMemberRegisterRequest(email: string, cnp: number): Observable<BackendResponse> {
	  return this._httpClient.post<BackendResponse>(
	    `${this.config?.backendApiUrl as string}existing-member-register/send`,
      { email, cnp })
      .pipe(
        catchError(error => {
          throw Functions.handleError(error);
        })
      );
  }

  public verifyCreatePasswordRequest(requestId: string): Observable<BackendResponse> {
    return this._httpClient.get<BackendResponse>(
      `${this.config?.backendApiUrl as string}existing-member-register/${requestId}`)
      .pipe(
        catchError(error => {
          throw Functions.handleError(error);
        })
      );
  }

  public createPassword(requestId: string, password: string): Observable<BackendResponse> {
    return this._httpClient.post<BackendResponse>(
      `${this.config?.backendApiUrl as string}create-password/${requestId}`,
      { password: password })
      .pipe(
        catchError(error => {
          throw Functions.handleError(error);
        })
      );
  }
  //#endregion

	public isAuthenticated() {
		const isValid = this._isTokenValid();
		if (isValid)
			this.isLoggedIn$.next(isValid);
		return isValid;
	}

	public isAuthorized(allowedRoles: Array<string>): boolean {
		if (allowedRoles == null || allowedRoles.length === 0)
			return true;
		const userRole = this.getUserRole();
		if (userRole) {
		  if (userRole instanceof Array) {
		    let foundRole = false;
		    for (const role of userRole) {
		      foundRole = !!allowedRoles.find(i => i === role);
		      if (foundRole)
		        return true;
        }
		    return false;
      }
      return allowedRoles.includes(userRole);
    }
		return false;
	}

	public getUserRole(): string | Array<string> | null {
		const decodedToken = this._jwtHelper.decodeToken();
		if (decodedToken[this._jwtRoleClaim])
			return decodedToken[this._jwtRoleClaim];
		return null;
	}

	public getUserId(): string | null {
		const decodedToken = this._jwtHelper.decodeToken();
		if (decodedToken && decodedToken.sub)
			return decodedToken.sub;
		return null;
	}

	get userData(): AuthenticatedUser | null {
		return JSON.parse(localStorage.getItem(this.config?.userInfoLocalStorageKey as string) as string) as AuthenticatedUser;
	}

	get token(): string | null {
		return localStorage.getItem(this.config?.tokenLocalStorageKey as string);
	}

	public setProfilePicture(pictureUrl: string | null) {
		const userData = JSON.parse(localStorage.getItem(this.config?.userInfoLocalStorageKey as string) as string) as AuthenticatedUser;
		userData.pictureUrl = pictureUrl;
		localStorage.setItem(this.config?.userInfoLocalStorageKey as string, JSON.stringify(userData));
		this.profilePicture$.next(pictureUrl);
	}
	//#endregion

	//#region Private Methods
	private _isTokenValid(): boolean {
		const localStorageIntact = !!localStorage.getItem(this.config?.tokenLocalStorageKey as string);
		if (!localStorageIntact) {
			// this._showSessionExpiredMessage();
			localStorage.removeItem(this.config?.tokenLocalStorageKey as string);
      localStorage.removeItem(this.config?.userInfoLocalStorageKey as string);
			this.isLoggedIn$.next(false);
			return false;
		}
		const isExpired = this._jwtHelper.isTokenExpired();
		if (isExpired) {
			this._showSessionExpiredMessage();
			this.logout();
			return false;
		}
		const notValidBeforeDate = this._jwtHelper.decodeToken().nbf;
		if (!notValidBeforeDate) {
			this._showSessionExpiredMessage();
			this.logout();
			return false;
		}
		const isNbfValid = !(new Date(notValidBeforeDate).valueOf() > new Date().valueOf());
		if (!isNbfValid) {
			this._showSessionExpiredMessage();
			this.logout();
			return false;
		}
		return true;
	}

	private _showSessionExpiredMessage() {
		// const that = this;
		// swal.fire({
		// 	title: that._translateService.instant('LoginPage.SessionExpiredTitle'),
		// 	html: that._translateService.instant('LoginPage.SessionExpiredMessage'),
		// 	icon: 'info',
		// 	allowOutsideClick: false,
		// 	allowEscapeKey: false,
		// 	allowEnterKey: false,
		// 	confirmButtonText: `<i class="la la-check"></i> ${this._translateService.instant('Global.Button.OK')}`
		// }).then(() => that.logout());
	}
	//#endregion

	/*
	private _startAutomaticDeauthentication(): void {
		if (this.automaticDeauthenticationRunning)
			return;
		// also get current date time from server
		this._globalDateTimeService.getCurrentDateTimeFromServer();
		//Start watching for user inactivity.
		this.userIdle.setConfigValues({
			idle: this.automaticDeauthenticationIdleValue,
			timeout: this.automaticDeauthenticationTimeoutValue,
			ping: this.automaticDeauthenticationPingValue
		});
		this.userIdle.startWatching();
		this.automaticDeauthenticationRunning = true;
		// Ping interval. Check if token is or will expire
		const that = this;
		this.userIdle.ping$.subscribe(() => {
			that._isTokenValid();
		});
	}

	private _updateSwalContent(value: number) {
		let selector = this.automaticDeauthenticationMessage.querySelector('strong');
		if (selector != null)
			selector.textContent = `${value}`;
	}

	private _stopAutomaticDeauthentication(): void {
		this.userIdle.stopWatching();
		this.automaticDeauthenticationRunning = false;
	}
	*/
}
