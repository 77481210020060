<span class="text-center font-weight-bold" style="display: block;">{{ 'paymentRequiredLabel' | translate }}</span><br>
<span class="text-center font-weight-bold" style="display: block;">{{ 'subtitleWorkplace' | translate }}</span>
<form [formGroup]="workplaceInfoForm" class="theme-form">
	<div class="form-group">
		<span>{{ 'labelsWorkplace.typeSelect' | translate }}</span>
		<ul>
			<li>
				<ejs-radiobutton
					label="{{ 'types.budgetary' | translate }}"
					[value]="WorkplaceType.SectorBugetar"
					formControlName="workplaceType"
					checked="true"
					(change)="workplaceTypeChange($event)">
				</ejs-radiobutton>
			</li>
			<li>
				<ejs-radiobutton
					label="{{ 'types.private' | translate }}"
					formControlName="workplaceType"
					[value]="WorkplaceType.SectorPrivat"
					(change)="workplaceTypeChange($event)">
				</ejs-radiobutton>
			</li>
		</ul>
	</div>
	<div class="form-group">
		<ejs-checkbox
			label="{{ 'labelsWorkplace.isRetired' | translate }}"
			formControlName="isRetired">
		</ejs-checkbox>
	</div>
	<div class="form-group">
		<ejs-combobox
				#categoryId
				formControlName="categoryId"
				[dataSource]="categoriesDataSource | async"
				[allowFiltering]="true"
				filterType="Contains"
				[autofill]="true"
				[allowCustom]="false"
				[fields]="{ text: 'name', value: 'id' }"
				[ignoreAccent]="true"
				[popupHeight]="200"
				cssClass="e-outline"
				floatLabelType="Auto"
				placeholder="{{ 'labelsWorkplace.categoryId' | translate }}"
				(change)="categoryIdChange($event)">
			<ng-template #itemTemplate="" let-data="">
				<div class="padded-multiline"><span class="name">{{data.name}}</span></div>
			</ng-template>
		</ejs-combobox>
		<div *ngIf="categoryIdControl.invalid && (categoryIdControl.dirty || categoryIdControl.touched)" class="error-msg">
			<div *ngIf="categoryIdControl.errors.required">{{ 'validationWorkplace.categoryIdRequired' | translate }}</div>
		</div>
	</div>
	<div *ngIf="showBudgetarySelectors()">
		<div *ngIf="showWorkplaceL1()" class="form-group">
			<ejs-combobox
					#workplaceL1Id
					formControlName="workplaceL1Id"
					[dataSource]="workplacesL1DataSource | async"
					[allowFiltering]="true"
					filterType="Contains"
					[autofill]="true"
					[allowCustom]="false"
					[fields]="{ text: 'name', value: 'id' }"
					[ignoreAccent]="true"
					[popupHeight]="200"
					cssClass="e-outline"
					floatLabelType="Auto"
					placeholder="{{ 'labelsWorkplace.workplaceL1Id' | translate }}"
					(change)="workplaceL1IdChange($event)">
				<ng-template #itemTemplate="" let-data="">
					<div class="padded-multiline"><span>{{data.name}}</span></div>
				</ng-template>
			</ejs-combobox>
			<div *ngIf="workplaceL1IdControl.invalid && (workplaceL1IdControl.dirty || workplaceL1IdControl.touched)" class="error-msg">
				<div *ngIf="workplaceL1IdControl.errors.required">{{ 'validationWorkplace.workplaceL1IdRequired' | translate }}</div>
			</div>
		</div>
		<div *ngIf="showWorkplaceL2()" class="form-group">
			<ejs-combobox
					#workplaceL2Id
					formControlName="workplaceL2Id"
					[dataSource]="workplacesL2DataSource | async"
					[allowFiltering]="true"
					filterType="Contains"
					[autofill]="true"
					[allowCustom]="false"
					[fields]="{ text: 'name', value: 'id' }"
					[ignoreAccent]="true"
					[popupHeight]="200"
					cssClass="e-outline"
					floatLabelType="Auto"
					placeholder="{{ 'labelsWorkplace.workplaceL2Id' | translate }}"
					(change)="workplaceL2IdChange($event)">
				<ng-template #itemTemplate="" let-data="">
					<div class="padded-multiline"><span>{{data.name}}</span></div>
				</ng-template>
			</ejs-combobox>
		</div>
		<div *ngIf="showWorkplaceL3()" class="form-group">
			<ejs-combobox
					#workplaceL3Id
					formControlName="workplaceL3Id"
					[dataSource]="workplacesL3DataSource | async"
					[allowFiltering]="true"
					filterType="Contains"
					[autofill]="true"
					[allowCustom]="false"
					[fields]="{ text: 'name', value: 'id' }"
					[ignoreAccent]="true"
					[popupHeight]="200"
					cssClass="e-outline"
					floatLabelType="Auto"
					placeholder="{{ 'labelsWorkplace.workplaceL3Id' | translate }}">
				<ng-template #itemTemplate="" let-data="">
					<div class="padded-multiline"><span>{{data.name}}</span></div>
				</ng-template>
			</ejs-combobox>
		</div>
		<div class="form-group">
			<ejs-checkbox
				label="{{ 'labelsWorkplace.isPersonalContractual' | translate }}"
				formControlName="isPersonalContractual">
			</ejs-checkbox>
		</div>
	</div>
	<div *ngIf="showWorkplaceL1()" class="form-group">
		<ejs-textbox
			formControlName="workplaceOther"
			placeholder="{{ 'labelsWorkplace.workplaceOther' | translate }}"
			cssClass="e-outline"
			floatLabelType="Auto"
			autocomplete="off">
		</ejs-textbox>
		<div *ngIf="workplaceOtherControl.invalid && (workplaceOtherControl.dirty || workplaceOtherControl.touched)" class="error-msg">
			<div *ngIf="workplaceOtherControl.errors.required">{{ 'validationWorkplace.workplaceOtherRequired' | translate }}</div>
		</div>
	</div>
	<div *ngIf="showPaidBy()" class="form-group">
		<ejs-textbox
			formControlName="paidBy"
			placeholder="{{ 'labelsWorkplace.paidBy' | translate }}"
			cssClass="e-outline"
			floatLabelType="Auto"
			autocomplete="off">
		</ejs-textbox>
		<div *ngIf="paidByControl.invalid && (paidByControl.dirty || paidByControl.touched)" class="error-msg">
			<div *ngIf="paidByControl.errors.required">{{ 'validationWorkplace.paidByRequired' | translate }}</div>
		</div>
</div>
	<button
		ejs-button
		cssClass="e-info left-button"
		awPreviousStep
		(click)="prev($event)">
		{{ 'labelsWorkplace.prev' | translate }}
	</button>
	<button
		ejs-button
		cssClass="e-info right-button"
		awNextStep
		(click)="next()"
		[disabled]="workplaceInfoForm.invalid">
		{{ 'labelsWorkplace.next' | translate }}
	</button>
</form>