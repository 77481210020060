import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { ChangeEventArgs, ComboBox } from '@syncfusion/ej2-angular-dropdowns';
import { ChangeArgs } from '@syncfusion/ej2-buttons/src/radio-button/radio-button';
import { DialogService, SyncfusionDataType } from '@dynamicmedia/syncfusion';
import { DMFunctions, ReactiveFormsValidation } from '@dynamicmedia/shared';
import { CommonApiService, WorkplaceType } from '@prolex/shared';
import { RegistrationMember } from '../../../../shared/models';
import { TranslationLoaderService } from '../../../../shared/services';
import { locale as romanian } from './i18n/ro';
import { lastEventId } from '@sentry/browser';

@Component({
	selector: 'members-auth-workplace-info',
	templateUrl: './workplace-info.component.html',
	styleUrls: ['./workplace-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkplaceInfoComponent implements OnInit {
	// Inputs
	@Input() public model: RegistrationMember;
	// Outputs
	@Output() public modelChange = new EventEmitter();
	// Components
	@ViewChild('categoryId') private _categoryIdComboBox: ComboBox;
	@ViewChild('workplaceL1Id') private _workplaceL1IdComboBox: ComboBox;
	@ViewChild('workplaceL2Id') private _workplaceL2IdComboBox: ComboBox;
	@ViewChild('workplaceL3Id') private _workplaceL3IdComboBox: ComboBox;
	// Public members
	public workplaceInfoForm: FormGroup;
	public WorkplaceType = WorkplaceType;
	public categoriesDataSource: Observable<Array<SyncfusionDataType>>;
	public workplacesL1DataSource: Observable<Array<SyncfusionDataType>>;
	public workplacesL2DataSource: Observable<Array<SyncfusionDataType>>;
	public workplacesL3DataSource: Observable<Array<SyncfusionDataType>>;
	public isNotionalPoliceCategory:boolean = false;

	//#region Form controls
	public get workplaceTypeControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('workplaceType');
	}
	public get isRetiredControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('isRetired');
	}
	public get isPersonalContractualControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('isPersonalContractual');
	}
	public get categoryIdControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('categoryId');
	}
	public get workplaceL1IdControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('workplaceL1Id');
	}

	public get workplaceL2IdControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('workplaceL2Id');
	}

	public get workplaceL3IdControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('workplaceL3Id');
	}

	public get workplaceOtherControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('workplaceOther');
	}

	public get paidByControl(): AbstractControl {
		return this.workplaceInfoForm && this.workplaceInfoForm.get('paidBy');
	}
	//#endregion

	constructor(
		private _formBuilder: FormBuilder,
		private _translationService: TranslationLoaderService,
		private _commonApiService: CommonApiService,
		private _dialogService: DialogService,
		private _changeDetector: ChangeDetectorRef)
	{
		this._translationService.loadRomanianTranslations(romanian);
		this.workplaceInfoForm = this._formBuilder.group({
			workplaceType: [ WorkplaceType.SectorBugetar, ReactiveFormsValidation.required ],
			isRetired: [ false ],
			isPersonalContractual: [ false ],
			categoryId: [ null, ReactiveFormsValidation.required ],
			workplaceL1Id: [ null ],
			workplaceL2Id: [ null ],
			workplaceL3Id: [ null ],
			workplaceOther: [ null ],
			paidBy: [null ]
		}, {
			validators: [this.otherWorkplaceRequired,this.paidByRequired] 
		});
		this.workplaceTypeControl.valueChanges.subscribe(() => {
			this._changeDetector.markForCheck();
		});
		this.workplaceOtherControl.valueChanges.subscribe(() => {
			this._changeDetector.markForCheck();
		});
		this.paidByControl.valueChanges.subscribe(() => {
			this._changeDetector.markForCheck();
		});
		this.otherWorkplaceRequired = this.otherWorkplaceRequired.bind(this);
		this.paidByRequired = this.paidByRequired.bind(this);
	}

	public ngOnInit() {
		this.categoriesDataSource = this._commonApiService.getCategories(WorkplaceType.SectorBugetar);
	}

	public workplaceTypeChange(args: ChangeArgs) {
		this.categoriesDataSource = this._commonApiService.getCategories((args.value as unknown) as WorkplaceType);
		this.workplaceOtherControl.setValue(null);
		this.categoryIdControl.setValue(null);
	}

	public categoryIdChange(args: ChangeEventArgs) {
		this.workplaceOtherControl.setValue(null);
		this.workplaceL3IdControl.setValue(null);
		this.workplaceL2IdControl.setValue(null);
		this.workplaceL1IdControl.setValue(null);
		this.paidByControl.setValue(null);
		if (args.value === null || args.value === undefined)
			return;
		this.workplacesL1DataSource = this._commonApiService.getWorkplaces(args.value as string);
		this._changeDetector.markForCheck();
	}

	public workplaceL1IdChange(args: ChangeEventArgs) {
		this.workplaceOtherControl.setValue(null);
		this.workplaceL3IdControl.setValue(null);
		this.workplaceL2IdControl.setValue(null);
		if (args.value === null || args.value === undefined)
			return;
		this.workplacesL2DataSource = this._commonApiService.getWorkplaces(this.categoryIdControl.value, args.value as string);
	}

	public workplaceL2IdChange(args: ChangeEventArgs) {
		this.workplaceOtherControl.setValue(null);
		this.workplaceL3IdControl.setValue(null);
		if (args.value === null || args.value === undefined)
			return;
		this.workplacesL3DataSource = this._commonApiService.getWorkplaces(this.categoryIdControl.value, args.value as string);
	}

	// public workplaceL3IdChange(args: ChangeEventArgs) {
	// 	if (args.value === null || args.value === undefined)
	// 		return;
	// }

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public prev(event: any): void {
		// FIX: prevent going to previous section when enter is pressed (screenX and screenY = 0)
		if (event.screenX == 0)
			return;
		this.model.workplaceInfoVerified = true;
	}

	public next(): void {
		this.model.categoryId = this.categoryIdControl.value;
		this.model.categoryName = this._categoryIdComboBox.text;
		this.model.workplaceType = this.workplaceTypeControl.value;
		this.model.workplaceL1Id = this.workplaceL1IdControl != null ? this.workplaceL1IdControl.value : null;
		this.model.workplaceL1Name = this._workplaceL1IdComboBox != null ? this._workplaceL1IdComboBox.text : null;
		this.model.workplaceL2Id = this.workplaceL2IdControl != null ? this.workplaceL2IdControl.value : null;
		this.model.workplaceL2Name = this._workplaceL2IdComboBox != null ? this._workplaceL2IdComboBox.text : null;
		this.model.workplaceL3Id = this.workplaceL3IdControl != null ? this.workplaceL3IdControl.value : null;
		this.model.workplaceL3Name = this._workplaceL3IdComboBox != null ? this._workplaceL3IdComboBox.text : null;
		this.model.workplaceOther = this.workplaceOtherControl.value;
		this.model.paidBy = this.paidByControl.value;
		this.model.isRetired = this.isRetiredControl.value;
		this.model.isPersonalContractual = this.isPersonalContractualControl != null ? this.isPersonalContractualControl.value : false;
		this.model.workplaceInfoVerified = true;
		this.modelChange.emit(this.model);
	}

	public showBudgetarySelectors(): boolean {
		return this.workplaceTypeControl.value === WorkplaceType.SectorBugetar;
	}

	public showWorkplaceL1(): boolean {
		return this.categoryIdControl.value != null;
	}

	public showWorkplaceL2(): boolean {
		return this.workplaceL1IdControl.value != null;
	}

	public showWorkplaceL3(): boolean {
		return this.workplaceL2IdControl.value != null;
	}

	public showPaidBy():boolean {
		return this.isNotionalPoliceCategory = this.categoryIdControl.value == 'vK51Dr1M';
	}

	public otherWorkplaceRequired(formGroup: FormGroup): ValidationErrors | null {
		if (formGroup == null)
			return null;
		if (formGroup.get('workplaceType') == null)
			return null;
		if (formGroup.get('workplaceL1Id') == null)
			return null;
		if (formGroup.get('workplaceType').value === WorkplaceType.SectorBugetar) {
			if (formGroup.get('workplaceL1Id').value === null)
				return DMFunctions.isNullOrEmpty(formGroup.get('workplaceOther').value) ? { required: true } : null;
			else
				return null;
		}
		return DMFunctions.isNullOrEmpty(formGroup.get('workplaceOther').value) ? { required: true } : null;
	}

	public paidByRequired(formGroup: FormGroup): ValidationErrors | null {
		if (formGroup == null)
			return null;
		if (formGroup.get('workplaceType') == null)
			return null;
		if (formGroup.get('workplaceType').value == WorkplaceType.SectorPrivat)
			return null;
		if (formGroup.get('workplaceType').value === WorkplaceType.SectorBugetar) {
			if (formGroup.get('categoryId').value === "vK51Dr1M")
				return DMFunctions.isNullOrEmpty(formGroup.get('paidBy').value) ? { required: true } : null;
			else
				return null;
		}
		return DMFunctions.isNullOrEmpty(formGroup.get('paidBy').value) ? { required: true } : null;
	}
}