/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { InjectionToken } from '@angular/core';

export class RememberMeServiceConfig {
  localStorageKey?: string;
  encryptionKey?: string;
}

export const RememberMeConfigService = new InjectionToken<RememberMeServiceConfig>('RememberMeServiceConfig');
