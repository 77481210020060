import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@prolex/shared';
import { AuthLayoutComponent, LoginComponent, ForgetPasswordComponent, RegisterComponent, ResetPasswordComponent,
	ExistingMemberRegisterComponent, CreatePasswordComponent, UnauthorizedComponent } from './auth';
import { paths } from './app-paths';
import { Error404Component } from './common/error-pages/404/error404.component';
import { PathResolveService } from './common/error-pages/404/path-resolve.service';
import { ResetPasswordResolver } from './auth/reset-password/reset-password.resolver';
import { CreatePasswordResolver } from './auth/create-password/create-password.resolver';
import { ContentLayoutComponent } from './shared/layouts';
import { LandingPageLayoutComponent } from './landing-page/landing-page-layout.component';
// import { PaymentsGuard } from './shared/services';

const routes: Routes = [
	// Landing Page
	{
		path: '',
		component: LandingPageLayoutComponent,
		children: [
			{
				path: paths.landingPage.slug,
				loadChildren: () => import('./landing-page/components/main-content/main-content.module').then(m => m.MainContentModule)
			},
			{
				path: paths.cookiePolicy.slug,
				loadChildren: () => import('./landing-page/components/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule)
			},
			{
				path: paths.termsAndConditions.slug,
				loadChildren: () => import('./landing-page/components/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
			},
			{
				path: paths.privacyPolicy.slug,
				loadChildren: () => import('./landing-page/components/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
			}
		]
	},
	// Authentication, registration, password recovery
	{
	 	path: '',
	 	component: AuthLayoutComponent,
	 	children: [
	 		{
	 			path: paths.login.slug,
	 			component: LoginComponent
	 		},
			{
				path: paths.register.slug,
				component: RegisterComponent
			},
      // TODO: Alias for admin.slug for compatibility with ProLex Club mobile app
      {
        path: 'forgot-password',
        component: ForgetPasswordComponent
      },
			{
				path: paths.forgotPassword.slug,
				component: ForgetPasswordComponent
			},
      // TODO: Alias for admin.slug for compatibility with ProLex Club mobile app
      {
        path: 'reset-password',
        component: ForgetPasswordComponent
      },
      {
        path: paths.resetPassword.slug,
        component: ResetPasswordComponent,
        resolve: { resetPasswordResolver: ResetPasswordResolver }
      },
      {
        path: paths.existingMemberRegister.slug,
        component: ExistingMemberRegisterComponent
      },
      {
        path: paths.createPassword.slug,
        component: CreatePasswordComponent,
        resolve: { createPasswordResolver: CreatePasswordResolver }
      },
      {
        path: paths.unauthorized.slug,
        component: UnauthorizedComponent
      }
    ]
  },
	// App internal routes
	// {
	// 	path: paths.dashboard.slug,
	// 	redirectTo: paths.dashboard.fullPath,
	// 	pathMatch: 'full'
	// },
  // TODO: Alias for admin.slug for compatibility with ProLex Club mobile app
  {
    path: 'admin',
    redirectTo: paths.dashboard.fullPath,
    pathMatch: 'full'
  },
	{
		path: paths.dashboard.slug,
		component: ContentLayoutComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{
				path: '', //paths.dashboard.slug,
				loadChildren: () => import('./member/dashboard/dashboard.module').then(m => m.DashboardModule),
				data: { state: paths.dashboard.slug }
			},
			{
				path: paths.profile.slug,
				loadChildren: () => import('./common/profile/profile.module').then(m => m.ProfileModule),
				data: { state: paths.profile.slug }
			},
			{
				path: paths.payments.slug,
				loadChildren: () => import('./member/payments/payments.module').then(m => m.PaymentsModule),
				data: { state: paths.payments.slug }
			},
			{
				path: paths.notifications.slug,
				loadChildren: () => import('./common/notifications/notifications.module').then(m => m.NotificationsModule),
				data: { state: paths.notifications.slug }
			},
			{
				path: paths.settings.slug,
				loadChildren: () => import('./common/settings/settings.module').then(m => m.SettingsModule),
				data: { state: paths.settings.slug }
			},
      {
        path: paths.categoryContacts.slug,
        loadChildren: () => import('./admin/category-contacts/category-contacts.module').then(m => m.CategoryContactsModule),
        data: { state: paths.categoryContacts.slug }
      },
      {
        path: paths.faq.slug,
        loadChildren: () => import('./admin/faq/faq.module').then(m => m.FaqModule),
        data: { state: paths.faq.slug }
      },
      {
        path: paths.genericTrialsEdit.slug,
        loadChildren: () => import('./admin/generic-trials/generic-trials.module').then(m => m.GenericTrialsModule),
        data: { state: paths.genericTrialsEdit.slug }
      },
			{
				path: paths.subscriptions.slug,
				loadChildren: () => import('./member/beneficiaries/member-beneficiaries.module').then(m => m.MemberBeneficiariesModule),
				data: { state: paths.subscriptions.slug }
			},
			{
				path: paths.beneficiariesList.slug,
				loadChildren: () => import('./admin/beneficiaries/beneficiaries-list/beneficiaries-list.module').then(m => m.BeneficiariesListModule),
				data: { state: paths.beneficiariesList.slug }
			},
			{
				path: paths.selectAdminAction.slug,
				loadChildren: () => import('./admin/select-action/select-action.module').then(m => m.SelectActionModule),
				data: { state: paths.selectAdminAction.slug }
			},
			{
			path: paths.voucherSettings.slug,
				loadChildren: () => import('./admin/vouchers/voucher-settings/voucher-settings.module').then(m => m.VoucherSettingsModule),
				data: { state: paths.voucherSettings.slug }
			},
			{
			path: paths.vouchersManagement.slug,
				loadChildren: () => import('./admin/vouchers/vouchers-management/vouchers-management.module').then(m => m.VouchersManagementModule),
				data: { state: paths.voucherSettings.slug }
			},
			{
				path: paths.vouchers.slug,
				loadChildren: () => import('./member/vouchers/member-vouchers.module').then(m => m.MemberVouchersModule),
				data: { state: paths.vouchers.slug }
			},
			{
				path: paths.clubMobileAppPrivacyPolicy.slug,
				loadChildren: () => import('./admin/club-mobile-app/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
				data: { state: paths.clubMobileAppPrivacyPolicy.slug }
			},
			{
				path: paths.clubMobileAppAbout.slug,
				loadChildren: () => import('./admin/club-mobile-app/about-app/about-app.module').then(a => a.AboutAppModule),
				data: { state: paths.clubMobileAppAbout.slug }
			},
			{
				path: paths.clubMobileReferenceCodeInfo.slug,
				loadChildren: () => import('./admin/club-mobile-app/reference-code-info/reference-code-info.module').then(a => a.ReferenceCodeInfoModule),
				data: { state: paths.clubMobileReferenceCodeInfo.slug }
			},
			{
				path: paths.clubMobileCasaDracula.slug,
				loadChildren: () => import('./admin/club-mobile-app/casa-dracula/casa-dracula.module').then(a => a.CasaDraculaModule),
				data: { state: paths.clubMobileCasaDracula.slug}
			},
			{
				path: paths.beneficiaryServices.slug,
				loadChildren: () => import('./admin/beneficiaries/services-list/services-list.module').then(m => m.ServicesListModule),
				data: { state: paths.beneficiaryServices.slug }
			},
			{
				path: paths.paymentsReport.slug,
				loadChildren: () => import('./admin/payments-report/payments-report.module').then(m => m.BeneficiariesListModule),
				data: { state: paths.paymentsReport.slug }
			},
			{
				path: paths.voucherManagerManagement.slug,
				loadChildren: () => import('./member/voucher-manager-management/voucher-manager-management.module')
					.then(m => m.VouchersManagerManagementModule),
				data: { state: paths.voucherManagerManagement.slug }
			}
		],
	},

	// Errors
	// {
	// 	path: paths.error.slug,
	// 	component: FullLayoutComponent,
	// 	loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule)
	// },
	// 404
	{
		path: '**',
		// redirectTo: `${paths.error.fullPath}/404`
		component: Error404Component,
		resolve: {
			path: PathResolveService
		}
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes /*, { relativeLinkResolution: 'legacy' }*/)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
