<!--
	Project: ProLex Club WebApp
	Author: Dynamic Media S.R.L.
	Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.

	Any use or reproduction of this source code is prohibited
	without the explicit consent by Dynamic Media S.R.L.
-->

<div class="page-main-header" [ngClass]="{ 'open': collapsedSidebar }" id="page-main-header">
  <div class="main-header-right row" style="padding: 0 10px;">
    <div class="main-header-left d-lg-none" style="width:auto;padding:0;">
      <div class="logo-wrapper" style="margin:0;">
				<a href="javascript:void(0)">
					<img src="assets/images/logo-small.png" alt="">
				</a>
			</div>
    </div>
    <div class="mobile-sidebar d-lg-none" style="width:auto;padding:0;">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebarInternal()" style="font-size: 36px;">
            <i class="cis-hamburger-menu" id="sidebar-toggle"></i>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col" style="width:auto;">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        <li>
          <a href="javascript:void(0)" class="text-dark" toggleFullscreen title="Ecran complet" style="font-size:16px;position:relative;top:4px;">
            <i class="cis-fullscreen"></i>
          </a>
        </li>
        <li class="onhover-dropdown">
          <i class="cis-bell" style="font-size: 20px;position:relative;top:4px;"></i>
          <span class="dot"></span>
          <ul class="notification-dropdown onhover-show-div">
            <li>Notificări <span class="badge badge-pill badge-primary pull-right">0</span></li>
            <!-- <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0"><span>
                      <app-feather-icons class="shopping-color" icon="shopping-bag"></app-feather-icons>
                    </span>Reînnoire cotizație<small class="pull-right">09:00</small></h6>
                  <p class="mb-0">Notificare test reînnoire cotizație.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 txt-success"><span>
                      <app-feather-icons class="download-color font-success" icon="download"></app-feather-icons>
                    </span>Plată cotizație efectuată<small class="pull-right">14:30</small></h6>
                  <p class="mb-0">Notificare test plată efectuată cu succes.</p>
                </div>
              </div>
            </li> -->
            <li class="bg-dark"><a [routerLink]="routerPaths.notifications.fullPath" class="txt-light">Toate notificările</a></li>
          </ul>
        </li>
        <li class="onhover-dropdown">
          <div class="media align-items-center">
            <div class="e-avatar e-avatar-large e-avatar-circle rose" style="font-size: 1em;">
              <div *ngIf="!profilePicture">{{ userData && userData.firstName && userData.lastName ? userData.firstName[0] + userData.lastName[0] : '' }}</div>
              <img *ngIf="profilePicture" src="{{ getAvatarPath(profilePicture) }}" alt="Avatar"/>
            </div>
            <div class="dotted-animation">
              <span class="animate-circle"></span>
              <span class="main-circle"></span>
            </div>
          </div>
          <members-user-menu></members-user-menu>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right onhover-dropdown" style="margin-right: 20px;">
				<div class="media align-items-center">
            <div class="e-avatar e-avatar-large e-avatar-circle rose" style="font-size: 1em;">
              <div *ngIf="!profilePicture">{{ userData && userData.firstName && userData.lastName ? userData.firstName[0] + userData.lastName[0] : '' }}</div>
              <img *ngIf="profilePicture" src="{{ getAvatarPath(profilePicture) }}" alt="Avatar"/>
            </div>
					<div class="dotted-animation">
						<span class="animate-circle"></span>
						<span class="main-circle"></span>
					</div>
				</div>
        <members-user-menu></members-user-menu>
      </div>
    </div>
  </div>
</div>
