<div class="error-wrapper">
    <div class="container">
        <img class="img-100" src="assets/images/sad.png" alt="">
        <div class="error-heading">
            <h2 class="headline font-primary">404</h2>
        </div>
        <div class="col-md-8 offset-md-2">
            <p class="sub-content">Pagina care încerci să o accesezi nu este disponibilă. Acest lucru se întâmplă dacă pagina nu există sau a fost mutată.</p>
            <p class="sub-content">Poate ai vrut să navighezi la pagina: <a [routerLink]="path">{{ path }}</a></p>
        </div>
        <div *ngIf="isAuthenticated(); else notAuthenticated">
            <a [routerLink]="routerPaths.admin.fullPath" class="btn btn-primary btn-lg">ÎNAPOI LA PAGINA TA PERSONALĂ</a>
        </div>
        <ng-template #notAuthenticated>
            <a [routerLink]="['/']" class="btn btn-primary btn-lg">ÎNAPOI LA PAGINA PRINCIPALĂ</a>
        </ng-template>
    </div>
</div>
