/*
 * Project: ProLex Membri Frontend
 * Author: Bogdan Anghel
 * Copyright © 2019 - 2020 Dynamic Media S.R.L.
 * Toate drepturile sunt rezervate.
 *
 * Folosirea acestui fisier și/sau conținutul acestuia parțial sau în totalitate
 * fără acordul explicit și în prealabil al Dynamic Media S.R.L. este interzis
 * în conformitate cu legile române și internaționale privind drepturile de autor.
 */

import { Component } from '@angular/core';
import { DMFunctions } from '@dynamicmedia/shared';

@Component({
  selector: 'members-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent {
	// Public members
	public randomBackgroundImage: number;

	constructor() {
		this.randomBackgroundImage = DMFunctions.getRandomInteger(1, 3);
	}
}
