<!--
 Project: Dynamic Media Shared Components & Functionality
 Author: Dynamic Media S.R.L.
 Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.

 Any use or reproduction of this source code is prohibited
 without the explicit consent by Dynamic Media S.R.L.
-->

<div>
  <div class="strength-meter">
    <div class="strength-meter-fill" [style.background]="getMeterFillColor(passwordStrength)" [attr.data-strength]="passwordStrength"></div>
  </div>
  <ng-container *ngIf="enableFeedback && feedback">
    <small class="password-feedback" *ngIf="feedback.warning">
      {{feedback.warning}}
    </small>
    <small class="password-suggestion" *ngIf="feedback.suggestions && feedback.suggestions.length > 0">
      <ng-container *ngFor="let suggestion of feedback.suggestions">{{suggestion}}</ng-container>
    </small>
  </ng-container>
</div>
