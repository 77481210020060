import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
	dsn: "https://06d977a9a51241da922fade8097946be@o340258.ingest.sentry.io/1878217",
	integrations: [
		new Integrations.BrowserTracing({
			tracingOrigins: ["localhost", "https://membri.prolex.ro"],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],
	tracesSampleRate: environment.production ? 0.7 : 1,
});

if (environment.production)
  enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
