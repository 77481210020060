export const locale = {
	lang: 'ro',
	data: {
    paymentRequiredLabel: 'Pentru a definitiva înregistrarea ca membru trebuie efectuată plata online a cotizației în termen de 24 de ore',
		subtitlePersonal: 'Câmpurile marcate cu * sunt obligatorii',
		labelsPersonal: {
			firstName: 'Prenume *',
			lastName: 'Nume *',
      cnp: 'CNP *',
      cnpSpinner: 'Te rog să aștepți. Verific CNP-ul tău.',
      next: 'Pasul următor',
      prev: 'Pasul precedent',
      validateCnp: 'Validează',
      referenceCode: 'Cod recomandare'
		},
		validationPersonal: {
		  firstNameRequired: 'Prenumele este obligatoriu',
      firstNameRange: 'Prenumele poate avea între 2 și 32 caractere',
      firstNamePattern: 'Prenumele are o formă invalidă',
      lastNameRequired: 'Numele este obligatoriu',
      lastNameRange: 'Numele poate avea între 2 și 32 caractere',
      lastNamePattern: 'Numele are o formă invalidă',
      cnpRequired: 'CNP-ul este obligatoriu',
      cnpInvalid: 'CNP-ul este invalid',
      cnpExists: 'CNP-ul există deja în baza de date'
		}
	}
};
