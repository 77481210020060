import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BackendDataResponse, BackendResponse, BackendUrls, Functions } from '@prolex/shared';
import { environment } from '../../../environments/environment';
import { BeneficiaryEditDto } from '@members/shared/models';

@Injectable({ providedIn: 'root' })
export class MembersService {
	public readonly hasValidPayments$ = new BehaviorSubject<boolean | null>(null);

	constructor(private _httpClient: HttpClient) {}

	public cnpExists(cnp: string): Observable<boolean> {
		return this._httpClient.get<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}members/cnp-exists/${cnp}`
		).pipe(
			map((response: BackendResponse) => {
				return response.isSuccess;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}

	public hasPayments(memberId: string): Observable<boolean>{
		return this._httpClient.get<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}payments/hasPayments/${memberId}`
		).pipe(
			map((response: BackendResponse) => {
				//this.verifiedPayments$.next(true);
				this.hasValidPayments$.next(response.isSuccess);
				return response.isSuccess;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}


	public hasBeneficiariesRecurrentPayments(memberId: string): Observable<boolean> {
		return this._httpClient.get<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}payments/has-beneficiaries-recurrent-payments/${memberId}`
		).pipe(
			map((response: BackendResponse) => {
				return response.isSuccess;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}

	public canPayAsirom(memberId: string): Observable<boolean> {
		return this._httpClient.get<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}payments/can-pay-asirom/${memberId}`
		).pipe(
			map((response: BackendResponse) => {
				return response.isSuccess;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}

	public cancelBeneficiariesRecurrentPayments(memberId: string): Observable<boolean> {
		return this._httpClient.get<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}payments/cancel-beneficiaries-recurrent-payments/${memberId}`
		).pipe(
			map((response: BackendResponse) => {
				return response.isSuccess;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}

	public getMemberAsBeneficiary(memberId: string): Observable<BeneficiaryEditDto> {
		return this._httpClient.get<BackendDataResponse<BeneficiaryEditDto>>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}beneficiaries/self/${memberId}`
		).pipe(
			map((response: BackendDataResponse<BeneficiaryEditDto>) => {
				if (!response.isSuccess)
					throw response.message;
				else
					return response.data;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}

	public simulateRecurrentPayments(): Observable<boolean> {
		return this._httpClient.get<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.membersApiUrl}payments/simulate-recurrent-payments`
		).pipe(
			map((response: BackendResponse) => {
				return response.isSuccess;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}
}
