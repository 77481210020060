import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DialogService } from '@dynamicmedia/syncfusion';
import { AuthenticationService } from '@prolex/shared';
import { TranslationLoaderService } from '../../../shared/services';
import { paths } from '../../../app-paths';
import { locale as romanian } from './i18n/ro';

@Component({
	selector: 'members-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent {
	// Public members
	public routerPaths = paths;

	constructor(
	  private _authenticationService: AuthenticationService,
    private _dialogService: DialogService,
    private _translationLoaderService: TranslationLoaderService)
	{
    this._translationLoaderService.loadRomanianTranslations(romanian);
  }

	public logout(): void {
	  const translationService = this._translationLoaderService.getTranslationService();
	  this._dialogService.question(translationService.instant('logoutMessage'), () => {
	    this._authenticationService.logout();
    });
	}
}
