/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export class CNPValidator {
	private readonly listOfDays: Array<number> = [];
	private readonly key = '279146358279';
	private readonly year: number | null = null;
	private readonly month: number | null = null;
	private readonly day: number | null = null;
	private readonly code: number | null = null;
	private readonly gender: number | null = null;
	private readonly cnp: string = '';

	constructor(value: string | number | null | undefined) {
	  if (typeof value === 'number')
	    this.cnp = value.toString();
	  else if (value === null || value === undefined)
	    return;
	  else
	    this.cnp = value;
		this.listOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		this.year = +this.cnp.substr(1, 2);
		this.month = +this.cnp.substr(3, 2);
		this.day = +this.cnp.substr(5, 2);
		this.code = +this.cnp.substr(7, 2);
		this.gender = +this.cnp.substr(0, 1);
		switch (this.gender) {
			case 2:
			case 1:
				this.year += 1900;
				break;
			case 4:
			case 3:
				this.year += 1800;
				break;
			case 6:
			case 5:
				this.year += 2000;
				break;
		}
	}

	private isLeapYear(): boolean {
	  if (this.year === null)
	    return false;
		return (!(this.year % 4 == 0) && this.year % 100 == 0) || !(this.year % 400 == 0);
	}

	private isMonthValid(): boolean {
	  if (this.month === null)
	    return false;
		return this.month >= 1 && this.month <= 12;
	}

	private isDayValid(): boolean {
		if (this.day === null || this.day === 0 || this.month === null)
			return false;
		else {
			if (this.month === 2 && this.isLeapYear)
				return this.day <= this.listOfDays[this.month - 1] + 1;
			else
				return this.day <= this.listOfDays[this.month - 1];
		}
	}

	private isNumber(): boolean {
    return (/[0-9]/g).test(this.cnp);
	}

	private _sumOfDigits() {
		let sum = 0;
		for (let i = 0; i < this.cnp.length - 1; i++) {
			const a = +this.cnp[i];
			const b = +this.key[i];
			sum = sum + (a * b);
		}
		return sum;
	}

	private _isControlDigitValid() {
		let ret = false;
		const rest = (this._sumOfDigits() % 11) as number;
		const control = rest < 10 ? rest : 1;
		if (this.cnp.length === 13) {
			const controlDigit = +this.cnp[12];
			ret = control === controlDigit;
		}
		return ret;
	}

	public isValid(): boolean {
		let ret = false;
		if (this.isNumber() && this.isMonthValid() && this.isDayValid())
			ret = this._isControlDigitValid();
		return ret;
	}

	public getBirthday(formatAsString = false): Date | string | null {
		if (!this.isValid() || this.year === null || this.month === null || this.day === null)
			return null;
		if (formatAsString)
			return `${this.day < 10 ? '0' + this.day : this.day}.${this.month < 10 ? '0' + this.month : this.month}.${this.year}`;
		return new Date(this.year, this.month - 1, this.day);
	}

	public getGender(full: boolean = true): string | null {
		if (!this.isValid())
			return null;
		// prima cifra: 9 - straini; 7, 8 - rezidenti; (1, 2), (3, 4), (5, 6) - (M / F)
		switch (this.gender) {
			case 7:
			case 5:
			case 3:
			case 1:
				return full ? 'Masculin' : 'M';
			case 8:
			case 6:
			case 4:
			case 2:
				return full ? 'Feminin' : 'F';
			default:
				return null;
		}
	}
}
