import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Functions } from '@prolex/shared';
import { CNPValidator, DMFunctions, ReactiveFormsValidation } from '@dynamicmedia/shared';
import { DialogService, SpinnerService } from '@dynamicmedia/syncfusion';
import { RegisterService, MembersService, TranslationLoaderService } from '../../../../shared/services';
import { RegistrationMember } from '../../../../shared/models';
import { locale as romanian } from './i18n/ro';

@Component({
  selector: 'members-auth-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalDataComponent implements AfterViewInit {
	// Inputs
	@Input() public model: RegistrationMember;
	// Outputs
	@Output() public modelChange = new EventEmitter();
	// Components
	@ViewChild('personalDataSpinner') private _spin: ElementRef;
	// Public members
	public personalDataForm: FormGroup;
	public validatedCnp = false;

	//#region Form controls
  public get firstNameControl(): AbstractControl {
    return this.personalDataForm && this.personalDataForm.get('firstName');
  }

  public get lastNameControl(): AbstractControl {
    return this.personalDataForm && this.personalDataForm.get('lastName');
  }

  public get cnpControl(): AbstractControl {
    return this.personalDataForm && this.personalDataForm.get('cnp');
  }

  public get referenceCodeControl(): AbstractControl {
    return this.personalDataForm && this.personalDataForm.get('referenceCode');
  }
  //#endregion

  constructor(
		private _registrationService: RegisterService,
		private _memberService: MembersService,
    private _formBuilder: FormBuilder,
    private _translationService: TranslationLoaderService,
    private _spinnerService: SpinnerService,
		private _dialogService: DialogService,
		private _changeDetector: ChangeDetectorRef)
	{
    this._translationService.loadRomanianTranslations(romanian);
    this.personalDataForm = this._formBuilder.group({
      lastName: [
        null,
        [
        	ReactiveFormsValidation.rangeLength(2, 32, true),
	        ReactiveFormsValidation.namePattern]
      ],
      firstName: [
        null,
        [
        	ReactiveFormsValidation.rangeLength(2, 32, true),
	        ReactiveFormsValidation.namePattern
        ]
      ],
      cnp: [
        null,
        ReactiveFormsValidation.cnpInvalid(true)
      ],
      referenceCode:[ null ]
    });
    this.personalDataForm.valueChanges.subscribe(() => {
      this._changeDetector.markForCheck();
    });
    this.cnpControl.valueChanges.subscribe(() => {
      this.validatedCnp = false;
    });
	}

  public ngAfterViewInit() {
    this._spinnerService.createSpinner({
	    target: this._spin.nativeElement,
	    label: this._translationService.getTranslation('labelsPersonal.cnpSpinner')
    });
  }

  public cnpExistsCheck() {
    this._spinnerService.showSpinner(this._spin.nativeElement);
    this._memberService.cnpExists(this.cnpControl.value).subscribe({
    	next: (value: boolean) => {
	      if (value)
	        this.cnpControl.setErrors({ cnpExists: value });
	      this.validatedCnp = !value;
	      this._changeDetector.markForCheck();
	      this._spinnerService.hideSpinner(this._spin.nativeElement);
	    },
	    error: (e) => {
		    this._spinnerService.hideSpinner(this._spin.nativeElement);
    		this._dialogService.error(Functions.handleError(e));
	    }
		});
  }

	public next(): void {
    this.model.lastName = DMFunctions.titleCase(this.lastNameControl.value);
    this.model.firstName = DMFunctions.titleCase(this.firstNameControl.value);
    this.model.cnp = this.cnpControl.value;
    const cnp = new CNPValidator(this.model.cnp);
    this.model.gender = cnp.getGender(false);
    this.model.birthday = cnp.getBirthday() as Date;
	  this.model.personalDataVerified = this.personalDataForm.valid;
    this.model.referenceCode = this.referenceCodeControl.value?.trim().toLowerCase() ?? null;
	  this.modelChange.emit(this.model);
	}
}
