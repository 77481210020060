import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DialogServiceModule } from '@dynamicmedia/syncfusion';
import { DMLoadingModule, GtagModule, RememberMeServiceModule, HttpHeadersInterceptor,
	HttpHeadersInterceptorModule } from '@dynamicmedia/shared';
import { AuthenticationGuardModule, AuthenticationServiceModule, AvailableLanguages, BackendUrls, ProLexApps,
	CommonApiServiceModule } from '@prolex/shared';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { LandingPageLayoutModule } from './landing-page/landing-page-layout.module';
import { ErrorPagesModule, PathResolveService } from './common/error-pages';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LandingPageLayoutComponent } from './landing-page/landing-page-layout.component';
import { AuthPagesModule } from './auth/auth-pages.module';
import { AuthLayoutComponent } from './auth';
import { paths } from './app-paths';
import { GlobalErrorHandler } from './shared';
import * as Sentry from "@sentry/angular";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Cookie consent
const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: environment.production ? 'membri.prolex.ro' : 'localhost'
	},
	position: 'bottom',
	theme: 'edgeless',
	palette: {
		popup: {
			background: '#000',
			text: '#ffffff',
			link: '#ffffff'
		},
		button: {
			background: '#f1d600',
			text: '#000000',
			border: 'transparent'
		}
	},
	type: 'info',
	content: {
		message: 'Acest website folosește cookie-uri pentru a vă oferi cea mai bună experiență de navigare.',
		allow: 'Accept cookie-urile',
		dismiss: 'Am înțeles',
		deny: 'Refuz',
		link: 'Află mai multe',
		href: '/politica-folosirii-cookieurilor',
		policy: 'Cookie-uri'
	}
};
export function cookieConfigFactory(http: HttpClient, config: NgcCookieConsentConfig) {
	return () => config.cookie.domain = environment.localDomain;
}

@NgModule({
	declarations: [
		AppComponent,
		LandingPageLayoutComponent,
		AuthLayoutComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		LandingPageLayoutModule,
		AuthPagesModule,
		BrowserAnimationsModule,
		ErrorPagesModule,
		HttpClientModule,
    PerfectScrollbarModule,
		HttpClientXsrfModule.withOptions({
			cookieName: 'X-XSRF-COOKIE',
			headerName: 'X-XSRF-TOKEN'
		}),
		TranslateModule.forRoot(),
		FormsModule,
		NgbModule,
		NgcCookieConsentModule.forRoot(cookieConfig),
		GtagModule.init({ targetId: 'UA-73582042-4' }),
		DMLoadingModule.forRoot({}),
    DialogServiceModule.forRoot({
      locale: AvailableLanguages.ro,
      appName: environment.appName
    }),
    RememberMeServiceModule.forRoot({
      localStorageKey: environment.localStorage.rememberMe,
      encryptionKey: '6PwUcKQ9'
    }),
    AuthenticationServiceModule.forRoot({
      backendApiUrl: `${environment.backendUrl}${BackendUrls.usersApiUrl}`,
      tokenLocalStorageKey: environment.localStorage.authenticationToken,
      userInfoLocalStorageKey: environment.localStorage.userInfo,
      localeLocalStorageKey: environment.localStorage.locale,
      app: ProLexApps.Members,
			loginUrl: paths.login.fullPath
    }),
    AuthenticationGuardModule.forRoot({
      loginPath: paths.login.fullPath,
      unauthorizedPath: paths.unauthorized.fullPath
    }),
    HttpHeadersInterceptorModule.forRoot({
      authenticationTokenLocalStorageKey: environment.localStorage.authenticationToken,
      localeLocalStorageKey: environment.localStorage.locale
    }),
		CommonApiServiceModule.forRoot({
			backendApiUrl: `${environment.backendUrl}`
		})
	],
	providers: [
		PathResolveService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpHeadersInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: cookieConfigFactory,
			deps: [HttpClient, NgcCookieConsentConfig],
			multi: true
		},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
		{ provide: Sentry.TraceService, deps: [Router] },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler }
	],
	bootstrap: [ AppComponent ]
})
export class AppModule { }
