<div class="terms-and-conditions-content">
	<div class="container">
		<div class="text-center" style="width:100%;">
			<h1 class="main-title">Termeni și condiții</h1>
		</div>
		<p>Vă rugăm să citiți cu atenție termenii și condițiile de utilizare ale acestui site.</p>
		<p>Accesul / vizitarea acestui site de către dumneavoastră se supune termenilor și condițiilor de utilizare, implică acceptul explicit al dumneavoastră cu privire la acestea, iar acceptarea reprezintă înțelegerea dintre părți.</p>
		<p><strong>Sindicatul Național Pro Lex</strong> cu sediul în București, str. Mendeleev nr 36-38 etaj 8, sector 1, cod de înregistrare fiscală 16392628, este proprietarul / administratorul site-ului <a routerLink="/">membri.prolex.ro</a>.</p>
		<p><strong>Sindicatul Național Pro Lex</strong> își rezervă dreptul de a schimba și actualiza în orice moment conținutul acestui site, <a [routerLink]="routerPaths.privacyPolicy.fullPath">&quot;Politica de confidențialitate&quot;</a> și &quot;Termenii și condițiile&quot; de folosire, fără niciun fel de notificare prealabilă.</p>
		<p>De aceea, vă rugăm să vizitați periodic această secțiune pentru a verifica termenii și condițiile pe care ați agreat să le respectați. Orice schimbare produce efecte doar pentru viitor.</p>
		<ol>
			<li><strong> Informații personale</strong></li>
		</ol>
		<p>Utilizatorul este responsabil pentru toate activitățile care survin prin accesarea site-ului <a routerLink="/">membri.prolex.ro</a>.</p>
		<p><strong>Sindicatul Național Pro Lex</strong> nu poate fi făcut responsabil pentru acțiunile survenite în urma neglijenței utilizatorului privind securitatea și confidențialitatea datelor personale.</p>
		<ol start="2">
			<li><strong> Drepturile utilizatorului</strong></li>
	 	</ol>
	 	<p><strong>Sindicatul Național Pro Lex</strong> garantează utilizatorului acces limitat, în interes personal, pe acest site și nu îi conferă dreptul de a descărca sau de a modifica parțial sau integral site-ul, de a reproduce parțial sau integral site-ul, de a copia, de a vinde / revinde sau de a exploata site-ul în orice altă manieră, fără acordul scris al <strong>Sindicatului Național Pro Lex</strong>.</p>
	 	<ol start="3">
			<li><strong> Securitatea datelor personale</strong></li>
		</ol>
		<p>Datele dumneavoastră personale vor putea fi utilizate de către <strong>Sindicatul Național Pro Lex</strong> în conformitate cu <a [routerLink]="routerPaths.termsAndConditions.fullPath">Politica de confidențialitate</a> cu care sunteți de acord prin utilizarea acestui site.</p>
		<p>Datele dumneavoastră personale vor putea fi transmise autorităților în drept cu scopul verificării tranzacțiilor comerciale sau altor autorități în drept pentru efectuarea oricăror verificări justificate în baza legii.</p>
		<p>Datele furnizate de către membri sunt strict confidențiale. <strong>Sindicatul Național Pro Lex</strong> se angajează în fața membrilor săi să nu furnizeze aceste date unor terțe persoane sau companii și să le utilizeze strict pentru uzul relației dintre membru și <strong>Sindicatul Național Pro Lex.</strong></p>
		<p>Acest site folosește măsuri de securitate împotriva pierderii, alterării sau folosirii greșite a informațiilor care se află în controlul nostru.</p>
		<p><strong>Sindicatul Național Pro Lex</strong> se obligă să respecte drepturile dumneavoastră conferite de Legea 190/2018, iar la cererea dumneavoastră trimisă la adresa <a href="mailto:office@prolex.ro">office@prolex.ro</a>, se obligă: să rectifice, actualizeze, blocheze, șteargă sau transforme în date anonime, în mod gratuit, datele a căror prelucrare nu este conformă dispozițiilor Legii nr. 190/2018 privind protecția persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date, să înceteze prelucrarea datelor dumneavoastră personale.</p>
		<p><strong>Sindicatul Național Pro Lex</strong> certifică faptul că va respecta drepturile conferite de Legea nr. 677/2001 privind protecția persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date, a Legii nr. 365/2002 privind comerțul electronic, precum și a Ordonanței nr. 130/2000 privind protecția consumatorilor la încheierea și executarea contractelor la distanță cu modificările ulterioare.</p>
		<p>Printre aceste drepturi se numără (enumerarea neavând un caracter limitativ):</p>
		<ul class="no-space-list">
			<li>dreptul de a cere <strong>Sindicatului Național Pro Lex</strong> să vă confirme dacă prelucrează sau nu datele dumneavoastră personale, în mod gratuit, pentru o solicitare pe an;</li>
			<li>dreptul de a cere <strong>Sindicatului Național Pro Lex</strong> să rectifice, actualizeze, blocheze sau șterge, în mod gratuit, acele date furnizate a căror prelucrare nu este conforma dispozițiilor Legii nr. 677/2001;</li>
			<li>dreptul de a cere <strong>Sindicatului Național Pro Lex</strong> să înceteze, în mod gratuit, prelucrarea datelor personale, cu efectele aferente (ex. pierderea calitătii de membru, ȋncetarea reprezentării ȋn instanţă, retragerea accesului la platforma on-line, aplicaţia de mobil ProLex Club etc.);</li>
			<li>dreptul de a cere <strong>Sindicatului Național Pro Lex</strong> să înceteze expedierea de mesaje promoționale.</li>
		</ul>
		<p>Orice astfel de cerere / notificare se va data, semna și trimite de către persoana înscrisa in baza de date prin e-mail la adresa <a href="mailto:office@prolex.ro">office@prolex.ro</a>.</p>
		<p>În vederea eliminării riscului ca datele dumneavoastră să intre în posesia și să fie folosite abuziv de terțe persoane: <strong>Sindicatul Național Pro Lex</strong> garantează securitatea sistemelor sale informatice.</p>
		<p>Informațiile privind datele cardului bancar (număr de card, data expirării etc.) nu sunt transferate sau stocate, la nici un moment de timp, pe serverele <strong>Sindicatului Național Pro Lex.</strong></p>
		<p>Orice încercare de a accesa datele personale ale altui utilizator sau de a modifica conținutul site-ului <a routerLink="/">membri.prolex.ro</a> sau de a afecta performanțele serverului pe care rulează site-ul, va fi considerată o tentativă de fraudare a site-ului și va fi urmată de o sesizare penală împotriva aceluia / acelora care a / au încercat acest fapt.</p>
		<ol start="4">
			<li><strong> Copyright</strong></li>
	 	</ol>
	 	<p>Întregul conținut al site-ului <a routerLink="/">membri.prolex.ro</a> – imagini, texte, grafice, simboluri, elemente de grafică web – este proprietatea <strong>Sindicatului Național Pro Lex</strong> și este apărat de Legea pentru protecția drepturilor de autor și de legile privind proprietatea intelectuală și industrială. Folosirea fără acordul <strong>Sindicatului Național Pro Lex</strong> a oricăror elemente enumerate mai sus se pedepsește conform legilor în vigoare.</p>
		<ol start="5">
				<li><strong> Mod de lucru</strong></li>
		</ol>
	  <p>Prin accesarea site-ului <a routerLink="/">membri.prolex.ro</a>, folosirea, vizitarea, cumpărarea de servicii sau trimiterea de e-mail-uri adresate <strong>Sindicatului Național Pro Lex</strong>, comunicarea se realizează în mod electronic, considerându-se astfel că utilizatorul consimte primirea notificărilor de la <strong>Sindicatul Național Pro Lex</strong> în modalitate electronică, incluzând și comunicări prin e-mail sau prin anunțuri pe site.</p>
	 	<ol start="6">
			<li><strong> Plata serviciilor</strong></li>
	 	</ol>
	 	<p>Plata serviciilor la unitățile de cazare deținute de <strong>Sindicatul Național Pro Lex</strong> se poate efectua în avans prin ordin de plată, plata cu cardul online sau la recepție la momentul prestării serviciilor de cazare, prin numerar, card bancar sau vouchere de vacanță.</p>
	 	<p><strong>Sindicatul Național Pro Lex</strong> acceptă următoarele cărți de credit / debit: Visa, Visa Electron, Mastercard.</p>
		<p>Plata cotizaţiei către <strong>Sindicatul Național Pro Lex</strong> se poate face prin reţinere pe statul de plată/pensii, ordin de plată, plata cu cardul online sau prin numerar la sediu.</p>
		<p>Plata bunurilor comandate şi achiziționate de <strong>Sindicatul Național Pro Lex</strong> ȋn numele şi pe seama membrilor, la cererea acestora, se poate face prin ordin de plată sau prin numerar la sediu.</p>
		<ol start="7">
			<li><strong> Tarife</strong></li>
		</ol>
		<p>Cotizaţia este stabilită prin <a href="{{ backendUrl + 'storage/documents/Statutul Sindicatului Pro Lex.pdf' }}" target="_blank">Statut</a>. ȋn prezent este ȋn sumă de <strong>25 lei</strong>. Cotizaţia virată eronat se returnează la cererea scrisă a persoanei, cu indicarea băncii şi contului IBAN ȋn care se va efectua returnarea sumei nedatorate.</p>
 		<p>Tarifele pentru ofertele de cazare la pensiunea <a href="https://casa-dracula.ro" target="_blank">Casa Dracula by ProLex</a> sunt afișate în site-ul pensiunii sau ȋn aplicaţia de mobil <strong>ProLex Club</strong>, sunt tarife de cazare pe cameră / noapte, includ mic dejun (dacă nu se specifică altfel, în mod expres) și taxa de stațiune. Excepțiile sunt specificate clar pe site. Tarifele se pot schimba conform politicii proprietarului. Majorările de tarife nu afectează rezervările deja confirmate. Reducerile de tarife pot afecta sau nu rezervările confirmate. Detalii referitoare la politica de rezervare şi de returnare a sumelor plătite se găsesc ȋn <a href="https://casa-dracula.ro" target="_blank">site-ul aferent pensiunii</a>.</p>
	</div>
</div>
