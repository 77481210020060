import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationPopupComponent } from '../';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'landing-page-member-fee',
	templateUrl: './member-fee.component.html',
	styleUrls: ['./member-fee.component.scss']
})
export class MemberFeeComponent {
	constructor(private _modalService: NgbModal) { }

	public registerClicked(): void {
		this._modalService.open(RegistrationPopupComponent, {
			size: 'xl',
			animation: true,
			backdrop: 'static',
			centered: true,
			keyboard: false,
			scrollable: true
		});
	}
}
