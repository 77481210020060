<span class="text-center font-weight-bold" style="display: block;">{{ 'subtitleVerify' | translate }}</span>
<form [formGroup]="createPasswordForm" class="theme-form">
	<div class="form-group">
		<div class="row subtitle">
			<div class="col-sm-12">
				{{ 'personalData' | translate}}
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.lastName' | translate }}:&nbsp;<span class="value">{{ model.lastName }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.firstName' | translate }}:&nbsp;<span class="value">{{ model.firstName }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.cnp' | translate }}:&nbsp;<span class="value">{{ model.cnp }}</span>
			</div>
		</div>
	</div>
	<div class="form-group">
		<div class="row subtitle">
			<div class="col-sm-12">
				{{ 'contactData' | translate }}
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.countyId' | translate }}:&nbsp;<span class="value">{{ model.countyName }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.localityId' | translate }}:&nbsp;<span class="value">{{ model.localityName }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.address' | translate }}:&nbsp;<span class="value">{{ model.address }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.email' | translate }}:&nbsp;<span class="value">{{ model.email }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.phoneNumber' | translate }}:&nbsp;<span class="value">{{ model.phoneNumber }}</span>
			</div>
		</div>
	</div>
	<div class="form-group">
		<div class="row subtitle">
			<div class="col-sm-12">
				{{ 'workplaceData' | translate }}
				</div>
			</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.workplaceType' | translate }}:&nbsp;<span class="value">{{ parseWorkplaceType(model.workplaceType) }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.isRetired' | translate }}:&nbsp;<span class="value">{{ parseBoolean(model.isRetired) }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				{{ 'labelsVerify.categoryId' | translate }}:&nbsp;<span class="value">{{ model.categoryName }}</span>
			</div>
		</div>
		<div *ngIf="model.workplaceType === WorkplaceType.SectorBugetar">
			<div class="row">
				<div class="col-sm-12">
					{{ 'labelsVerify.isPersonalContractual' | translate }}:&nbsp;<span class="value">{{ parseBoolean(model.isPersonalContractual) }}</span>
				</div>
			</div>
		</div>
		<div *ngIf="model.workplaceL1Id !== null && model.workplaceL1Id !== undefined">
			<div class="row">
				<div class="col-sm-12">
					{{ 'labelsVerify.workplaceL1Id' | translate }}:&nbsp;
					<span class="value">{{ model.workplaceL1Name }}</span>
				</div>
			</div>
		</div>
		<div *ngIf="model.workplaceL2Id !== null && model.workplaceL2Id !== undefined">
			<div class="row">
				<div class="col-sm-12">
					{{ 'labelsVerify.workplaceL2Id' | translate }}:&nbsp;
					<span class="value">{{ model.workplaceL2Name }}</span>
				</div>
			</div>
		</div>
		<div *ngIf="model.workplaceL3Id !== null && model.workplaceL3Id !== undefined">
			<div class="row">
				<div class="col-sm-12">
					{{ 'labelsVerify.workplaceL3Id' | translate }}:&nbsp;<span class="value">{{ model.workplaceL3Name }}</span>
				</div>
			</div>
		</div>
		<div *ngIf="model.workplaceOther !== null && model.workplaceOther !== undefined">
			<div class="row">
				<div class="col-sm-12">
					{{ 'labelsVerify.workplaceOther' | translate }}:&nbsp;<span class="value">{{ model.workplaceOther }}</span>
				</div>
			</div>
		</div>
		<div *ngIf="model.paidBy !== null && model.paidBy !== undefined">
			<div class="row">
				<div class="col-sm-12">
					{{ 'labelsVerify.paidBy' | translate }}:&nbsp;<span class="value">{{ model.paidBy }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="details">{{ 'passwordComplexity' | translate }}</div>
	<div class="form-group">
		<ejs-textbox
			placeholder="{{ 'labelsVerify.password' | translate }}"
			formControlName="password"
			cssClass="e-outline"
			type="password"
			floatLabelType="Auto">
		</ejs-textbox>
		<dm-password-strength-meter [password]="passwordControl.value" [enableFeedback]="false" (strengthChanged)="passwordStrengthChanged($event)"></dm-password-strength-meter>
		<div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)" class="error-msg">
			<div *ngIf="passwordControl.errors.required">{{ 'validationVerify.passwordRequired' | translate }}</div>
		</div>
	</div>
	<div class="form-group">
		<ejs-textbox
			placeholder="{{ 'labelsVerify.verifyPassword' | translate }}"
			formControlName="verifyPassword"
			cssClass="e-outline"
			type="password"
			floatLabelType="Auto">
		</ejs-textbox>
		<div *ngIf="createPasswordForm.errors?.mismatchedValues && (verifyPasswordControl.dirty || verifyPasswordControl.touched)" class="error-msg">
			<div *ngIf="createPasswordForm.errors?.mismatchedValues">{{ 'validationVerify.passwordsNotEqual' | translate }}</div>
		</div>
	</div>
	<div class="form-group signature-height">
		<signature-pad
			#signaturePad
			(onSave)="onSaveHandler($event)"
			[width]="width"
			[height]="height"
			[hideFooter]="noFooter">
		</signature-pad>
	</div>
	<div class="form-group">
		<ejs-checkbox formControlName="termsAndConditions"></ejs-checkbox>
		<span style="padding-left: 10px;" (click)="termeniClick($event)" class="fakeLink noSelect" innerHTML="{{ termsAndConditionsText }}"></span>
		<div *ngIf="termsAndConditionsControl.invalid && (termsAndConditionsControl.dirty || termsAndConditionsControl.touched)" class="error-msg">
			<div *ngIf="termsAndConditionsControl.errors.required">{{ 'validation.termsAndConditions' | translate }}</div>
		</div>
	</div>
	<button
		ejs-button
		cssClass="e-info left-button"
		awPreviousStep
		(click)="prev()">
		{{ 'labelsVerify.prev' | translate }}
	</button>
	<button
		ejs-button
		cssClass="e-info right-button"
		awNextStep
		(click)="next()"
		[disabled]="createPasswordForm.invalid">
		{{ 'labelsVerify.next' | translate }}
	</button>
</form>
