/* eslint-disable max-len */
import { paths } from '../../../../../app-paths';

export const locale = {
	lang: 'ro',
	data: {
		personalData: 'Date personale',
		contactData: 'Date contact',
		workplaceData: 'Date despre locul de muncă',
		subtitleVerify: 'Câmpurile marcate cu * sunt obligatorii',
		passwordComplexity: 'Parola trebuie să aibă o lungime minimă de 8 caractere, din care cel puțin o literă majusculă, cel puțin o literă minusculă, cel puțin un semn de punctuație (, . @ _ - # *) și cel puțin o cifră.',
		successRegistration: 'Contul de utilizator a fost creat cu succes.Te voi redirecționa către pagina de autentificare.\nPentru a definitiva înregistrarea ca membru trebuie efectuată plata online a cotizației în termen de 24 de ore',
		labelsVerify: {
			firstName: 'Prenume',
			lastName: 'Nume',
      cnp: 'CNP',
			countyId: 'Județ',
			localityId: 'Localitate',
			address: 'Adresă',
			email: 'Email',
			phoneNumber: 'Telefon',
			workplaceType: 'Sector de activitate',
			isRetired: 'Pensionar(ă)',
			categoryId: 'Categorie profesională',
			isPersonalContractual: 'Personal contractual',
			workplaceL1Id: 'Unitate',
			workplaceL2Id: 'Subunitate',
			workplaceL3Id: 'Serviciu / Departament',
			workplaceOther: 'Loc de muncă',
			paidBy: 'Salarizat de către ',
			password: 'Parolă *',
			verifyPassword: 'Verificare parolă *',
			next: 'Finalizare',
			prev: 'Pasul precedent'
		},
		validationVerify: {
			passwordRequired: 'Parola este obligatorie',
			passwordsNotEqual: 'Parolele nu coincid'
		}
	}
};
