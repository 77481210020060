import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { Functions } from '@prolex/shared';
import { environment } from '../../environments/environment';

export class MembersDataAdaptor extends UrlAdaptor {
  // Add necessary headers to requests
  public beforeSend(dm: DataManager, request: XMLHttpRequest): void {
    // customizing the Url of lHttpRequest
    request.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    const token = localStorage && localStorage.getItem(environment.localStorage.authenticationToken);
    if (token != null && token.length > 0)
      request.setRequestHeader('Authorization', `Bearer ${token}`)
    request.setRequestHeader(
      'Accept-Language',
      Functions.parseAvailableLanguage(localStorage && localStorage.getItem(environment.localStorage.locale)
    ));
  }
}
