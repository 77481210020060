export const locale = {
	lang: 'ro',
	data: {
		paymentRequiredLabel: 'Pentru a definitiva înregistrarea ca membru trebuie efectuată plata online a cotizației în termen de 24 de ore',
		subtitleWorkplace: 'Câmpurile marcate cu * sunt obligatorii',
		types: {
			budgetary: 'bugetar',
			private: 'privat'
		},
		labelsWorkplace: {
			typeSelect: 'Care este sectorul tău de activitate?',
			isRetired: 'Sunt pensionar(ă)',
			isPersonalContractual: 'Sunt personal contractual',
			categoryId: 'Categoria profesională *',
			workplaceL1Id: 'Unitatea',
			workplaceL2Id: 'Subunitatea',
			workplaceL3Id: 'Serviciul / departamentul',
			workplaceOther: 'Loc de muncă',
			paidBy: 'Salarizat de către ',
			next: 'Pasul următor',
			prev: 'Pasul precedent'
		},
		validationWorkplace: {
			categoryIdRequired: 'Categoria profesională este obligatorie',
			workplaceL1IdRequired: 'Unitatea este obligatorie',
			workplaceOtherRequired: 'Locul de muncă este obligatoriu',
			paidByRequired: 'Acest câmp este obligatoriu'
		}
	}
};
