/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

export interface IDMLoadingConfig {
	backdropBorderRadius?: string;
	backdropBackgroundColour?: string;
	fullScreenBackdrop?: boolean;
	animationType?: string;
	primaryColour?: string;
	secondaryColour?: string;
	tertiaryColour?: string;
	[key: string]: string | boolean | undefined;
}

export class DMLoadingConfig implements IDMLoadingConfig {
	backdropBorderRadius?: string;
	backdropBackgroundColour?: string;
	fullScreenBackdrop?: boolean;
	animationType?: string;
	primaryColour?: string;
	secondaryColour?: string;
	tertiaryColour?: string;
	[key: string]: string | boolean | undefined;

	constructor(config: IDMLoadingConfig = {}) {
		this.backdropBorderRadius = config.backdropBorderRadius;
		this.backdropBackgroundColour = config.backdropBackgroundColour;
		this.fullScreenBackdrop = config.fullScreenBackdrop;
		this.animationType = config.animationType;
		this.primaryColour = config.primaryColour;
		this.secondaryColour = config.secondaryColour;
		this.tertiaryColour = config.tertiaryColour;
	}
}

export const dmLoadingAnimationTypes = {
	chasingDots: 'chasing-dots',
	circle: 'sk-circle',
	circleSwish: 'circleSwish',
	cubeGrid: 'sk-cube-grid',
	doubleBounce: 'double-bounce',
	none: 'none',
	pulse: 'pulse',
	rectangleBounce: 'rectangle-bounce',
	rotatingPlane: 'rotating-plane',
	threeBounce: 'three-bounce',
	wanderingCubes: 'wandering-cubes'
};
