<div class="authentication-box">
	<div class="text-center"><img src="/assets/images/logo.png" alt="ProLex Logo"></div>
	<div class="card mt-4 shadow-box">
		<div class="card-body">
			<div class="text-center">
				<h4>{{ 'labels.title' | translate }}</h4>
				<h6>{{ 'labels.subtitle' | translate }}</h6>
			</div>
			<form [formGroup]="loginForm" class="theme-form" (submit)="loginClicked()">
				<div class="form-group">
          <ejs-textbox
            placeholder="{{ 'labels.email' | translate }}"
            formControlName="email"
            cssClass="e-outline"
            floatLabelType="Auto"
            autocomplete="off">
          </ejs-textbox>
          <div *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)" class="error-msg">
            <div *ngIf="emailControl.errors.required">{{ 'validation.emailRequired' | translate }}</div>
            <div *ngIf="emailControl.errors.email">{{ 'validation.emailInvalid' | translate }}</div>
          </div>
				</div>
				<div class="form-group">
					<ejs-textbox #password
            id="password"
            placeholder="{{ 'labels.password' | translate }}"
            formControlName="password"
            cssClass="e-outline"
            type="password"
            floatLabelType="Auto">
          </ejs-textbox>
          <div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)" class="error-msg">
            <div *ngIf="passwordControl.errors.required">{{ 'validation.passwordRequired' | translate }}</div>
          </div>
				</div>
				<div class="form-group">
					<ejs-checkbox
            formControlName="rememberMe"
            label="{{ 'labels.rememberMe' | translate }}">
          </ejs-checkbox>
				</div>
				<div class="form-group text-center">
					<button #loginButton
						ejs-progressbutton
						content="{{ 'labels.login' | translate }}"
						cssClass="dm-progress-button e-success"
						iconCss="cis-lock-plus"
            type="submit"
						[spinSettings]="spinSettings"
						[duration]="spinDuration"
            [disabled]="loginForm.invalid"
						(begin)="contractBegin()"
						(end)="contractEnd()">
					</button>
				</div>
				<div class="form-group text-center">
					<button #forgotPasswordButton
              ejs-button
              cssClass="gray-button"
              iconCss="cis-fingerprint"
              (click)="forgotPasswordClicked($event)">
            {{ 'labels.forgotPassword' | translate }}
					</button>
				</div>
				<hr class="horizontal-line">
				<div class="form-group text-center">
					<button #registerButton
              ejs-button
              cssClass="gray-button"
              iconCss="cis-user-plus"
              (click)="registerClicked($event)">
            {{ 'labels.register' | translate }}
          </button>
				</div>
			</form>
		</div>
	</div>
</div>
