<div id="signature-pad" class="m-signature-pad" [style.width.px]="_width" [style.height.px]="_height">
	<div class="m-signature-pad-body" [style.width.px]="_width-40" [style.height.px]="_height-40">
		<canvas [width]="_width-40" [height]="_height-40" style="touch-action: none;"></canvas>
	</div>
	<div class="m-signature-pad-footer" [hidden]="_hideFooter">
		<div class="description">{{_label}}</div>
		<button type="button" class="button clear" data-action="clear" (click)="onClearClick()">Șterge</button>
<!--		<button type="button" class="button save" data-action="save" (click)="onSaveClick()">Save</button>-->
	</div>
</div>
