import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressButton, SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { ReactiveFormsValidation } from '@dynamicmedia/shared';
import { DialogService } from '@dynamicmedia/syncfusion';
import { AuthenticationService } from '@prolex/shared';
import { TranslationLoaderService } from '../../shared/services';
import { paths } from '../../app-paths';
import { locale as romanian } from './i18n/ro';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'members-existing-member-register',
  templateUrl: './existing-member-register.component.html',
  styleUrls: ['./existing-member-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExistingMemberRegisterComponent {
	// Components
	@ViewChild('sendButton') private _sendButton!: ProgressButton;
	// Public members
  public existingMemberRegisterForm!: FormGroup;
  public spinSettings = {
    position: 'Right',
    width: 20,
    template: `
			<div class="sk-fading-circle sk-fading-circle-round-button">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
      </div>`
  } as SpinSettingsModel;
  public spinDuration = 12000;
  // Private members
  private _submitIsSpinning = false;

  //#region Form controls
  public get emailControl(): AbstractControl {
    return this.existingMemberRegisterForm && this.existingMemberRegisterForm.get('email');
  }

  public get cnpControl(): AbstractControl {
    return this.existingMemberRegisterForm && this.existingMemberRegisterForm.get('cnp');
  }

  public get termsAndConditionsControl(): AbstractControl {
    return this.existingMemberRegisterForm && this.existingMemberRegisterForm.get('termsAndConditions');
  }
  //#endregion

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _translationLoaderService: TranslationLoaderService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef)
  {
    if (this._authenticationService.isAuthenticated()) {
      void this._router.navigate([paths.admin.fullPath]);
      return;
    }
    this._translationLoaderService.loadRomanianTranslations(romanian);
    this.existingMemberRegisterForm = this._formBuilder.group({
      email: new FormControl(null, [ReactiveFormsValidation.required, Validators.email]),
      cnp: new FormControl(null, ReactiveFormsValidation.cnpInvalid(true)),
      termsAndConditions: new FormControl(false, ReactiveFormsValidation.required)
    });
    this.existingMemberRegisterForm.valueChanges.subscribe(() => {
      this._changeDetector.detectChanges();
    });
  }

  public contractBegin() {
    this._sendButton.element.classList.add('e-round');
    this.emailControl.disable();
    this.cnpControl.disable();
  }

  public contractEnd() {
    this._sendButton.element.classList.remove('e-round');
    this.emailControl.enable();
    this.cnpControl.enable();
  }

  public sendClicked(): void {
    if (this._submitIsSpinning)
      return;
    this._sendButton.start();
    this._submitIsSpinning = true;
    this._authenticationService.existingMemberRegisterRequest(this.emailControl.value, this.cnpControl.value)
			.pipe(
				finalize(() => {
					this._sendButton.progressComplete();
					this._submitIsSpinning = false;
				})
			).subscribe({
        next: (result) => {
          if (result.isSuccess) {
            this._dialogService.information(result.message, () => {
              this.spinDuration = 0;
              setTimeout(() => void this._router.navigate(['/']), 200);
            });
          }
          else {
            if (!result.isQuestion)
              this._dialogService.warning(result.message);
            else {
              // Member is retired => send to register
              this._dialogService.question(result.message, () => {
                this.spinDuration = 0;
                setTimeout(() => void this._router.navigate([paths.register.fullPath]), 200);
              });
            }
          }
        },
        error: (e) => {
          this._dialogService.error(e);
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public termeniClick(event: any): void {
    if (event.target && event.target.nodeName === 'A')
      return;
    this.termsAndConditionsControl.setValue(!this.termsAndConditionsControl.value);
  }
}
