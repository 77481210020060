/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { DialogConfigService, DialogServiceConfig } from './dialog-service.config';
import { DialogService } from './dialog.service';

@NgModule()
export class DialogServiceModule {
  static forRoot(config: DialogServiceConfig): ModuleWithProviders<DialogServiceModule> {
    return {
      ngModule: DialogServiceModule,
      providers: [
      	DialogService,
        { provide: DialogConfigService, useValue: config }
      ]
    };
  }
}
