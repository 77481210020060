<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper"><a href="javascript:void(0)"><img src="assets/images/logo-small.png" alt=""></a></div>
</div>
<div class="sidebar">
  <div class="sidebar-user text-center">
    <div style="display: flex; justify-content: center;">
      <div class="e-avatar e-avatar-xlarge e-avatar-circle rose" style="font-size: 16px;">
				<div *ngIf="!profilePicture">{{ userData && userData.firstName && userData.lastName ? userData.firstName[0] + userData.lastName[0] : '' }}</div>
				<img *ngIf="profilePicture" src="{{ getAvatarPath(profilePicture) }}" alt="Avatar"/>
      </div>
    </div>
    <h6 style="font-size:12px;margin-top:5px;">{{ userData?.firstName }}</h6>
    <h6 style="font-size:12px;">{{ userData?.lastName }}</h6>
  </div>
  <div class="shadow-top"></div>
  <ul class="sidebar-menu custom-scrollbar" [perfectScrollbar]="{}">
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
      <!-- Sub -->
      <a
				href="javascript:void(0)"
				class="sidebar-header"
				*ngIf="menuItem.type === 'sub'"
        (click)="toggleNavigationActive(menuItem)">
          <i class="item-icon {{ menuItem.icon }}"></i>
        	<span class="menu-item-text">
						{{ menuItem.title }}
						<span
							class="badge badge-{{menuItem.badgeType}} ml-3"
							*ngIf="menuItem.badgeType">{{menuItem.badgeValue}}
						</span>
					</span>
          <i class="sub-item icofont-simple-right icofont-pull-right"></i>
      </a>
      <!-- Link -->
      <a
				[routerLink]="!menuItem.type ? null : [menuItem.path]"
				routerLinkActive="active"
				class="sidebar-header"
        *ngIf="menuItem.type === 'link'">
        <i class="item-icon {{ menuItem.icon }}"></i>
        <span class="menu-item-text">
					{{ menuItem.title }}
					<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">
							{{menuItem.badgeValue}}
					</span>
				</span>
        <i class="sub-item icofont-simple-right icofont-pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- External Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header" *ngIf="menuItem.type === 'extLink'">
        <i class="item-icon {{ menuItem.icon }}"></i>
        <span class="menu-item-text">
					{{ menuItem.title }}
					<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">
							{{ menuItem.badgeValue }}
					</span>
				</span>
        <i class="sub-item icofont-simple-right icofont-pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- External Tab Link -->
      <a
					href="{{ !menuItem.type ? null : menuItem.path }}"
					target="_blank"
					class="sidebar-header"
					*ngIf="menuItem.type === 'extTabLink'">
				<i class="item-icon {{ menuItem.icon }}"></i>
        <span class="menu-item-text">
					{{ menuItem.title }}
					<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">
							{{ menuItem.badgeValue }}
					</span>
				</span>
        <i class="sub-item icofont-simple-right icofont-pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{ active: childrenItem.active }">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggleNavigationActive(childrenItem)">
            <i class="cis-circle"></i>
						{{ childrenItem.title }}
						<span
								class="badge badge-{{childrenItem.badgeType}} pull-right"
								*ngIf="childrenItem.badgeType">
							{{ childrenItem.badgeValue }}
						</span>
            <i class="sub-item icofont-simple-down icofont-pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- Link -->
          <a
							[routerLink]="!childrenItem.type ? null : [childrenItem.path]"
							*ngIf="childrenItem.type === 'link'"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }">
            <i class="cis-circle"></i>
						{{ childrenItem.title }}
						<span
								class="badge badge-{{childrenItem.badgeType}} pull-right"
								*ngIf="childrenItem.badgeType">
							{{ childrenItem.badgeValue }}
						</span>
            <i class="sub-item icofont-simple-down icofont-pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Link -->
          <a
							href="{{ !childrenItem.type ? null : childrenItem.path }}"
							*ngIf="childrenItem.type === 'extLink'"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }">
            <i class="cis-circle"></i>
						{{ childrenItem.title }}
						<span
								class="badge badge-{{childrenItem.badgeType}} pull-right"
								*ngIf="childrenItem.badgeType">
							{{ childrenItem.badgeValue }}
						</span>
            <i class="sub-item icofont-simple-down icofont-pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Tab Link -->
          <a
						href="{{ !childrenItem.type ? null : childrenItem.path }}"
						target="_blank"
            *ngIf="childrenItem.type === 'extTabLink'">
            <i class="cis-circle"></i>
						{{ childrenItem.title }}
						<span
								class="badge badge-{{childrenItem.badgeType}} pull-right"
								*ngIf="childrenItem.badgeType">
							{{ childrenItem.badgeValue }}
						</span>
            <i class="sub-item icofont-simple-down icofont-pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="childrenItem.children">
            <li *ngFor="let childrenSubItem of childrenItem.children">
              <!-- Link -->
              <a
									[routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
									*ngIf="childrenSubItem.type === 'link'"
									routerLinkActive="active"
									[routerLinkActiveOptions]="{ exact: true }">
                <i class="cis-circle"></i>
								{{ childrenSubItem.title }}
								<span
										class="badge badge-{{childrenSubItem.badgeType}} pull-right"
										*ngIf="childrenSubItem.badgeType">
									{{ childrenSubItem.badgeValue }}
								</span>
              </a>
              <!-- External Link -->
              <a
									href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
									*ngIf="childrenSubItem.type === 'extLink'"
									routerLinkActive="active"
									[routerLinkActiveOptions]="{ exact: true }">
                <i class="cis-circle"></i>
								{{ childrenSubItem.title }}
								<span
										class="badge badge-{{childrenSubItem.badgeType}} pull-right"
										*ngIf="childrenSubItem.badgeType">
									{{ childrenSubItem.badgeValue }}
								</span>
              </a>
              <!-- External Tab Link -->
              <a
									href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
									target="_blank"
									*ngIf="childrenSubItem.type === 'extTabLink'">
                <i class="cis-circle"></i>
								{{ childrenSubItem.title }}
								<span
										class="badge badge-{{childrenSubItem.badgeType}} pull-right"
										*ngIf="childrenSubItem.badgeType">
									{{ childrenSubItem.badgeValue }}
								</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <div class="shadow-bottom"></div>
  <p class="footer-text">
		&copy; 2020 - 2021
		<br/>
		<a href="https://dynamic-media.ro" target="_blank" class="dynamicmedia">
			<span>Dynamic</span>&nbsp;<span>Media</span>
		</a>
	</p>
</div>
