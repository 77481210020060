import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

/* eslint-disable no-var, @typescript-eslint/no-explicit-any */
declare var $: any;
declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'members-landing-page',
  templateUrl: './landing-page-layout.component.html',
  styleUrls: ['./landing-page-layout.component.scss']
})
export class LandingPageLayoutComponent implements OnInit {
  constructor(private _router: Router) {
		this._router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				const t = event as NavigationEnd;
				if (t.url.indexOf('/politica-folosirii-cookieurilor') !== -1 ||
						t.url.indexOf('/termeni-si-conditii') !== -1 ||
						t.url.indexOf('/politica-de-confidentialitate') !== -1) {
					window.DMGlobal_isMainContent = false;
					$('html, body').animate({ scrollTop: $('html').offset().top }, 1000);
					$('.one-page-scroll-menu li.current').removeClass('current');
				}
				else
					window.DMGlobal_isMainContent = true;
			});
	}

  public ngOnInit(): void {
		(function ($) {
			'use strict';
			const scrollToTarget = $('.scroll-to-target');
			if (scrollToTarget.length) {
				scrollToTarget.on('click', function () {
					const target = $(this).attr('data-target');
					// animate
					$('html, body').animate({ scrollTop: $(target).offset().top }, 1000);
					return false;
				});
			}
			function SmoothMenuScroll() {
				const anchor = $('.scrollToLink');
				if (anchor.length) {
					anchor.children('a').bind('click', function (event: any) {
						const headerH = $(window).scrollTop() > 10 ? 80 : 120;
						const target = $(this);
						$('html, body')
							.stop()
							.animate({ scrollTop: ($(target.attr('data-fragment')).offset().top - headerH).toString() + 'px' }, 1200, 'easeInOutExpo');
						anchor.removeClass('current');
						target.parent().addClass('current');
						event.preventDefault();
					});
				}
			}
			SmoothMenuScroll();
			function OnePageMenuScroll() {
				const windowScroll = $(window).scrollTop();
				if (windowScroll >= 100) {
					const menuAnchor = $('.one-page-scroll-menu .scrollToLink').children('a');
					menuAnchor.each(function () {
						// grabbing section id dynamically
						const sections = $(this).attr('data-fragment');
						$(sections).each(function () {
							// checking is scroll bar are in section
							if ($(this).offset().top <= windowScroll + 100) {
								// grabbing the dynamic id of section
								const sectionId = $(sections).attr('id');
								// removing current class from others
								const onePageScrollMenu = $('.one-page-scroll-menu');
								onePageScrollMenu.find('li').removeClass('current');
								// adding current class to related navigation
								onePageScrollMenu.find('a[data-fragment="#' + sectionId + '"]').parent().addClass('current');
							}
						});
					});
				}
				else {
					$('.one-page-scroll-menu li.current').removeClass('current');
					if (window.DMGlobal_isMainContent)
						$('.one-page-scroll-menu li:first').addClass('current');
				}
			}
			$(window).on('scroll', function () {
				const scrollToTop = $('.prolex-scroll-to-top');
				if (scrollToTop.length) {
					const stickyScrollPos = 100;
					if ($(window).scrollTop() > stickyScrollPos)
						scrollToTop.fadeIn(500);
					else if ($(this).scrollTop() <= stickyScrollPos)
						scrollToTop.fadeOut(500);
				}
				const sticky = $('.stricky');
				if (sticky.length) {
					const headerScrollPos = 100;
					if ($(window).scrollTop() > headerScrollPos) {
						sticky.removeClass('animate__slideInUp animate__animated');
						sticky.addClass('stricky-fixed animate__slideInDown animate__animated');
					}
					else if ($(this).scrollTop() <= headerScrollPos) {
						sticky.removeClass('stricky-fixed animate__slideInDown animate__animated');
						sticky.addClass('animate__slideInUp animate__animated');
					}
				}
				OnePageMenuScroll();
			});
			$(window).on('load', function () {
				const boxLoading = $('.cssload-box-loading');
				if (boxLoading.length)
					boxLoading.fadeOut('slow');
			});
		})(jQuery);
	}
}
