import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { DialogService } from '@dynamicmedia/syncfusion';
import { DMFunctions, ReactiveFormsValidation } from '@dynamicmedia/shared';
import { SignaturePadComponent } from '@dynamicmedia/shared';
import { BackendResponse, Functions, WorkplaceType } from '@prolex/shared';
import { RegistrationMember } from '../../../../shared/models';
import { RegisterService, TranslationLoaderService } from '../../../../shared/services';
import { paths } from '../../../../app-paths';
import { locale as romanian } from './i18n/ro';

@Component({
  selector: 'members-auth-verify-set-password',
  templateUrl: './verify-set-password.component.html',
  styleUrls: ['./verify-set-password.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifySetPasswordComponent {
	// Inputs
	@Input() public model: RegistrationMember;
	// Public members
  public passwordVerification: string;
  public WorkplaceType = WorkplaceType;
  public createPasswordForm: FormGroup;
	//Public member for Signature member
	public label = 'Semnează deasupra';
  public width = 290;
  public height = 200;
	public noFooter = false;
	// Private members
  private _currentPasswordStrength = 0;
	@ViewChild('signaturePad') private _signaturePad: SignaturePadComponent;
	// eslint-disable-next-line
	public termsAndConditionsText = `Sunt de acord cu <a href="${paths.termsAndConditions.fullPath}" target="_blank">Termenii și condițiile</a> de folosire a platformei Membri ProLex. *`;

  //#region Form controls
	public get passwordControl(): AbstractControl {
		return this.createPasswordForm && this.createPasswordForm.get('password');
	}

	get verifyPasswordControl(): AbstractControl {
		return this.createPasswordForm && this.createPasswordForm.get('verifyPassword');
	}

	public get termsAndConditionsControl(): AbstractControl {
		return this.createPasswordForm && this.createPasswordForm.get('termsAndConditions');
	}
	//#endregion

  constructor(
	  private _translationService: TranslationLoaderService,
	  private _registerService: RegisterService,
	  private _dialogService: DialogService,
	  private _router: Router,
	  private _formBuilder: FormBuilder,
	  private _changeDetector: ChangeDetectorRef)
  {
	  this._translationService.loadRomanianTranslations(romanian);
	  this.createPasswordForm = this._formBuilder.group(
		  {
			  password: [null, ReactiveFormsValidation.required],
			  verifyPassword: [null, ReactiveFormsValidation.required],
				termsAndConditions: [null, ReactiveFormsValidation.required]
		  },
		  {
			  validators: ReactiveFormsValidation.matchingValues('password', 'verifyPassword')
		  }
	  );
	  this.createPasswordForm.valueChanges.subscribe(() => {
		  this._changeDetector.markForCheck();
	  });
  }

	public prev(): void {
    this.model.passwordVerified = true;
	}

	public next(): void {
		this.model.password = this.passwordControl.value;
		if (this._signaturePad.isEmpty()) {
			this._dialogService.warning('Semnătura este obligatorie');
			return;
		}
		this.model.signature = this._signaturePad.saveSignature();
    this.model.passwordVerified = true;
		//documentType = 1 for PDF document || documentType = 2 for docx document
		this.model.documentType = 1;
		//Register user
		this._registerService.registerUser(this.model.toUserData()).subscribe({
	    next: (result: BackendResponse) => {
	    	if (result.isSuccess) {
					// Generate and Send the Member Registration Document;
					// Update the database with path + document name;
					this._registerService.sendMemberRegisterDocument(this.model.toMemberRegisterDocumentDataDto())
						.subscribe({
							next: (result: HttpResponse<Blob>) => {
								const fileName = DMFunctions.getFileNameFromResponseContentDisposition(result);
								DMFunctions.saveFile(result.body,fileName);
								this._dialogService.information(this._translationService.getTranslation('successRegistration'), () => {
									void this._router.navigate([paths.login.fullPath]);
								});
							},
							error: (e) => {
								this._dialogService.error(Functions.handleError(e));
							}
						});
		    }
	    	else
	    		this._dialogService.error(Functions.handleError(result.message));
	    },
	    error: (e) => {
	    	this._dialogService.error(Functions.handleError(e));
	    }
    });
	}

	public passwordStrengthChanged(event: number): void {
		this._currentPasswordStrength = event;
	}

	public parseBoolean(value: boolean): string {
  	return value ? 'Da' : 'Nu';
	}

	public parseWorkplaceType(value: WorkplaceType): string {
  	if (value === WorkplaceType.SectorBugetar)
  		return 'Bugetar';
  	else if (value === WorkplaceType.SectorPrivat)
  		return 'Privat';
  	else
  		return 'NEDEFINIT';
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public termeniClick(event: any): void {
		if (event.target && event.target.nodeName === 'A')
			return;
		this.termsAndConditionsControl.setValue(!this.termsAndConditionsControl.value);
	}

	//#region Signature Pad methods
  public onSaveHandler(data): void {
    // console.log('onsave clicked');
    // console.log(data);
    window.open(data);
  }
	//#endregion
}
