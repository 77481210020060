import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BackendResponse, BackendUrls, Functions, ProLexApps } from '@prolex/shared';
import { environment } from '../../../environments/environment';
import { UserData } from '../../shared/models';
import { MemberRegisterDocumentDataDto } from '../models/member-register-document-data.dto';

@Injectable()
export class RegisterService {
	constructor(
		private _httpClient: HttpClient,
		private _translateService: TranslateService)
	{ }

	public sendValidationEmail(email: string): Observable<BackendResponse> {
		return this._httpClient.get<BackendResponse>(`${environment.backendUrl}${BackendUrls.usersApiUrl}verify-email/send/${email}/${ProLexApps.Members}`);
	}

	public verifyEmail(email: string, code: string): Observable<BackendResponse> {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		return this._httpClient.post<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.usersApiUrl}verify-email`,
			{ email, code, source: ProLexApps.Members },
			{ headers }
		);
	}

	public registerUser(model: UserData): Observable<BackendResponse> {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		return this._httpClient.post<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.usersApiUrl}register`,
			model,
			{ headers }
		);
	}

	public emailExists(email: string): Observable<boolean> {
		return this._httpClient.get<BackendResponse>(`${environment.backendUrl}${BackendUrls.usersApiUrl}email-exists/${email}`)
			.pipe(
				map((response: BackendResponse) => {
					return response.isSuccess;
				}),
				catchError(error => {
					throw Functions.handleError(error);
				})
			);
	}

	public sendMemberRegisterDocument(model: MemberRegisterDocumentDataDto): Observable<HttpResponse<Blob>>{
		let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'application/octet-stream');
    // eslint-disable-next-line @typescript-eslint/ban-types
    const httpOptions: object = {
      headers: httpHeaders,
      responseType: 'blob',
      observe: 'response'
    };
    return this._httpClient.post<HttpResponse<Blob>>(
      `${environment.backendUrl}${BackendUrls.membersApiUrl}documents/member-register`,
      model, httpOptions
    );
	}
}
