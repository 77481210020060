<!--
 Project: Dynamic Media Angular Libraries
 Author: Dynamic Media S.R.L.
 Copyright © 2021 Dynamic Media S.R.L. All rights reserved.

 Any use or reproduction of this source code is prohibited
 without the explicit consent by Dynamic Media S.R.L.
-->

<div [id]="controlId" class="e-phone-input e-float-input e-input-group e-corner e-control-wrapper e-outline">
  <ejs-dropdownlist
    #country
    [width]="55"
    [dataSource]="countriesDataSource"
    [fields]="{ text: 'n', value: 'c' }"
    [popupWidth]="320"
    [showClearButton]="false"
    [allowFiltering]="true"
    cssClass="inlinecss"
    [htmlAttributes]="{ 'autocomplete': 'off' }"
    (focus)="countryFocus()"
    (blur)="countryBlur()"
    (select)="countrySelected($event)">
    <ng-template #itemTemplate let-data>
      <div style="display: inline-flex;">
        <div class="{{data.f}}"></div>
        <div style="line-height:15px;">{{data.n}} +{{data.i}} </div>
      </div>
    </ng-template>
    <ng-template #valueTemplate let-data>
      <div class="country-value {{data.f}}"></div>
    </ng-template>
  </ejs-dropdownlist>
  <ejs-textbox
    #number
    [width]="'100%'"
    [placeholder]="placeholder"
    cssClass="inlinecss"
    [htmlAttributes]="{ 'autocomplete': 'off', 'maxLength': '24' }"
    (focus)="numberFocus()"
    (blur)="numberBlur()"
    (input)="numberInputChange($event)">
  </ejs-textbox>
  <label [id]="labelId" class="e-float-text e-label-top" style="font-size: 13px;">{{ placeholder }}</label>
</div>
