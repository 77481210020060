import { ProLexApps } from '../../enums';
import { InjectionToken } from '@angular/core';

export class AuthenticationServiceConfig {
  backendApiUrl?: string;
  tokenLocalStorageKey?: string;
  userInfoLocalStorageKey?: string;
  localeLocalStorageKey?: string;
  app?: ProLexApps;
  loginUrl?: string;
}

export const AuthenticationConfigService = new InjectionToken<AuthenticationServiceConfig>('AuthenticationServiceConfig');
