import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BackendDataResponse, BackendResponse, BackendUrls } from '@prolex/shared';
import { Functions } from '@prolex/shared';
import { User } from '../../shared/models';

@Injectable({ providedIn: 'root' })
export class ProfileService {
	constructor(
		private _httpClient: HttpClient,
		private _translateService: TranslateService)
	{ }

	public changePassword(oldPassword: string, newPassword: string): Observable<void> {
		return this._httpClient.post<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.usersApiUrl}/change-password`,
			{ oldPassword, newPassword })
			.pipe(
				map((response: BackendResponse) => {
					if (!response.isSuccess)
						throw response.message;
				}),
				catchError(error => {
					throw Functions.handleError(error);
				})
			);
	}

	public getProfile(userId: string): Observable<User> {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		return this._httpClient.get<BackendDataResponse<User>>(
			`${environment.backendUrl}${BackendUrls.usersApiUrl}profile/${userId}`,
			{ headers })
			.pipe(
				map((response: BackendDataResponse<User>) => {
					if (!response.isSuccess)
						throw response.message;
					return response.data;
				}),
				catchError(error => {
					throw Functions.handleError(error);
				})
			);
	}

	public updateProfile(model: User): Observable<void> {
		return this._httpClient.put<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.usersApiUrl}/profile`,
			model
		).pipe(
			map((response: BackendResponse) => {
				if (!response.isSuccess)
					throw response.message;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}

	public removeAvatar(userId: string, fileName: string): Observable<boolean | void> {
		return this._httpClient.post<BackendResponse>(
			`${environment.backendUrl}${BackendUrls.usersApiUrl}/profile/${userId}/remove-avatar/`,
			{ fileName: fileName }
		).pipe(
			map((response: BackendResponse) => {
				if (!response.isSuccess)
					throw response.message;
			}),
			catchError(error => {
				throw Functions.handleError(error);
			})
		);
	}
}
