/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { InjectionToken } from '@angular/core';
import { Gtag, ConfigParams, CustomParams } from './gtag-definitions';

export interface GtagConfig {
  targetId: string; // gtag('config', 'targetId', configInfo )
  configInfo?: ConfigParams;
  setParams?: CustomParams; // gtag('set', setParams)
  moreIds?: Array<string>;
}

export const GtagConfigToken = new InjectionToken<GtagConfig>('wizdm.gtag.config');

/* Reproduces the standard code snippet we would paste in index.html
 *	@see: https://developers.google.com/analytics/devguides/collection/gtagjs */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function gtagFactory(config: GtagConfig): Gtag {
  if((window as any).gtag)
		return (window as any).gtag;
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.targetId;
  script.type = 'text/javascript';
  script.async = true;
  document.head.appendChild(script);
  (window as any).dataLayer = (window as any).dataLayer || [];
  function gtag(...args: any) {
		(window as any).dataLayer.push(args);
	}
  gtag('js', new Date());
  gtag('config', config.targetId, { send_page_view: false, ...config.configInfo });
  // tslint:disable-next-line: no-unused-expression
  ('setParams' in config) && gtag('set', config.setParams );
  // tslint:disable-next-line: no-unused-expression
  ('moreIds' in config) && config && config.moreIds && config.moreIds.forEach(id => gtag('config', id));
  return (window as any).gtag = gtag;
}
