export enum AvailableLanguages {
  ro = 'ro'
}

export enum UserRoles {
  SuperAdministrator = 'SuperAdministrator',
  // Club
  ClubAdministrator = 'AdministratorClub',
  ClubUser = 'UtilizatorClub',
  ClubCompanyAdministrator = 'AdministratorCompanieClub',
  ClubCompanyUser = 'UtilizatorCompanieClub',
  ClubMobileUser = 'UtilizatorAplicatieMobilaClub',
  // Members
  MembersAdministrator = 'AdministratorMembri',
  MembersUser = 'UtilizatorMembri',
  Member = 'Membru',
	VouchersAdministrator = 'AdministratorVouchere'
}

export enum BackendUrls {
  commonApiUrl = 'common/rest/v1.0/',
  membersApiUrl = 'members/rest/v1.0/',
  usersApiUrl = 'users/rest/api/v1.0/',
  clubApiUrl = 'club/rest/v1.0/web-app/',
	storageApiUrl = 'storage/rest/v1.0/'
}

export enum ProLexApps {
  Club = 1,
  Members = 3
}

export enum WorkplaceType {
	SectorBugetar = 1,
	SectorPrivat = 2
}

export enum CategoryType {
	SectorBugetar = 1,
	SectorPrivat = 2,
	Pensionari = 3,
	Retrasi = 4,
	Simpatizanti = 5,
	Diversi = 6
}

export enum EmploymentType {
	FunctionarPublic = 1,
	PersonalContractual = 2,
	Angajat = 3
}

export enum PaymentType {
	MembershipFee = 1,
	AsiromFee = 2,
	//Other = 3
}

export enum PaymentMethod {
	OnlinePayment = 1,
	Cash = 2
}

export enum PaymentSystem {
	MobilPay = 1
}

export enum BeneficiaryServiceFeeStatus {
	Active = 1,
	RequiresPayment,
	NotPaid,
	Deactivated
}

export enum PaymentStatus {
	All = -2,
	Unset = -1,
	New = 0,
	PaidPending = 1,
	Paid = 2,
	ConfirmedPending = 3,
	Confirmed = 4,
	Credit = 5,
	Canceled = 6,
	Rejected = 7
}

export enum PaymentAction {
	Membership = 'membership',
	Asirom = 'asirom'
}

export enum VouchersValabilityType {
	OneMonth = 1,
	SixMonths = 2,
	OneYear = 3,
}

export enum VoucherStatus {
	Valid = 1,
	Used = 2,
	Expired = 3,
	Cancelled = 4
}

export enum VoucherType {
	Normal = 1,
	SuperVoucher = 2
}

export enum VoucherSystemGenerationMode {
	Both = 1,
	OnlyRegisteringMember = 2,
	OnlyReferencedMember = 3
}
