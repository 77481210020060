/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Inject, Injectable } from '@angular/core';
import { createElement } from '@syncfusion/ej2-base';
import { AlertDialogArgs, ConfirmDialogArgs, Dialog, ButtonPropsModel, DialogModel, ButtonArgs } from '@syncfusion/ej2-angular-popups';
import { DialogConfigService, DialogServiceConfig } from './dialog-service.config';
import { DialogType } from './dialog-service.types';

@Injectable()
export class DialogService {
	private readonly DLG_UTIL_CUSTOM_CLASS = 'members-custom-dialog';
	private readonly DLG_UTIL_WARNING_CLASS = 'members-warning-dialog';
	private readonly DLG_UTIL_ERROR_CLASS = 'members-error-dialog';
	private readonly DLG_UTIL_CONTENT_CLASS = 'content-wrapper';
	private readonly DLG_UTIL_CONTENT_TEXT_CLASS = 'content-text';
	private readonly DLG_UTIL_ALERT: string = 'e-alert-dialog';
	private readonly DLG_UTIL_CONFIRM: string = 'e-confirm-dialog';
	private readonly DLG_UTIL_DEFAULT_TITLE: string;
	private readonly DLG_UTIL_ROOT: string = 'e-scroll-disabled';

	constructor(@Inject(DialogConfigService) private readonly config: DialogServiceConfig) {
    this.config = this.config || { appName: '', locale: 'ro' };
    //this._translateService.addLangs([this.config?.locale as string]);
    //this._translateService.setDefaultLang(this.config?.locale as string);
    //switch (this.config.locale) {
    //  case 'ro':
    //    this._translateService.setTranslation(romanianLocale.lang, romanianLocale.data, true);
    //    break;
    //}
    //this._translateService.use(this.config?.locale as string);
    this.DLG_UTIL_DEFAULT_TITLE = this.config?.appName as string;
	}

	public information(args?: AlertDialogArgs | string, okButtonClickEvent?: () => void): Dialog {
		return this._genericDialog(DialogType.Information, args, okButtonClickEvent);
	}

	public warning(args?: AlertDialogArgs | string, okButtonClickEvent?: () => void): Dialog {
		return this._genericDialog(DialogType.Warning, args, okButtonClickEvent);
	}

	public error(args?: AlertDialogArgs | string, okButtonClickEvent?: () => void): Dialog {
		return this._genericDialog(DialogType.Error, args, okButtonClickEvent);
	}

	public question(args?: ConfirmDialogArgs | string, okButtonClickEvent?: () => void, cancelButtonClickEvent?: () => void): Dialog {
		return this._genericDialog(DialogType.Question, args, okButtonClickEvent, cancelButtonClickEvent);
	}

	private _genericDialog(
		type: DialogType,
		args?: AlertDialogArgs | ConfirmDialogArgs | string,
		okButtonClickEvent?: () => void,
		cancelButtonClickEvent?: () => void
	): Dialog {
		const dialogElement: HTMLElement = createElement('div', {
			className: type != DialogType.Question ? this.DLG_UTIL_ALERT : this.DLG_UTIL_CONFIRM
		});
		document.body.appendChild(dialogElement);
		let dialogObject: Dialog;
		const buttonsModel: Array<ButtonPropsModel> = type != DialogType.Question ?
		 [
			 {
				buttonModel: {
					isPrimary: true,
					content: 'OK',
					cssClass: 'e-control e-btn e-lib',
					iconCss: 'cis-check'
				},
				click: function () : void {
					this.hide();
					if (okButtonClickEvent)
						okButtonClickEvent();
				}
			}
		] :
		[
			{
				buttonModel: { isPrimary: true, content: 'DA' },
				click: function (): void {
					this.hide();
					if (okButtonClickEvent)
						okButtonClickEvent();
				}
			},
			{
				buttonModel: { content: 'NU' },
				click: function (): void {
					this.hide();
					if (cancelButtonClickEvent)
						cancelButtonClickEvent();
				}
			}
		];
		if (typeof (args) === 'string') {
			// create content html
			const cnt = document.createElement('div');
			cnt.classList.add(this.DLG_UTIL_CONTENT_CLASS);
			const cntImage = document.createElement('i');
			cntImage.classList.add(this._getContentIcon(type));
			cnt.append(cntImage);
			const cntText = document.createElement('div');
			cntText.classList.add(this.DLG_UTIL_CONTENT_TEXT_CLASS);
			cntText.innerText = args;
			cnt.append(cntText);
			dialogObject = this._createDialog(
				{
					content: cnt,
					position: { X: 'center', Y: 'center' },
					cssClass: this._getCssClass(type),
					isModal: true,
					header: this._getTitle(type) ?? undefined,
					buttons: buttonsModel,
					showCloseIcon: false,
					animationSettings: { effect: 'FadeZoom' },
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					beforeOpen: (event: any) => {
						// remove flat style from footer buttons
						const button = event.element.querySelector('.e-flat');
						if (button && button.classList)
						  button.classList.remove('e-flat');
					}
				},
				dialogElement
			);
		}
		else {
			if (args && (args.showCloseIcon === null || args.showCloseIcon === undefined))
				args.showCloseIcon = false;
			if (args) {
				args.cssClass = this._getCssClass(type);
				args.animationSettings = { effect: 'FadeZoom' };
			}
			dialogObject = this._createDialog(
			  type != DialogType.Question ?
          this._alertOptions(args) :
          this._confirmOptions(args), dialogElement
      );
		}
		dialogObject.close = () => {
			if (type != DialogType.Question) {
				if (args && (args as AlertDialogArgs).close)
					(args as AlertDialogArgs).close?.apply(dialogObject);
			}
			else {
				if (args && (args as ConfirmDialogArgs).close)
					(args as ConfirmDialogArgs).close?.apply(dialogObject);
			}
			dialogObject.destroy();
			if (dialogObject.element.classList.contains('e-dlg-modal')) {
				dialogObject.element.parentElement?.remove();
				(dialogObject.target as HTMLElement).classList.remove(this.DLG_UTIL_ROOT);
			}
			else
				dialogObject.element.remove();
		};
		return dialogObject;
	}

	private _getTitle(type: DialogType): string | null {
		switch (type) {
			case DialogType.Information:
				return 'Informare';
			case DialogType.Warning:
				return 'Atenționare';
			case DialogType.Error:
				return 'Eroare';
			default:
				return this.DLG_UTIL_DEFAULT_TITLE;
		}
	}

	private _getCssClass(type: DialogType): string {
		switch (type) {
			case DialogType.Warning:
				return `${this.DLG_UTIL_CUSTOM_CLASS} ${this.DLG_UTIL_WARNING_CLASS}`;
			case DialogType.Error:
				return `${this.DLG_UTIL_CUSTOM_CLASS} ${this.DLG_UTIL_ERROR_CLASS}`;
			default:
				return this.DLG_UTIL_CUSTOM_CLASS;
		}
	}

	private _getContentIcon(type: DialogType): string {
		switch (type) {
			case DialogType.Information:
				return 'cis-info-circle';
			case DialogType.Warning:
				return 'cis-warning';
			case DialogType.Error:
				return 'cis-report';
			case DialogType.Question:
				return 'cis-comment-buble-question';
		}
	}

	private _createDialog(options?: DialogModel, element?: string | HTMLElement): Dialog {
		const dialogObject: Dialog = new Dialog(options);
		dialogObject.appendTo(element);
		return dialogObject;
	}

	private _alertOptions(option?: AlertDialogArgs): DialogModel {
		let options: DialogModel = {};
		options.buttons = [];
		options = this._formOptions(options, option);
		options = this._setAlertButtonModel(options, option);
		return options;
	}

	private _confirmOptions(option?: ConfirmDialogArgs): DialogModel {
		let options: DialogModel = {};
		options.buttons = [];
		options = this._formOptions(options, option);
		options = this._setConfirmButtonModel(options, option);
		return options;
	}

	private _formOptions(options: DialogModel, option?: AlertDialogArgs): DialogModel {
		options.header = !(option?.title === null || option?.title === undefined) ? option.title : this.DLG_UTIL_DEFAULT_TITLE;
		options.content = !(option?.content === null || option?.content === undefined) ? option.content : '';
		options.isModal = !(option?.isModal === null || option?.isModal === undefined) ? option.isModal : true;
		options.showCloseIcon = !(option?.showCloseIcon === null || option?.showCloseIcon === undefined) ? option.showCloseIcon : false;
		options.allowDragging = !(option?.isDraggable === null || option?.isDraggable === undefined) ? option.isDraggable : false;
		options.closeOnEscape = !(option?.closeOnEscape === null || option?.closeOnEscape === undefined) ? option.closeOnEscape : false;
		options.position = !(option?.position === null || option?.position === undefined) ? option.position : { X: 'center', Y: 'top' };
		options.animationSettings = !(option?.animationSettings === null || option?.animationSettings === undefined) ?
			option.animationSettings : { effect: 'Fade', duration: 400, delay: 0 };
		options.cssClass = !(option?.cssClass === null || option?.cssClass === undefined) ? option.cssClass : '';
		options.zIndex = !(option?.zIndex === null || option?.zIndex === undefined) ? option.zIndex : 1000;
		options.open = !(option?.open === null || option?.open === undefined) ? option.open : undefined;
			return options;
	}

	private _setAlertButtonModel(options: DialogModel, option?: AlertDialogArgs): DialogModel {
		const alertButtonModel: ButtonPropsModel[]  = [{
			buttonModel: { isPrimary: true, content: 'OK' },
			click: function (): void {
				this.hide();
			}
		}];
		if (!(option?.okButton === null || option?.okButton === undefined)) {
			if (options.buttons)
				options.buttons[0] = this._formButtonModel(options.buttons[0], option.okButton, alertButtonModel[0]);
		}
		else
			options.buttons = alertButtonModel;
		return options;
	}

	private _setConfirmButtonModel(options: DialogModel, option?: ConfirmDialogArgs): DialogModel {
		const okButtonModel: ButtonPropsModel = {
			buttonModel: { isPrimary: true, content: 'OK' },
			click: function (): void {
				this.hide();
			}
		};
		const cancelButtonModel: ButtonPropsModel = {
			buttonModel: { content: 'Cancel' },
			click: function (): void {
				this.hide();
			}
		};
		if (!(option?.okButton === null || option?.okButton === undefined)) {
			if (options.buttons)
				options.buttons[0] = this._formButtonModel(options.buttons[0], option.okButton, okButtonModel);
		}
		else {
			if (options.buttons)
				options.buttons[0] = okButtonModel;
		}
		if (!(option?.cancelButton === null || option?.cancelButton === undefined)) {
			if (options.buttons)
				options.buttons[1] = this._formButtonModel(options.buttons[1], option.cancelButton, cancelButtonModel);
		}
		else {
			if (options.buttons)
				options.buttons[1] = cancelButtonModel;
		}
		return options;
	}

	private _formButtonModel(_buttonModel: ButtonPropsModel, option: ButtonArgs, buttonPropModel: ButtonPropsModel): ButtonPropsModel {
			const buttonProps: ButtonPropsModel = buttonPropModel;
			if (!(option?.text === null || option?.text === undefined)) {
				if (buttonProps.buttonModel)
					buttonProps.buttonModel.content = option.text;
			}
			if (!(option?.icon === null || option?.icon === undefined)) {
				if (buttonProps.buttonModel)
					buttonProps.buttonModel.iconCss = option.icon;
			}
			if (!(option?.cssClass === null || option?.cssClass === undefined)) {
				if (buttonProps.buttonModel)
					buttonProps.buttonModel.cssClass = option.cssClass;
			}
			if (!(option?.click === null || option?.click === undefined))
					buttonProps.click = option.click;
			return buttonProps;
	}
}
