<div class="dark-body-only">
  <div class="page-wrapper" [ngClass]="'compact-wrapper'" id="canvas-bookmark">
    <members-header (collapseSidebar)="collapseSidebar($event)"></members-header>
    <div class="page-body-wrapper" [ngClass]="'sidebar-icon'">
      <div class="page-sidebar dark-sidebar" id="pages-sidebar" [class.open]="collapsedSidebar" [attr.sidebar-layout]="'default-sidebar'">
        <members-sidebar></members-sidebar>
      </div>
      <div class="page-body">
        <main class="router-wrapper" [@fromLeftEasing]="getState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
    </div>
  </div>
</div>
