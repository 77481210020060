/* eslint-disable max-len */
export const locale = {
  lang: 'ro',
  data: {
    labels: {
      title: 'Creare parolă',
      subtitle: 'Introdu noua ta parolă ținând cont de următoarele reguli: cel puțin o literă minusculă, cel puțin o literă majusculă, cel puțin o cifră și cel puțin un semn de punctuație (, . ? ! @ # $) și minim 8 caractere în total.',
      password: 'Parolă',
      verifyPassword: 'Verificare parolă',
      createPassword: 'Crează parola'
    },
    validation: {
      passwordRequired: 'Parola este obligatorie',
      passwordsNotEqual: 'Parolele nu coincid',
      noRequestId: 'Cererea nu are un identificator care să poată fi validat. Solicită crearea unui cont din nou.'
    }
  }
};
