import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AvailableLanguages } from '@prolex/shared';

export interface Locale {
	lang: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
	data: Object;
}

@Injectable({ providedIn: 'root' })
export class TranslationLoaderService {
	// Private members
	private _isLoaded = false;

	constructor(private readonly _translateService: TranslateService) { }

	public loadTranslations(defaultLanguage: AvailableLanguages, availableLanguages: Array<AvailableLanguages>, locales: Array<Locale>) {
		this._translateService.addLangs(availableLanguages);
		this._translateService.setDefaultLang(defaultLanguage);
		locales.forEach((locale) => {
			// use setTranslation() with the third argument set to true to append
			// translations instead of replacing them
			this._translateService.setTranslation(locale.lang, locale.data, true);
		});
		this._translateService.use(defaultLanguage);
		this._isLoaded = true;
	}

	public loadRomanianTranslations(locale: Locale) {
		this.loadTranslations(AvailableLanguages.ro, [AvailableLanguages.ro], [locale]);
	}

	public getTranslation(path: string): string | null {
		if (!this._isLoaded)
			return null;
		return this._translateService.instant(path) as (string | null);
	}

	public getTranslationService(): TranslateService {
		return this._translateService;
	}
}
