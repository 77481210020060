<div class="authentication-box">
  <div class="text-center"><img src="assets/images/logo.png" alt="ProLex Logo"></div>
  <div class="card mt-4 shadow-box">
    <div class="card-body">
      <div class="text-center">
        <h4>{{ 'labels.title' | translate }}</h4>
        <h6 class="text-justify">{{ 'labels.subtitle' | translate }}</h6>
      </div>
      <form [formGroup]="createPasswordForm" class="theme-form" (submit)="createPasswordClicked()">
        <div class="form-group">
          <ejs-textbox
            placeholder="{{ 'labels.password' | translate }}"
            formControlName="password"
            cssClass="e-outline"
            type="password"
            floatLabelType="Auto">
          </ejs-textbox>
          <dm-password-strength-meter [password]="passwordControl.value" [enableFeedback]="false" (strengthChanged)="passwordStrengthChanged($event)"></dm-password-strength-meter>
          <div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)" class="error-msg">
            <div *ngIf="passwordControl.errors.required">{{ 'validation.passwordRequired' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <ejs-textbox
            placeholder="{{ 'labels.verifyPassword' | translate }}"
            formControlName="verifyPassword"
            cssClass="e-outline"
            type="password"
            floatLabelType="Auto">
          </ejs-textbox>
          <div *ngIf="createPasswordForm.errors?.mismatchedValues && (verifyPasswordControl.dirty || verifyPasswordControl.touched)" class="error-msg">
            <div *ngIf="createPasswordForm.errors?.mismatchedValues">{{ 'validation.passwordsNotEqual' | translate }}</div>
          </div>
        </div>
        <div class="form-group text-center">
          <button
						#createPasswordButton
						ejs-progressbutton
						content="{{ 'labels.createPassword' | translate }}"
						cssClass="dm-progress-button e-success"
						iconCss="cis-fingerprint"
						[spinSettings]="spinSettings"
						[duration]="spinDuration"
						type="submit"
						[disabled]="createPasswordForm.invalid || this.currentPasswordStrength !== 5"
						(begin)="contractBegin()"
						(end)="contractEnd()">
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
