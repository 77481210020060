<div class="authentication-box">
	<div class="text-center"><img src="assets/images/logo.png" alt="ProLex Logo"></div>
	<div class="card mt-4 shadow-box">
		<div class="card-body">
			<div class="text-center">
				<h4>{{ 'labels.title' | translate }}</h4>
				<h6 class="text-justify">{{ 'labels.subtitle' | translate }}</h6>
			</div>
			<form [formGroup]="forgotPasswordForm" class="theme-form" (submit)="resetClicked()">
				<div class="form-group">
					<ejs-textbox #email
						placeholder="{{ 'labels.email' | translate }}"
						formControlName="email"
						cssClass="e-outline"
						floatLabelType="Auto"
						autocomplete="off">
					</ejs-textbox>
          <div *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)" class="error-msg">
            <div *ngIf="emailControl.errors.required">{{ 'validation.emailRequired' | translate }}</div>
            <div *ngIf="emailControl.errors.email">{{ 'validation.emailInvalid' | translate }}</div>
          </div>
				</div>
				<div class="form-group text-center">
					<button #resetButton
						ejs-progressbutton
						content="{{ 'labels.reset' | translate }}"
						cssClass="dm-progress-button e-success"
						iconCss="cis-fingerprint"
						[spinSettings]="spinSettings"
						[duration]="spinDuration"
            type="submit"
            [disabled]="forgotPasswordForm.invalid"
						(begin)="contractBegin()"
						(end)="contractEnd()">
					</button>
				</div>
				<hr class="horizontal-line">
				<div class="form-group text-center">
					<button #loginButton
              ejs-button
              cssClass="gray-button"
              iconCss="cis-lock-plus"
              (click)="gotoLoginPage($event)">
            {{ 'labels.login' | translate }}
          </button>
				</div>
			</form>
		</div>
	</div>
</div>
