<header class="prolex-site-header header-one">
	<nav class="navbar prolex-navbar-expand-lg navbar-light prolex-header-navigation sticky">
		<div class="container clearfix">
			<!-- Brand and toggle get grouped for better mobile display -->
			<div class="prolex-logo-box clearfix">
				<a class="navbar-brand" href="index.html">
					<img src="assets/images/logo.png" alt="Pro Lex logo" style="width:250px;height:auto;" />
				</a>
				<button class="prolex-menu-toggler" data-target=".header-one .main-navigation">
					<i class="icofont-navigation-menu"></i>
				</button>
			</div>
			<!-- Collect the nav links, forms, and other content for toggling -->
			<div class="main-navigation">
				<ul class="one-page-scroll-menu prolex-navigation-box">
					<li class="current scrollToLink">
						<a routerLink="/" fragment="intro" data-fragment="#intro">Acasă</a>
					</li>
					<li class="scrollToLink">
						<a routerLink="/" fragment="prolex-club-mobile" data-fragment="#prolex-club-mobile">ProLex Club</a>
					</li>
					<li class="scrollToLink">
						<a routerLink="/" fragment="casa-dracula" data-fragment="#casa-dracula">Casa Dracula</a>
					</li>
					<li class="scrollToLink">
						<a routerLink="/" fragment="cotizatie" data-fragment="#cotizatie">Cotizație</a>
					</li>
					<li class="scrollToLink">
						<a routerLink="/" fragment="cont" data-fragment="#cont">Cont</a>
					</li>
				</ul>
			</div>
			<div class="right-side-box">
				<a [routerLink]="routerPaths.login.fullPath" class="header-btn">Autentificare</a>
			</div>
		</div>
	</nav>
</header>
