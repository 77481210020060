<div class="authentication-box">
  <div class="text-center"><img src="assets/images/logo.png" alt="ProLex Logo"></div>
  <div class="card mt-4 shadow-box">
    <div class="card-body">
      <div class="text-center">
        <h4>{{ 'labels.title' | translate }}</h4>
        <h6 class="text-justify">{{ 'labels.subtitle' | translate }}</h6>
      </div>
      <form [formGroup]="resetPasswordForm" class="theme-form" (submit)="resetClicked()">
        <div class="form-group">
          <ejs-textbox
            placeholder="{{ 'labels.password' | translate }}"
            formControlName="password"
            cssClass="e-outline"
            type="password"
            floatLabelType="Auto">
          </ejs-textbox>
          <dm-password-strength-meter [password]="passwordControl.value" [enableFeedback]="false" (strengthChanged)="passwordStrengthChanged($event)"></dm-password-strength-meter>
          <div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)" class="error-msg">
            <div *ngIf="passwordControl.errors.required">{{ 'validation.passwordRequired' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <ejs-textbox
            placeholder="{{ 'labels.verifyPassword' | translate }}"
            formControlName="verifyPassword"
            cssClass="e-outline"
            type="password"
            floatLabelType="Auto">
          </ejs-textbox>
          <div *ngIf="resetPasswordForm.errors?.mismatchedValues && (verifyPasswordControl.dirty || verifyPasswordControl.touched)" class="error-msg">
            <div *ngIf="resetPasswordForm.errors?.mismatchedValues">{{ 'validation.passwordsNotEqual' | translate }}</div>
          </div>
        </div>
        <div class="form-group text-center">
          <button #resetButton
            ejs-progressbutton
            content="{{ 'labels.reset' | translate }}"
            cssClass="dm-progress-button e-success"
            iconCss="cis-fingerprint"
            [spinSettings]="spinSettings"
            [duration]="spinDuration"
            type="submit"
            [disabled]="resetPasswordForm.invalid || this.currentPasswordStrength !== 5"
            (begin)="contractBegin()"
            (end)="contractEnd()">
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
