<section class="prolex-services-style-one" id="intro">
	<div class="container">
		<div class="prolex-block-title text-center">
			<h1>Portalul membrilor<br>Pro Lex</h1>
			<div class="row" style="margin-bottom:10px;">
				<div class="col-md-3" style="align-self: center;">
					<img class="img-fluid" src="assets/images/landing-page/de-ce.png" style="max-height:250px;" alt="De ce ProLex" />
				</div>
				<div class="col-md-9">
					<section class="prolex-subscribe-style-one">
						<div class="container">
							<h3 class="text-center">Vrei să ai acces la platforma ProLex?</h3>
							<h6>Apasă butonul de mai jos, introdu datele tale și te vei bucura de toate avantajele unui membru.</h6>
							<a class="feature-btn" style="margin-bottom: 20px;" (click)="registerClicked()">Vreau cont de utilizator</a>
						</div>
					</section>
				</div>
				<div class="col-md-12">
					<h2>De ce membru Pro Lex ?</h2>
					<p class="text-justify" style="font-size:20px;line-height:30px;">
            Pro Lex, lider al mișcării sindicale din administrația publică, oferă asigurare profesională, gratuită, prin acordarea de servicii juridice asigurate de un departament juridic cu avocați și specialiști.
          </p>
          <p class="text-justify" style="font-size:20px;line-height:30px;">
            Cartea de vizită a Pro Lex o reprezintă faptele și rezultatele, iar experiența acumulată din 2004 a demonstrat că am avut succes chiar și cu o atitudine normală, echilibrată și responsabilă.
          </p>
          <p class="text-justify" style="font-size:20px;line-height:30px;">
            Comunitatea Pro Lex suntem noi toți, rezultatele depind de efortul fiecăruia dintre noi. Astfel, Pro Lex vrea să aibă membri activi, nu doar cotizanți. Beneficiile acoperă integral cotizația și chiar aduc economii semnificative membrilor și familiilor acestora. În aceste condiții, retragerea reprezintă o pierdere pentru membru, nu pentru comunitate, drept pentru care la reînscriere trebuie să achite o taxă suplimentară ("Taxă de Fraier"), în sumă de 100 lei.
          </p>
          <p class="text-justify" style="font-size:20px;line-height:30px;">
            Pro Lex este o comunitate selectă din care poate face parte orice persoană care apreciază și rezonează cu valorile promovate de noi.
          </p>
          <p class="text-center" style="font-size: 18px;">
            <a href="{{ backendUrl + 'storage/documents/Statutul Sindicatului Pro Lex.pdf' }}" target="_blank">Citește aici Statutul Sindicatului Național Pro Lex</a>
          </p>
				</div>
			</div>
			<div class="row row-eq-height justify-content-center mb-3">
        <div class="col-lg-6 col-md-12 mb-3">
          <div class="single-service-one">
            <div class="hover-block"></div>
            <i class="cis-handshake"></i>
            <h3>Consiliere profesională prin avocaţi sau specialişti ȋn domeniu.</h3>
            <p>
              Legislația în domeniul muncii, al funcției publice și al salarizării este una foarte vastă, necesitând un nivel de cunoștințe juridice și specifice pe care cei mai mulți dintre noi nu îl au. Este foarte important să ne cunoaștem drepturile și obligațiile profesionale, precum și efectele actelor administrative care ne privesc. În aceste condiții, consilierea acordată la momentul potrivit de către avocați sau specialiștii sindicatului ne protejează de greșeli sau abuzuri ale șefilor.
            </p>
          </div>
        </div>
				<div class="col-lg-6 col-md-12 mb-3">
					<div class="single-service-one">
						<div class="hover-block"></div>
						<i class="cis-balance-scale"></i>
						<h3>Asistență juridică pentru litigii izvorâte din raporturile de serviciu ce privesc cariera profesională sau salarizarea.</h3>
            <p>
              Prin departamentul juridic și avocații cu care colaborăm permanent, Pro Lex formulează contestații împotriva actelor administrative ce privesc cariera profesională sau salarizarea membrilor, precum și împotriva dispozițiilor de sancționare sau de evaluare profesională.
            </p>
            <p>
              Toate acțiunile se formulează la cererea scrisă a membrilor și transmiterea înscrisurilor solicitate de avocați.
            </p>
            <p>
              Realitatea a demonstrat faptul că apartenența la Pro Lex a făcut diferența în cazurile de reorganizarea a instituției publice prin reduceri de posturi, ca urmare a succesului acțiunilor formulate de avocații noștri.
            </p>
					</div>
				</div>
			</div>
			<div class="row row-eq-height justify-content-center">
        <div class="col-lg-4 col-md-12 mb-3">
          <div class="single-service-one">
            <div class="hover-block"></div>
            <i class="icofont-users-alt-2"></i>
            <h3>Pro Lex analizează modul de aplicare a legilor şi concepe acţiuni colective.</h3>
            <p>
              Cele mai multe acțiuni în instanță nu sunt cele individuale, introduse la cererea membrilor, ci cele colective. Specialiștii sindicatului analizează legislația ce reglementează drepturile salariale ale membrilor și verifică dacă aceasta a fost aplicată corect.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mb-3">
          <div class="single-service-one">
            <div class="hover-block"></div>
            <i class="cis-comment-bubble-edit"></i>
            <h3>Reprezentare la elaborarea actelor normative.</h3>
            <p>
              Datorită afilierii la o confederație sindicală reprezentativă, avem un membru în Consiliul Economic Social și delegați la comisiile de dialog social la ministerele în care membrii își desfășoară activitatea, având posibilitatea de a formula observații și amendamente la proiectele de legi ce urmează a fi adoptate.
            </p>
          </div>
        </div>
				<div class="col-lg-4 col-md-12 mb-3">
					<div class="single-service-one">
						<div class="hover-block"></div>
						<i class="icofont-law-order"></i>
						<h3>Beneficiile dobândirii reprezentativității la nivelul instituției</h3>
            <p>
              Dacă numărul membrilor Pro Lex din cadrul instituției este mai mare de 50%, se constată de către instanță reprezentativitatea sindicatului. Acest lucru permite desemnarea unor membri în cadrul comisiei de disciplină, a comisiei de concurs, încheierea contractului/acordului colectiv de muncă. De asemenea, instituția este obligată să consulte reprezentanții sindicatului  în probleme ce țin de salarizare, reorganizare, protecția muncii etc
            </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
