import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { paths } from '../../../app-paths';
import { RegistrationPopupComponent } from '../registration-popup/registration-popup.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'landing-page-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
	// Public members
  public routerPaths = paths;
  public backendUrl = environment.backendUrl;

	constructor(private _modalService: NgbModal) { }

	public registerClicked(): void {
		this._modalService.open(RegistrationPopupComponent, {
			size: 'xl',
			animation: true,
			backdrop: 'static',
			centered: true,
			keyboard: false,
			scrollable: true
		});
	}
}
