import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { DMFunctions } from '../functions';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public transform(value: string): SafeHtml | null {
		if (DMFunctions.isNullOrEmpty(value))
			return null;
		return this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(value));
	}
}
