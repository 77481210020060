<dm-loading
	[show]="isSpinnerVisible$ | async"
	[config]="{
		animationType: dmLoadingAnimationTypes.wanderingCubes,
		primaryColour: primaryColor,
		secondaryColour: secondaryColor,
		backdropBorderRadius: '3px'
	}">
</dm-loading>
<router-outlet></router-outlet>
