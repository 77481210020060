import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// import { Error400Component } from './error400/error400.component';
// import { Error401Component } from './error401/error401.component';
// import { Error403Component } from './error403/error403.component';
import { Error404Component } from './404/error404.component';
// import { Error500Component } from './error500/error500.component';
// import { Error503Component } from './error503/error503.component';
import { PathResolveService } from './404/path-resolve.service';

const routes: Routes = [
	{
		path: '',
		children: [
			// {
			//   path: '400',
			//   component: Error400Component,
			// },
			// {
			//   path: '401',
			//   component: Error401Component
			// },
			// {
			//   path: '403',
			//   component: Error403Component
			// },
			{
				path: '404',
				component: Error404Component,
				resolve: {
					path: PathResolveService
				}
			} // ,
			// {
			//   path: '500',
			//   component: Error500Component
			// },
			// {
			//   path: '503',
			//   component: Error503Component
			// }
		]
	}
];

@NgModule({
	declarations: [
		// Error400Component,
		// Error401Component,
		// Error403Component,
		Error404Component,
		// Error500Component,
		// Error503Component
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes)
	]
})
export class ErrorPagesModule { }
