/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { IDMLoadingConfig, DMLoadingConfig } from './loading-config';

@Injectable({ providedIn: 'root' })
export class DMLoadingService {
  public loadingConfig: IDMLoadingConfig;

  constructor(@Optional() @Inject('loadingConfig') private config: IDMLoadingConfig) {
    this.loadingConfig = this.config || new DMLoadingConfig();
  }
}
