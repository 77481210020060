<section class="prolex-testimonials-style-one" id="cont">
	<img src="assets/images/landing-page/map-1-1.png" alt="Cont" class="map-img" />
	<div class="container">
		<div class="row text-center mb-3">
			<h1>Cont personal</h1>
		</div>
		<div class="row text-center mb-3">
			<div class="col-md-12">
				<ol class="gradient-list description">
					<li>Plată cotizație on-line</li>
					<li>Alegerea tipului de abonament pentru servicii de sănătate</li>
					<li>Ghidul membrului - Legislație specifică privind cariera și salarizarea</li>
					<li>Acțiuni specifice categoriei din care face parte</li>
					<li>Situația dosarelor în care este parte</li>
          <li>Întrebări frecvente</li>
				</ol>
			</div>
		</div>
	</div>
</section>
