<span class="text-center font-weight-bold" style="display: block;">{{ 'paymentRequiredLabel' | translate }}</span><br>
<span class="text-center font-weight-bold" style="display: block;">{{ 'subtitleContact' | translate }}</span>
<form [formGroup]="contactInfoForm" class="theme-form">
  <div class="form-group">
    <ejs-combobox
			#county
			formControlName="countyId"
			[dataSource]="countiesDataSource | async"
			[autofill]="true"
			[allowFiltering]="true"
			filterType="Contains"
			[allowCustom]="false"
			[fields]="{ text: 'name', value: 'id' }"
			[ignoreAccent]="true"
			[popupHeight]="200"
			cssClass="e-outline"
			floatLabelType="Auto"
			placeholder="{{ 'labelsContact.county' | translate }}"
			(change)="countyOnValueChanged($event)">
    </ejs-combobox>
  </div>
  <div class="form-group">
    <ejs-combobox
	    #locality
      formControlName="localityId"
      [dataSource]="localitiesDataSource | async"
      [autofill]="true"
			[allowFiltering]="true"
			filterType="Contains"
      [allowCustom]="false"
      [fields]="{ text: 'name', value: 'id' }"
      [ignoreAccent]="true"
	    [popupHeight]="200"
      cssClass="e-outline"
      floatLabelType="Auto"
      placeholder="{{ 'labelsContact.locality' | translate }}"
      [enabled]="false"
      (change)="localityOnValueChanged($event)">
    </ejs-combobox>
  </div>
  <div class="form-group">
    <ejs-textbox
      formControlName="address"
      placeholder="{{ 'labelsContact.address' | translate }}"
      [enabled]="false"
      cssClass="e-outline"
      floatLabelType="Auto"
      autocomplete="off"
			[htmlAttributes]="{ 'maxLength': '256'  }">
    </ejs-textbox>
  </div>
  <div class="form-group">
		<div style="display: inline-flex; align-items: center;">
			<div style="width: 100%; margin-right: 5px;">
				<ejs-textbox
					formControlName="email"
					placeholder="{{ 'labelsContact.email' | translate }}"
					cssClass="e-outline"
					floatLabelType="Auto"
					autocomplete="on">
				</ejs-textbox>
			</div>
			<div style="width: auto">
				<button
					ejs-button
					cssClass="e-info"
					[disabled]="emailControl.errors != null || validatedEmail"
					(click)="emailExistsValidation()">
					{{ 'labelsContact.validateEmail' | translate }}
				</button>
			</div>
	  </div>
		<div *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)" class="error-msg">
			<div *ngIf="emailControl.errors.emailInvalid">{{ 'validationContact.emailInvalid' | translate }}</div>
			<div *ngIf="emailControl.errors.emailExists">{{ 'validationContact.emailExists' | translate }}</div>
		</div>
  </div>
	<div *ngIf="isMailSent" class="form-group">
		<div style="display: inline-flex; align-items: center;">
			<div style="width: 100%; margin-right: 5px;">
				<ejs-numerictextbox
					formControlName="emailCode"
					placeholder="{{ 'labelsContact.emailValidationCode' | translate }}"
					cssClass="e-outline"
					floatLabelType="Auto"
					[min]="1000"
					[max]="9999"
					format="####"
					[showSpinButton]="false"
					[htmlAttributes]="{ 'maxLength': '4' }">
				</ejs-numerictextbox>
			</div>
			<div style="width: auto">
				<button
					ejs-button
					cssClass="e-info"
					[disabled]="emailControl.errors != null || validatedEmail"
					(click)="verifyEmail()">
					{{ 'labelsContact.verifyEmail' | translate }}
				</button>
			</div>
		</div>
	</div>
	<div class="form-group">
		<ejs-phone-input
			placeholder="{{ 'labelsContact.phoneNumber' | translate }}"
			formControlName="phoneNumber">
		</ejs-phone-input>
		<div *ngIf="phoneNumberControl.invalid && (phoneNumberControl.dirty || phoneNumberControl.touched)" class="error-msg">
			<div *ngIf="phoneNumberControl.errors.invalidNumber">{{ 'validationContact.phoneNumberInvalid' | translate }}</div>
		</div>
	</div>
  <button
      ejs-button
      cssClass="e-info left-button"
      awPreviousStep
      (click)="prev($event)">
    {{ 'labelsContact.prev' | translate }}
  </button>
  <button
      ejs-button
      cssClass="e-info right-button"
      awNextStep
      (click)="next()"
      [disabled]="contactInfoForm.invalid || !validatedEmail">
    {{ 'labelsContact.next' | translate }}
  </button>
	<div #contactInfoSpinner></div>
</form>
