export const locale = {
	lang: 'ro',
	data: {
		paymentRequiredLabel: 'Pentru a definitiva înregistrarea ca membru trebuie efectuată plata online a cotizației în termen de 24 de ore',
		subtitleContact: 'Câmpurile marcate cu * sunt obligatorii',
		labelsContact: {
			county: 'Județ *',
			locality: 'Localitate *',
      address: 'Adresă *',
			email: 'Email *',
			phoneNumber: 'Telefon *',
			validateEmail: 'Validează',
			verifyEmail: 'Validează cod',
      next: 'Pasul următor',
      prev: 'Pasul precedent',
			emailSpinner: 'Te rog să aștepți. Verific adresa de email.',
			emailValidationCode: 'Cod verificare email'
		},
		validationContact: {
			emailInvalid: 'Adresa de email este invalidă',
			emailExists: 'Adresa de email există deja în baza de date',
			phoneNumberInvalid: 'Numărul de telefon este invalid'
		}
	}
};
