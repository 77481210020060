import { Component } from '@angular/core';
import { paths } from '../../../app-paths';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'landing-page-footer',
	templateUrl: './landing-page-footer.component.html',
	styleUrls: ['./landing-page-footer.component.scss']
})
export class LandingPageFooterComponent {
	// Public components
	public routerPaths = paths;
}
