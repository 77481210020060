/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CNPValidator } from './cnp.validation';
import { DMFunctions } from '../functions';

//@dynamic
export class ReactiveFormsValidation {
  public static required(control: AbstractControl) {
    if (typeof control.value !== 'boolean')
      return DMFunctions.isNullOrEmpty(control.value) ? { required: true } : null;
    else
      return !control.value ? { required: true } : null;
  }

  public static rangeLength(minLength: number, maxLength: number, required: boolean = true): ValidatorFn {
     return (control: AbstractControl): ValidationErrors | null => {
       if (DMFunctions.isNullOrEmpty(control.value))
         return required ? { required: true } : null;
       return (control.value.length >= minLength && control.value.length <= maxLength) ? null : { rangeLength: true };
     }
  }

  public static rangeHtmlLength(minLength: number, maxLength: number, required: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const plainText = DMFunctions.htmlToText(control.value);
      if (plainText === null)
        return required ? { required: true } : null;
      return (plainText.length >= minLength && plainText.length <= maxLength) ? null : { rangeLength: true };
    }
  }

  public static minLength(minLength: number, required: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (DMFunctions.isNullOrEmpty(control.value))
        return required ? { required: true } : null;
      return (control.value.length >= minLength) ? null : { minLength: true };
    }
  }

  public static maxLength(maxLength: number, required: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (DMFunctions.isNullOrEmpty(control.value))
        return required ? { required: true } : null;
      return (control.value.length <= maxLength) ? null : { maxLength: true };
    }
  }

  public static namePattern(control: AbstractControl) {
    if (DMFunctions.isNullOrEmpty(control.value))
      return null;
    const respectsPattern =/^(([A-ZȘȚĂÂÎa-zșțăâî]+)((([-' ])|( - ))([A-ZȘȚĂÂÎa-zșțăâî]+)){0,2})$/.test(control.value);
    return !respectsPattern ? { namePattern: true } : null;
  }

  public static cnpInvalid(required: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (required && control.value === null || control.value === undefined)
        return { required: true };
      return new CNPValidator(control.value).isValid() ? null : { cnpInvalid: true };
    }
  }

  public static emailInvalid(required: boolean = true): ValidatorFn {
  	return (control: AbstractControl): ValidationErrors | null => {
  		const isEmpty = DMFunctions.isNullOrEmpty(control.value);
  		if (isEmpty) {
  			if (required)
					return { required: true };
  			else
  				return null;
			}
			const emailParts = control.value.split('@');
			if (emailParts.length !== 2)
				return { emailInvalid: true };
			// account
			if(emailParts[0].length > 64)
				return { emailInvalid: true };
			// domain
			else if(emailParts[1].length > 192)
				return { emailInvalid: true };
			const domainParts = emailParts[1].split('.');
			if (domainParts.some((part: string) => part.length > 63))
				return { emailInvalid: true };
			// eslint-disable-next-line max-len
			if (!(/^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(control.value)))
				return { emailInvalid: true };
			return null;
		}
	}

  public static matchingValues(firstField: string, secondField: string) {
    return (group: FormGroup): ValidationErrors | null => {
      const field1 = group.controls[firstField];
      const field2 = group.controls[secondField];
      if (field1 && field2 && (field1.value !== field2.value))
        return { mismatchedValues: true };
      return null;
    }
  }

  public static requiredIfChecked(checkField: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const checkControl = control.parent?.get(checkField);
			if (checkControl && !checkControl.value && DMFunctions.isNullOrEmpty(control.value))
				return { required: true };
			return null;
		}
	}
}
