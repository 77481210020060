import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '@prolex/shared';
import { RegisterService, TranslationLoaderService } from '../../shared/services';
import { RegistrationMember } from '../../shared/models';
import { paths } from '../../app-paths';
import { locale as romanian } from './i18n/ro';

@Component({
	selector: 'members-auth-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default // important
})
export class RegisterComponent {
	// Components
	@ViewChild('registerWizard') private registerWizard: WizardComponent;
	// Private members
	private _wizardModel: RegistrationMember = new RegistrationMember();

	//#region Wizard model
	public set wizardModel(value: RegistrationMember) {
		this._wizardModel = value;
	}

	public get wizardModel(): RegistrationMember {
		return this._wizardModel;
	}
	//#endregion

	constructor(
		private _registerService: RegisterService,
    private _authenticationService: AuthenticationService,
    private _translationLoaderService: TranslationLoaderService,
		private _router: Router)
	{
		if (this._authenticationService.isAuthenticated()) {
			void this._router.navigate([paths.dashboard.fullPath]);
			return;
		}
    this._translationLoaderService.loadRomanianTranslations(romanian);
		this.personalDataCanExit = this.personalDataCanExit.bind(this);
		this.contactDataCanExit = this.contactDataCanExit.bind(this);
		this.workplaceDataCanExit = this.workplaceDataCanExit.bind(this);
		this.passwordCanExit = this.passwordCanExit.bind(this);
	}

	public next(): void {
		if (this.registerWizard.hasNextStep())
			this.registerWizard.goToNextStep();
	}

	public prev(): void {
		if (this.registerWizard.hasPreviousStep())
			this.registerWizard.goToPreviousStep();
	}

	public personalDataEnter(): void {
		this.wizardModel.personalDataVerified = false;
	}

	public contactDataEnter(): void {
		this.wizardModel.contactInfoVerified = false;
	}

	public workplaceDataEnter(): void {
		this.wizardModel.workplaceInfoVerified = false;
	}

	public passwordEnter(): void {
		this.wizardModel.passwordVerified = false;
	}

	public personalDataCanExit(): boolean {
		return this.wizardModel.personalDataVerified;
	}

	public contactDataCanExit(): boolean {
		return this.wizardModel.contactInfoVerified;
	}

	public workplaceDataCanExit(): boolean {
		return this.wizardModel.workplaceInfoVerified;
	}

	public passwordCanExit(): boolean {
		return this.wizardModel.passwordVerified;
	}
}
