import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '@prolex/shared';
import { paths } from '../../../app-paths';

@Component({
	selector: 'members-error404',
	templateUrl: './error404.component.html',
	styleUrls: ['./error404.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class Error404Component implements OnInit {
	// Public members
	public routerPaths = paths;
	public path: string;

	constructor(
		private _route: ActivatedRoute,
		private _authenticationService: AuthenticationService)
	{ }

	public isAuthenticated(): boolean {
		return this._authenticationService.isLoggedIn$.value;
	}

	public ngOnInit(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this._route.data.pipe(take(1)).subscribe((data: any) => {
			this.path = data.path;
		});
	}
}
