<div class="container-fluid registration-popup">
	<div class="row">
		<div class="col registration-popup-title">
			Înregistrare
		</div>
	</div>
	<div class="row mt-2" [formGroup]="registrationFormGroup">
		<div class="form-group col-6">
			<ejs-radiobutton
				label="Nu sunt membru ProLex"
				formControlName="type"
				value="1">
			</ejs-radiobutton>
			<div style="width:100%;text-align:left;margin-top:5px;">
				Nu am completat adeziune scrisă la Sindicatul Pro Lex și vreau să mă înregistrez online
			</div>
		</div>
		<div class="form-group col-6">
			<ejs-radiobutton
				label="Sunt deja membru ProLex"
				formControlName="type"
				value="2">
			</ejs-radiobutton>
			<div style="width:100%;text-align:left;margin-top:5px;">
				Am completat adeziune scrisă la Sindicatul Pro Lex
			</div>
		</div>
	</div>
	<div class="row mt-2 registration-popup-footer">
		<div class="col">
			<button class="btn btn-primary btn-block action-button" [disabled]="continueDisabled" (click)="gotoRegistration()">
				<i class="cis-lock-unlocked"></i>Continuă
			</button>
		</div>
		<div class="col">
			<button class="btn btn-danger btn-block action-button" (click)="cancel()">
				<i class="cis-x-circle"></i>Renunț
			</button>
		</div>
	</div>
</div>
