import { CrudOptions, DataManager, DataOptions, DataResult, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import { AvailableLanguages } from './enums';

export class ProLexDataAdaptor extends UrlAdaptor {
  private readonly _authenticationTokenLocalStorageKey: string;
  private _internalData: DataResult | null = null;

  constructor(authenticationTokenLocalStorageKey: string, withInternalData = false) {
    super();
    this._authenticationTokenLocalStorageKey = authenticationTokenLocalStorageKey;
  }

  // Add necessary headers to requests
  public beforeSend(dm: DataManager, request: XMLHttpRequest): void {
    // customizing the Url of lHttpRequest
    request.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    const token = localStorage && localStorage.getItem(this._authenticationTokenLocalStorageKey);
    if (token != null && token.length > 0)
      request.setRequestHeader('Authorization', `Bearer ${token}`)
    request.setRequestHeader('Accept-Language', AvailableLanguages.ro);
  }

  public processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Object, changes?: CrudOptions): DataResult {
    this._internalData = data;
    return super.processResponse(data, ds, query, xhr, request, changes);
  }

  public get internalData(): DataResult | null {
    return this._internalData;
  }
}
