export const locale = {
	lang: 'ro',
	data: {
		labels: {
			title: 'Autentificare',
			subtitle: 'Bine ai venit',
			email: 'Email',
			password: 'Parolă',
			rememberMe: 'Ține-mă minte',
			login: 'Autentifică-mă',
			forgotPassword: 'Mi-am uitat parola...',
			register: 'Nu ai cont? Înregistrează-te!'
		},
		validation: {
			emailRequired: 'Adresa de email este obligatorie',
			emailInvalid: 'Adresa de email este invalidă',
			passwordRequired: 'Parola este obligatorie'
		}
	}
};