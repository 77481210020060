/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Component, Input, ViewChild, ViewEncapsulation, AfterViewInit,
         forwardRef, HostBinding, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleChanges } from '@angular/core';
import { DMTelephoneCountries, DMTelephoneCountry } from '@dynamicmedia/shared';
import { DropDownListComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxComponent, InputEventArgs } from '@syncfusion/ej2-angular-inputs';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { DMFunctions } from '@dynamicmedia/shared';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ejs-phone-input',
  templateUrl: './ejs-phone-input.component.html',
  styleUrls: ['./ejs-phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneInputComponent),
    multi: true
  }]
})
export class PhoneInputComponent implements AfterViewInit, OnChanges, ControlValueAccessor {
  public countriesDataSource = DMTelephoneCountries;
  public controlId: string;
  public labelId: string;
  @Input() public placeholder!: string;
  @Input() public defaultCountry = 'ro';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') public _value: string | null;

  @ViewChild('country') private _countryDropdown!: DropDownListComponent;
  @ViewChild('number') private _numberTextBox!: TextBoxComponent;

  private _controlContainer!: HTMLElement;
  private _controlLabel!: HTMLElement;
  private _id: string | null;
  private _currentIndicative!: string;
  private _valueReceived = false;
  private _formattedValue: string | null = null;
  private _isDisabled = false;

  @HostBinding('attr.id') public externalId: string | null = null;

  @Input() set id(value: string | null) {
    this._id = value;
    this.externalId = null;
  }

  get id(): string | null {
    return this._id;
  }

  get value(): string | null {
    if (this._value == this._currentIndicative)
      this._value = null;
    return this._value;
  }

  set value(val: string | null) {
		this._value = val;
		if (this._numberTextBox)
			this._formatNumber(val);
		else {
			this.onChange(val);
			this.onTouched();
		}
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-explicit-any
  onChange: any = () => {};
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-explicit-any
  onTouched: any = () => {};

  constructor() {
    this.controlId = DMFunctions.generateRandomString(8);
    this.labelId = DMFunctions.generateRandomString(8);
    this._value = null;
    this._id = null;
  }

  public ngAfterViewInit(): void {
  	if (this._currentIndicative) {
		  const result = this.countriesDataSource.find((item: DMTelephoneCountry) => item.i === this._currentIndicative);
		  this._currentIndicative = result ? `+${result.i}` : '+40';
		  this._countryDropdown.value = result ? result.c : this.defaultCountry;
	  }
  	else {
		  this._countryDropdown.value = this.defaultCountry;
		  const result = this.countriesDataSource.find((item: DMTelephoneCountry) => item.c === this.defaultCountry);
		  this._currentIndicative = result ? `+${result.i}` : '+40';
	  }

    if (DMFunctions.isNullOrEmpty(this._value)) {
      this._value = this._currentIndicative;
      this._numberTextBox.value = this._currentIndicative;
    }
    else {
	    if (this._valueReceived)
		    this._numberTextBox.value = this._formattedValue as string;
	    else
	      this._numberTextBox.value = this._value as string;
    }
    this._controlContainer = document.getElementById(this.controlId) as HTMLElement;
    if (this._isDisabled) {
	    this._controlContainer.classList.add('e-disabled');
			if (this._numberTextBox)
				this._numberTextBox.setDisabledState(true);
    }
    else {
	    this._controlContainer.classList.remove('e-disabled');
			if (this._numberTextBox)
				this._numberTextBox.setDisabledState(false);
    }
    this._controlLabel = document.getElementById(this.labelId) as HTMLElement;
    if (DMFunctions.isNullOrEmpty(this._value))
      this._controlLabel.style.visibility = 'hidden';
    else {
      this._controlContainer.classList.add('e-valid-input');
      this._controlLabel.style.visibility = 'visible';
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultCountry && changes.defaultCountry.currentValue) {
      const result = this.countriesDataSource.find((item: DMTelephoneCountry) => item.c === changes.defaultCountry.currentValue);
      this._currentIndicative = result ? `+${result.i}` : '+40';
    }
  }

  public countryFocus(): void {
    this._controlContainer.classList.add('e-input-focus');
    if (DMFunctions.isNullOrEmpty(this._numberTextBox.value)) {
      this._controlLabel.style.visibility = 'visible';
      this._numberTextBox.placeholder = '';
    }
  }

  public countryBlur(): void {
    this._controlContainer.classList.remove('e-input-focus');
    if (DMFunctions.isNullOrEmpty(this._numberTextBox.value)) {
      this._controlLabel.style.visibility = 'hidden';
      this._numberTextBox.placeholder = this.placeholder;
    }
  }

  public countrySelected(args: SelectEventArgs): void {
    this._countryChanged(args.itemData as DMTelephoneCountry);
  }

  public numberFocus(): void {
    this._controlContainer.classList.add('e-input-focus');
    if (DMFunctions.isNullOrEmpty(this._numberTextBox.value)) {
      this._controlLabel.style.visibility = 'visible';
      this._numberTextBox.placeholder = '';
    }
  }

  public numberBlur(): void {
    this._controlContainer.classList.remove('e-input-focus');
    if (DMFunctions.isNullOrEmpty(this._numberTextBox.value)) {
      this._controlLabel.style.visibility = 'hidden';
      this._numberTextBox.placeholder = this.placeholder;
    }
  }

  public numberInputChange(args: InputEventArgs): void {
		// disable paste
		if ((args.event as InputEvent).inputType === 'insertFromPaste')
			args.value = args.previousValue;
		const emptyValue = DMFunctions.isNullOrEmpty(args.value);
		if (emptyValue) {
			args.value = this._currentIndicative;
			this._numberTextBox.value = 'O';// to emit value change event
			this._value = null;
			setTimeout(() => { this._numberTextBox.value = this._currentIndicative; }, 50);
			this.onChange(null);
			this.onTouched();
		}
    else {
			// disable other characters than digits
			let lastChar = emptyValue ? '' : (args.value as string)[(args.value as string).length - 1];
			if (lastChar === ' ')
				lastChar = emptyValue ? '' : (args.value as string)[(args.value as string).length - 2];
			if (/^\d+$/.test(lastChar))
				this._formatNumber(args.value as string | null);
			else
				this._numberTextBox.value = args.previousValue as string;
		}
  }

  public isValid(requiredToBeValid: boolean = true) {
    if (requiredToBeValid && DMFunctions.isNullOrEmpty(this._value))
      return false;
    if (this._value == this._currentIndicative)
      return !requiredToBeValid;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const parser = parsePhoneNumberFromString(this._value!);
    return parser && parser.isValid();
  }

  //#region Reactive forms implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  	if (this._countryDropdown != null)
    	this._countryDropdown.setDisabledState(isDisabled);
  	if (this._numberTextBox != null)
    	this._numberTextBox.setDisabledState(isDisabled);
    if (this._controlContainer) {
      if (isDisabled)
        this._controlContainer.classList.add('e-disabled');
      else
        this._controlContainer.classList.remove('e-disabled');
    }
  }

  public writeValue(value: string): void {
    this._value = value;
    if (this._numberTextBox)
      this._numberTextBox.value = value;
	  this._formatNumber(this._value);
  }
  //#endregion

  private _formatNumber(number: string | null): void {
    if (!number)
      return;
    if (!this._currentIndicative) {
    	const hasPlus = number[0] === '+';
    	const possibleIndicative = number.substr(hasPlus ? 1 : 0, 3);
	    const result = this.countriesDataSource.find((item: DMTelephoneCountry) => possibleIndicative.startsWith(item.i));
	    this._currentIndicative = result ? `+${result.i}` : '+40';
    }
    if (!number.startsWith(this._currentIndicative)) {
      if (number[0] != '+')
        number = `${this._currentIndicative}${number as string}`;
      else {
        if (number.length < this._currentIndicative.length) {
          this._value = number; // to trigger change in UI
          number = this._currentIndicative;
        }
      }
    }
    const asYouType = new AsYouType();
    this._formattedValue = asYouType.input(number);
    const phoneNumber = asYouType.getNumber();
    if (phoneNumber && number !== phoneNumber.number) {
    	number = phoneNumber.number as string;
	    this._formattedValue = new AsYouType().input(number as string);
    }
    if (phoneNumber && phoneNumber.country && this._countryDropdown)
      this._countryDropdown.value = phoneNumber.country.toLowerCase();
    if (this._formattedValue != null && this._formattedValue !== '' && this._formattedValue[0] !== '+')
	    this._formattedValue = `+${this._formattedValue}`;
    const newValue = this._formattedValue && this._formattedValue.replace(/\s/g, '');
	  this._valueReceived = true;
    if (newValue !== this._value) {
      this._value = newValue;
      if (!DMFunctions.isNullOrEmpty(this._value))
        this._controlContainer.classList.add('e-valid-input');
      else
        this._controlContainer.classList.remove('e-valid-input');
      if (this._numberTextBox)
        this._numberTextBox.value = this._formattedValue;
      this.onChange(this.value);
      this.onTouched();
    }
    else if (newValue === this._currentIndicative) {
    	if (this._numberTextBox)
				this._numberTextBox.value = newValue;
			this.onChange(this.value);
			this.onTouched();
		}
    else {
	    if (this._numberTextBox)
		    this._numberTextBox.value = this._formattedValue;
	    this.onTouched();
    }
  }

  private _countryChanged(newValue: DMTelephoneCountry) {
    if (!newValue)
      return;
    const oldIndicative = this._currentIndicative;
    this._currentIndicative = `+${newValue.i}`;
    this._formatNumber(this._numberTextBox.value.replace(oldIndicative, this._currentIndicative));
  }

	// eslint-disable-next-line @typescript-eslint/member-ordering
	public static getCorrectedPhoneNumber(number: string): string | null {
		if (!number)
			return null;
		number = number.replace('.', '')
		.replace(' ', '').replace('(', '')
		.replace(')', '').replace('-', '');
		const hasPlus = number[0] === '+';
		if (hasPlus)
			number = number.substr(1);
		const possibleIndicative = number.substr(0, 3);
		const result = DMTelephoneCountries.find((item: DMTelephoneCountry) => possibleIndicative.startsWith(item.i));
		if (result)
			number = number.replace(result.i, '');
		number = (result ? `+${result.i}` : '+40') + (number[0] === '0' ? number.substr(1) : number);
		const asYouType = new AsYouType();
		const phoneNumber = asYouType.getNumber();
		if (phoneNumber && number !== phoneNumber.number)
			number = phoneNumber.number as string;
		return number;
	}
}
