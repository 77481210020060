/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { createSpinner, showSpinner, setSpinner, hideSpinner, SpinnerArgs, SetSpinnerArgs } from '@syncfusion/ej2-popups';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  public hideSpinner: Function = (element: HTMLElement) => {
	  hideSpinner(element);
  };

  public showSpinner: Function = (element: HTMLElement) => {
    showSpinner(element);
  };

  public createSpinner: Function = (spinnerArgs: SpinnerArgs) => {
  	if (spinnerArgs.target)
  		spinnerArgs.target.classList.add('dm-spinner-service');
    createSpinner({
      target: spinnerArgs.target,
      label: spinnerArgs.label ?? 'Te rog să aștepți',
      cssClass: spinnerArgs.cssClass ?? 'e-spin-overlay',
      template: spinnerArgs.template,
      type: spinnerArgs.type ?? 'Material',
      width: spinnerArgs.width,
    });
  };

  public setSpinner: Function = (spinnerArgs: SetSpinnerArgs) => {
    setSpinner({
      cssClass: spinnerArgs.cssClass ?? 'e-spin-overlay',
      template: spinnerArgs.template,
      type: spinnerArgs.type ?? 'Material',
    });
  };
}
