<section class="prolex-banner-style-one" id="prolex-club-mobile">
	<div class="container">
		<div class="row row-eq-height align-items-center mb-3">
			<div class="col-md-4 text-center">
				<img class="img-fluid" src="assets/images/landing-page/discount.png" style="max-height:250px;" />
			</div>
			<div class="col-md-8 text-center align-middle">
				<h2 class="text-center">Reduceri la bunuri și servicii</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-md-5 col-lg-5 text-center">
				<ol class="gradient-list description">
					<li>Prezentare oferte parteneri prin intermediul aplicației ProLex Club.</li>
					<li>Compartiment dedicat pentru creşterea portofoliului de parteneri.</li>
					<li>Reducerile acoperă integral cotizaţia şi aduce mai multe avantaje băneşti.</li>
					<li>Legitimaţie tip cod QR şi adeverintă generate de ProLex Club.</li>
				</ol>
			</div>
			<div class="col-md-7 col-lg-7"></div>
		</div>
	</div>
	<div style="height:50px;"></div>
	<img src="assets/images/landing-page/banner-prolex-club.png" class="banner-mock" alt="ProLex Club" />
	<div class="container">
		<div class="row">
			<div class="col-xl-12 col-lg-12">
				<div class="content-block">
					<h3>ProLex Club <br> pentru dispozitivele mobile</h3>
					<p class="text-justify">
						Descarcă aplicația ProLex Club pentru dispozitivele mobile accesând Google Play pentru smartphone-urile cu sistem de operare Android sau App Store pentru smartphone-urile iPhone.
					</p>
					<div class="button-block text-center">
						<a href="https://play.google.com/store/apps/details?id=ro.dynamicmedia.prolex" class="banner-btn">
							<i class="cib-google-play"></i>
							Descarcă din<span>Google Play</span>
						</a>
						<a href="https://apps.apple.com/ro/app/prolex-club/id1468707657?l=ro" class="banner-btn">
							<i class="cib-app-store"></i>
							Descarcă din<span>App Store</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
