/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { dmLoadingAnimationTypes, IDMLoadingConfig, DMLoadingConfig } from './loading-config';
import { DMLoadingService } from './loading.service';

@Component({
  selector: 'dm-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class DMLoadingComponent implements OnInit {
  @Input() show: boolean | null;
  @Input() config: IDMLoadingConfig = new DMLoadingConfig();
  @Input() template!: TemplateRef<unknown>;

  private defaultConfig: IDMLoadingConfig = {
    animationType: dmLoadingAnimationTypes.threeBounce,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: false,
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff'
  };
  public ngxLoadingAnimationTypes = dmLoadingAnimationTypes;

  constructor(private LoadingService: DMLoadingService, private changeDetectorRef: ChangeDetectorRef) {
    this.show = false;
  }

  public ngOnInit() {
    this.setupConfig();
  }

  private setupConfig(): void {
    for (const option in this.defaultConfig) {
      if (typeof this.config[option] === 'boolean') {
        if (this.config[option] != null)
          continue;
        this.config[option] = this.LoadingService.loadingConfig[option] != null ?
          this.LoadingService.loadingConfig[option] :
          this.defaultConfig[option];
      }
      else {
        if (this.config[option] != null)
          continue;
        this.config[option] = this.LoadingService.loadingConfig[option] != null ?
          this.LoadingService.loadingConfig[option] :
          this.defaultConfig[option];
      }
    }
  }

  public setShow(show: boolean): void {
    this.show = show;
    this.changeDetectorRef.markForCheck();
  }
}
