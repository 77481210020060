import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonApiConfigService, CommonApiServiceConfig } from './common-api-service.config';
import { CommonApiService } from './common-api.service';

@NgModule()
export class CommonApiServiceModule {
	static forRoot(config: CommonApiServiceConfig): ModuleWithProviders<CommonApiServiceModule> {
		return {
			ngModule: CommonApiServiceModule,
			providers: [
				CommonApiService,
				{ provide: CommonApiConfigService, useValue: config }
			]
		};
	}
}
