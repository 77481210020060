/*
 * Project: Dynamic Media Angular Libraries
 * Author: Dynamic Media S.R.L.
 * Copyright © 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DMFunctions } from '@dynamicmedia/shared';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

// @dynamic
export class EjsPhoneInputValidation {
  public static validNumber(required: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (DMFunctions.isNullOrEmpty(control.value))
        return required ? { required: true } : null;
      const parser = parsePhoneNumberFromString(control.value);
      return parser && parser.isValid() ? null : { invalidNumber: true };
    }
  }
}
