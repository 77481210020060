/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { HttpResponse } from "@angular/common/http";
import { saveAs } from 'file-saver';

export abstract class DMFunctions {
	// general functionality
	public static loadScript(url: string) {
		const body = document.body as HTMLDivElement;
		const script = document.createElement('script');
		script.innerHTML = '';
		script.src = url;
		script.async = false;
		script.defer = true;
		body.appendChild(script);
	}

	// object helpers
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static deepCopy(oldObj: any) {
		let newObj = oldObj;
		if (oldObj && typeof oldObj === 'object') {
			if (oldObj instanceof Date)
			 return new Date(oldObj.getTime());
			newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
			for (const i in oldObj)
				newObj[i] = this.deepCopy(oldObj[i]);
		}
		return newObj;
	}

	// number helpers
	public static getRandomInteger(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min) ) + min;
	}

	// string helpers
	public static titleCase(value: string) {
		if (value === null)
			return null;
		value = value.trim();
		// set first letter to uppercase
		value = value[0].toUpperCase() + value.slice(1);
		// set subsequent words to titlecase
		for (let i = 0; i < value.length; i++) {
			if ((value[i] === ' ' || value[i] === '-') && value.length > i)
				value = value.slice(0, i + 1) + value[i + 1].toUpperCase() + value.slice(i + 2);
		}
		return value;
	}

	// date helpers
	public static addMonths(date: Date, months: number, isContract: boolean = true): Date {
		const targetMonth = date.getMonth() + months;
		const year = Math.floor(date.getFullYear() + (targetMonth / 12));
		const month = targetMonth % 12;
		let day = date.getDate();
		const lastDay = new Date(year, month + 1, 0).getDate();
		if (day > lastDay)
				day = lastDay;
		const newDate = new Date(year, month, day);
		if (isContract)
			newDate.setDate(newDate.getDate() - 1);
		else
			newDate.setDate(newDate.getDate());
		return newDate;
	}

	public static removeMonths(date: Date, months: number, isContract: boolean = true): Date {
		const targetMonth = date.getMonth() - months;
		const year = date.getFullYear();
		const month = targetMonth % 12;
		let day = date.getDate();
		const lastDay = new Date(year, month + 1, 0).getDate();
		if (day > lastDay)
			day = lastDay;
		const newDate = new Date(year, month, day);
		if (isContract)
			newDate.setDate(newDate.getDate() + 1);
		else
			newDate.setDate(newDate.getDate());
		return newDate;
	}

	public static dateFormat(date: Date, withTime = false): string {
		if (!(date instanceof Date))
			return date;
		const day = date.getDate();
		const month = date.getMonth() + 1;
		let time = '';
		if (withTime)
			time = ` ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
		return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${date.getFullYear()}${time}`;
	}

	public static dateFormatIso(date: Date, withTime = false): string {
		if (!(date instanceof Date))
			return date;
		const day = date.getDate();
		const month = date.getMonth() + 1;
		let time = '';
		if (withTime)
			time = ` ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
		else
			time = '00:00:00';
		return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T${time}Z`;
	}

  public static isNullOrEmpty(value: unknown) {
    return value === undefined || value === null || (typeof value === 'string' && value.length == 0);
  }

  public static uuidv4() {
    function randomDigit() {
      if (crypto && crypto.getRandomValues) {
        const rands = new Uint8Array(1);
        crypto.getRandomValues(rands);
        return (rands[0] % 16).toString(16);
      } else {
        return ((Math.random() * 16) | 0).toString(16);
      }
    }
    const crypto = window.crypto;
    return 'xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx'.replace(/x/g, randomDigit);
  }

  public static htmlToText(html: string): string | null {
	  if (this.isNullOrEmpty(html))
	    return null;
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || null;
  }

  public static generateRandomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ )
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
  }

	// #region Document utilities
  public static getFileNameFromResponseContentDisposition(res: HttpResponse<Blob>) {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename="([^;]+)"/ig.exec(contentDisposition);
    if (!matches)
      return null;
    return (matches[1] || 'untitled').trim().replace('\'', '').replace('"', '');
  }

  public static saveFile(blobContent: Blob, fileName: string) {
    saveAs(new Blob([blobContent], { type: 'application/octet-stream' }), fileName);
  }
	//#endregion
}
