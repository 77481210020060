import { ProLexApps } from '../enums';

export class LoginRequestModel {
	public email?: string;
	public password?: string;
	public app?: number;

	constructor(email: string, password: string, app: ProLexApps) {
		this.email = email;
		this.password = password;
		this.app = app;
	}
}
