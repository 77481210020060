import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ProgressButton, SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { ReactiveFormsValidation } from '@dynamicmedia/shared';
import { DialogService } from '@dynamicmedia/syncfusion';
import { AuthenticationService, BackendResponse, Functions } from '@prolex/shared';
import { paths } from '../../app-paths';
import { TranslationLoaderService } from '../../shared/services';
import { locale as romanian } from './i18n/ro';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'members-forget-password',
  templateUrl: './forget-password.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgetPasswordComponent implements OnInit {
	// Components
	@ViewChild('resetButton') private _resetButton: ProgressButton;
	@ViewChild('loginButton') private _loginButton: ProgressButton;
	// Public members
  public forgotPasswordForm: FormGroup;
  public spinSettings = {
  	position: 'Right',
	  width: 20,
	  template:
		  `<div class="sk-fading-circle sk-fading-circle-round-button">
				<div class="sk-circle1 sk-circle"></div>
				<div class="sk-circle2 sk-circle"></div>
				<div class="sk-circle3 sk-circle"></div>
				<div class="sk-circle4 sk-circle"></div>
				<div class="sk-circle5 sk-circle"></div>
				<div class="sk-circle6 sk-circle"></div>
				<div class="sk-circle7 sk-circle"></div>
				<div class="sk-circle8 sk-circle"></div>
				<div class="sk-circle9 sk-circle"></div>
				<div class="sk-circle10 sk-circle"></div>
				<div class="sk-circle11 sk-circle"></div>
				<div class="sk-circle12 sk-circle"></div>
			</div>` } as SpinSettingsModel;
  public spinDuration = 12000;
  // Private members
  private _submitIsSpinning = false;

  //#region Form controls
  public get emailControl(): AbstractControl {
    return this.forgotPasswordForm && this.forgotPasswordForm.get('email');
  }
  //#endregion

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _translationLoaderService: TranslationLoaderService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef)
  {
    if (this._authenticationService.isAuthenticated()) {
      void this._router.navigate([paths.admin.fullPath]);
      return;
    }
    this._translationLoaderService.loadRomanianTranslations(romanian);
    this.forgotPasswordForm = this._formBuilder.group({
      email: [null, [ReactiveFormsValidation.required, Validators.email]]
    });
    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this._changeDetector.detectChanges();
    });
  }

  public ngOnInit(): void {
    const emailParameter = new URLSearchParams(window.location.search).get('email');
    if (emailParameter !== null && emailParameter !== undefined && emailParameter.length > 0)
      this.forgotPasswordForm.setValue({ email: emailParameter });
  }

	public resetClicked(): void {
    if (this._submitIsSpinning)
      return;
    this._resetButton.start();
    this._submitIsSpinning = true;
    this._authenticationService.forgotPassword(this.emailControl.value as string)
			.pipe(
				finalize(() => {
					this._resetButton.progressComplete();
					this._submitIsSpinning = false;
				})
			).subscribe({
        next: (result: BackendResponse) => {
          this._dialogService.information(result.message, () => {
            this.spinDuration = 0;
            setTimeout(() => void this._router.navigate([paths.login.fullPath]), 200);
          }).show();
        },
        error: (e) => {
          this._dialogService.error(Functions.handleError(e));
        }
			});
  }

  public contractBegin() {
    this._resetButton.element.classList.add('e-round');
    this.emailControl.disable();
    this._loginButton.disabled = true;
  }

  public contractEnd() {
    this._resetButton.element.classList.remove('e-round');
    this.emailControl.enable();
    this._loginButton.disabled = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoLoginPage(event: any): void {
    event.preventDefault();
    const params: NavigationExtras = { relativeTo: this._route.parent };
    const emailParam: string | null = this.emailControl.value;
    if (emailParam !== null && emailParam !== undefined && emailParam.length > 0)
      params.queryParams = { email: emailParam };
    void this._router.navigate([ paths.login.fullPath ], params);
  }
}
