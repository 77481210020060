/*
 * Project: Dynamic Media Shared Components & Functionality
 * Author: Dynamic Media S.R.L.
 * Copyright © 2018 - 2021 Dynamic Media S.R.L. All rights reserved.
 *
 * Any use or reproduction of this source code is prohibited
 * without the explicit consent by Dynamic Media S.R.L.
 */

import { Inject, Injectable } from '@angular/core';
import CryptoAes from 'crypto-js/aes';
import CryptoEnc from 'crypto-js/enc-utf8';
import { RememberMeServiceModel } from './remember-me-service.model';
import { RememberMeConfigService, RememberMeServiceConfig } from './remember-me-service.config';

@Injectable()
export class RememberMeService {
  constructor(@Inject(RememberMeConfigService) private readonly config: RememberMeServiceConfig)
  {
    this.config = this.config || { localStorageKey: 'rememberMeStorageKey', encryptionKey: '29AAF686' };
  }

	public getData(): RememberMeServiceModel | null {
		const rawModel = localStorage.getItem(this.config?.localStorageKey as string);
		if (rawModel == null)
			return null;
		const model = JSON.parse(rawModel) as RememberMeServiceModel;
		if (model.rememberMe) {
			try {
				model.password = CryptoAes.decrypt(model.password, this.config?.encryptionKey as string).toString(CryptoEnc);
				return model;
			}
			catch {
				return null;
			}
		}
		return null;
	}

	public setData(email: string, password: string): void {
		const model = {
			rememberMe: true,
			email: email,
			password: CryptoAes.encrypt(password, this.config?.encryptionKey as string).toString()
		} as RememberMeServiceModel;
		localStorage.setItem(this.config?.localStorageKey as string, JSON.stringify(model));
	}

	public clear(): void {
		localStorage.removeItem(this.config?.localStorageKey as string);
	}
}
